import React, { useState, useEffect } from 'react';
import { authenticatedFetch } from '../utils/auth';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Section, ErrorText } from '../constants/StyleComponents';
import { useForm } from "react-hook-form";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import outreach from '../assets/outreach.png'

const Link = styled('a')({
  cursor: 'pointer',
})

export default function AddEditCEO({open,close,ceoDetails,setCeoDetails,setWarning,hideSequences=false,setCeoEdited}) {
  const { register, handleSubmit, errors, getValues, setValue } = useForm();
  const [sequenceList, setSequenceList] = useState([])
  const [sequences, setSequences] = useState([])
  const [seqValue, setSeqValue] = useState('')
  const [oid, setOid] = useState(null)

  useEffect(() => {
     async function getSequences() {
       let url = `/companies/api/sequences/${ceoDetails['companyId']}`
       let res = await authenticatedFetch(url, {method: 'GET'})
       setSequenceList(res)
     }
     ceoDetails['companyId'] && open && getSequences()
     setOid(ceoDetails['outreach_id'])
  }, [ceoDetails,open]);

  useEffect(() => {
    async function sequences() {
      const res = await authenticatedFetch(`/companies/api/outreach/${oid}/sequences`, {method: 'GET'})
      setSequences(res.sequences)
    }
    if (oid){
      sequences()
    } else {
      setSequences([])
      setSeqValue('')
    }
  },[oid])

  const doLookup = () => {
	let fn = getValues().firstname
	let ln = getValues().lastname
  
	async function getEmail() {
     setWarning({text:"Searching...",severity:'info'})
	   let res = await authenticatedFetch(`/companies/api/person/email`, {
	     method: 'POST', 
	     body: JSON.stringify({
	       cid: ceoDetails.companyId,
	       fn: fn,
	       ln: ln,
	     })
	   })
	   setValue('email',res['email'])
	   if (res['email'] === ''){
      setWarning({text:"Sorry, no email found.",severity:'error'})
	   } else {
	     setWarning({text:"Success!",severity:'success'})
	   }
	}
		getEmail()
  }
  
  const onCEOSubmit = (ceo) => {	  
    setWarning({text:'',severity:''})
	  if (seqValue && !ceo.email){
	  	alert("Can't add to sequence without an email")
      return
	  }
    close()
    if (setCeoEdited){setCeoEdited(true)}
    
    
    let newCeo = {...ceoDetails,...ceo,...{sequence:seqValue,name:`${ceo.firstname} ${ceo.lastname}`}}
    async function fetchData() {
        let url, method
       setWarning({text:'Saving...',severity:'info'})
        if (ceoDetails.id){
          url = `/companies/api/person/${ceoDetails.id}`
          method = 'PATCH'
        } else {
          url = `/companies/api/company/${ceoDetails.companyId}/person`
          method = 'POST'
        }
      
        let resp = await authenticatedFetch(url, {
          method: method,
          body: JSON.stringify(newCeo)
        })
        let combo = {...ceoDetails,...resp}
        setCeoDetails(combo)
        if (!resp.id){
          setWarning({text:'CEO not saved',severity:'error'})
        }
        if (resp.email) {
          if (seqValue) {
            setWarning({text:'CEO saved, adding to Outreach...please wait for result',severity:'success'})
            toOutreach(resp)
          } else {
            setWarning({text:'CEO saved, no sequence selected – will not be added to Outreach',severity:'warning'})
          }
        } else {
          setWarning({text:'CEO saved, no email – will not be added to Outreach',severity:'warning'})
        }
    }
    async function toOutreach(response) {
        let resp = await authenticatedFetch(`/companies/api/person/outreach`, {
          method: 'POST',
          body: JSON.stringify({
            pid: response.id,
            seq: seqValue,
            cid: ceoDetails.companyId
          })
        })
        setOid(null)
        if(resp.oid && !resp.added.includes('Error')){
          setWarning({text:resp.added,severity:'success'})
        } else {
          setWarning({text:resp.added,severity:'error'})
        }
        setCeoDetails({...ceoDetails,...resp.ceo})
        
    }
    fetchData()
  }

  return (
    <Dialog open={open} onClose={close}>
      <form onSubmit={handleSubmit(onCEOSubmit)}>   
        <DialogTitle>Edit CEO</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update CEO name, email, and LinkedIn URL here. Note that background will take some time to update following changes to the profile URL.
          </DialogContentText>
                  <Section>
                    <TextField
                      inputRef={register({required: true})}
                      name="firstname"
                      label="First Name"
                      fullWidth
                      defaultValue={ceoDetails['firstname']}
                    />
                    {errors.firstname && <ErrorText>This field is required</ErrorText>}
                  </Section>
                  <Section>
                    <TextField
                      inputRef={register({required: true})}
                      name="lastname"
                      label="Last Name"
                      fullWidth
                      defaultValue={ceoDetails['lastname']}
                    />
                    {errors.lastname && <ErrorText>This field is required</ErrorText>}
                  </Section>
                  <Section>
                    <TextField
					            inputRef={register({validate:() => (getValues('url').includes('linkedin.com/in/'))})}
                      name="url"
                      label="LinkedIn Profile (must contain linkedin.com/in/)"
                      fullWidth
                      defaultValue={ceoDetails['url']}
                    />
                     {errors.url && <ErrorText>Required, must contain linkedin.com/in/</ErrorText>}
                  </Section>
                  <Section>
                    <TextField
                      inputRef={register}
                      name="email"
                      fullWidth
                      placeholder={!ceoDetails['email'] ? 'No Email' : ''}
                      defaultValue={ceoDetails['email'] || ''}
					            style={{maxWidth:'80%'}}
                    />
		            <Button onClick={doLookup} color="primary">
		              Lookup
		            </Button>
                  </Section>
          {!hideSequences && 
            <Box disabled>
              <Section>
                <DialogContentText>Outreach</DialogContentText>
                {sequences.map((seq,ind) =>
                  <Stack spacing={0} sx={{flexWrap:'wrap'}} key={ind}>
                    <Typography variant="button" component='div' color='primary'>{seq.sequence} <Link target="_blank" style={{marginLeft:3}} href={`//app1a.outreach.io/prospects/${ceoDetails.outreach_id}/sequences/${seq.id}`}><img style={{width:22}} src={outreach} alt="Outreach"/></Link></Typography> 
                    <Typography variant="body" component='div' color='primary'>Status: {seq.status}</Typography> 
                    <Typography variant="body"  component='div' color='primary' sx={{marginBottom:2}}>{seq.step ? `Step: ${seq.step}` : `Replied: ${seq.replied}`}</Typography> 
                  </Stack>
				         )}
              </Section>
              <Section>
                <InputLabel>Add to Sequence</InputLabel>
                <Select
                  style={{width:'300px'}}
                  value={seqValue}
                  onChange={(e)=>setSeqValue(e.target.value)}
                >
                  {sequenceList.filter((s)=>!sequences.map((x)=>x.sequence).includes(s.name)).map((s,sIndex) =>
                    <MenuItem key={sIndex} value={`${s.oid}`}>{s.name} ({s.user})</MenuItem>
                  )}
                </Select>
              </Section>
             </Box>}
        </DialogContent>
        <DialogActions>

          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button type='submit' color="primary">
            Save
          </Button>
        </DialogActions>
      </form>       
    </Dialog>
  )
}