import React from 'react';
import { authenticatedFetch } from '../utils/auth';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Section } from '../constants/StyleComponents';
import { useForm } from "react-hook-form";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

const InputSection = styled(Section)({
  paddingLeft: 25,
  paddingRight: 25
})


export default function NoteBox({open,close,companyId, assignment}) {
  const { register, handleSubmit } = useForm()
  
  const onSubmit = (form) => {
    async function sendNotification(){
      await authenticatedFetch(`/companies/api/company/${companyId}/assign`,{
        method:'POST',
        body: JSON.stringify({
          note: form.note,
		  assigner: assignment.assigner,
		  assignee: assignment.assignee
        })
      })
    }
	sendNotification()
	close()
  }
  
  return (
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth={'md'}>
        <form onSubmit={handleSubmit(onSubmit)}>   
        <DialogTitle>Add a note?</DialogTitle>
          <InputSection>
            <TextField
              inputRef={register({required: true})}
              multiline={true}
              rows={3}
              name="note"
              label={"Tell " + assignment.assignee + " about this assignment"}
              variant="filled"
              fullWidth
            />
          </InputSection>
          <DialogActions>
            <Button onClick={close} color="primary">
              Skip
            </Button>
            <Button type='submit' color="primary">
              Send
            </Button>
          </DialogActions>
        </form>       
    </Dialog>
  )
}