import React, { useState, useEffect } from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis } from 'recharts';
import sv from '../../constants/Styles';
import Box from '@mui/material/Box';
import {daysSince,calculateTimingScore} from '../../utils/tools';

function ScoreChart({history,data,parentWidth}) {
  
  const [chartData, setChartData] = useState([])
  const [timingScore, setTimingScore] = useState(null)
  
  function scoreIcon(score){
    if (score === null){return null}
    if (score >= 90) {
      return sv.colors.blue1
    } else if (score < 50){
      return sv.colors.blue3
    }
    else {
      return sv.colors.blue2
    }
  }
  
  useEffect(()=>{
      setChartData([
        {name:`Growth: ${data.growth_score !== null ? data.growth_score : 'N/A'}`,val:data.growth_score || 0},
        {name:`Retention: ${data.retention_score !== null ? data.retention_score : 'N/A'}`,val:data.retention_score || 0},
        {name:`Investor: ${data.investor_score !== null ? data.investor_score : 'N/A'}`,val:data.investor_score || 0},
        {name:`Timing: ${timingScore !== null ? timingScore : 'N/A'}`,val:timingScore || 0},
        {name:`Market: ${data.social_score !== null ? data.social_score : 'N/A'}`,val:data.social_score || 0},
        {name:`Team: ${data.employee_score !== null ? data.employee_score : 'N/A'}`,val:data.employee_score || 0},
        {name:`CEO: ${data.ceo_score !== null ? data.ceo_score : 'N/A'}`,val:data.ceo_score || 0},
      ])
  },[data,timingScore])
  
  useEffect(()=>{
    setTimingScore(calculateTimingScore(daysSince(data.latest_round_date)))
  },[data])
  
  const CustomTick = (props) => {
    return (
      <text
        x={props.x}
        y={props.y}
        fill={scoreIcon(props.payload.value.split(':')[1])}
        textAnchor={props.textAnchor}
        fontSize={14}
        fontWeight="bold"
        transform={props.transform}
      >
        {props.payload.value}
      </text>
    );
  };
  
  return (
      <Box sx={{borderBottomWidth:1,borderColor:sv.colors.blue1,marginTop:2,marginBottom:2,height:300,width:450}}>
        {chartData.length > 0 &&
          <RadarChart height={300} width={450} margin={0} data={chartData}>
            <PolarGrid />
            <PolarAngleAxis dataKey="name" tick={<CustomTick />}/>
            <Radar dataKey="val" stroke={sv.colors.blue1} fill={sv.colors.blue2} fillOpacity={0.4} />
          </RadarChart>
        }
      </Box>
  );
}

export default ScoreChart;