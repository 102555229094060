import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Section, ErrorText } from '../constants/StyleComponents';
import { useForm } from "react-hook-form";

export default function ContactEdit({open,close,person,contactList,setContactList,contactSelected,setContactSelected}) {
  const { register, handleSubmit, errors } = useForm();
  
  const onSubmit = (p) => {
    close()    
    let contacts = [...contactList]
    if (person['id']){
      p.id = Number(person['id'])
      let obj = contactList.filter((x) => x.id === p.id)[0]
      let objIndex = contactList.indexOf(obj)
      let repl = {...obj,...p}
      contacts[objIndex] = repl
    } else {
      p.id = Math.floor(Math.random()*100000) + 10000000
      p.custom = true
      while (contactSelected.includes(p.id)){
        p.id = Math.floor(Math.random()*100000) + 10000000
      }
      contacts.push(p)
      let selected = [...contactSelected]
      selected.push(p.id)
	  
      setContactSelected(selected)
    }
	
    setContactList(contacts)
  }

  return (
    <Dialog open={open} onClose={close} fullWidth={true}>
      <form onSubmit={handleSubmit(onSubmit)}>   
        <DialogTitle>{person.id ? "Edit Contact" : "Add Contact"}</DialogTitle>
        <DialogContent>
                  <Section>
                    <TextField
                      inputRef={register({required: true})}
                      name="employee__person__name"
                      label="Name"
                      fullWidth
                      defaultValue={person.name}
                    />
                    {errors.name && <ErrorText>This field is required</ErrorText>}
                  </Section>
                  {!person.id &&
                  <Section>
                    <TextField
                      inputRef={register({required: true})}
                      name="employee__company__name"
                      label="Company"
                      fullWidth
                    />
                    {errors.company && <ErrorText>This field is required</ErrorText>}
                  </Section>
                  }
                  {!person.id &&
                  <Section>
                    <TextField
                      inputRef={register({required: true})}
                      name="employee__company__website"
                      label="Company Website"
                      fullWidth
                    />
                  {errors.company_website && <ErrorText>This field is required</ErrorText>}
                </Section>
                  }
                  <Section>
                    <TextField
                      inputRef={register({required: true})}
                      name="employee__title"
                      label="Title"
                      fullWidth
                      defaultValue={person.title}
                    />
                      {errors.title && <ErrorText>This field is required</ErrorText>}
                   </Section>
                  <Section>
                    <TextField
                      inputRef={register({required: true})}
                      name="employee__person__url"
                      label="Profile"
                      fullWidth
                      defaultValue={person.url}
                    />
                    {errors.url && <ErrorText>This field is required</ErrorText>}
                  </Section>
                  {!person.id &&
                  <Section>
                    <TextField
                      inputRef={register()}
                      name="person__name"
                      label="Connection"
                      fullWidth
                    />
                  </Section>
                  }
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button type='submit' color="primary">
            Save
          </Button>
        </DialogActions>
      </form>       
    </Dialog>
  )
}