import React, {useState,useEffect} from 'react';
import TableBase from './TableBase';
import sv from '../constants/Styles';
import { styled } from '@mui/material/styles';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import {Text} from '../constants/StyleComponents';
import Language from '@mui/icons-material/Language';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useGridApiRef,  GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";
import {fundings} from '../utils/tools'
import {listOperators} from './helpers/Filters'
import {formatMMDDYYYY} from '../utils/tools'
import {stringOperators,companySearch,dateOperators,multiInputOperators} from './helpers/Filters'

const Name = styled('a')({
  cursor:'pointer',
})

const Link = styled('a')({
  marginLeft: 5,
  textDecoration: null,
  fontSize: sv.bodyText.fontSize,
  cursor: 'pointer',
})

const StyledTip = styled(Tooltip)({
  marginLeft:5,
})

const TipText = styled(Text)({
  color:'white',
})

export default function FullEmployeeList({history,path,name}) {
  const [data,setData] = useState([])
  const apiRef = useGridApiRef();
  const [warning,setWarning] = useState({text:'',severity:''})
  const [fundingList, setFundingList] = useState([])
  
  useEffect(()=>{
  	fundings().then((v) => setFundingList(JSON.parse(v)))
  },[])
	
  const columns = 
  [
    { headerName: 'Name', field: 'name', width:180, renderCell: (params) => (
      <Name href={`//${params.row.url}`.replace('https://','').replace('http://','')} target="_blank">{params.row.name}<LinkedInIcon style={{ fontSize: sv.bodyText.fontSize, color:"0F4EB5", marginLeft:5}}/></Name>
    )},
    { headerName: 'LI URL', field: 'url', hide:true,sortable:false,filterable:false},
    { headerName: 'Company', field: 'company_name',width:180,filterOperators:companySearch.concat(stringOperators.filter((op) => op.value === "contains")),renderCell:(params) => 
    (
      <Box component='span'>
      <Link href={`/company/${params.row.company_id}`} target="_blank">{params.row.company_name}</Link>
    {(params.row.company_description || params.row.li_shortdesc) &&
    <StyledTip title={<TipText>{params.row.company_description || params.row.li_shortdesc || ''}</TipText>} placement='top'>
      <InfoOutlinedIcon style={{ fontSize: sv.bodyIcon.fontSize}}/>
    </StyledTip>
    }
        <Link
          href={`//www.${params.row.company_website}`}
          target="_blank"
        >
          <Language style={{ fontSize: sv.bodyIcon.fontSize }}/>
        </Link>
      </Box>)},
    { headerName: 'Title', field: 'title', sortable:false, width:150},
    { headerName: 'Seniority', field: 'seniority', width:150},
    { headerName: 'Company Status', field: 'company_status', width:150},
    { headerName: 'Location', field: 'location', width:180},
    { headerName: 'Latest Round', field:'company_latest_round',filterOperators:multiInputOperators(fundingList),valueGetter: (p) => p.value?.parent ? `${p.value.parent} (${p.value.name})` : p.value?.name},
    { headerName: 'Latest Round Date', field:'company_latest_round_date',filterOperators: dateOperators,valueFormatter: (params) => formatMMDDYYYY(params.value)},
    
	{ headerName: 'List', field:'listing',hide:true,filterOperators:listOperators},
  ] 
  
  const [cols, setCols] = useState([])
  const [defaultColumns, setDefaultColumns] = useState([])
  useEffect(()=>{
	  setCols(columns)
	  setDefaultColumns(columns)
     // eslint-disable-next-line
  },[fundingList])
  
    
  return (
      <>
        <TableBase
          data={data}
		      selection={true}
          setData={setData}
          pinnedColumns={{left: [GRID_CHECKBOX_SELECTION_COL_DEF.field,'name']}}
          history={history}
          path={'employees'}
          columns={cols}
		      setColumns={setCols}
		      defaultColumns={defaultColumns}
          name={'All People'}
          warning={warning}
  		    setWarning={setWarning}
          type={"Employees"}
		      apiRef={apiRef}
        />
      </>
  );
}