import React, {useState,useEffect,useRef} from 'react'
import Header from './Header'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import sv from '../constants/Styles';
import TextField from '@mui/material/TextField';
import { authenticatedFetch } from '../utils/auth';
import Grid from '@mui/material/Grid';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {formatMMDDYYYY} from '../utils/tools';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CachedIcon from '@mui/icons-material/Cached';

const Content = styled(Container)({
    width:'100%',
    padding:0,
    marginTop:20,
    marginLeft:0,
    marginRight:0
})

const CommentItem = styled(Paper)({
  minHeight:30,
  marginBottom: 30,
  padding: 10,
})

const MessageContainer = styled(Container)({
    width:1000,
    height:500,
    overflow:'scroll',
    paddingTop:5
})

const LiMsg = styled(TextField)({
    marginTop:10,
    width:'100%',
    align:'center'
})

export default function Messaging({history}) {
  const [fellows, setFellows] = useState([])
  const [openId, setOpenId] = useState(null)
  const [messages, setMessages] = useState([])
  const [fellow, setFellow] = useState(null)
  const [contact, setContact] = useState(null)
  const [comment, setComment] = useState('')
  const scrollRef = useRef(null)
  const [threadId, setThreadId] = useState(null)

  const handleClick = (fellow) => {
    if (openId === fellow.id){
        setOpenId(null)
        setFellow(null)
    } else {
        setOpenId(fellow.id)
        setFellow(fellow.name)
    }
  }
  
  useEffect(()=>{
      if (scrollRef.current){
          scrollRef.current.scrollIntoView({behavior:'smooth'})
      }
  },[messages])
  
  const handleSend = () => {
      async function send() {   
   	   let res = await authenticatedFetch(`/companies/api/messages`, {
   	     method: 'POST', 
   	     body: JSON.stringify({
   	       text: comment,
           thread: threadId
   	     })
   	   })
       setMessages(res)
     }
     send()
  }
    
   const handleThreadClick = (thread) => {
       setMessages(thread.messages)
       setContact(thread.lead.firstname + " " + thread.lead.lastname)
       setThreadId(thread.id)
   }
       
  useEffect(() => {
      async function get() {
          let res = await authenticatedFetch('/companies/api/leads', {method: 'GET'})
          setFellows(res.results)
       }
       get()
  },[])
  
  return <>
    <Header 
      history={history}
      name={'Messaging'}
    />
    <Content>
    <Grid container spacing={20}>
    <Grid item xs={3}>
        <List
          subheader={<ListSubheader>Inbox</ListSubheader>}
          sx={{width:300}}
        >
          {fellows.map((fellow,i) =>
              <React.Fragment key={i}>
                <ListItemButton key={i}>
                  <ListItemText primary={fellow.name} onClick={()=>handleClick(fellow)}/>
                  {openId === fellow.id ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openId === fellow.id}>
                  {fellow.threads.map((thread,id) =>
                        <ListItemButton sx={{ pl: 4 }} key={id}>
                          <ListItemText onClick={()=>handleThreadClick(thread)} primary={`${thread.lead.firstname} ${thread.lead.lastname}`} />
                          <LinkedInIcon onClick={()=>window.open(thread.lead.url)} style={{ fontSize: sv.bodyText.fontSize * 1.4, color:"0F4EB5" }}/>
                        </ListItemButton>
                  )}
                </Collapse>
              </React.Fragment>
          )}
        </List>
    </Grid>
    <Grid item xs={9}>
          {fellow && contact &&<h4>Conversation between {fellow} and {contact}</h4>}
          <MessageContainer sx={{border:1}}>
              {messages.filter((m)=>m.text !== '').map((m,ind) =>
                 <Box key={ind}>
                    <Box sx={{textAlign:'center',marginBottom:2}}>{(ind === 0 || formatMMDDYYYY(m.created_at) !== formatMMDDYYYY(messages[ind-1].created_at)) && formatMMDDYYYY(m.created_at)}</Box>
                  <Grid container key={ind} justifyContent='space-between' direction={m.direction === 'outgoing' ? 'row-reverse' : 'row'}>
                    <Grid item sx={{maxWidth:'70%'}}>
                        <CommentItem 
                            sx={{maxWidth:'93%',backgroundColor:m.direction === 'incoming' ? '#d3d3d3' : '#06283c',color:m.direction === 'outgoing' && 'white',float:'left',display:'inline'}}
                        >
                            {m.text}
                        </CommentItem>
                      {m.pending && <CachedIcon sx={{float:'left',display:'inline'}}/>}
                    </Grid>
                  </Grid>
                </Box>
              )}
              <Box ref={scrollRef} />
        </MessageContainer>
        <Grid container alignItems="center">
         <Grid item xs={10}>
             <LiMsg
                label="New Message"
                multiline
                rows={4}
                value={comment}
                onChange={(e)=>setComment(e.target.value)}
                component='span'
             />
         </Grid>
         <Grid item xs={2}>
             <Button variant="contained" color="secondary" size="large" sx={{marginLeft:5}} onClick={handleSend}>Send</Button>
         </Grid>
      </Grid>
    </Grid>
    </Grid>
   </Content>
  </>;
}