import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Text, Section } from '../constants/StyleComponents';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { authenticatedFetch } from '../utils/auth';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const ErrorText = styled(Text)({
  color:'red',
  fontStyle:'italic',
  marginTop: 10,
  marginLeft: 5
})

export default function ChartMilestone({open,close,id,companyName,setRefreshGraphData,fromCompany=true}) {
  const [type,setType] = useState('metric')
  const [metric, setMetric] = useState('')
  const [text, setText] = useState('')
  const [metricValue,setMetricValue] = useState('')
  const [error, setError] = useState('')
  const [date, setDate] = useState(new Date())
    
  const handleSubmit = () => {
    if (date === null){
      setError('Date is required')
      return
    }
    if (type === 'metric' && (metric === '' || metricValue === null)){
      setError('Must specify metric')
      return
    }
    if (type === 'metric' && metricValue === ''){
      setError('Must specify metric value')
      return
    }
    if (type === 'milestone' && text === ''){
      setError('Must specify milestone')
      return
    }

    let url = `/companies/api/company/${id}/milestone`
    async function saveCompany() {
      await authenticatedFetch(url, {
              method: 'POST',
              body: JSON.stringify({
                type:type,
                text: text,
                value: metric,
                metricValue: metricValue,
                date: date
              })
            })
            fromCompany && setRefreshGraphData(true)
            setMetric('')
            setType('metric')
            setText('')
            setMetricValue('')
            setError('')
            setDate(null)
        }
    saveCompany()
    close()
  }
  
  return (
      <Dialog 
        open={open}
        onClose={close}
        fullWidth={true}
        maxWidth={'sm'}
      >
        {error && <ErrorText>{error}</ErrorText>}
        <DialogTitle>Add Data for {companyName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add a key milestone (used to track one-time event) or metric (used to track the same data over time) to this company
          </DialogContentText>
              <Section>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        minDate={new Date().setFullYear(new Date().getFullYear()-5)}
                        maxDate={new Date()}
                        views={['year', 'month']}
                        label="Select Date"
                        value={date}
                        onChange={(newValue) => {
                          setDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                </Section>
              <Section>
                <InputLabel>Select Type</InputLabel>
                <Select
                  style={{width:300}}
                  value={type}
                  onChange={(e)=>setType(e.target.value)}
                >
                  <MenuItem key={0} value={`metric`}>Metric</MenuItem>
                  <MenuItem key={1} value={`milestone`}>Milestone</MenuItem>
                </Select>
              </Section>              
              {type === 'metric' ?
              <>
              <Section>
                <Select
                  style={{width:300}}
                  value={metric}
                  onChange={(e)=>setMetric(e.target.value)}
                >
                  {['Annual Revenue ($M)','ARR ($M)','COGS ($)','Customer LTV ($)','Future Expectations','MAUs','MRR ($M)','NPS','Past Performance','Weighted Pipeline Value ($M)'].map((m,i)=>(
                    <MenuItem key={i} value={m}>{m}</MenuItem>
                  ))}
                </Select>
              </Section>
              <Section>
              <TextField
                  label='Metric Value'
                  value={metricValue}
                  type='number'
                  sx={{width:500}}
                  onChange={(e)=>setMetricValue(e.target.value)}
                />
                </Section>
              </>
              :
              <Section>
              <TextField
                  label='Enter Milestone'
                  maxRows={8}
                  minRows={2}
                  multiline
                  value={text}
                  sx={{width:500}}
                  onChange={(e)=>setText(e.target.value)}
                />
                </Section>}
        
        
        
        
        
                  
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
