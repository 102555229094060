import React, {useState,useEffect} from 'react'
import Header from './Header'
import { authenticatedFetch } from '../utils/auth';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import 'react-csv-importer/dist/index.css';
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CsvDownloader from 'react-csv-downloader';

export default function AutoOutreach({history}) {
  const [warning,setWarning] = useState({text:'',severity:''})
  const [loading, setLoading] = useState(false)
  const [searches, setSearches] = useState([])
  
  useEffect(() => {
     async function getSearches() {
       let url = `/companies/api/lisearches`
       let res = await authenticatedFetch(url, {method: 'GET'})
       setSearches(res.results)
     }
     getSearches()
  }, []);

  const getData = (sid) => {
    setLoading(true)
    let resp = authenticatedFetch( `/companies/api/lisearches/${sid}/download`, {method: 'POST'})
    setLoading(false)
    return Promise.resolve(resp)
  };
  
  return <>
  <Header 
    history={history}
    name={'Automated LI'}
    warning={warning}
    setWarning={setWarning}
  />
	<Grid alignItems='center' justifyContent='center' sx={{marginTop:5}}>
      <Box sx={{height:500,width:'80%',marginLeft:'10%',marginTop:5}}>
        <Typography variant="h6" color='primary' sx={{textAlign:'center'}}>Automated Searches</Typography>
        <Table>
            <TableHead>
              <TableRow>
                 <TableCell>Name</TableCell>
                 <TableCell>User</TableCell>
                 <TableCell>URL</TableCell>
                 <TableCell>Is List</TableCell>
                 <TableCell>Per Day</TableCell>
                 <TableCell>Sequence</TableCell>
                 <TableCell>Count</TableCell>
                 <TableCell>Accepted</TableCell>
                 <TableCell>Withdrawn</TableCell>
                 <TableCell>Downloaded</TableCell>
                 <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
             {searches.map((s,i) => 
               <TableRow key={i}>
                 <TableCell>{s.name}</TableCell>
                 <TableCell>{s.fellow}</TableCell>
                 <TableCell>{s.url}</TableCell>
                 <TableCell>{s.is_list ? 'Yes' : 'No'}</TableCell>
                 <TableCell>{s.perday}</TableCell>
                 <TableCell>{s.sequence}</TableCell>
                 <TableCell>{s.thread_count}</TableCell>
                 <TableCell>{s.thread_accepted}</TableCell>
                 <TableCell>{s.thread_withdrawn}</TableCell>
                 <TableCell>{s.thread_downloaded}</TableCell>
                 <TableCell>{s.thread_withdrawn - s.thread_downloaded}<CsvDownloader
                       datas={()=>getData(s.id)}
                       filename={s.name}
                >{loading ? 'Loading...' :<FileDownloadIcon sx={{cursor:'pointer'}}/>}</CsvDownloader></TableCell>
               </TableRow>
             )}
            </TableBody>
         </Table>
      </Box>
	</Grid>

  </>
}
