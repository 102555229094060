import React, { useState, useEffect } from 'react';
import { authenticatedFetch } from '../utils/auth';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Section, ErrorText } from '../constants/StyleComponents';
import { useForm } from "react-hook-form";
import Typography from '@mui/material/Typography';
import sv from '../constants/Styles';
import Alert from '@mui/material/Alert';

export default function EmailFinder({open,close}) {
  const { register, errors, getValues } = useForm();
  const [emailVal,setEmailVal] = useState('')
  const [warning, setWarning] = useState({text:'',severity:''})
  
  useEffect(()=>{
    if (warning?.text){
      setTimeout(() => {setWarning({text:'',severity:''})}, 5000)
    }
   // eslint-disable-next-line
  },[warning])
  
  
  const doLookup = () => {
	  let fn = getValues().firstname
	  let ln = getValues().lastname
    let domain = getValues().domain
    
    if (!fn || !ln || !domain){
      setWarning({text:"Missing required field",severity:'error'})
      return
    }
    
	  async function getEmail() {
       setWarning({text:"Searching...",severity:'info'})
	     let res = await authenticatedFetch(`/companies/api/person/email`, {
	       method: 'POST', 
	       body: JSON.stringify({
	         domain: domain,
	         fn: fn,
	         ln: ln,
	       })
	     })
	     setEmailVal(res['email'])
	     if (res['email'] === ''){
        setWarning({text:"Sorry, no email found.",severity:'error'})
	     } else {
	       setWarning({text:"Success!",severity:'success'})
	     }
	  }
	  	getEmail()
  }
  
  return (
    <Dialog open={open} onClose={close}>
        {warning && warning.text && <Alert severity={warning.severity} onClose={() => setWarning({})}>{warning.text}</Alert>}
        <DialogTitle>Email Finder</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter firstname, lastname, and domain to attempt to find email
          </DialogContentText>
                  <Section>
                    <TextField
                      inputRef={register({required: true})}
                      name="firstname"
                      label="First Name"
                      fullWidth
                    />
                    {errors.firstname && <ErrorText>This field is required</ErrorText>}
                  </Section>
                  <Section>
                    <TextField
                      inputRef={register({required: true})}
                      name="lastname"
                      label="Last Name"
                      fullWidth
                    />
                    {errors.lastname && <ErrorText>This field is required</ErrorText>}
                  </Section>
                  <Section>
                    <TextField
                      inputRef={register({required: true})}
                      name="domain"
                      label="Domain"
                      fullWidth
                    />
                    {errors.lastname && <ErrorText>This field is required</ErrorText>}
                  </Section>
                
                <Box sx={{marginTop:2}} display="flex" alignItems="center"> {/* Flex container */}
    		              <Button onClick={doLookup} color="primary" variant='contained'>
    		                Lookup
    		              </Button>
                      <Typography variant="body1" style={{fontWeight:800,marginLeft:40,fontSize:sv.bodyIcon.fontSize*1.5}}>
                        {emailVal && 'Email: '}
                        {emailVal}
                      </Typography>
                    </Box>
               
        </DialogContent>
        <DialogActions>
        </DialogActions>
    </Dialog>
  )
}