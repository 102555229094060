import React, { useState } from 'react';
import TableBase from './TableBase';


export default function Stats({history,path,name}) {
  const [data, setData] = useState([])
  
  const columns = 
  [ { headerName: 'Name', field: 'user',  width:120 }, 
    { headerName: 'Owned', type:'number',field: 'owned_cos',  width:150 }, 
    { headerName: 'Active', type:'number',field: 'active_cos',  width:150 }, 
    { headerName: '7d Sequenced', type:'number',field: 'sequence7',  width:150 }, 
    { headerName: '30d Sequenced', type:'number',field: 'sequence30',  width:150 }, 
    { headerName: '7d Emailed', type:'number',field: 'emails7',  width:150 }, 
    { headerName: '30d Emailed', type:'number',field: 'emails30',  width:150 }, 
    { headerName: 'Last W Mtgs', type:'number',field: 'lastw_mtgs',  width:150 }, 
    { headerName: 'This W Mtgs', type:'number',field: 'thisw_mtgs',  width:150 }, 
    { headerName: 'Next W Mtgs', type:'number',field: 'nextw_mtgs',  width:150 },
    { headerName: '7d Comments', type:'number',field: 'comments7',  width:150 }, 
    { headerName: '30d Comments', type:'number',field: 'comments30',  width:150 },
  ]

  return (
      <>
        <TableBase
          data={data}
          history={history}
          setData={setData}
          path={'userstats'}
          columns={columns}
          name={'Stats'}
          type={"Stats"}
        />
      </>
  );
}