import React, { useState, useEffect } from 'react';
import TableBase from './TableBase';
import { useGridApiRef,  GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Language from '@mui/icons-material/Language';
import sv from '../constants/Styles';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {Text,Name} from '../constants/StyleComponents';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsightsIcon from '@mui/icons-material/Insights';
import { authenticatedFetch, getUser } from '../utils/auth';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddEditCompany from './AddEditCompany.js'
import AddEditCEO from './AddEditCEO.js'
import AddEditReferrer from './AddEditReferrer.js'
import { Statuses,OrgStatuses } from '../constants/Constants'
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import CommentBox from './CommentBox'
import NoteBox from './NoteBox'
import makeStyles from '@mui/styles/makeStyles';
import EditSnooze from './EditSnooze.js'
import pitchbook from '../assets/pitchbook.png';
import {dateOperators,multiInputOperators,investorOperators,stringOperators,numberOperators,ceoOperators,companySearch,listOperators,tagOperators} from './helpers/Filters'
import {formatMMDDYYYY,industries,technologies,customerTypes,getUsers,fundings,highlights,preferredInvestors,countries,daysSince,calculateTimingScore,formatInvestors,millionMoney,cleanInvestments,urlify} from '../utils/tools'
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
// import Popper from "@mui/material/Popper";
// import Paper from "@mui/material/Paper";
import outreach from '../assets/outreach.png'
import twitter from '../assets/twitter.png'
import ChartMilestone from './ChartMilestone.js'
import ReactHtmlParser from 'react-html-parser';

const Link = styled('a')({
  marginLeft: 5,
  textDecoration: null,
  fontSize: sv.bodyText.fontSize,
  cursor: 'pointer',
})

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 600,
  },
  root: {
    '& .editable': {
       backgroundColor: 'rgba(245, 245, 245)',
     },
  }
}));

const StyledTip = styled(Tooltip)({
  marginLeft:5,
})

const TipText = styled(Text)({
  color:'white',
})

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

function renderMultiList(params){
	return <Tooltip placement='top-start' title={params.value.join(', ')}><Box>{params.value.join(', ')}</Box></Tooltip>
}

// function handleMulti(id,field,api,value){
//     api.setCellMode(id, field, "edit");
//     api.setEditRowsModel({[id]:{[field]:{value: value}}})
//     api.commitCellChange({id:id,field:field})
// }

// const EditMultiList = (props) => {
//   const { id, field, value, api, colDef, list=[] } = props
//     const [anchorEl, setAnchorEl] = useState(null)
//
//   const handleRef = (el) => {
//       setAnchorEl(el)
//     }
//
//   return (<>
//         <Box
//           ref={handleRef}
//           sx={{height:1,width:colDef.computedWidth}}
//         />
//           {anchorEl &&
//         <Popper open anchorEl={anchorEl} placement='bottom-start'>
//           <Paper elevation={1} sx={{p: 1, minWidth: colDef.computedWidth,maxWidth:300 }}>
//           <Autocomplete
//             multiple
//             fullWidth
//             size='small'
//             options={list.map((x) => x.name)}
//             getOptionLabel={(option) => option.name || option}
//             value={value || []}
//             renderInput={(props) => (
//                       <TextField
//                         {...props}
//                         variant="standard"
//                       />
//                     )}
//             onChange={(e,nv)=>handleMulti(id,field,api,nv)}
//           />
//           </Paper>
//         </Popper>
//         }
//       </>
// )}

export default function CompanyTable({history,path,name,extraCols,type,defaultFilter}) {
  const [data,setData] = useState([])
  const [users,setUsers] = useState([])
  const [showAddEdit, setShowAddEdit] = useState(false)
  const [company, setCompany] = useState({})
  const [warning,setWarning] = useState({text:'',severity:''})
  const [ceoOpen, setCeoOpen] = useState(false);
  const [ceoDetails, setCeoDetails] = useState({})
  const [commentOpen, setCommentOpen] = useState(false)
  const [noteOpen, setNoteOpen] = useState(false)
  const [rowId, setRowId] = useState(null)
  const [rowName, setRowName] = useState(null)
  const classes = useStyles();
  const [snoozeOpen, setSnoozeOpen] = useState(false)
  const [snoozeDate, setSnoozeDate] = useState('')
  const [assignment, setAssignment] = useState({})
  const apiRef = useGridApiRef();
  const [industryList, setIndustryList] = useState([])
  const [technologyList, setTechnologyList] = useState([])
  const [fundingList, setFundingList] = useState([])
  const [highlightList, setHighlightList] = useState([])
  const [customerTypeList, setCustomerTypeList] = useState([])
  const [investorList, setInvestorList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [ceoEdited, setCeoEdited] = useState(false)
  const [showMilestone,setShowMilestone] = useState(false)
  const [comments, setComments] = useState([])
  const [showHelp, setShowHelp] = useState(false)
  const [showReferrer, setShowReferrer] = useState(false)
  
  useEffect(()=>{
	  getUsers().then((v) => setUsers(v))  
	  industries().then((v) => setIndustryList(JSON.parse(v)))
  	technologies().then((v) => setTechnologyList(JSON.parse(v)))
  	fundings().then((v) => setFundingList(JSON.parse(v)))
	  highlights().then((v) => setHighlightList(JSON.parse(v)))
	  customerTypes().then((v) => setCustomerTypeList(JSON.parse(v)))
	  preferredInvestors().then((v) => setInvestorList(JSON.parse(v)))  
  	countries().then((v) => setCountryList(JSON.parse(v)))

    const addDomain = (new URLSearchParams(window.location.search)).get('add_domain')
    
    if (addDomain){
      setCompany({'website':urlify(addDomain)})
      setShowAddEdit(true)
    }
  },[])

  function handleSnoozeClose(){
    setSnoozeOpen(false)
  }
  
  function handleNoteClose(){
    setNoteOpen(false)
  }

  useEffect(() => {
    if (ceoDetails.companyId && ceoEdited){
      let obj = data.filter((x) => x.id === ceoDetails.companyId)[0]
      let objIndex = data.indexOf(obj)
      
      let replacementObj = {...obj.ceo,...ceoDetails}
      let newDataArray = [...data]
      newDataArray[objIndex].ceo = replacementObj
      setData(newDataArray)
    }
  // eslint-disable-next-line
  },[ceoDetails])  
   
   function handleCommentClose(){
      setCommentOpen(false)
  }
  
  function handleCommentOpen(row){
    setRowId(row.id)
    setRowName(row.name)
    setCommentOpen(true)
    
    async function getComments() {
      let url = `/companies/api/company/${row.id}/comments`
      let res = await authenticatedFetch(url, {method: 'GET'})
      setComments(res)
    }
    getComments()
  } 
  
  function handleMilestoneOpen(row){
    setRowId(row.id)
    setShowMilestone(true)
    setRowName(row.name)
  } 
  
  
  function handleCeoClose(){
    setCeoOpen(false)
  }
  
  const editCo = (params) => {
    setShowAddEdit(true)
    let co = {
      id: params['id'],
      name: params['name'],
      website: params['website'],
      li_url: params['li_url'],
      tw_url:params['tw_url'],
      pitchbook_id:params['pitchbook_id'],
      harmonic_id:params['harmonic_id'],
      cbi_profile_url:params['cbi_profile_url'],
      referrer:params['referrer']
    }
    setCompany(co)
  }
  
  const hideAddEdit = () => {
    setShowAddEdit(false)
  }
  
  function handleMilestoneClose(){
    setShowMilestone(false)
  }
  
  function handleCeoOpen(row){
      setCeoDetails({...row.ceo,...{companyId:row.id}})
      setCeoOpen(true)
  }
  
const handleCellEditCommit = (params,event) => {
	let val = ['','None'].includes(params.value) ? null : params.value
	
	async function doUpdate(){
		await authenticatedFetch(`/companies/api/company/${params.id}/update`, {
			method: 'PATCH',
			body: JSON.stringify({
			  [params.field]: val
			 	     })
			 	   })
			  	}
	doUpdate()
	if (params.field === "owner" && val && getUser()["name"] !== val){
		setAssignment({'assignee':val,'assigner':getUser()["name"]})
		setNoteOpen(true)
		setRowId(params.id)
	}
	if (params.field === "status"){
		let row = data.filter((x) => x.id === params.id)[0]
        if (!row.owner && ['Outreach','Discussions','Snoozed'].includes(val)) {

			    event.defaultMuiPrevented = true
	        const model = apiRef.current.getEditRowsModel()
	        const { value } = model[params.id].status
	        const row = apiRef.current.getRow(params.id)
	        const rowUpdate = { ...row, status: value, owner: getUser()['name'] }
	        apiRef.current.updateRows([rowUpdate])
        }
        if (val === 'Snoozed'){
          setSnoozeOpen(true)
          setSnoozeDate(row.snooze_date)
          setRowId(params.id)
        }
		if (val === 'Discussions'){
			setRowId(params.id)
			setRowName(row.name)
			setCommentOpen(true)
		}
    if (val === 'Outreach'){
      handleCeoOpen(row)
      setRowId(params.id)
      !row.referrer && setShowReferrer(true)
    }
		if (['Recycled','Trash'].includes(val)){
			event.defaultMuiPrevented = true
		    event.defaultMuiPrevented = true
	        const model = apiRef.current.getEditRowsModel()
	        const { value } = model[params.id].status
	        const row = apiRef.current.getRow(params.id)
	        const rowUpdate = { ...row, status: value, owner: null }
	        apiRef.current.updateRows([rowUpdate])
		}
	}
}
  const columns = 
  [
    { headerName: 'Company', description:'Name of the company – click to view, or use icons to edit and go to external links',field: 'name', filterOperators:companySearch.concat(stringOperators.filter((op) => op.value === "contains")),width:200, renderCell: (params: ValueFormatterParams) => (
        <span>
      <Name href={`/company/${params.row.id}`} target="_blank">{params.row.name}</Name>
      <EditOutlinedIcon style={{fontSize: sv.bodyIcon.fontSize, cursor:'pointer' }} onClick={() => {editCo(params.row)}}/>
    {(params.row.clean_description || params.row.description || params.row.harmonic_desc || params.row.li_shortdesc || params.row.pb_desc) &&
    <StyledTip title={<TipText>{params.row.clean_description || params.row.description || params.row.harmonic_desc || params.row.pb_desc || params.row.li_shortdesc}</TipText>} placement='top'>
      <InfoOutlinedIcon style={{ fontSize: sv.bodyIcon.fontSize}}/>
    </StyledTip>
    }
    <Link onClick={() => handleCommentOpen(params.row)}><CommentOutlinedIcon style={{ fontSize: sv.bodyIcon.fontSize, cursor:'pointer'}} /></Link>
     <Link onClick={() => handleMilestoneOpen(params.row)}><InsightsIcon style={{ fontSize: sv.bodyIcon.fontSize, cursor:'pointer'}} /></Link>
        <Link
          href={`//www.${params.row.website}`}
          target="_blank"
        >
          <Language style={{ fontSize: sv.bodyIcon.fontSize }}/>
        </Link>
            {params.row.li_url &&
        <Link
          href={`//${params.row.li_url}`.replace('https://','').replace('http://','')}
          target="_blank"
        >
          <LinkedInIcon style={{ fontSize: sv.bodyIcon.fontSize, color:"0F4EB5", display: 'inline-block' }}/>
            </Link>}
        {params.row.pitchbook_id && <Link target="_blank" style={{marginLeft:5}} href={`//my.pitchbook.com/profile/${params.row.pitchbook_id}/company/profile`}><img style={{width:16}} src={pitchbook} alt="Pitchbook"/></Link>}
        {params.row.tw_url && <Link target="_blank" style={{marginLeft:5}} href={params.row.tw_url}><img style={{width:16}} src={twitter} alt="Twitter"/></Link>}
		
        </span>
      )},
      { headerName: 'CEO', description:'Company CEO – click to add/edit', field: 'person__name',width:140, filterOperators:ceoOperators,renderCell: (params) => (
        <>
          <Name style={{fontStyle: params.row.ceo && params.row.ceo.email ? 'none' : 'italic'}} onClick={()=>handleCeoOpen(params.row)}>
            {params.row.ceo ? params.row.ceo.name : ''}
            <EditOutlinedIcon style={{fontSize: sv.bodyText.fontSize * 0.9, marginLeft: 5}} />
          </Name>      
          {params.row.ceo && params.row.ceo.url && params.row.ceo.url.includes('linkedin.com/in/') && <Link target="_blank" style={{marginLeft:3}} href={`//${params.row.ceo.url}`.replace('https://','').replace('http://','')}> <LinkedInIcon style={{ fontSize: sv.bodyIcon.fontSize, color:"0F4EB5", display: 'inline-block' }}/></Link>}
          {params.row.ceo && params.row.ceo.outreach_id && <Link target="_blank" style={{marginLeft:3}} href={`//app1a.outreach.io/prospects/${params.row.ceo.outreach_id}/sequences`}><img style={{width:14}} src={outreach} alt="Outreach"/></Link>}
        </>
    )},
    { headerName: 'Owner', description:'MVP Owner of company',field: 'owner',filterOperators:multiInputOperators(users),type:'singleSelect',cellClassName: 'editable',valueGetter: (p) => (p.value || ''),valueOptions: [''].concat(users),editable: true,width: 130},
    { headerName:'Status', renderHeader: (params: GridColParams) => (
      <Tooltip classes={{ tooltip: classes.customWidth }} title={(
        <Box>
        <ul>
          <li>New: Haven’t tried to interact</li>
          <li>Outreach: Attempting to make initial contact</li>
          <li>Discussions: Engaged with company</li>
          <li>Invested: Completed investment</li>
          <li>Snoozed: Interesting but timing not right, re-surface at defined date for follow-up</li>
          <li>Recycled: Conversation didn’t go anywhere or never got in touch</li>
          <li>Trash: Not a relevant company (was not nor ever will be venture-backed)</li>
        </ul>
        </Box>
      )} placement="top"><Box>Status</Box></Tooltip>
    ),
    field: 'status',filterOperators:multiInputOperators(Statuses),cellClassName: 'editable',type:'singleSelect',valueOptions:Statuses,editable: true,width: 130},
	  { headerName: 'Headcount', description:'Number of current employees',filterOperators:numberOperators,field: 'current', type: 'number',width:120 },
    { headerName: 'Last Round Type', description:"Categorization of company's last round",field: 'latest_round_type', width:150,filterOperators:multiInputOperators(fundingList),valueGetter: (p) => p.value?.parent ? `${p.value.parent} (${p.value.name})` : p.value?.name},
    { headerName: 'Last Round Date',description:"Date of company's last round", field: 'latest_round_date', type:'date', width:150,filterOperators: dateOperators,valueFormatter: (params) => formatMMDDYYYY(params.value)},
    { headerName: 'Last Round Amount',description:"Size of company's last round",filterOperators:numberOperators, field: 'latest_round_amount', type:'number', width:150,valueFormatter: (params) => millionMoney(params.value)},
    { headerName: 'Valuation',description:"Latest valuation",filterOperators:numberOperators, field: 'valuation', type:'number', width:150,valueFormatter: (params) => millionMoney(params.value)},
	  { headerName: 'Latest Investors', filterOperators:investorOperators.concat(multiInputOperators(investorList)),description:'Investors who particpated in latest round',field: 'latest_investors', width:150,sortable:false, renderCell: (params: ValueFormatterParams) => {
      const invString = ReactHtmlParser(formatInvestors(cleanInvestments(params.row.canonical_investments)[0]?.cleanInvestors))
      return (
          <NoMaxWidthTooltip title=<Box>{invString}</Box> placement="top-start"><Box>{invString}</Box></NoMaxWidthTooltip>
      )}},
    { headerName: 'Growth Score', description:'Rates how company has scaled vs companies of a similar size, weighted towards recent months',filterOperators:numberOperators,field: 'growth_score', type: 'number',width:120 },  
    { headerName: 'Retention Score', description:'Ability of company to retain employees over time',field: 'retention_score', filterOperators:numberOperators,type: 'number',width:120 },
    { headerName: 'Investor Score', description:"Quality of investors backing company, weighted towards more recent rounds",field: 'investor_score', filterOperators:numberOperators,type: 'number',width:120 },
    { headerName: 'CEO Score', description:'Rating of CEO background based on prior experience',field: 'ceo_score', filterOperators:numberOperators,type: 'number',width:120 },
    { headerName: 'Team Score', description:'Rating of full team backgrounds based on prior experience, weighted towards founders and execsm',field: 'employee_score', filterOperators:numberOperators,type: 'number',width:120 },
    { headerName: 'Market Score', description:"Growth of company web and social presence in market",field: 'social_score', filterOperators:numberOperators,type: 'number',width:120 },
    { headerName: 'Timing Score', description:'Likelihood company will be raising soon',field: 'timing_score',valueGetter: (p) => (calculateTimingScore(daysSince(p.row.latest_round_date))),filterOperators:numberOperators,type: 'number',width:120 },
    { headerName: 'Overall Score', description:'Summarizes all other scores, excluding Timing',filterOperators:numberOperators,field: 'overall_score', type: 'number',width:120 },
	  { headerName: 'Industries', description:'Industry tags',field: 'industries',filterOperators:multiInputOperators(industryList),renderCell: renderMultiList,width:150},
    { headerName: 'Technologies', description:'Technology tags',field: 'technologies',filterOperators:multiInputOperators(technologyList),renderCell: renderMultiList,editable:true,width:150},
    { headerName: 'Keywords', description:'All Keywords',field: 'keywords',renderCell: renderMultiList,filterOperators:tagOperators,width:150},    
    // { headerName: 'Tags', description:'All keywords and tags',field: 'tags',hide:true,sortable:false},
    { headerName: 'Sales Type', description:'Sales customer type',field: 'customer_type',width:150,type:'singleSelect',valueOptions:customerTypeList.map((x) => x.name),valueGetter: (p) => (p.value || ''),filterOperators:multiInputOperators(customerTypeList)},
    // { headerName: 'Value-Add Priority',description:'1-5 ranking of value-add priority level',filterOperators:numberOperators,cellClassName: 'editable', field: 'excitement',valueGetter: (p) => (p.value || ''),type:'singleSelect',valueOptions:['',1,2,3,4,5],editable: true,width:130},
    { headerName: 'Country',field: 'country',width:150,filterOperators:multiInputOperators(countryList)},
    { headerName: 'State', field: 'state',width:150,hide:true,filterOperators:stringOperators },
  	{ headerName: 'Last Update', description:"Most recent update from company",type:'date', field: 'last_update',width:150,filterOperators: dateOperators,valueFormatter: (params) => formatMMDDYYYY(params.value)},
    { headerName: 'Meetings - Last', description:"Date of last meeting with company",filterOperators: dateOperators,field: 'last_meeting', type:'date', width:120,valueFormatter: (params) => formatMMDDYYYY(params.value)},
    { headerName: 'Meetings - Count', description:"Total number of meetings with company",filterOperators: numberOperators,field: 'total_meetings', type: 'number',width:120 },  
    { headerName: 'Messages - Last Received', description:"Date of last email received from company",filterOperators: dateOperators,field: 'last_received_date', type:'date', width:120,valueFormatter: (params) => formatMMDDYYYY(params.value)},
    { headerName: 'Messages - Last Sent', description:"Date of last email sent to company",filterOperators: dateOperators,field: 'last_sent_date', type:'date', width:120,valueFormatter: (params) => formatMMDDYYYY(params.value)},
    { headerName: 'Snooze Date',description:"Date company is snoozed until",cellClassName: 'editable', editable:true,field: 'snooze_date',width:180,type:'date',filterOperators: dateOperators,valueFormatter: (params) => formatMMDDYYYY(params.value)},
	  { headerName: 'Last Data Refresh', description:"Date company data was last updated",type:'date', field: 'last_scrape',width:150,hide:true,filterOperators: dateOperators,valueFormatter: (params) => formatMMDDYYYY(params.value)},
	  { headerName: 'Initial Add Date', description:"Date company was first added to tech",type:'date', field: 'created_at',width:150,hide:true,filterOperators: dateOperators,valueFormatter: (params) => formatMMDDYYYY(params.value)},
	  { headerName: 'List', field:'listing',hide:true,filterOperators:listOperators},
    { headerName: 'Source', field: 'source',width:150,hide:true,filterOperators:stringOperators },
    { headerName: 'Description',description:"Description",hide:true,field: 'description'},
    { headerName: 'Organization Status', field: 'org_status',width:150,hide:true,filterOperators:multiInputOperators(OrgStatuses) },
	  { headerName: 'Fund Rank', description:"Highest ranking preferred firm of all funds in company's last round", filterOperators:numberOperators,field: 'preferred_rank', type: 'number',width:120,hide:true },
	  { headerName: 'Partner Rank',description:"Highest ranking of individual partner scores for all investors in company's last round", filterOperators:numberOperators, field: 'midas_rank', type: 'number',width:120,hide:true },
	  { headerName: 'Fund Rank - Lifetime', description:"Highest ranking preferred firm of all funds in all of company's rounds",filterOperators:numberOperators,field: 'lifetime_preferred_rank', type: 'number',width:120,hide:true },
	  { headerName: 'Partner Rank - Lifetime', description:"Highest ranking of individual partner scores for all investors in all of company's rounds",filterOperators:numberOperators,field: 'lifetime_midas_rank', type: 'number',width:120,hide:true },
	  { headerName: 'Founded', description:"Year when company was founded",filterOperators:numberOperators,type:'number',field: 'founded', hide:true},
    { headerName: 'Portfolio Fit', description:"How well company matches portfolio",filterOperators:numberOperators,type:'number',field: 'portfolio_fit', hide:true},
    { headerName: 'Portfolio FIt Reason', description:"Reasoning behind portfolio fit",filterOperators:numberOperators,type:'number',field: 'portfolio_fit_reason', hide:true,filterable:false,sortable:false},
	  { headerName: 'Investors', filterOperators:investorOperators.concat(multiInputOperators(investorList)),description:'All Investors',field: 'investors', width:150,sortable:false}

  ]
  
  const [cols, setCols] = useState([])
  const [defaultColumns, setDefaultColumns] = useState([])

  useEffect(()=>{
	  setCols(columns)
	  setDefaultColumns(columns)
    let combined = columns.slice(0,8).concat((extraCols || [])).concat(columns.slice(8,columns.length))
    if (type !== 'Pipeline'){
      let el = combined.filter((x) => x.headerName === 'Excitement')[0]
      let ind = combined.indexOf(el)
      combined.splice(ind,1)
    }
   // eslint-disable-next-line
 },[users,industryList,technologyList,fundingList,highlightList,customerTypeList,investorList,countryList])
	  
  return (
    <Box className={classes.root}>
      <TableBase
        pinnedColumns={{left: [GRID_CHECKBOX_SELECTION_COL_DEF.field,'name']}}
        data={data}
        setData={setData}
        history={history}
        path={path}
        columns={cols}
        defaultColumns={defaultColumns}
        setColumns={setCols}
        name={name}
        type={type || "Growth"}
		    defaultFilter={defaultFilter}
        defaultSort={[{field: 'overall_score',sort: 'desc',},]}
        warning={warning}
		    setWarning={setWarning}
		    handleCellEditCommit={handleCellEditCommit}
		    apiRef={apiRef}
		    selection={true}
        showHelp={showHelp}
        setShowHelp={setShowHelp}
      />
      <ChartMilestone companyName={rowName} fromCompany={false} open={showMilestone} close={handleMilestoneClose} id={rowId}/>
      <EditSnooze open={snoozeOpen} close={handleSnoozeClose} id={rowId} date={snoozeDate}/>
      <AddEditCompany setWarning={setWarning} open={showAddEdit} close={hideAddEdit} company={company} setCompany={setCompany} />
      <AddEditCEO open={ceoOpen} setWarning={setWarning} setCeoEdited={setCeoEdited} close={handleCeoClose} ceoDetails={ceoDetails} setCeoDetails={setCeoDetails}/>
      <CommentBox open={commentOpen} setWarning={setWarning} close={handleCommentClose} companyId={rowId} companyName={rowName} comments={comments} setComments={setComments}/>
      <NoteBox open={noteOpen} close={handleNoteClose} companyId={rowId} companyName={rowName} assignment={assignment}/>
      <AddEditReferrer setWarning={setWarning} rowId={rowId} open={showReferrer} close={()=>setShowReferrer(false)}/>
		
    </Box>
  );
}