import { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {markdownToHtml} from '../../utils/tools'

const TypingText = ({ text, speed }) => {
  const [displayText, setDisplayText] = useState('');
  
  useEffect(() => {
    let i = 0;
    const timerId = setInterval(() => {
      if (i < text.length) {
        setDisplayText((prevText) => prevText + text[i]);
        i++;
      } else {
        clearInterval(timerId);
      }
    }, speed);

    // Cleanup function to clear the interval if the component unmounts
    return () => clearInterval(timerId);
  }, [text, speed]);

  return (
    ReactHtmlParser(markdownToHtml(displayText))
  );
};

export default TypingText;
