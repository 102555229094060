import { useEffect } from 'react';
import { authenticatedFetch} from '../utils/auth';
var _ = require('lodash')

export function uuid() {
    // http://www.ietf.org/rfc/rfc4122.txt
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    var uuid = s.join("");
    return uuid;
}

export function trim(string,limit){
  if (string.length <= limit){
    return string
  } else {
    return string.substring(0,limit)+'...'
  }
}

export function makeDateRange(emp){
  let s = ""
  if (emp.startdate){
    s += `(${formatYYYY(emp.startdate)} – `
    if (emp.enddate){
        s += `${formatYYYY(emp.enddate)}`
    } 
    s += ")"
  }
  return s
}

export function compareDates(a, b) {
  const dateA = new Date(a.startdate);
  const dateB = new Date(b.startdate);

  // Compare in reverse order (newest first)
  return dateB - dateA;
}

export function formatMMDDYYYY(inp){
  if (inp){
    var d = new Date(inp)
    return (d.getMonth() + 1) + 
    "/" +  d.getDate() +
    "/" +  d.getFullYear();
  }
  return null
}

export function formatYYYY(inp){
  if (inp){
    var d = new Date(inp)
    return d.getFullYear()
  }
  return ''
}

export function formatYYYYMMDD(inp){
  if (inp){
    var d = new Date(inp)
    return d.getFullYear() + "-" + (d.getMonth()+1)+"-"+("0"+d.getDate()).slice(-2)
  }
  return null
}

export function formatMMYYYY(inp){
    if (inp){
      var d = new Date(inp)
      return (d.getMonth() + 1) + 
      "/" +  d.getFullYear();
    }
    return null
  }

export function formatDayMonthYear(inp){
    if (inp){
      const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }
      var d = new Date(inp)
      return new Intl.DateTimeFormat('en-US', options).format(d)
    }
    return null
}

export function formatMonthYear(inp){
    if (inp){
      const options = {
          year: 'numeric',
          month: 'short',
        }
      var d = new Date(inp)
      return new Intl.DateTimeFormat('en-US', options).format(d)
    }
    return null
}

export function daysSince(inp,start=Date.now()){
  if (inp){
    var d = new Date(inp)
    return Math.round((start-d)/(1000*60*60*24),0)
  }
  return null
}

export function calculateTimingScore(value){
  const mean = 400
  const std_dev = 225
  
  const f_x = (1 / (std_dev * Math.sqrt(2 * Math.PI))) * Math.E**(-((value - mean)**2) / (2 * std_dev**2))
  const peak = (1 / (std_dev * Math.sqrt(2 * Math.PI)))
  return Math.round((f_x / peak) * 100,0)
}

export function timestamp(inp){
  if (inp){
    var d = new Date(inp)
    return d.toLocaleString(undefined, {
    	month: "short", day: "numeric", 
    	hour: "numeric", minute: "numeric"
	}) 
  }
  return null
}

export function extractFilterSort(inp){
  var filterPattern = /<filter>\s*(.*?)\s*<\/filter>/s
  var sortPattern = /<sort>\s*(.*?)\s*<\/sort>/s
  
  var filterContent = ''
  var sortContent = ''
  
  try {
    var filterMatch = inp.match(filterPattern)
    var sortMatch = inp.match(sortPattern)
  
    filterContent = filterMatch ? JSON.parse(filterMatch[1]) : null
    sortContent = sortMatch ? JSON.parse(sortMatch[1]) : null
  } catch (e) {
      console.log('JSON parse error',e.message)
  } 

  return [filterContent, sortContent]
}

export function money(num,digits=0) {
  if (num !== undefined){
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: digits }).format(num);
  }
  return null
}

export function millionMoney(num) {
  let formattedNum;
  if (num) {
    if (num >= 1_000) {
      formattedNum = (num / 1_000).toFixed(1) + 'B';
    } else {
      formattedNum = num.toFixed(1) + 'M';
    }
    return '$' + formattedNum;
  }
  return null
}


export function parseQuery(input) {
  let str = input.replace('?','')
  let pars = str.split('&')
  let dict = {}
  for (let  i = 0; i < pars.length; i++) {
    let spl = pars[i].split('=')
    dict[spl[0]] = spl[1]
  }
  return dict
}

export function urlify(url) {
    var cleanedUrl = (url || '').toLowerCase()
    return cleanedUrl.replace(/https?:\/\//, '').replace(/www\./, '').split('/')[0]
}

export function markdownToHtml(markdown) {
  if (typeof(markdown)!=='string'){return markdown}
    const rules = [
        { regex: /(\*\*|__)(.*?)\1/g, replacement: '<strong>$2</strong>' },    // Bold
        { regex: /(\*|_)(.*?)\1/g, replacement: '<em>$2</em>' },               // Italics
        { regex: /\n/g, replacement: '<br>' },                                 // Newline
        { regex: /(^|\n)\* (.*?)($|\n)/g, replacement: '<li>$2</li>' },        // Unordered list item
        { regex: /(<li>.*<\/li>)(?!(<\/ul>))/g, replacement: '<ul>$1</ul>' },  // Wrap list items in <ul>
        { regex: /(^|\n)\d+\. (.*?)($|\n)/g, replacement: '<li>$2</li>' },     // Ordered list item
        { regex: /(<li>.*<\/li>)(?!(<\/ol>))/g, replacement: '<ol>$1</ol>' },  // Wrap list items in <ol>
        { regex: /\[([^\]]+)\]\(([^)]+)\)/g, replacement: '<a href="$2" target="_blank">$1</a>' }, //Links
        { regex: /(^|\n)###### (.*?)(\n|$)/g, replacement: '<h6>$2</h6>' },    // H6
        { regex: /(^|\n)##### (.*?)(\n|$)/g, replacement: '<h5>$2</h5>' },     // H5
        { regex: /(^|\n)#### (.*?)(\n|$)/g, replacement: '<h4>$2</h4>' },      // H4
        { regex: /(^|\n)### (.*?)(\n|$)/g, replacement: '<h3>$2</h3>' },       // H3
        { regex: /(^|\n)## (.*?)(\n|$)/g, replacement: '<h2>$2</h2>' },        // H2
        { regex: /(^|\n)# (.*?)(\n|$)/g, replacement: '<h1>$2</h1>' },         // H1
    ];

    let html = markdown;

    rules.forEach(rule => {
        html = html.replace(rule.regex, rule.replacement);
    });

    return html;
}

export function cleanInvestments(investments) {
  if (!investments) { return [] }
  const reversed = [...investments].reverse()
  const clean = reversed.map(v => {
    const invList = v.investorinvestments?.map(i => i.investor.id)
    const flatInvestors = v.investorinvestments?.map(i => {
      return { ...i.investor, lead: i.lead, new: i.new, source: i.source }
    })
    const cleanInvestors = flatInvestors?.filter(i => 
      (i.name !== 'Undisclosed Investors') && (i.parent === null || !invList.includes(i.parent.id))
    )
    let cleanWithPartners = cleanInvestors?.map(i => {
      const partner = flatInvestors?.find(i2 => i.id === i2.parent?.id)
      return { partnerName: partner?.name, partnerRank: partner?.rank, ...i }
    }).filter(i => i.pitchbook_person_id === '' || i.midas === true)

    // Ensure uniqueness based on id
    cleanWithPartners = cleanWithPartners?.reduce((acc, current) => {
      const x = acc.find(item => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    return {
      cleanInvestors: cleanWithPartners,
      ...v
    }
  }).reverse()
  return clean
}

export function cleanRound(round,amount,date) {
  var out = ""
  if (amount){
    out += `$${amount}M`
  }
  if (round){
    if (out.length > 0){out += ' '}
    out += `${(round.parent || round.name)}`
  } 
  if (date){
    if (out.length > 0){out += ', '}
    out += formatMonthYear(date)
  }
  return out
}

export function formatInvestors(input) {
  if (input === null || input === undefined || input.length === 0) { return "" }
  
  let out = "";
  let hasKey = false;
  let addedNames = new Set();  // Set to keep track of added names

  if (input.filter(i => i.lead || i.new).length > 0) { hasKey = true } 

  _.orderBy(input, ['lead', 'rank'], ['desc', 'asc']).forEach((ii, ind) => {
    if ((ii.new || ii.lead || !hasKey) && !addedNames.has(ii.name)) {
      if (out.length > 0) { out += ", " }
      out += ii.name;
      if (ii.preferred || ii.midas) { out += `(${ii.rank})`.sup() }
      if (ii.lead) { out += `(L)`.sup() }
      
      addedNames.add(ii.name); // Add name to the set
    }
  })
  return out;
}


export function titleize(str) {
  if (str){
    return str
        .split(' ')
        .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join(' ')
  }
  
  return ''
}

export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}

export function isMobile(){
  if (window.innerWidth < 1000){
  	return true
  }
  return false
}

export async function industries(){
	return authenticatedFetch(`/companies/api/industries`, {
          method: 'GET', 
    }).catch(e =>{
		return
    }).then(r => {
    	return r
    })
}	

export async function tags(){
	return authenticatedFetch(`/companies/api/tags`, {
          method: 'GET', 
    }).catch(e =>{
		return
    }).then(r => {
    	return r
    })
}	
export async function countries(){
	return authenticatedFetch(`/companies/api/countries`, {
          method: 'GET', 
    }).catch(e =>{
		return
    }).then(r => {
    	return r
    })
}	

export async function fellows(){
	return authenticatedFetch(`/companies/api/fellows`, {
          method: 'GET', 
    }).catch(e =>{
		return
    }).then(r => {
    	return r
    })
}	

export async function technologies(){
	return authenticatedFetch(`/companies/api/technologies`, {
          method: 'GET', 
    }).catch(e =>{
		return
    }).then(r => {
    	return r
    })
}	

export async function preferredInvestors(){
	return authenticatedFetch(`/companies/api/preferred_investors`, {
          method: 'GET', 
    }).catch(e =>{
		return
    }).then(r => {
    	return r
    })
}	

export async function customerTypes(){
	return authenticatedFetch(`/companies/api/customer_types`, {
          method: 'GET', 
    }).catch(e =>{
		return
    }).then(r => {
    	return r
    })
}	


export async function getUsers() {
      let url = `/accounts/api/users`
      let res = await authenticatedFetch(url, {method: 'GET'})
      return res.map(x => x.first_name)
}

export async function highlights() {
      let url = `/companies/api/highlights`
      let res = await authenticatedFetch(url, {method: 'GET'})
      return res
}

export async function fundings() {
      let url = `/companies/api/fundings`
      let res = await authenticatedFetch(url, {method: 'GET'})
      return res
}