import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { Title, Text, Section } from '../constants/StyleComponents';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import mvp from '../assets/mvp.png';
import { unAuthenticatedFetch } from '../utils/auth';


const Content = styled(Container)({
  marginTop: 20,
})

const HeaderImg = styled('img')({
  width:300,
  margin:30,
})

const FormField = styled(TextField)({
  marginRight:10,
  color:"primary",
  width:500,
})

export default function Login(props) {
  
  const [done, setDone] = useState(false);
  
  const initialValues = {
        email: "",
        password: "",
        name: ""
      };

  const [values, setValues] = useState(initialValues || {});
 
  const handleSubmit = (event) => {
    event.preventDefault();
    async function fetchData() {
      let url = `/logins/li_register/`
     await unAuthenticatedFetch(url, {
        method: 'POST',
        body: JSON.stringify({
          'email':values.email,
          'password':values.password,
          'name':values.name,
          'profile':values.profile
        })
      })
     setDone(true)
    }
    fetchData()
  }
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        const { name, value } = target;
        event.persist();
        setValues({ ...values, [name]: value });
      };
 
  return (
    <>
      <HeaderImg src={mvp} alt="Modern Venture Partners"/>
      <Content maxwidth="lg">
        <Title>LinkedIn Connect</Title>
    { done ? <Text>Success!</Text> :
      <>
        <Text>Connect your LinkedIn account to MVP here</Text>
        <Section>
          <form onSubmit={handleSubmit} autoComplete="off">
          <Section>
            <FormField
              required
              label="Firstname"
              name="name"
              value={values.name}
              onChange={handleChange}
              variant="filled"
            />
            </Section>
            <Section>
              <FormField
                required
                label="LinkedIn Profile Link"
                name="profile"
                value={values.profile}
                onChange={handleChange}
                variant="filled"
              />
              </Section>
            <Section>
            <FormField
              required
              label="LinkedIn Email"
              name="email"
		          value={values.email}
		          onChange={handleChange}
              variant="filled"
            />
            </Section>
            <Section>
            <FormField
              required
              label="LinkedIn Password"
              name="password"
              value={values.password}
              onChange={handleChange}
              variant="filled"
              type="password"
            />
          </Section>
          <Section>
            <Button type="submit" variant="contained" color="primary">Connect</Button>
          </Section>
          </form>
        </Section>
        </>
      }
      </Content>
    </>

  );
}
 
