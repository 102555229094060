import React, { useState, useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles';
import sv from '../constants/Styles'
import Box from '@mui/material/Box'
import mvp from '../assets/mvp-square-white.svg'
import {isManager,clearUserInfoAndForwardToLogin } from '../utils/auth'
import SearchBox from './SearchBox'
import SearchIcon from '@mui/icons-material/Search'
import Drawer from '@mui/material/Drawer'
import MenuIcon from '@mui/icons-material/Menu'
import makeStyles from '@mui/styles/makeStyles';
import {isMobile} from '../utils/tools'
import Alert from '@mui/material/Alert';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Stack from '@mui/material/Stack';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import JarvisGPT from './JarvisGPT.js'
import EmailFinder from './EmailFinder.js'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
  drawerPaper: {
    width: '50%',
  }
})

const Left = styled('span')({
  flexGrow: 1,
  marginLeft: 50,
  marginRight:'20%'
})

const MenuButton = styled(Button)({
  color:'white',
  fontSize: sv.bodyText.fontSize,
  paddingLeft: 10,
  paddingRight: 10
})

const NameText = styled(Typography)({
  fontSize: sv.bodyText.fontSize*1.2,
  lineHeignt: 40,
  marginLeft:25,
})

const DropDownMenu = styled(MenuItem)({
  fontSize: sv.bodyText.fontSize
})

const Logo = styled('img')({
  height:30,
})

const BoringLink = styled('a')({
  '&:hover': {
      color: 'black',
    },
})

export default function Header({name,history,warning,setWarning,showHelp,setShowHelp,path,setFilterModel,setSortModel,setSortCode,makeFilterValue,setFilterValue,setFetchable,setJarvisOpen,selectionModel,companyName=null,companyId=null,intros=[],files=[]}) {
  const [valueAddAnchorEl, setValueAddAnchorEl] = useState(null)
  const [moreAnchorEl, setMoreAnchorEl] = useState(null)
  const [marketingAnchorEl, setMarketingAnchorEl] = useState(null)
  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [openSearch, setOpenSearch] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const classes = useStyles()
  const [showJarvisGPT, setShowJarvisGPT] = useState(false)
  const [showEmailFinder, setShowEmailFinder] = useState(false)
  const [openValueAdd, setOpenValueAdd] = useState(false);
  const [openTools, setOpenTools] = useState(false);
  const [openMore, setOpenMore] = useState(false);
  
  const handleClick = (setOpen) => {
      setOpen((prev) => !prev);
    };
    
  useEffect(()=> {
    document.title = name + " | Jarvis"
  },[name])
  
  useEffect(()=>{
    setJarvisOpen && setJarvisOpen(showJarvisGPT)
  },[showJarvisGPT,setJarvisOpen])

  const handleValueAddClick = (event) => {
    setValueAddAnchorEl(event.currentTarget);
  };
  
  const handleMoreClick = (event) => {
    setMoreAnchorEl(event.currentTarget);
  };
  
  const handleMarketingClick = (event) => {
    setMarketingAnchorEl(event.currentTarget);
  };
  
  const doChange = (event, newValue) => {
    if (newValue){
      if (newValue.group === 'Companies') {
        window.open(`/company/${newValue.id}`,'_blank').focus()
      } else if (newValue.group === 'People') {
        window.open(`/person/${newValue.id}`,'_blank').focus()
      }
      setValue("")
    }
  }
  
  useEffect(()=>{
    if (warning?.text){
      setTimeout(() => {setWarning({text:'',severity:''})}, warning.timeout || 60000)
    }
   // eslint-disable-next-line
  },[warning])

  return (
    <>
      {warning && warning.text && <Alert severity={warning.severity} onClose={() => setWarning({})}>{warning.text}</Alert>}
      <AppBar position="sticky" style={{boxShadow: 'none'}}>
          <Toolbar variant="dense">
	  		{!(isMobile() && openSearch) &&
			  <>
              	<span>
              	  <Logo component='span' src={mvp}/>
              	</span>
              	<NameText component='span'>
              	  {name.toUpperCase()}
              	</NameText>
			  </>
			}
            <Left>
				        {openSearch || !isMobile() ?
              	<SearchBox
              	  value={value}
              	  setValue={setValue}
              	  doChange={doChange}
              	  inputValue={inputValue}
              	  setInputValue={setInputValue}
			  	        setOpenSearch={setOpenSearch}
                  searchText={`Search everywhere...`}
				          url={`/companies/api/search/global`}
              	/>  
				:
			  	<SearchIcon onClick={()=>setOpenSearch(true)} style={{marginLeft: isMobile() && !name ? -30 : ''}}/>
			}
            </Left>
			{!isMobile() ?
            	<Stack direction="row" alignItems="center">
            	 	<Box component='span' borderRight={1}>
            	 	   <MenuButton>
            	 	     <a style={{color:'white',textTransform:'none'}} href='/companies'>COMPANIES</a>
            	 	   </MenuButton>
            	 	 </Box>
           	 	<Box component='span' borderRight={1}>
           	 	   <MenuButton>
           	 	     <a style={{color:'white',textTransform:'none'}} href='/news'>NEWS</a>
           	 	   </MenuButton>
           	 	 </Box>
           	 	 <Box component='span' borderRight={1}>
            	  <MenuButton onClick={handleValueAddClick} style={{textTransform:'none'}}>
            	    VALUE ADD ▾
            	  </MenuButton>
            	  <Menu
            	    anchorEl={valueAddAnchorEl}
            	    open={Boolean(valueAddAnchorEl)}
            	    onClose={()=>setValueAddAnchorEl(null)}
            	    anchorOrigin={{vertical: "bottom",horizontal: "center",}}
            	    transformOrigin={{vertical: "top",horizontal: "center",}}
            	  >             
            	    <BoringLink href='/contacts/list'><DropDownMenu onClick={()=>setValueAddAnchorEl(null)}>MVP Network</DropDownMenu></BoringLink>
            	    <BoringLink href='/contacts/seconddegree'><DropDownMenu onClick={()=>setValueAddAnchorEl(null)}>Extended Network</DropDownMenu></BoringLink>
            	    <BoringLink href='/people'><DropDownMenu onClick={()=>setValueAddAnchorEl(null)}>All Employees</DropDownMenu></BoringLink>
            	    {false &&<BoringLink href='/tasks'><DropDownMenu  onClick={()=>setValueAddAnchorEl(null)}>Tasks</DropDownMenu></BoringLink>}
            	    <BoringLink href='/connector'><DropDownMenu onClick={()=>setValueAddAnchorEl(null)}>Connector</DropDownMenu></BoringLink>
					        
            	  </Menu>
              </Box>
           	 	 <Box component='span' borderRight={1}>
            	  <MenuButton onClick={handleMarketingClick} style={{textTransform:'none'}}>
            	    TOOLS ▾
            	  </MenuButton>
            	  <Menu
            	    anchorEl={marketingAnchorEl}
            	    open={Boolean(marketingAnchorEl)}
            	    onClose={()=>setMarketingAnchorEl(null)}
            	    anchorOrigin={{vertical: "bottom",horizontal: "center",}}
            	    transformOrigin={{vertical: "top",horizontal: "center",}}
            	  >             
                  <BoringLink><DropDownMenu onClick={()=>setShowEmailFinder(true)}>Email Finder</DropDownMenu></BoringLink>
                  <BoringLink href='/lp_search'><DropDownMenu onClick={()=>setMoreAnchorEl(null)}>Institutional Research</DropDownMenu></BoringLink>
            	    <BoringLink href='/lead_gen'><DropDownMenu onClick={()=>setMoreAnchorEl(null)}>Outreach Cleaner</DropDownMenu></BoringLink>
                  <BoringLink href='/li_outreach'><DropDownMenu onClick={()=>setMoreAnchorEl(null)}>Automated LI</DropDownMenu></BoringLink>
            	    <BoringLink href='/finder'><DropDownMenu onClick={()=>setMoreAnchorEl(null)}>LI Profile Finder</DropDownMenu></BoringLink>
            	  </Menu>
              </Box>
            	<Box component='span' borderLeft={1}>
            	  <MenuButton onClick={handleMoreClick} style={{textTransform:'none'}}>
            	    MORE ▾
            	  </MenuButton>
            	  <Menu
            	    anchorEl={moreAnchorEl}
            	    open={Boolean(moreAnchorEl)}
            	    onClose={()=>setMoreAnchorEl(null)}
            	    anchorOrigin={{vertical: "bottom",horizontal: "center",}}
            	    transformOrigin={{vertical: "top",horizontal: "center",}}
            	  >                 
					<BoringLink href='/profile'><DropDownMenu onClick={()=>setMoreAnchorEl(null)}>My Profile</DropDownMenu></BoringLink>     	    
	            	{isManager() && <BoringLink href='/stats'><DropDownMenu onClick={()=>setMoreAnchorEl(null)}>Outreach Stats</DropDownMenu></BoringLink>}
  					{isManager() &&<BoringLink href='/outreach'><DropDownMenu onClick={()=>setMoreAnchorEl(null)}>Outreach Flow</DropDownMenu></BoringLink>}
					        <BoringLink href='/docgen'><DropDownMenu onClick={()=>setMoreAnchorEl(null)}>Document Generator</DropDownMenu></BoringLink>
                  <BoringLink onClick={()=>clearUserInfoAndForwardToLogin()}><DropDownMenu onClick={()=>setMoreAnchorEl(null)}>Log Out</DropDownMenu></BoringLink>
            	  </Menu>
            	</Box>
              <SmartToyOutlinedIcon sx={{cursor:'pointer',fontSize:sv.bodyText.fontSize*2,marginLeft:1,marginRight:2}} onClick={()=>setShowJarvisGPT(!showJarvisGPT)}/>
              {(setShowHelp && !isMobile()) && <HelpOutlineIcon sx={{cursor:'pointer',fontSize:sv.bodyText.fontSize*2}} onClick={()=>setShowHelp(!showHelp)}/>}
            	</Stack>
				  :
				<Box>
				  <MenuIcon onClick={()=>setDrawerOpen(true)}/>
				</Box>
				}
          </Toolbar>
      </AppBar>
	  <Drawer 
			open={drawerOpen} 
			anchor="right" 
			onClose={()=>setDrawerOpen(false)} 
			classes={{
          	  paper: classes.drawerPaper,
        	}}
		>
      <List component="nav">
            <ListItem button onClick={() => history.push('/companies')}>
              <ListItemText primary="Companies" />
            </ListItem>

            <ListItem button onClick={() => history.push('/news')}>
              <ListItemText primary="News" />
            </ListItem>

            <ListItem button onClick={() => handleClick(setOpenValueAdd)}>
              <ListItemText primary="Value Add" />
              {openValueAdd ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openValueAdd} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button onClick={() => history.push('/contacts/list')}>
                  <ListItemText primary="MVP Network" />
                </ListItem>
                <ListItem button onClick={() => history.push('/contacts/seconddegree')}>
                  <ListItemText primary="Other Connections" />
                </ListItem>
                <ListItem button onClick={() => history.push('/people')}>
                  <ListItemText primary="All Employees" />
                </ListItem>
                <ListItem button onClick={() => history.push('/tasks')}>
                  <ListItemText primary="Tasks" />
                </ListItem>
                <ListItem button onClick={() => history.push('/connector')}>
                  <ListItemText primary="Connector" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={() => handleClick(setOpenTools)}>
              <ListItemText primary="Tools" />
              {openTools ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openTools} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button onClick={() => setShowEmailFinder(true)}>
                  <ListItemText primary="Email Finder" />
                </ListItem>
                <ListItem button onClick={() => history.push('/lp_search')}>
                  <ListItemText primary="Institutional Research" />
                </ListItem>
                <ListItem button onClick={() => history.push('/lead_gen')}>
                  <ListItemText primary="Lead Generator" />
                </ListItem>
                <ListItem button onClick={() => history.push('/finder')}>
                  <ListItemText primary="Profile Finder" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={() => handleClick(setOpenMore)}>
              <ListItemText primary="More" />
              {openMore ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openMore} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button onClick={() => history.push('/profile')}>
                  <ListItemText primary="My Profile" />
                </ListItem>
                {isManager() && (
                  <>
                    <ListItem button onClick={() => history.push('/stats')}>
                      <ListItemText primary="Outreach Stats" />
                    </ListItem>
                    <ListItem button onClick={() => history.push('/outreach')}>
                      <ListItemText primary="Outreach Flow" />
                    </ListItem>
                  </>
                )}
                <ListItem button onClick={() => history.push('/vcs/edit')}>
                  <ListItemText primary="Investors" />
                </ListItem>
                <ListItem button onClick={() => history.push('/docgen')}>
                  <ListItemText primary="Document Generator" />
                </ListItem>
                <ListItem button onClick={() => clearUserInfoAndForwardToLogin()}>
                  <ListItemText primary="Log Out" />
                </ListItem>
              </List>
            </Collapse>
          </List>
	  </Drawer>
    <JarvisGPT 
        open={showJarvisGPT} 
        close={()=>setShowJarvisGPT(false)} 
        path={path} 
        setFilterModel={setFilterModel} 
        setSortModel={setSortModel}
        setSortCode={setSortCode}
        makeFilterValue={makeFilterValue}
        setFilterValue={setFilterValue}
        setFetchable={setFetchable}
        setWarning={setWarning}
        selectionModel={selectionModel}
        companyName={companyName}
        companyId={companyId}
        intros={intros}
        files={files}
      /> 
    <EmailFinder
        open={showEmailFinder}
        close={()=>setShowEmailFinder(false)}
    />
  </>
  );
}