import React, { useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import Header from './Header'
import Grid from '@mui/material/Grid';
import { authenticatedFetch, setProfiled, unsetProfiled } from '../utils/auth';
import { styled } from '@mui/material/styles';
import {Text} from '../constants/StyleComponents';
import sv from '../constants/Styles';
import SearchBox from './SearchBox'
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const HeaderText = styled(Text)({
	fontSize: sv.bodyText.fontSize * 1.8,
	textAlign:'center',
	marginTop:30,
	marginBottom:10,
})

const SubHeaderText = styled(Text)({
	fontSize: sv.bodyText.fontSize * 1.1,
	textAlign:'center',
	marginBottom:40,
  marginLeft:'20%',
  marginRight:'20%'
  
})

const SubHead = styled(Text)({
	fontSize: sv.bodyText.fontSize * 1.4,
	textAlign:'center',
	marginBottom:0,
})

const Item = styled('li')({
	marginLeft:20,
})

const ItemText = styled('span')({
	fontSize: sv.bodyText.fontSize * 1.2,
})

const IB = styled(IconButton)({
	width:40,
	height:40,
	marginLeft:15,
	marginBottom:10
	
})

export default function Profile({history}) {

  const [warning,setWarning] = useState({text:'',severity:''})
  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [employers, setEmployers] = useState([])
  const [schools, setSchools] = useState([])
  const [valueSchools, setValueSchools] = useState(null)
  const [inputValueSchools, setInputValueSchools] = useState('')
  
  useEffect(()=>{
    async function getProfile() {
      const res = await authenticatedFetch('/companies/api/profile', {method: 'GET'})
	    let obj = JSON.parse(res)
      setEmployers(obj.employers)
      setSchools(obj.schools)
    }
    getProfile()
  },[])
  
  useEffect(()=> {
    if (employers.length > 0 && schools.length > 0) {
      setProfiled()
    } else {
      unsetProfiled()
    }
  },[employers,schools])

  function addToProfile(id,kind) {
    async function saveProfile() {
        setWarning({text:"Saving...",severity:'info'})
        let res = await authenticatedFetch('/companies/api/profile/add', {
          method: 'POST', 
          body: JSON.stringify({
            [kind]:id
          })
        })
        setWarning({text:"Saved!",severity:'success'})
        let obj = JSON.parse(res)
        setEmployers(obj.employers)
        setSchools(obj.schools)
    }
    saveProfile()  
  }
  
  function deleteFromProfile(id,kind) {
    async function deleteProfile() {
        setWarning({text:"Saving...",severity:'info'})
        let res = await authenticatedFetch('/companies/api/profile/delete', {
          method: 'POST', 
          body: JSON.stringify({
            [kind]:id
          })
        })
        setWarning({text:"Saved!",severity:'success'})
        let obj = JSON.parse(res)
        setEmployers(obj.employers)
        setSchools(obj.schools)
    }
    deleteProfile()  
  }

  const doChange = (event, newValue) => {
    if (newValue){
      setInputValue("")
      addToProfile(newValue.id,'company')
    }
  }
  
  const doChangeSchools = (event, newValue) => {
    if (newValue){
      setValueSchools(newValue)
      addToProfile(newValue.id,'school')
    }
  }

  return (
    <>
      <Header 
        history={history}
        name={'Profile'}
        warning={warning}
        setWarning={setWarning}
      />
      <Container>
		<Grid item xs={12}>
			<HeaderText>Personalize Jarvis</HeaderText>
      <SubHeaderText>Add your previous employers and schools for targeted outreach suggestions. Related companies will be available under the "For You" Saved Search. At least one school and one employer must be added to continue, come back and edit anytime under My Profile.</SubHeaderText>
      
		</Grid>
		<Grid container spacing={10}>
			<Grid item xs={6}>
				<SubHead>Past Employers</SubHead>
              	<SearchBox
              	  value={value}
				          style={{width:'100px'}}
              	  setValue={setValue}
              	  doChange={doChange}
              	  inputValue={inputValue}
              	  setInputValue={setInputValue}
				          url={`/companies/api/search/companies`}
				/>
			  <ul>
              {employers.map((emp,ind) =>
                      <Item key={ind}>
					  	        <ItemText>{emp.company__name}</ItemText>
                    	<IB edge="end" onClick={()=>deleteFromProfile(emp.id,'company')}>
                    	  <DeleteIcon />
                    	</IB>
					  </Item>
              )}
			  </ul>
			</Grid>
			<Grid item xs={6}>
				<SubHead>Past Schools</SubHead>
                	<SearchBox
                	  value={valueSchools}
  				          style={{width:'100px'}}
                	  setValue={setValueSchools}
                	  doChange={doChangeSchools}
                	  inputValue={inputValueSchools}
                	  setInputValue={setInputValueSchools}
  				  url={`/companies/api/search/schools`}
  				/>
  			  <ul>
                {schools.map((emp,ind) =>
                      <Item key={ind}>
					  	      <ItemText>{emp.school__name}</ItemText>
                    	<IB edge="end" onClick={()=>deleteFromProfile(emp.id,'school')}>
                    	  <DeleteIcon />
                    	</IB>
					  </Item>
                )}
  			  </ul>
			</Grid>
		</Grid>
		
      </Container>
       
    </>
  );
}