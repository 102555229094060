import React, {useEffect,useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";
import { Text, Section } from '../constants/StyleComponents';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { authenticatedFetch } from '../utils/auth';

const ErrorText = styled(Text)({
  color:'red',
  fontStyle:'italic',
})

export default function EditSnooze({open,close,date,id,setDate}) {
  const { register, handleSubmit, errors } = useForm();
  const [defaultDate,setDefaultDate] = useState('')
  
  const onSubmit = (form) => {
    let url = `/companies/api/company/${id}/update`
    date = form['date'] + " 00:00:00"
    async function saveCompany() {
      await authenticatedFetch(url, {
              method: 'PATCH',
              body: JSON.stringify({
               snooze_date : date
              })
            })
        }
    saveCompany()
    setDate(form['date'])
    close()
  }
  
  useEffect(() => {
    if (date === '' || date === null || date === undefined){
      var future = new Date();
      future.setDate(future.getDate() + 60)
      let mo = future.getMonth() + 1
      if (mo < 10){
        mo = `0${mo}`
      }
      let day = future.getDate()
      if (day < 10){
        day = `0${day}`
      }
      let formatted = future.getFullYear() + "-" +  mo + "-" +  day
      setDefaultDate(formatted)
    } else {
      setDefaultDate(date.split("T")[0])
    }
  },[date])
  
  return (
      <Dialog 
        open={open}
        onClose={close}
        fullWidth={true}
        maxWidth={'sm'}
      >
      <form onSubmit={handleSubmit(onSubmit)}>   
        <DialogTitle>Edit Snooze Date</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose a date to snooze this company until – on the chosen date the company will move back to the Outreach status
          </DialogContentText>
                  <Section>
                    <TextField
                      type="date"
                      defaultValue={defaultDate} 
                      inputRef={register({required: true})}
                      name="date"
                    />
                    {errors.name && <ErrorText>This field is required</ErrorText>}
                  </Section>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button type='submit' color="primary">
            Save
          </Button>
        </DialogActions>
      </form>       
      </Dialog>
    )
  }
