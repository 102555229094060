import React, {useState,useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export default function EditDeliveryDate({open,close,activeTask,sendMailing}) {
  const [value, setValue] = useState(activeTask?.scheduled?.substring(0,16)||'')
  useEffect(()=>{
    console.log("DATE",value)
  },[value])
  return (
      <Dialog 
        open={open}
        onClose={close}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>Set Delivery Date</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Note: Time is UTC (PT+7, ET+4)
          </DialogContentText>
                    <TextField
                      type="datetime-local"
                      value={value}
                      onChange={(nv)=>setValue(nv.target.value)}
                    />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button type='submit' color="primary" onClick={()=>sendMailing(activeTask?.mid,value)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
