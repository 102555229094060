import React, { useState, useEffect, useRef } from 'react';
import {useParams} from "react-router";
import { authenticatedFetch } from '../utils/auth';
import { styled } from '@mui/material/styles';
import { Title } from '../constants/StyleComponents';
import Box from '@mui/material/Box';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Language from '@mui/icons-material/Language';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Grid2 from '@mui/material/Unstable_Grid2'
import {formatMMDDYYYY,money,titleize} from '../utils/tools';
import sv from '../constants/Styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Header from './Header';
import InnerComments from './InnerComments'
import IntroTable from './IntroTable'
import CircularProgress from '@mui/material/CircularProgress';
import {isMobile} from '../utils/tools'
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import Chip from '@mui/material/Chip';
import AddEditContact from './AddEditContact.js'
import CircleIcon from '@mui/icons-material/Circle';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ExpandableBox from './ExpandableBox'
import pitchbook from '../assets/pitchbook.png';


const Link = styled('a')({
  cursor: 'pointer',
})

export default function Investor({history}) {
  const [data,setData] = useState(null)
  const [warning,setWarning] = useState({text:'',severity:''})
  const [commentsOpen, setCommentsOpen] = useState(isMobile() ? false : true)
  const [showAddEditContact, setShowAddEditContact] = useState(false)
  const [employers, setEmployers] = useState([])
  const [summary, setSummary] = useState('')
  const [summaryStatus, setSummaryStatus] = useState('')
  const [slackSummary, setSlackSummary] = useState(true)
  const [pageLoading,setPageLoading] = useState(false)
  const {id} = useParams()
  const [introRows, setIntroRows] = useState([])
  const [comments, setComments] = useState([])
  const [tab,setTab] = useState(0)
  
  
  setCommentsOpen(false) // REMOVE THIS
  
  const boxRef = useRef(null)  
  
  const currentVintageYear = new Date().getMonth() < 9 ? new Date().getFullYear() : new Date().getFullYear() + 1
  
  useEffect(() => {
    if (data?.name){document.title = data.name + " | Jarvis"}
  },[data])
  
  
  function boldHeader(params){
    return (
      <strong style={{fontSize:18}}>{titleize(params.field)}</strong>
    )
  }
  const investmentCols = [
    { headerName: 'Name',renderHeader:(params)=>boldHeader(params),headerAlign: 'center', field: 'name',filterable:false,width:200},
    { headerName: 'Amount',renderHeader:(params)=>boldHeader(params),headerAlign: 'center',align:'center', field: 'amount', type: 'number',width:200,filterable:false,valueFormatter: (params) => money(params.value)},
    { headerName: 'Date',renderHeader:(params)=>boldHeader(params),headerAlign: 'center',align:'center', field: 'date', type: 'date',width:200,filterable:false,valueFormatter: (params) => formatMMDDYYYY(params.value)},
     
  ]
  
  function scoreIcon(score,kind){
    if (score === null){return null}
    if (kind === 'investor' && score === 0){return null}
    if (score >= 80) {
      return <KeyboardDoubleArrowUpIcon sx={{color:'green',verticalAlign:'middle'}}/>
    } else if (score >= 50) {
      return <KeyboardArrowUpIcon sx={{color:'green',verticalAlign:'middle'}}/>
    } else if (score >= 0 || (score && kind === 'investor')) {
      return <HorizontalRuleIcon sx={{color:'#ffd700',verticalAlign:'middle'}}/>
    } else if (score >= -50) {
      return <KeyboardArrowDownIcon sx={{color:'red',verticalAlign:'middle'}}/>
    } else if (score) {
      return <KeyboardDoubleArrowDownIcon sx={{color:'red',verticalAlign:'middle'}}/>
    }
  }

  function handleContactClose(){
    setShowAddEditContact(false)
  } 
   
  const postSummary = () => {   
      async function updateData() {      
        setWarning({text:"Saving...",severity:'info'})
        await authenticatedFetch(`/companies/api/contacts/update/${id}`, {
          method: 'PATCH', 
          body: JSON.stringify({
            expertise: summary,
            slack: slackSummary
          })
        })
        setWarning({text:"Saved!",severity:'success'})
        setSummaryStatus('saved')
      }
      updateData()
  }
  
  const updatePerson = (k,v) => {
    async function updateData() {      
      let res = await authenticatedFetch(`/companies/api/contacts/update/${id}`, {
        method: 'PATCH', 
        body: JSON.stringify({
          [k]: v
        })
      })
      setData(res)
      
      let empList = [];
      [...new Set(res.employees.map((e)=>e.company?.name || e.institution))].forEach((e) => {
        empList.push(res.employees.filter((emp) => (emp.company?.name || emp.institution) === e)[0])
      })
      setEmployers(empList)
      setSummary(res.expertise)
    }
    updateData()
  }
  
  useEffect(() => {
    async function fetchData() {
      let url = `/companies/api/investor/${id}`
      let res = await authenticatedFetch(url, {method: 'GET'})
      console.log("data",res)
      setData(res)
    }
    fetchData()
    
    async function getComments() {
      let url = `/companies/api/company/${id}/personcomments`
      let res = await authenticatedFetch(url, {method: 'GET'})
      setComments(res)
    }
    getComments()
  }, [id]);

  return (
    <>
    <Header 
      history={history}
      name={''}
      doSearch={null}
      warning={warning}
      setWarning={setWarning}
    />
    { data &&
      <>
      <AppBar position="sticky" style={{marginTop:10,color:'#06283C',backgroundColor:'white',boxShadow:'none'}}>
         <Grid2 container>
           <Grid2 xs={12} md={8}>
            <Stack direction={isMobile() ? "column" : "row"} sx={{paddingLeft:2,paddingTop:2}}>
             <Title component="span" sx={{fontSize: sv.bodyText.fontSize * 2,}}>{data.name}</Title>
             <Stack direction="row" spacing={1} >
               <Link sx={{marginLeft:1}}><EditOutlinedIcon style={{fontSize: sv.bodyIcon.fontSize*1.4, cursor:'pointer' }} onClick={() => {setShowAddEditContact(true)}}/></Link>
               {data.website && <Link href={`//www.${data.website}`} target="_blank"><Language style={{ fontSize: sv.bodyText.fontSize * 1.6, color:"0F4EB5" }}/></Link>}
               {data.investor_pitchbook_id && <Link target="_blank" href={`//my.pitchbook.com/profile/${data.investor_pitchbook_id}/investor/profile`}><img style={{marginLeft:4,width:20}} src={pitchbook} alt="Pitchbook"/></Link>}
               {data.pitchbook_person_id && <Link target="_blank" href={`//my.pitchbook.com/profile/${data.pitchbook_person_id}/person/profile`}><img style={{marginLeft:4,width:20}} src={pitchbook} alt="Pitchbook"/></Link>}
               
             </Stack>
            </Stack>
           </Grid2>
         </Grid2>
      </AppBar>
      
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={pageLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid2 container columns={12} ref={boxRef} sx={{backgroundColor:sv.colors.blue1,padding:2}} spacing={2}>
        <Grid2 xs={12} md={9} sx={{padding:2,overflow:'auto',borderRadius:3,backgroundColor:'white'}}>
          <Typography sx={{fontFamily:'system-ui',fontSize:'1.1rem',fontWeight:700}} color='primary'>Overview''</Typography>
          <ExpandableBox text={data.description} maxLength={isMobile() ? 200 : 1000} styles={{fontFamily:'system-ui',fontSize:'0.95rem'}}/>
        </Grid2>
      </Grid2>
        
        
        
        
        
        
    {false &&
     <Grid2 container columns={12} ref={boxRef} sx={{backgroundColor:sv.colors.blue1,padding:2}} spacing={2}>
       {(!isMobile() || !commentsOpen) && <Grid2 xs={12} md={9} sx={{padding:2,overflow:'auto',borderRadius:3,backgroundColor:'white'}}>
              <Box sx={{margin:2,marginTop:0}}>
                {data.tags.map((t,ind) => (
                  <Chip key={ind} label={t} onDelete={()=>updatePerson('tags',data.tags.filter((x)=>x !== t))} color='primary' sx={{padding:1,fontSize:14,marginRight:2,marginTop:2}}/>
                ))}
              </Box>
            
            
            
            
            <Tabs sx={{paddingBottom:2}} value={tab} variant="fullWidth" onChange={(e,nv)=>setTab(nv)}>
                <Tab label="Investor Overview" sx={{'&.Mui-selected': {backgroundColor:sv.colors.blue4,},backgroundColor:sv.colors.blue5,borderRight:1,}}/>
                <Tab label="Value-Add" sx={{'&.Mui-selected': {backgroundColor:sv.colors.blue4,},backgroundColor:sv.colors.blue5,borderRight:1,}}/>
                <Tab label="Background" sx={{'&.Mui-selected': {backgroundColor:sv.colors.blue4,},backgroundColor:sv.colors.blue5,borderRight:1,}}/>
            </Tabs>
                
            <Grid2 container spacing={2} sx={{borderRadius:3,backgroundColor:'gray'}}>    
              {tab === 0 &&
              <>
                
                <Grid2 xs={12} sx={{borderRadius:3,backgroundColor:'white',marginTop:1}}>
                  <Stack direction={isMobile() ? "column" : "row"} justifyContent="space-evenly">
                    <Stack>
                        <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>Status</Typography>
                        <Typography sx={{fontWeight:700}} color='primary'>{data.kind}&nbsp;</Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem sx={{borderRightWidth:2,borderColor:sv.colors.blue1}}/>
                    <Stack>
                        <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>First Investment</Typography>
                        <Typography sx={{fontWeight:700}} color='primary'>{formatMMDDYYYY((data.deals[data.deals.length - 1] || {}).date)}&nbsp;</Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem sx={{borderRightWidth:2,borderColor:sv.colors.blue1}}/>
                    <Stack>
                        <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>Signup Date</Typography>
                        <Typography sx={{fontWeight:700}} color='primary'>{formatMMDDYYYY(data.created_at)}&nbsp;</Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem sx={{borderRightWidth:2,borderColor:sv.colors.blue1}}/>
                    <Stack>
                        <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>Total $$</Typography>
                        <Typography sx={{fontWeight:700}} color='primary'>{money(data.deals.map((d)=>d.amount).reduce(function(a,b){return a+b},0))}&nbsp;</Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem sx={{borderRightWidth:2,borderColor:sv.colors.blue1}}/>
                    <Stack>
                        <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>This Year $$</Typography>
                        <Typography sx={{fontWeight:700}} color='primary'>{money(data.deals.filter((d)=>d.vintage_year === currentVintageYear).map((d)=>d.amount).reduce(function(a,b){return a+b},0))}&nbsp;</Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem sx={{borderRightWidth:2,borderColor:sv.colors.blue1}}/>
                    <Stack>
                        <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>Fund Investor</Typography>
                        <Typography sx={{fontWeight:700}} color='primary'>{data.deals.some((d)=>d.is_vintage) ? 'Yes' : 'No'}&nbsp;</Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem sx={{borderRightWidth:2,borderColor:sv.colors.blue1}}/>
                    <Stack>
                        <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>Co-Invests</Typography>
                        <Typography sx={{fontWeight:700}} color='primary'>{data.deals.filter((x)=>!x.is_vintage).length}&nbsp;</Typography>
                    </Stack>
                  </Stack>
                </Grid2>
                <Grid2 md={12} lg={7} sx={{borderRadius:3,marginTop:2,backgroundColor:'white',width:'100%'}}>
                  <Box sx={{ height:300}}>
                      <DataGridPro
                        hideFooter
                        columns={investmentCols}
                        rows={data.deals}
                        density="compact"
                      />
                  </Box>
                </Grid2>
                <Grid2 md={12} lg={5} sx={{paddingTop:2,margin:0}}>
                  <Stack justifyContent="space-evenly" sx={{margin:0,backgroundColor:'white',borderRadius:3,height:'93.5%',padding:2}}>
                    <Stack>
                      <Typography sx={{fontWeight:600,color:'gray'}} color='primary'>Hubspot Owner</Typography>
                      <ExpandableBox text={data.user} maxLength={300} styles={{fontWeight:700,lineHeight:1.5}}/>
                    </Stack>
                    <Divider flexItem sx={{borderBottomWidth:2,borderColor:sv.colors.blue1}}/>
                    <Stack>
                     <Typography sx={{fontWeight:600,color:'gray'}} color='primary'>Next Step</Typography>
                     <ExpandableBox text={data.next_step} maxLength={300} styles={{fontWeight:700,lineHeight:1.5}}/>
                    </Stack>
                    <Divider flexItem sx={{borderBottomWidth:2,borderColor:sv.colors.blue1}}/>
                    <Stack>
                     <Typography sx={{fontWeight:600,color:'gray'}} color='primary'>Industries</Typography>
                     <ExpandableBox text={data.industries.join(', ')} maxLength={300} styles={{fontWeight:700,lineHeight:1.5}}/>
                    </Stack>
                    <Divider flexItem sx={{borderBottomWidth:2,borderColor:sv.colors.blue1}}/>
                    <Stack>
                     <Typography sx={{fontWeight:600,color:'gray'}} color='primary'>Technologies</Typography>
                     <ExpandableBox text={data.technologies.join(', ')} maxLength={300} styles={{fontWeight:700,lineHeight:1.5}}/>
                    </Stack>
                  </Stack>  
                </Grid2> 
              </>}
              
              {tab === 1 &&
                <>
                  <Grid2 xs={12} sx={{borderRadius:3,backgroundColor:'white',marginTop:1}}>
                      <Box sx={{float:'right',fontSize:12,marginRight:15,marginBottom:2}} component='span'>{summaryStatus}</Box>
                        <TextareaAutosize
                          defaultValue={summary}
                          minRows={3}
                          style={{padding:10,fontSize:18,width: '80%',marginLeft:'10%',fontFamily:"Roboto,Helvetica,Arial,sans-serif"}}
                          onChange={(e)=>{
                            setSummary(e.currentTarget.value)
                            setSummaryStatus(<em>unsaved</em>)
                          }}
                        />
                       <Box sx={{textAlign:'right'}}>           
                         <FormControlLabel
                           control={
                             <Checkbox
                               color="primary"
                               defaultChecked
                               value={slackSummary}
                               onChange={()=>setSlackSummary(!slackSummary)}
                             />
                           }
                           label="Post to Slack"
                         />
  	                     <Button sx={{marginLeft:5,marginRight:15}} onClick={postSummary} variant="contained" color="primary">
  	                       Save
  	                     </Button>
                       </Box>
                  </Grid2>
                  <Grid2 xs={12} sx={{borderRadius:3,backgroundColor:'white',marginTop:2}}>         
                    <Box sx={{height:300}}>
                      <IntroTable rows={introRows} setWarning={setWarning} setRows={setIntroRows} person={data} company={{}}/>
                    </Box>
                  </Grid2>
                </>
              }
              
              {tab === 2 &&
                <Stack direction={isMobile() ? "column" : "row"} justifyContent="space-between">
                  <Box sx={{borderRadius:3,backgroundColor:'white',marginTop:1,width:isMobile() ? '100%' : '47%',padding:2}}>
                      <Typography sx={{color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.4}}>Employment</Typography> 
                      {!data.url && <Typography variant="body1" component='div' sx={{textAlign:'center',padding:2,fontStyle:'italic'}} color='primary'>LinkedIn profile required</Typography>}
                      <Box sx={{overflow:'auto',height:300}}>
                        {employers.map((e,ind) => (
                          <Box key={ind} sx={{marginTop:3}}>
                            <Typography variant="body1" component='span' sx={{marginLeft:2,fontWeight:'bold',fontSize:sv.bodyText.fontSize*1.1}} color='primary'>{e.company ? <Link href={`/company/${e.company.id}`} target="_blank">{e.company?.name || e.institution}</Link> : e.company?.name || e.institution}</Typography>
 		                        {e.company &&
                               <>
                                 <Link
 		                               href={`//${e.company.li_url}`.replace('https://','').replace('http://','')}
 		                               target="_blank"
 		                             >
 		                               <LinkedInIcon style={{ fontSize: sv.bodyText.fontSize * 1.4, color:"0F4EB5" }}/>
 		                              </Link>
                                  <Link
                                    href={`//www.${e.company.website}`}
                                    target="_blank"
                                  >
                                    <Language style={{ fontSize: sv.bodyText.fontSize * 1.4, color:"0F4EB5" }}/>
                                  </Link>
                                  <>
                                    <Typography component='div' variant="body2" sx={{paddingLeft:2,paddingRight:2}} color='primary'>Employees: {e.company.current}</Typography>
                                    {e.company.growth_score &&<Typography component='div' variant="body2" sx={{paddingLeft:2,paddingRight:2}} color='primary'>Growth: {e.company.growth_score} {scoreIcon(e.company.growth_score,'retgrow')}</Typography>}
                                    {(e.company.industries && e.company.technologies.length > 0) &&<Typography component='div' variant="body2" sx={{paddingLeft:2,paddingRight:2}} color='primary'>Industries: {e.company.industries.concat(e.company.technologies).join(', ')}</Typography>}
                                  </>
                                  <Typography variant="body1" sx={{marginTop:1,paddingLeft:2,paddingRight:2,maxWidth:'90%'}} color='primary'>{e.company.clean_description || e.company.harmonic_desc || e.company.description || e.company.li_longdesc}</Typography>
                            </>}
                            {data.employees.filter((x) => (x.company?.name || x.institution) === (e.company?.name || e.institution)).map((x,ind) => (
                             
                             <Box key={ind} sx={{marginLeft:5,maxWidth:'70%',marginTop:2}}>
                                <CircleIcon color="primary" sx={{fontSize:sv.bodyText.fontSize*0.8}}/>
                                <Typography variant="body1" component='span' sx={{marginLeft:1,fontWeight:'bold'}} color='primary'>{x.title}</Typography>
                                {x.startdate && <Typography variant="body1" component='div' sx={{marginLeft:3}} color='primary'>{formatMMDDYYYY(x.startdate)} - {formatMMDDYYYY(x.enddate) || 'Present'}</Typography>}
                                <Typography variant="body2" component='div' sx={{marginLeft:3}} color='primary'>{x.description}</Typography>
                             </Box>
                            ))}
                          </Box>
                        ))}
                      </Box>
                  </Box>
                  <Box sx={{borderRadius:3,backgroundColor:'white',marginTop:1,width:isMobile() ? '100%' : '47%',padding:2}}>
                      <Typography sx={{color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.4}}>Education</Typography>   
                      {!data.url && <Typography variant="body1" component='div' sx={{textAlign:'center',padding:2,fontStyle:'italic'}} color='primary'>LinkedIn profile required</Typography>}
                      <Box sx={{overflow:'auto',height:300}}>
                        {data.students.map((e,ind) => (
                          <Box key={ind} sx={{marginTop:3}}>
                            <Typography variant="body1" component='span' sx={{marginLeft:2,fontWeight:'bold',fontSize:sv.bodyText.fontSize*1.1}} color='primary'>{e.school?.name || e.institution}</Typography>
                            {data.students.filter((x) => (x.school?.name || x.institution) === (e.school?.name || e.institution)).map((x,ind) => (
                             
                             <Box key={ind} sx={{marginLeft:5,maxWidth:'70%',marginTop:2}}>
                                <CircleIcon color="primary" sx={{fontSize:sv.bodyText.fontSize*0.8}}/>
                                <Typography variant="body1" component='span' sx={{marginLeft:1,fontWeight:'bold'}} color='primary'>{x.title}</Typography>
                                {x.startdate && <Typography variant="body1" component='div' sx={{marginLeft:3}} color='primary'>{formatMMDDYYYY(x.startdate)} - {formatMMDDYYYY(x.enddate) || 'Present'}</Typography>}
                                <Typography variant="body2" component='div' sx={{marginLeft:3}} color='primary'>{x.description}</Typography>
                             </Box>
                            ))}
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </Stack>
              }
          </Grid2>
        </Grid2>}
        {(!isMobile() || commentsOpen) &&<Grid2 md={3} xs={12}> 
            <InnerComments style={{borderRadius:3,paddingTop:2,backgroundColor:'white'}} contactId={id} person={data} setWarning={setWarning} comments={comments} setComments={setComments}/>
        </Grid2>}
      </Grid2>}
        <AddEditContact setWarning={setWarning} open={showAddEditContact} close={handleContactClose} contact={data} person={data} data={data} setContact={setData} setPageLoading={setPageLoading}/>
      </>
      }
    </>
      

      
  );
}