import React, {useState,useEffect} from 'react'
import Header from './Header'
import { authenticatedFetch,getUser } from '../utils/auth';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import 'react-csv-importer/dist/index.css';
import { Importer, ImporterField } from 'react-csv-importer';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import mvp from '../assets/mvp-square-white.svg'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { styled } from '@mui/material/styles';
import sv from '../constants/Styles'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import {uuid} from '../utils/tools'
import Button from '@mui/material/Button';

const NameText = styled(Typography)({
  fontSize: sv.bodyText.fontSize*1.2,
  lineHeignt: 40,
  marginLeft:20,
})

const Logo = styled('img')({
  height:40,
})

export default function LiFinder({history}) {
  const [activeStep, setActiveStep] = useState(0)
  const [warning,setWarning] = useState({text:'',severity:''})
  const [batchName, setBatchName] = useState()
  const [batches, setBatches] = useState([])
  const [selectedBatch, setSelectedBatch] = useState(null)
  const [batchData, setBatchData] = useState([])
  const [selectedBatchName, setSelectedBatchName] = useState('')
  
  const rando = uuid()
  
  useEffect(() => {
     async function getBatches() {
       let url = `/companies/api/batchList`
       let res = await authenticatedFetch(url, {method: 'GET'})
       setBatches(res.results)
       
     }
     getBatches()
  }, []);
  
  useEffect(() => {
     async function getBatch() {
       let url = `/companies/api/batch/${selectedBatch}?size=5000`
       let res = await authenticatedFetch(url, {method: 'GET'})
       setBatchData(res.results)
       setActiveStep(1)
     }
     selectedBatch && getBatch()
  }, [selectedBatch]);
  
  let allRows = []

  const cols = [
    { headerName: 'Firstname', field: 'firstname',width:200},
    { headerName: 'Lastname', field: 'lastname',width:200},
    { headerName: 'School', field: 'school',width:200},
    { headerName: 'LinkedIn', field: 'url',width:200}, 
    { headerName: 'Processed', field: 'last_run',width:200},     
  ]

  return <>
  {getUser().email === 'wes@mvp-vc.com' ?
    <AppBar position="sticky" style={{boxShadow: 'none'}}>
        <Toolbar variant="dense">
            	<span>
            	  <Logo component='span' src={mvp}/>
            	</span>
            	<NameText component='span'>
            	  Profile Finder
            	</NameText>
        </Toolbar>
    </AppBar>
    :
  <Header 
    history={history}
    name={'Profile Finder'}
    warning={warning}
    setWarning={setWarning}
  />
  }
	<Grid alignItems='center' justifyContent='center'>
  
     {activeStep === 0 &&
       <>
        <Typography variant="h5" color='primary' sx={{marginTop:10,textAlign:'center'}}>Upload CSV with Firstname, Lastname, School to try to find LinkedIn Profile</Typography>
        <Typography variant="body2" color='primary' sx={{marginTop:2,textAlign:'center'}}>Note, "school" can also be a company or location but the column title must still be "school."</Typography>
        
        <Box sx={{width:'60%',marginTop:2,marginLeft:'20%'}}>
       <FormControl variant="standard" style={{width:400,marginBottom:20,marginTop:20}} size="small">
         <TextField
             variant="standard"
             value={batchName}
             onChange={(e)=>setBatchName(e.target.value)}
             placeholder="Enter batch name"
           />
       </FormControl>
        <Importer
           assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
           restartable={false} // optional, lets user choose to upload another file when import is complete
           processChunk={async (rows, { startIndex }) => {
              for (let row of rows) {
                let res = await authenticatedFetch(`/companies/api/finder`, {
                  method: 'POST',
                  body: JSON.stringify({
                    firstname: row.firstname,
                    lastname: row.lastname,
                    school: row.school,
                    batch: batchName || rando
                  })
                })
                allRows = allRows.concat(res)
              }
           }}
           onComplete={({ file, preview, fields, columnFields }) => {
             // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
           }}
           >
           <ImporterField name="firstname" label="firstname" />
           <ImporterField name="lastname" label="lastname" />
           <ImporterField name="school" label="school" />
       </Importer>
       <Table style={{marginTop:50}}>
           <TableHead>
             <TableRow>
                <TableCell>Batch (click to download)</TableCell>
                <TableCell>Creator</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Progress</TableCell>
             </TableRow>
           </TableHead>
           <TableBody>
            {batches.map((b,i) => 
              <TableRow key={i}>
                <TableCell style={{cursor:'pointer'}} onClick={()=>{
                  setSelectedBatch(b.id)
                  setSelectedBatchName(b.name)
                }}>{b.name}</TableCell>
                <TableCell>{b.user}</TableCell>
                <TableCell>{b.created_at}</TableCell>
                <TableCell>{b.done[0]} / {b.done[1]}</TableCell>
              </TableRow>
            )}
           </TableBody>
        </Table>
           
      </Box>
     </>
      }
      {activeStep === 1 &&
      <>
        
        <Typography variant="h5" color='primary' sx={{marginTop:10,textAlign:'center'}}>Batch {selectedBatchName} Results</Typography>
        <Box sx={{height:500,width:'60%',marginLeft:'20%',marginTop:5}}>
          <Button variant="text" onClick={()=>setActiveStep(0)}>Back</Button>
          <DataGridPro
            hideFooter
            columns={cols}
            rows={batchData}
            getRowId={(row)=>row.firstname+row.lastname+row.school}
            density="compact"
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </>
      }
	</Grid>

  </>
}
