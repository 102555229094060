import sv from './Styles'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const text = '#222'

const Title = styled(Typography)({
  color: text,
  fontWeight: '600',
  fontSize: sv.bodyText.fontSize * 2,
  lineHeight: '1.25em',
  letterSpacing: .2,
  paddingBottom: 20,
})

const Text = styled(Box)({
  color: text,
  lineHeight: 1.25,
  fontSize: sv.bodyText.fontSize,
})

const SpanText = styled('span')({
  color: text,
  lineHeight: 1.25,
  fontSize: sv.bodyText.fontSize,
})

const BoldText = styled(Text)({
  fontWeight:'bold',
})

const Section = styled(Box)({
  marginTop:20,
})

const ErrorText = styled(Text)({
  color:'red',
  fontStyle:'italic',
})

const Name = styled('a')({
  '&:hover': {
      color: '#1B8D77',
    },
    cursor:'pointer'
})

// const Text = styled()`
//   ${sv.bodyText};
//   line-height: 1.25;
//   color: ${props => props.color || sv.colors.text};
//   margin-bottom: ${props => props.noMargin ? 0 : sv.grid}px;
// `;

// const Container = styled.div`
//   ${sv.bodyText};
//   width: 100%;
//   height: 100vh;
//   overflow: scroll;
// `;
//
// const Content = styled.div`
//   width: 100%;
//   height: 100%;
//   max-width: 1200px;
//   margin: 20px auto;
//   ${sv.box};
//   ${sv.flexColumn};
// `;
//
// const SectionContent = styled.div`
//   width: 100%;
//   max-width: 1200px;
//   margin: 0 auto;
//   padding: 0 ${sv.appPadding}px;
//   ${sv.box};
// `;
//
// const Actions = styled.div`
//   ${sv.flexRow};
//   justify-content: flex-end;
// `;
//
// const PageTitle = styled.div`
//   ${sv.title};
//   margin-bottom: ${sv.grid*2}px;
// `
//
// const SectionTitle = styled.div`
//   ${sv.h2};
//   margin-bottom: ${sv.grid}px;
// `;
//
// const Label = styled.div`
//   ${sv.label};
//   margin-bottom: ${props => props.noMargin ? 0 : sv.grid}px;
// `;
//

//
// const H4 = styled.h4`
//   ${sv.h4};
//   margin: 0;
// `;
//
// const H3 = styled.h3`
//   ${sv.h3};
//   margin: 0;
// `;
//
// const H2 = styled.h2`
//   ${sv.h2};
//   margin: 0;
// `;
//
// const LightText = styled.div`
//   ${sv.bodyText};
//   color: ${sv.colors.buttonText};
// `;
//
// const TextLink = styled.span`
//   text-decoration: none;
//   ${sv.bodyText};
//   color: ${sv.colors.cta};
//   cursor: pointer;
//   &:hover {
//     color: ${sv.colors.hoverCta};
//   }
// `
//
// const Link = styled(RouterLink)`
//   text-decoration: none;
//   ${sv.bodyText};
//   color: ${sv.colors.cta};
//   cursor: pointer;
//   &:hover {
//     color: ${sv.colors.hoverCta};
//   }
// `;
//
// const ExternalLink = styled.a`
//   text-decoration: none;
//   ${sv.bodyText};
//   color: ${sv.colors.cta};
//   cursor: pointer;
//   &:hover {
//     color: ${sv.colors.hoverCta};
//   }
// `;
//
// const Tag = styled.div`
//   padding: ${sv.grid}px;
//   border-radius: ${sv.grid*.5}px;
//   background: blue;
//   color: ${props => props.color ? props.color : sv.colors.cell};
//   font-size: 14px;
//   font-weight: 600;
//   width: fit-content;
//   text-transform: uppercase;
// `
// const Input = styled.input`
//   width: 100%;
//   height: 30px;
//   outline: none;
//   border: none;
//   background: #b9b9b9;
//   padding: 0px;
//   ${sv.bodyText};
//   &:-webkit-autofill, &:-internal-autofill-selected {
//     -webkit-text-fill-color: ${sv.colors.text};
//     -webkit-box-shadow: 0 0 0px 1000px ${sv.colors.cellSubtle} inset;
//   }
// `;

export {
  Title,
  Text,
  Section,
  BoldText,
  ErrorText,
  Name,
  SpanText
}
