import React from 'react';
import {Router, Switch} from "react-router-dom"
import Main from './components/Main'
import history from './constants/History'
import { ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material';
import Box from '@mui/material/Box'
import routes from './constants/routes'


const theme = createMuiTheme(adaptV4Theme({
  overrides: {
          MuiTooltip: {
              tooltip: {
                  fontSize: "0.9em",
              },
          },
      },
  palette: {
    primary: {
      main: '#06283C',
    },
    secondary: {
      main: '#51B748',
    },
  },
}));

export default function App() {

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
          <Box>
            <Router history={history}>
              <Switch>
                {routes.map((route) => (
                  <Main
                    exact
                    key={route.path}
                    path={route.path}
                    component={route.component}
                    isProtected={route.isProtected}
                    history={history}
                  />
                ))}
              </Switch>
            </Router>
          </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}