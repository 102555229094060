import React, { useState,useEffect } from 'react';
import Container from '@mui/material/Container';
import SearchBox from './SearchBox'
import { Title,Text } from '../constants/StyleComponents';
import { styled } from '@mui/material/styles';
import sv from '../constants/Styles';
import { DataGridPro,GridOverlay } from '@mui/x-data-grid-pro';import Grid from '@mui/material/Grid';
import { authenticatedFetch } from '../utils/auth';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

import 'react-csv-importer/dist/index.css';

const SubTitle = styled(Title)({
  fontSize: sv.bodyText.fontSize * 1.1,
  margin:0,
  padding:0
})

const HelpText = styled(Text)({
  fontSize: sv.bodyText.fontSize * 0.9,
  marginTop:5
})

const TipText = styled(Text)({
  color:'white',
})

const StyledTip = styled(Tooltip)({
  marginLeft:5,
})

export default function ConnectorCompaniesStep({history,companyList,setCompanyList,companySelected,setCompanySelected}) {
  const [value,setValue] = useState(null)
  const [inputValue,setInputValue] = useState(null)
  const [competitors,setCompetitors] = useState([])
  const [selectedCompetitors,setSelectedCompetitors] = useState([])
  const [sortModel, setSortModel] = useState([{field: 'name',sort: 'asc',},])
  const [sortCompetitorModel, setSortCompetitorModel] = useState([{field: 'name',sort: 'asc',},])
  
  const handleSelectionModelChange = (newSelection) => {
	setCompanySelected(newSelection)
  }
	
  const doChange = (event, newValue) => {
    if (newValue){
      setValue(null)
      setInputValue('')
      let nobj = {
        'id':newValue.id,
        'name':newValue.name,
        'company_website':newValue.website,
        'contacts':newValue.contacts,
        'description':newValue.description,
        'li_shortdesc':newValue.li_shortdesc,
        'pb_desc':newValue.pb_desc}
      let selected = [...companySelected]
      selected.push(newValue.id)
      setCompanySelected(selected)
      setCompanyList([...companyList,nobj])
    }
  }
  
  const addCompetitor = (newSelection) => {
	let selected = [...companySelected]
	selected = selected.concat(newSelection)
	setCompanySelected(selected)
    setSelectedCompetitors(newSelection)
    let newComps = [...companyList]
    competitors.filter((x) => newSelection.includes(x.id)).forEach((x) => {
      newComps.push({
        'name':x.name,
        'id':x.id,
        'company_website':x.website,
        'contacts':x.contacts,
        'description':x.description,
        'li_shortdesc':x.li_shortdesc,
        'pb_desc':x.pb_desc
      })
    })    
    setCompanyList(newComps)
  }
  
  const cols = [
    { headerName: 'Name', field: 'name', flex:3, renderCell:(params) =>(
      <span>
        <span>{params.row.name}</span>
        {(params.row.description || params.row.li_shortdesc || params.row.pb_desc) &&
        <StyledTip title={<TipText>{params.row.description || params.row.pb_desc || params.row.li_shortdesc}</TipText>} placement='top'>
          <InfoOutlinedIcon style={{ fontSize: sv.bodyIcon.fontSize}}/>
        </StyledTip>
        }
      </span>
      )},
    { headerName: 'Website', flex:3,field: 'company_website'},
    { headerName: 'Contacts', flex:2,field: 'contacts',type:'number'}
  ]
  
  function NoCompanyRows() {
    return (
      <GridOverlay >
        <div>Search for companies by name or website to get started</div>
      </GridOverlay>
    );
  }
  
  function NoCompetitorRows() {
    return (
      <GridOverlay >
        <div style={{margin:20,textAlign:'center'}}>Related companies will appear as Company List is populated</div>
      </GridOverlay>
    );
  }
  
  useEffect(()=>{
    if (companyList.length > 0){
      async function get() {
        const res = await authenticatedFetch(`/companies/api/getcompetitors`, {
          method: 'POST', 
          body: JSON.stringify({
            companies: companyList.map((x)=>x.id),
            connector: true
          })
        })
        setCompetitors(res)
      }
      get()
    }
  },[companyList])
  
  return (
      <Container maxWidth='lg'>
          <Grid container spacing={5} style={{textAlign:'center'}}>
            <Grid item xs={6}>
              <SubTitle>Company List</SubTitle>
            </Grid>
            <Grid item xs={6}>
              <SubTitle>Additional Related Companies</SubTitle>
              <HelpText>Select to add to Company List</HelpText>
            </Grid>
          </Grid>
          <SearchBox
            value={value}
            setValue={setValue}
            doChange={doChange}
            inputValue={inputValue}
            setInputValue={setInputValue}
            searchText={"Add Company"}
			      url={`/companies/api/bigsearch`}
          />  
          <Grid container spacing={5}>
            <Grid item xs={6} style={{height:400,marginTop:5}}>
              <DataGridPro
                columns={cols}
                rows={companyList}
                components={{NoRowsOverlay: NoCompanyRows}}
                density="compact"
                checkboxSelection={true}
                onSelectionModelChange={handleSelectionModelChange}
                sortModel={sortModel}
                selectionModel={companySelected}     
				onSortModelChange={(model) => setSortModel(model)}           
              />
            </Grid>
            <Grid item xs={6} style={{height:400,marginTop:5}}>
              <DataGridPro
                columns={cols}
                rows={competitors}
                density="compact"
                components={{NoRowsOverlay: NoCompetitorRows,}}
                checkboxSelection={true}
                onSelectionModelChange={addCompetitor}
                sortModel={sortCompetitorModel}
                selectionModel={selectedCompetitors}
				        onSortModelChange={(model) => setSortCompetitorModel(model)}
              />
            </Grid>
            <Grid item xs={12} style={{textAlign:'center',margin:0,padding:0}}>
              <Text>Select companes from the Company List and press "Next" to filter contacts</Text>
            </Grid>
          </Grid>
        </Container>
  );
}