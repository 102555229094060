import React, { useState,useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { authenticatedFetch } from '../utils/auth';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Text } from '../constants/StyleComponents';
import sv from '../constants/Styles';
import useDebounce from '../utils/debounce.js'
import ClearIcon from '@mui/icons-material/Clear';
import CircularProgress from '@mui/material/CircularProgress';

const SearchBar = styled(Autocomplete)({
  color:'secondary',
  backgroundColor:'white',
  transition: 'width 0.75s cubic-bezier(0.000, 0.795, 0.000, 1.000)',
  width: props => props.fullWidth ? '100%' : '80%',	
})

const Name = styled(Text)({
  fontSize: sv.bodyText.fontSize * 0.9,
})

const Site = styled(Text)({
  fontSize: sv.bodyText.fontSize * 0.8,
})

const SmallestSite = styled(Text)({
  fontSize: sv.bodyText.fontSize * 0.7,
})

export default function SearchBox({history,value,setValue,doChange,inputValue,setInputValue,searchText="Search...",setOpenSearch,url,fullWidth,multiple}) {
  const [loading,setLoading] = useState(true)
  const [open,setOpen] = useState(false)
  const [options,setOptions] = useState([])
  const debounced = useDebounce(inputValue,300)
  
  useEffect(()=>{
    setLoading(false)
  },[])

  const makeOption = (opt) => {
	if (opt.website||opt.url){
		return opt.name+"   ("+(opt.website || opt.url)+")"
	}
	  return opt.name || ''
  }
  
  useEffect(() => {     
     async function search() {
       const res = await authenticatedFetch(url, {
         method: 'POST', 
         body: JSON.stringify({
           term: debounced
         })
       })
       setOptions(JSON.parse(res))
       setLoading(false)
     }
     if (debounced && debounced.length > 2){
       setLoading(true)
       search()
     }
   }, [debounced,url]);

  return (
    <SearchBar
	      freeSolo
        groupBy={(option) => option.group}
	      multiple={multiple}
        loading={loading}
	      fullWidth={fullWidth}
        clearIcon={loading ? <CircularProgress fontSize="small" size={20}/> : <ClearIcon fontSize="small" />}
        open={!loading && open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={()=> {
          setOpen(false)
          setInputValue('')
        }}
		    onKeyDown={(event) => {
		      if (event.key === 'Enter') {
		        event.defaultMuiPrevented = true;
				if (options[0]){
					setValue(options[0])
					setInputValue(options[0])
					doChange(null,options[0])
				}
		      }
		    }}
		    filterOptions={(x)=>x}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => makeOption(option)}
            renderOption={(props,option) => (
			         <li {...props}>
                 <Box>
 			              <Name>
                      {option.name}
                      {option.website && ` (${option.website})`}
                    </Name>
 			              <Site>
                          {(option.url && !option.website) && `${option.url}`}
                          {option.status && `${option.status}`} 
                          {option.owner__first_name && ` | ${option.owner__first_name}`}
                          {option.latest_round_type__name && ` | ${option.latest_round_type__parent__name||option.latest_round_type__name}`}
                          {option.yr && ` | ${option.mo}/${option.yr}`}
                          {option.industries__name && ` | ${option.industries__name}`}
                          {(!option.industries__name && option.technologies__name) && ` | ${option.technologies__name}`}
                    </Site>
                    <SmallestSite>
                          {(option.harmonic_desc || option.clean_description) && `${(option.clean_description || option.harmonic_desc).substring(0,180)}...`}
                    </SmallestSite>
                 </Box>
			          </li>
		    )}
          options={options}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          value={value}
          onChange={doChange}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={searchText}
              variant="outlined"
              size="small"
            />
          )}
        />
  );
}