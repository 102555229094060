import React, { useState, useEffect } from 'react';
import { authenticatedFetch } from '../utils/auth';
import {useParams} from "react-router";
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Section, Text } from '../constants/StyleComponents';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import {formatMMYYYY} from '../utils/tools';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';

const InputSection = styled(Section)({
  paddingLeft:25,
  paddingRight:25
})

const Question = styled(Box)({
  fontWeight:'bold',
})

const QuestionSection = styled(Section)({
  marginBottom:10,
})

export default function Scorecard() {
  const {id} = useParams();
  const [scorecard, setScorecard] = useState(null)
  const [categories, setCategories] = useState([])
  const [categoryScores, setCategoryScores] = useState({})
  const [runningTotal, setRunningTotal] = useState(0)
  const [runningDenominator, setRunningDenominator] = useState(0)
  const [catDenoms, setCatDenoms] = useState({})
  const [recommendation, setRecommendation] = useState(null)
  const [comments, setComments] = useState({})
  const [values,setValues] = useState({})
  const [companyName, setCompanyName] = useState(null)
  const [warning,setWarning] = useState({text:'',severity:''})
  const [saved, setSaved] = useState(true)
  const [currentCard,setCurrentCard] = useState({})
  const [changed,setChanged] = useState([])

  const handleRecChange = (event) => {
    setRecommendation(event.target.value)
    setSaved(false)
    async function updateScorecard(){
      await authenticatedFetch(`/companies/api/scorecard/${id}`, {
        method: 'POST', 
        body: JSON.stringify({
          recommendation: event.target.value
        })
      })
    }
    updateScorecard()
  }

  const handleChange = (event,cat) => {
      setSaved(false)
      let nobj = {}
      let questionId = event.target.name
      let choiceId = event.target.value
      nobj[questionId] = choiceId
      setValues({...values,...nobj})
      
      async function updateScorecard(){
        const res = await authenticatedFetch(`/companies/api/scorecard/${id}`, {
          method: 'POST', 
          body: JSON.stringify({
            question: questionId,
            answer: choiceId
          })
        })
        if (res.warning){setWarning(res.warning)}
      }
      updateScorecard()
  };
  
  const handleUpToChange = (event,answer,cat) => {
    let nobj = {}
    let questionId = event.target.name
    let choiceValue = event.target.value
    nobj[questionId] = choiceValue
    setValues({...values,...nobj});
    async function updateScorecard(){
      const res = await authenticatedFetch(`/companies/api/scorecard/${id}`, {
        method: 'POST', 
        body: JSON.stringify({
          question: questionId,
          answer: answer,
          points: choiceValue
        })
      })
      if (res.warning){setWarning(res.warning)}
    }
    updateScorecard()
  }
  
  const handleSave = async () => {
    setWarning({text:"Saving...",severity:'info'})
    
    for (let i = 0; i < changed.length;i++){
      let qid = changed[i]
      let note = document.getElementsByName(`note-${qid}`)[0].value
      await authenticatedFetch(`/companies/api/scorecardchoice/${id}`,{
          method:'POST',
          body: JSON.stringify({
            comment: note,
            question: qid
          })
      })
    }

    setSaved(true)
    setWarning({text:"Saved!",severity:'success'})
    await authenticatedFetch(`/companies/api/scorecardslack/${id}`,{
      method:'GET',
    })
  }

  
  useEffect(() => {    
    if (scorecard){
      let scores = {}
      let categoryMaxes = {}
      let maxPoints = 0
      for (let q in values){
        let val = values[q]
        let score = scorecard.filter((x) => x.id === Number(q))[0]
		    maxPoints += score.max_points
        let category = score.category
        categoryMaxes[category] = categoryMaxes[category] ? categoryMaxes[category] += score.max_points : score.max_points
        let option = score.options.filter((y) => y.id === Number(val))[0]
        let points = 0
        if (option) {
          points = Number(option.points)
        } else {
          points = Number(val)
        }
        scores[category] = scores[category] ? scores[category] += points : points
      }
      setRunningDenominator(maxPoints)
      setCatDenoms({...catDenoms,...categoryMaxes})
      setCategoryScores(scores)
      setRunningTotal(Object.keys(scores).reduce((acc, value) => acc + scores[value], 0))
    }
  // eslint-disable-next-line
  },[values,scorecard,])

  useEffect(() => {
    if (id){
      async function getScorecard(){
        const res = await authenticatedFetch(`/companies/api/scorecard`, {
          method: 'GET', 
        })
        setScorecard(res)   
        setCategories([...new Set(res.map((v,_) => v.category))])
      }
      async function getResponses(){
        const res = await authenticatedFetch(`/companies/api/scorecard/${id}`, {
          method: 'GET',
        })
        let valObject = {}
        setCurrentCard(res)
        setCompanyName(res.name)
        res.responses.forEach(function(x){
          if (x.kind === 'upto') {
            valObject[x.question] = `${x.points}`
          } else {
            valObject[x.question] = `${x.answer}`
          }          
        })
        setValues(valObject)
        if (res.recommendation != null){
          setRecommendation(res.recommendation.toString()) 
        }
        let noteObject = {}
        res.notes.forEach(function(x){
          noteObject[x.question] = x.comment
        })
        noteObject['0'] = res.comment
        setComments(noteObject)
      }
      getScorecard() 
      getResponses()
    }
  }, [id]);

  return (
    <>  
        <AppBar sx={{paddingBottom:2}}>
          {warning && warning.text && <Alert severity={warning.severity} onClose={() => setWarning({})}>{warning.text}</Alert>}
          <Grid container sx={{marginTop:2,textAlign:'center'}}>
            <Grid item xs={3}><Typography variant="h6">Company Scorecard: {companyName}</Typography></Grid>
            <Grid item xs={3}><Typography variant="h6">Total Score: {runningTotal}/{runningDenominator} {recommendation==null ? '' : recommendation === 'true' ? <CheckCircleIcon style={{color:'green'}} /> : <NotInterestedIcon style={{color:'red'}} />}</Typography></Grid>
            <Grid item xs={3}><Typography variant="h6">Created {formatMMYYYY(currentCard.created_at)}</Typography></Grid>
            <Grid item xs={3}>
              <Button variant="contained" color="secondary" onClick={() => handleSave()}>
                  Save
              </Button>
              {!saved && <Typography variant="body" sx={{fontStyle:'italic',marginLeft:2}}>unsaved</Typography>}
            </Grid>
          </Grid>
        </AppBar>
        <DialogContent sx={{marginTop:6}}>
          {categories.map((category,questionIndex) =>
            <Section key={questionIndex}>
              <h3>{category} ({categoryScores[category] || 0} / {catDenoms[category] || 0})</h3>
                {scorecard.filter((x) => x.category === category).map((question,questionIndex) =>
                  <Grid container spacing={2} key={questionIndex}>
                    <Grid item xs={6}>
                      <QuestionSection>
                        <Question>{question.question}</Question>
                          <FormControl component="fieldset">
                            <Text>{question.subtitle}</Text>
                              {question.kind === '' ?      
                                  <Select
                                    name={`${question.id}`}
                                    value={values[question.id] || ''}
                                    onChange={e => handleChange(e, question.category)}
                                    style={{width:'500px'}}
                                  >
                                    {question.options.map((option,optionIndex) =>
                                      <MenuItem key={optionIndex} value={`${option.id}`}>{`(${option.points}) ${option.text}`}</MenuItem>
                                    )}
                                  </Select>
                                        :
                                    <Select
                                      name={`${question.id}`}
                                      value={values[question.id] || 0}
                                      onChange={e => handleUpToChange(e, question.options[0].id, question.category)}
                                      style={{width:'300px'}}
                                    >
                                      {Array(question.max_points+1).fill(0).map((_,i) =>
                                        <MenuItem key={i} value={i}>{i}</MenuItem>
                                       )}
                                    </Select>
                              }
                          </FormControl>
                      </QuestionSection>
                     </Grid>
                     <Grid item xs={6}>
                        <InputSection>
                          <TextField
                            onChange={()=>setChanged([...new Set(changed.concat(question.id))])}
                            multiline={true}
                            rows={2}
                            name={`note-${question.id}`}
                            label={`Add ${question.question} comments`}
                            defaultValue={comments[question.id]}
                            variant="filled"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </InputSection>
                     </Grid>
                  </Grid>
                )}
            </Section>
          )}
           <Grid container spacing={2}>
              <Grid item xs={6}>
                <h3>Overall Recommendation:</h3>
                   <RadioGroup value={recommendation} onChange={handleRecChange}>
                       <FormControlLabel value='true' control={<Radio />} label="Yes"/>
                       <FormControlLabel value='false' control={<Radio />} label="No"/>
                   </RadioGroup>
             </Grid>
             <Grid item xs={6}>
       <InputSection>
         <TextField
           multiline={true}
           rows={2}
           name={`note-0`}
           label={`Add overall comments`}
           defaultValue={comments['0']}
           variant="filled"
           fullWidth
           InputLabelProps={{ shrink: true }}
         />
       </InputSection>
             </Grid>
          </Grid>

        </DialogContent>
      </>
  )
}




