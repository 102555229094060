import React, {useState,useEffect} from 'react';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import sv from '../constants/Styles';
import { DataGridPro,GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport} from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Grid';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Tooltip from '@mui/material/Tooltip';
import {isMobile,formatMMDDYYYY} from '../utils/tools'
import { isAdmin } from '../utils/auth'
import Box from '@mui/material/Box';
import {Text} from '../constants/StyleComponents';
import Language from '@mui/icons-material/Language';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {listOperators,dateOperators,stringOperators,companySearch} from './helpers/Filters'

const Name = styled('a')({
  cursor:'pointer',
})

const Link = styled('a')({
  marginLeft: 5,
  textDecoration: null,
  fontSize: sv.bodyText.fontSize,
  cursor: 'pointer',
})

const StyledTip = styled(Tooltip)({
  marginLeft:5,
})

const TipText = styled(Text)({
  color:'white',
})

  const columns = 
  [
    { headerName: 'Name', field: 'person__name', width:180, renderCell: (params) => (
      <Name href={`//${params.row.person__url}`.replace('https://','').replace('http://','')} target="_blank">{params.row.person__name}<LinkedInIcon style={{ fontSize: sv.bodyText.fontSize, color:"0F4EB5", marginLeft:5}}/></Name>
    ),valueGetter:(params) => params.row.person__name,valueSetter:(value) => value},
    
    { headerName: 'Network Degree',type:'singleSelect',valueOptions:['1st','2nd'], field: 'network_degree', width:180, valueGetter:(params) => params.row.person__kind ? `1st (${params.row.person__kind})` : '2nd'},        
    { headerName: 'Network Kind',type:'singleSelect',valueOptions:['MVP LP','MVP Lead','Champ LP','Champ Lead','CEO','Candidate','Value-Add'], field: 'person__kind', width:180},        
    { headerName: 'LI URL', field: 'person__url', hide:true,sortable:false,filterable:false,valueGetter:(params) => params.row.person__url,valueSetter:(value) => value},
    { headerName: 'Employer',filterOperators:companySearch.concat(stringOperators.filter((op) => op.value === "contains")), field: 'company__name',width:180,renderCell:(params) => 
    (
      <Box component='span'>
      <Link href={`/company/${params.row.company__id}`} target="_blank">{params.row.company__name}</Link>
    {(params.row.ecompany__description || params.row.company__li_shortdesc) &&
    <StyledTip title={<TipText>{params.row.company__description || params.row.company__li_shortdesc || ''}</TipText>} placement='top'>
      <InfoOutlinedIcon style={{ fontSize: sv.bodyIcon.fontSize}}/>
    </StyledTip>
    }
        <Link
          href={`//www.${params.row.company__website}`}
          target="_blank"
        >
          <Language style={{ fontSize: sv.bodyIcon.fontSize }}/>
        </Link>
            {params.row.company__li_url &&
        <Link
          href={`//${params.row.company__li_url}`.replace('https://','').replace('http://','')}
          target="_blank"
        >
          <LinkedInIcon style={{ fontSize: sv.bodyIcon.fontSize, color:"0F4EB5", display: 'inline-block' }}/>
            </Link>} 
      </Box>)},
  { headerName: 'Employer Size', field: 'company__current', type:'number', width:150},
  { headerName: 'Jobtitle', field: 'title',sortable:false, width:150, renderCell: (params) => (
        <Tooltip 
          title={params.row.title} placement="top-start"
        >
          <Box>{params.row.title}</Box>
        </Tooltip>)},
  { headerName: 'Location', field: 'location', width:180},
  { headerName: 'Start Date', field:'startdate', type:'date', width:120,valueFormatter: (params) => formatMMDDYYYY(params.value),filterOperators: dateOperators},
  { headerName: 'End Date', field:'enddate', type:'date', width:120,valueFormatter: (params) => formatMMDDYYYY(params.value),filterOperators: dateOperators},
  { headerName: 'Network Connection Name', field: 'connections',width:180, renderCell:(p) => (
    <Tooltip
      title={p.row.connections.map(c=>`${c.name} (${c.kind})`).join(', ')} placement="top-start"
    >
      <Box>{p.row.connections.map(c=>`${c.name} (${c.kind})`).join(', ')}</Box>
    </Tooltip>)},
	{ headerName: 'Network Connection Strength', field: 'connections_strength',width:180,type:'number',sortable:false, renderCell:(p)=>{
    const strength = p.row.connections.filter(c=>c.company_overlap===true).length
    return (
    <Tooltip
      title={p.row.connections.filter(c=>c.company_overlap===true).map(c=>c.name).join(', ')}
      placement="top-start"
    >
        <Box>{strength} {strength > 0 && <span sx={{marginLeft:'0.5em'}}>({p.row.connections.filter(c=>c.company_overlap===true).map(c=>c.name).join(', ')})</span>}</Box>        
    </Tooltip>
	)}},
  { headerName: 'Network Connection Kind', field:'connections_kind',type:'singleSelect',valueOptions:['MVP LP','MVP Lead','Champ LP','Champ Lead','CEO','Candidate','Value-Add'],hide:true},
	{ headerName: 'List', field:'listing',hide:true,filterOperators:listOperators},
  ]

export default function ConnectorContactStep({history,contactList,setContactList,contactSelected,setContactSelected,loading,showAll,allContacts}) {
  const [height] = useState(window.innerHeight)
  
  useEffect(()=>{
	if (showAll){
		setContactList(allContacts)
	} 
	else {
		setContactList(contactList.filter((x) => contactSelected.includes(x.id)))
	}
  // eslint-disable-next-line
  },[showAll,contactSelected])
  

  
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        {!isMobile() && <GridToolbarDensitySelector />}
		    {isAdmin() && <GridToolbarExport csvOptions={{ allColumns: true }} />}
      </GridToolbarContainer>
    );
  }
  
  return (
      <Container maxWidth='xl'>
          <div style={{ height:height - 220, width: '100%' }}>
            <DataGridPro
              columns={columns}
			        loading={loading}
              rows={contactList}
              density="compact"
              checkboxSelection={true}
			        disableSelectionOnClick
              onSelectionModelChange={(newSelection) => {
                setContactSelected(newSelection)
              }}
	            sortingOrder={['asc', 'desc']}
              selectionModel={contactSelected}
	            components={{
	              Toolbar: CustomToolbar,
	            }}
            />
          </div>
          <Grid item xs={12} style={{textAlign:'center',marginTop:10,padding:0}}>
            <Text>Select contacts and press "Next" to finalize the connection report</Text>
          </Grid>
      </Container>
  );
}