import React, {useState,useEffect} from 'react'
import Header from './Header'
import { authenticatedFetch } from '../utils/auth';
import {Sankey} from 'react-vis'
import Grid from '@mui/material/Grid';
import {BoldText} from '../constants/StyleComponents';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function OutreachFlow({history}) {
  const [days, setDays] = useState(365)
  const [nodes, setNodes] = useState([])
  const [links, setLinks] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [total,setTotal] = useState(0)
  const [newCat, setNewCat] = useState('')
  const [newVal, setNewVal] = useState(0)
  const [addVal, setAddVal] = useState(false)
  const [passed, setPassed] = useState(0)
	
  useEffect(() => {     
     async function getit() {
       const res = await authenticatedFetch(`/companies/api/outreachflow?days=${days}`, {
         method: 'GET', 
       })
	   setNodes([{name:''}, {name:`Responded (${res.responded})`},{name:`No Response (${res.unresponded})`},{name:`Met (${res.met})`},{name:`No Meeting (${res.unmet})`},{name:`Invested (${res.invested})`},{name:`Passed (${res.passed})`},])
	   setLinks([
    		{source: 0, target: 1, value: res.responded,color:'#b4f9b4'},
    		{source: 0, target: 2, value: res.unresponded,color:'#dc826e'},
    		{source: 1, target: 3, value: res.met,color:'#7ac263'},
			{source: 1, target: 4, value: res.unmet,color:'#dc826e'},
  			{source: 3, target: 5, value: res.invested, color:'#1b8d03'},
			{source: 3, target: 6, value: res.passed,color:'#dc826e'},    	
  	 ])
	  setLoaded(true)
	  setTotal(res.emailed)
	  setPassed(res.passed)
     }
	 getit()
   }, [days]);
   
   useEffect(() => {
   	if (addVal){
		let newNodes = nodes.concat({name:`${newCat} (${newVal})`})
		newNodes[6] = {name:`Passed (${passed - newVal})`}
		let newLinks = links.concat({source:3,target: links.length + 1, value: newVal, color:'#dc826e'})
		newLinks[5] = {source: 3, target: 6, value: passed - newVal,color:'#dc826e'}
		setNodes(newNodes)
		setLinks(newLinks)
		setAddVal(false)
		setPassed(passed - newVal)
   	}
	// eslint-disable-next-line
   },[addVal])
	
  return <>
    <Header 
      history={history}
      name={'Outreach Flow'}
    />
	<Grid style={{marginLeft:10,marginRight:10}} container spacing={2} alignItems='center' justifyContent='center'>
		<Grid item xs={2}>
			<BoldText>From {total} emails over the last {days} days...</BoldText>
		</Grid>
		<Grid item xs={8}>
			{loaded &&
			<Sankey
			  nodes={nodes}
			  links={links}
			  width={900}
			  height={500}
			  align={'left'}
			  nodePadding={80}
			  nodeWidth={0}
			/>
			  }
		</Grid>
		<Grid item xs={2}>
			  <InputLabel>Lookback Window</InputLabel>
			  <Select
			    value={days}
			    label="Lookback Window"
			    onChange={(e)=>setDays(e.target.value)}
			  >
			    <MenuItem value={30}>One Month</MenuItem>
			    <MenuItem value={90}>Three Months</MenuItem>
			    <MenuItem value={180}>Six Months</MenuItem>
			    <MenuItem value={365}>One Year</MenuItem>
			  </Select>
			  <InputLabel style={{marginTop:50,marginBottom:10}}>Add Final Outcome:</InputLabel>
			  <Box><TextField onChange={(e)=>setNewCat(e.target.value)} label="Category" variant="outlined" size="small"/></Box>
			  <Box style={{marginTop:10}}><TextField onChange={(e)=>setNewVal(e.target.value)} label="Value" type="number" variant="outlined" size="small"/></Box>
			  <Box style={{marginTop:10}}><Button onClick={()=>setAddVal(true)} variant="contained">Add</Button></Box>
		</Grid>
	</Grid>

  </>
}
