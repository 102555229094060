import React, {useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {getGridStringOperators,getGridDateOperators,getGridNumericOperators,GridFilterInputValue } from '@mui/x-data-grid-pro';
import useDebounce from '../../utils/debounce.js'
import { authenticatedFetch } from '../../utils/auth';
import { styled } from '@mui/material/styles';
import { Text } from '../../constants/StyleComponents';
import sv from '../../constants/Styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export const stringOperators = getGridStringOperators().filter((operator) => ['contains','isEmpty','isNotEmpty'].includes(operator.value))
export const numberOperators = getGridNumericOperators().filter((operator) => ["=",">","<","isEmpty","isNotEmpty"].includes(operator.value))

function SeniorityInput(props){
  const { item, applyValue } = props;
  const seniorities = ['Founder','CXO','Director','Senior']
  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };
  
  return (
    <>
    
    <Box sx={{height:1}} />
      <Select
        value={item.value || ''}
        label="Seniority"
        onChange={handleFilterChange}
      >
        {seniorities.map((s,i)=>
          <MenuItem key={i} value={s}>{s}</MenuItem>
        )}
      </Select>
    </>
  )
}

function DaysInput(props) {
  const { item, applyValue } = props;
  
  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
	  <Box component='span'>
	  	<TextField
	  	      label={'Value'}
	  	      placeholder={'0'}
	  	      value={item.value || ''}
	  	      onChange={handleFilterChange}
	  	      type={'text'}
	  	      variant="standard"
	  	      InputLabelProps={{
	  	        shrink: true,
	  	      }}
	  	    />
		<Box component='span'>days ago</Box>
	 </Box>
  );
}

export const CompanySearchBox = (props) => {
	  const Name = styled(Text)({
	    fontSize: sv.bodyText.fontSize * 0.9,
	  })

	  const Site = styled(Text)({
	    fontSize: sv.bodyText.fontSize * 0.7,
	  })
	  
	  const { item, applyValue,field='select' } = props;
	  
	  if (!['object','undefined'].includes(typeof(item.value))){
	  	item.value = null
	  }

	  const [options, setOptions] = useState([])
	  const [value, setValue] = React.useState([]);
	  const [inputValue, setInputValue] = React.useState('');
	  const debounced = useDebounce(inputValue,400)
	  
	  if (!['object','undefined'].includes(typeof(value))){
	  	setValue(null)
	  }

	  useEffect(()=>{
        async function search() {
          const res = await authenticatedFetch('/companies/api/search/companies', {
            method: 'POST', 
            body: JSON.stringify({
              term: debounced
            })
          })
          setOptions(JSON.parse(res))
        }
        if (debounced && debounced.length > 3){
          search()
        }
	  },[debounced])
	  
	  const handleFilterChange = (event,nv) => {
		setValue(nv)
	    applyValue({ ...item, value: nv });
	  };

	  return (
		  <>
		  	<Box
		  		sx={{height:1}}
		  	/>
				<Autocomplete
						freeSolo
						multiple
				    value={item.value || value || []}
				    onChange={handleFilterChange}
				    inputValue={inputValue}
				    onInputChange={(event, newInputValue) => {
				      setInputValue(newInputValue);
				    }}
						getOptionLabel={(option) => option.name}
				        options={options}
			            renderOption={(props,option) => (
			  			<li {...props}>
			  			   <Box>
			  			     <Name>{option.name}</Name>
			  			     <Site>{option.website}</Site>
			  			   </Box>
			  			 </li>
			  		    )}
						isOptionEqualToValue={(option, value) => option.name === value.name}
				  		renderInput={(props) => (
				  			          <TextField
				  			            {...props}
				  			            variant="standard"
									    label="Values"
									    placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
				  			          />
				  			        )}
				      />
		 </>
	  );
}

export const KeywordSearchBox = (props) => {
	  const Name = styled(Text)({
	    fontSize: sv.bodyText.fontSize * 0.9,
	  })

	  const { item, applyValue,field='select' } = props;
	  
	  if (!['object','undefined'].includes(typeof(item.value))){
	  	item.value = null
	  }

	  const [options, setOptions] = useState([])
	  const [value, setValue] = React.useState([]);
	  const [inputValue, setInputValue] = React.useState('');
	  const debounced = useDebounce(inputValue,400)
	  
	  if (!['object','undefined'].includes(typeof(value))){
	  	setValue(null)
	  }

	  useEffect(()=>{
        async function search() {
          const res = await authenticatedFetch('/companies/api/keywords/keyword_search', {
            method: 'POST', 
            body: JSON.stringify({
              term: debounced
            })
          })
          setOptions(JSON.parse(res))
        }
        if (debounced && debounced.length > 3){
          search()
        }
	  },[debounced])
	  
	  const handleFilterChange = (event,nv) => {
		setValue(nv)
	    applyValue({ ...item, value: nv });
	  };

	  return (
		  <>
		  	<Box
		  		sx={{height:1}}
		  	/>
				<Autocomplete
						freeSolo
						multiple
				    value={item.value || value || []}
				    onChange={handleFilterChange}
				    inputValue={inputValue}
				    onInputChange={(event, newInputValue) => {
				      setInputValue(newInputValue);
				    }}
						getOptionLabel={(option) => option.name}
				        options={options}
			            renderOption={(props,option) => (
			  			<li {...props}>
			  			   <Box>
			  			     <Name>{option.name}</Name>
			  			   </Box>
			  			 </li>
			  		    )}
						isOptionEqualToValue={(option, value) => option.name === value.name}
				  		renderInput={(props) => (
				  			          <TextField
				  			            {...props}
				  			            variant="standard"
									    label="Values"
									    placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
				  			          />
				  			        )}
				      />
		 </>
	  );
}

export const companySearch = [
	{
		label: `is one of`,
		value: 'isOne',
	      getApplyFilterFn: (filterItem) => {
	        if (
	          !filterItem.columnField ||
	          !filterItem.value ||
	          !filterItem.operatorValue
	        ) {
	          return null;
	        }

	        return (params) => {
	          return null
	        };
	      },
	      InputComponent: CompanySearchBox,
	},
	{
		label: 'is or is competitor of',
		value: 'isOneCompetitor',
	      getApplyFilterFn: (filterItem) => {
	        if (
	          !filterItem.columnField ||
	          !filterItem.value ||
	          !filterItem.operatorValue
	        ) {
	          return null;
	        }

	        return (params) => {
	          return null
	        };
	      },
	      InputComponent: CompanySearchBox,
	},
	// {
//     label: 'is or is similar to',
//     value: 'isOneSimilar',
//         getApplyFilterFn: (filterItem) => {
//           if (
//             !filterItem.columnField ||
//             !filterItem.value ||
//             !filterItem.operatorValue
//           ) {
//             return null;
//           }
//
//           return (params) => {
//             return null
//           };
//         },
//         InputComponent: CompanySearchBox,
//   },
	{
		label: 'contains or contains competitor of',
		value: 'isCompetitorName',
	      getApplyFilterFn: (filterItem) => {
	        if (
	          !filterItem.columnField ||
	          !filterItem.value ||
	          !filterItem.operatorValue
	        ) {
	          return null;
	        }

	        return (params) => {
	          return null
	        };
	      },
	      InputComponent: GridFilterInputValue,
	},
	// {
//     label: 'contains or contains similar to',
//     value: 'isSimilarName',
//         getApplyFilterFn: (filterItem) => {
//           if (
//             !filterItem.columnField ||
//             !filterItem.value ||
//             !filterItem.operatorValue
//           ) {
//             return null;
//           }
//
//           return (params) => {
//             return null
//           };
//         },
//         InputComponent: CompanySearchBox,
//   }
	
]

export const listOperators = getGridStringOperators().filter((operator) => operator.value === 'contains').concat(
    {
      label: 'id is',
      value: 'listid',
      getApplyFilterFn: (filterItem) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }

        return (params) => {
          return null
        };
      },
      InputComponent: GridFilterInputValue
    })


export const roleOperators = 
    [{
      label: 'title includes any (,)',
      value: 'contains_one',
      getApplyFilterFn: (filterItem) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }

        return (params) => {
          return null
        };
      },
      InputComponent: GridFilterInputValue
    },
    {
          label: 'current title includes any (,)',
          value: 'curr_contains_one',
          getApplyFilterFn: (filterItem) => {
            if (
              !filterItem.columnField ||
              !filterItem.value ||
              !filterItem.operatorValue
            ) {
              return null;
            }

            return (params) => {
              return null
            };
          },
          InputComponent: GridFilterInputValue
    },
    
    {
          label: 'company is any (,)',
          value: 'comp_is_one',
          getApplyFilterFn: (filterItem) => {
            if (
              !filterItem.columnField ||
              !filterItem.value ||
              !filterItem.operatorValue
            ) {
              return null;
            }

            return (params) => {
              return null
            };
          },
          InputComponent: GridFilterInputValue
    },
    {
          label: 'company includes any (,)',
          value: 'comp_contains_one',
          getApplyFilterFn: (filterItem) => {
            if (
              !filterItem.columnField ||
              !filterItem.value ||
              !filterItem.operatorValue
            ) {
              return null;
            }

            return (params) => {
              return null
            };
          },
          InputComponent: GridFilterInputValue
    },
    {
          label: 'current company is any (,)',
          value: 'curr_comp_is_one',
          getApplyFilterFn: (filterItem) => {
            if (
              !filterItem.columnField ||
              !filterItem.value ||
              !filterItem.operatorValue
            ) {
              return null;
            }
    
            return (params) => {
              return null
            };
          },
          InputComponent: GridFilterInputValue
    },
    {
          label: 'current company includes any (,)',
          value: 'curr_comp_contains_one',
          getApplyFilterFn: (filterItem) => {
            if (
              !filterItem.columnField ||
              !filterItem.value ||
              !filterItem.operatorValue
            ) {
              return null;
            }

            return (params) => {
              return null
            };
          },
          InputComponent: GridFilterInputValue
    }]

export const dateOperators = [
  {
    label: 'is less than',
    value: 'newerDays',
    getApplyFilterFn: (filterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params) => {
        return Number(params.value) >= Number(filterItem.value);
      };
    },
    InputComponent: DaysInput,
    InputComponentProps: { type: 'number' },
  },
  {
    label: 'is more than',
    value: 'olderDays',
    getApplyFilterFn: (filterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params) => {
        return Number(params.value) >= Number(filterItem.value);
      };
    },
    InputComponent: DaysInput,
    InputComponentProps: { type: 'number' },
  },
].concat(getGridDateOperators().filter((operator) => ['is','after','before','isEmpty','isNotEmpty'].includes(operator.value)));

export const MultiInput = (props) => {
	  const { item, applyValue,list=[],field='select' } = props;
	  
	  if (!['object','undefined'].includes(typeof(item.value))){
	  	item.value = null
	  }
	  
	  const handleFilterChange = (event,nv) => {
	    applyValue({ ...item, value: nv });
	  };

	  return (
		  <>
		  	<Box
		  		sx={{height:1}}
		  	/>
		  		<Autocomplete
		  			multiple
		  			fullWidth
		  			size='small'
				    value={item.value || []}
					onChange={(event,nv)=>handleFilterChange(event,nv)}
		  			options={list}
					getOptionLabel={(option) => option.name || option}
		  			renderInput={(props) => (
		  			          <TextField
		  			            {...props}
		  			            variant="standard"
							    label="Values"
							    placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
		  			          />
		  			        )}
		  		/>
		 </>
	  );
}
  

export const investorOperators = [
  {
    label: 'preferred firm',
    value: 'preferred_firm',
    getApplyFilterFn: (filterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value 
      ) {
        return null;
      }
	  
      return (params) => {
        return null
      };
 
    },
    InputComponent: null,
    InputComponentProps: null,
  },
  {
    label: 'preferred individual',
    value: 'preferred_individual',
    getApplyFilterFn: (filterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value 
      ) {
        return null;
      }

      return (params) => {
        return null
      };
    },
    InputComponent: null,
    InputComponentProps: null,
  },
]

export function multiInputOperators(list){
	return [
  {
    label: 'is one of',
    value: 'isOne',
    getApplyFilterFn: (filterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params) => {
        return null
      };
    },
    InputComponent: (props) => <MultiInput list={list} {...props}/>
  },
  {
    label: 'is all of',
    value: 'isAll',
    getApplyFilterFn: (filterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params) => {
        return null
      };
    },
    InputComponent: (props) => <MultiInput list={list} {...props}/>
  },
  {
    label: 'is not',
    value: 'isNot',
    getApplyFilterFn: (filterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params) => {
        return null
      };
    },
    InputComponent: (props) => <MultiInput list={list} {...props}/>
  },
  {
    label: 'is exactly',
    value: 'isExactly',
    getApplyFilterFn: (filterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params) => {
        return null
      };
    },
    InputComponent: GridFilterInputValue
  }
].concat(stringOperators)
}

export const backgroundOperators = [
  {
    label: 'schools include',
    value: 'school',
    getApplyFilterFn: (filterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params) => {
        return null
      };
    },
    InputComponent: GridFilterInputValue
  },
	{
		label: 'prev companies include',
		value: 'company',
	      getApplyFilterFn: (filterItem) => {
	        if (
	          !filterItem.columnField ||
	          !filterItem.value ||
	          !filterItem.operatorValue
	        ) {
	          return null;
	        }

	        return (params) => {
	          return null
	        };
	      },
	      InputComponent: CompanySearchBox,
	},
  {
    label: 'prev seniority',
    value: 'prevseniority',
    getApplyFilterFn: (filterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value
      ) {
        return null;
      }

      return (params) => {
        return null
      };
    },
    InputComponent: SeniorityInput
  },
]

export const tagOperators = [
  	{
  		label: `is one of`,
  		value: 'isOne',
  	      getApplyFilterFn: (filterItem) => {
  	        if (
  	          !filterItem.columnField ||
  	          !filterItem.value ||
  	          !filterItem.operatorValue
  	        ) {
  	          return null;
  	        }

  	        return (params) => {
  	          return null
  	        };
  	      },
  	      InputComponent: KeywordSearchBox,
  	},
    // {
//     label: 'top 25 similar to',
//     value:'similar_25',
//       getApplyFilterFn: (filterItem) => {
//         if (
//           !filterItem.columnField ||
//           !filterItem.value ||
//           !filterItem.operatorValue
//         ) {
//           return null;
//         }
//
//         return (params) => {
//           return null
//         };
//       },
//       InputComponent: GridFilterInputValue
//   },
//   {
//     label: 'top 50 similar to',
//     value:'similar_50',
//       getApplyFilterFn: (filterItem) => {
//         if (
//           !filterItem.columnField ||
//           !filterItem.value ||
//           !filterItem.operatorValue
//         ) {
//           return null;
//         }
//
//         return (params) => {
//           return null
//         };
//       },
//       InputComponent: GridFilterInputValue
//   },
//   {
//     label: 'top 100 similar to',
//     value:'similar_100',
//       getApplyFilterFn: (filterItem) => {
//         if (
//           !filterItem.columnField ||
//           !filterItem.value ||
//           !filterItem.operatorValue
//         ) {
//           return null;
//         }
//
//         return (params) => {
//           return null
//         };
//       },
//       InputComponent: GridFilterInputValue
//   },
//   {
//     label: 'top 250 similar to',
//     value:'similar_250',
//       getApplyFilterFn: (filterItem) => {
//         if (
//           !filterItem.columnField ||
//           !filterItem.value ||
//           !filterItem.operatorValue
//         ) {
//           return null;
//         }
//
//         return (params) => {
//           return null
//         };
//       },
//       InputComponent: GridFilterInputValue
//   },
//   {
//     label: 'top 500 similar to',
//     value:'similar_500',
//       getApplyFilterFn: (filterItem) => {
//         if (
//           !filterItem.columnField ||
//           !filterItem.value ||
//           !filterItem.operatorValue
//         ) {
//           return null;
//         }
//
//         return (params) => {
//           return null
//         };
//       },
//       InputComponent: GridFilterInputValue
//   },
//   {
//     label: 'top 1000 similar to',
//     value:'similar_1000',
//       getApplyFilterFn: (filterItem) => {
//         if (
//           !filterItem.columnField ||
//           !filterItem.value ||
//           !filterItem.operatorValue
//         ) {
//           return null;
//         }
//
//         return (params) => {
//           return null
//         };
//       },
//       InputComponent: GridFilterInputValue
//   }
  
].concat(getGridStringOperators().filter((operator) => operator.value === 'contains'))

export const ceoOperators = [
  {
    label: 'name contains',
    value: 'contains',
    getApplyFilterFn: (filterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params) => {
        return null
      };
    },
    InputComponent: GridFilterInputValue
  },
  
  ].concat(backgroundOperators).concat(stringOperators.filter((operator) => ['isEmpty','isNotEmpty'].includes(operator.value)))







