import { DataGridPro,GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridFilterPanel} from '@mui/x-data-grid-pro';
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Section,Title } from '../constants/StyleComponents';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { authenticatedFetch,getUser } from '../utils/auth';
import sv from '../constants/Styles';
import Box from '@mui/material/Box';
import {getLists,addRemoveList} from './helpers/APIs'
import Header from './Header';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import Badge from '@mui/material/Badge';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useForm } from "react-hook-form";
import AddEditCompany from './AddEditCompany.js'
import AddEditContact from './AddEditContact.js'
import NewListModal from './NewListModal.js'
import keyPress from '../utils/keyPress'
import {Text} from '../constants/StyleComponents';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {isMobile,makeDateRange,compareDates} from '../utils/tools'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PublicIcon from '@mui/icons-material/Public';
import { isAdmin, isJason } from '../utils/auth'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonIcon from '@mui/icons-material/Person';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import Container from '@mui/material/Container'
import mvp from '../assets/mvp.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Guide from './CompanyPage/Guide.js'
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import hubspot from '../assets/hubspot.png'
import Slider from '@mui/material/Slider';
import useDebounce from '../utils/debounce.js'

const ErrorText = styled(Text)({
  color:'red',
  fontStyle:'italic',
})

const FilterButton = styled(Button)({
  height: isMobile() ? 25 : 30,
  fontSize: isMobile() ? sv.bodyIcon.fontSize*0.8 : sv.bodyIcon.fontSize,
  paddingLeft: 0,
  paddingRight: 0,
  marginBottom: 5,
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const HeaderImg = styled('img')({
  width:200,
  margin:30,
})

const Note = styled(Text)({
  fontStyle:'italic',
  fontSize: sv.bodyText.fontSize * 0.9,
  marginLeft:'15%',
  marginRight:'15%'
})

const Link = styled('a')({
  fontSize: sv.bodyText.fontSize*1.1,
  '&:link': {
      color: '#1B8D77',
      textDecoration:'underline'
  },
  '&:visited': {
      color: '#1B8D77',
      textDecoration:'underline'
    },
  '&:hover': {
      color: '#1B8D77',
      textDecoration:'underline'
    },
  '&:active': {
      color: '#1B8D77',
      textDecoration:'underline'
    },
    cursor:'pointer',
})

const NoLink = styled('span')({
  fontSize: sv.bodyText.fontSize*1.1,
  cursor:'default'
})

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const FavoriteMenu = props => {
  return (
	<Badge anchorOrigin={{vertical: 'top',horizontal: 'left'}} sx={{ marginLeft:3,"& .MuiBadge-badge": {color:'white',marginTop:0.5,fontSize:14,fontWeight:'bold' } }} badgeContent={props.favoriteAlertSum} onClick={props.handleFavoriteClick} max={999} color="secondary">
      <FilterButton  color="primary" style={{marginBottom:0,marginRight:16}} >
         <BookmarkBorderIcon/> Saved Filters
      </FilterButton>
	</Badge>
  )
}

function CustomToolbar(props) {
  const {favoriteAlertSum,handleFavoriteClick,favoriteAnchorEl,handleFavoriteClose,favorites,handleAlertSwap,handlePublicSwap,handleFavoriteDelete,path,showNew,handleNewClick,newCount,newAnchorEl,handleNewClose,setNewOnly,handleClear,openAddEditContact,openAddEdit,type,currentFav,selectionModel,lists,listAnchorEl,setListAnchorEl,selectedListAnchorEl,setSelectedListAnchorEl,setNewListModal,handleListDelete,handleListPublicSwap,handleListClose,currentList,setSelectionModel,setFilterModel,setFilterValue,setSortModel,defaultSort,setSortCode,setFetchable,setFavoritesText,setCurrentFav,setNewCount,setShowNew,setCurrentList,exportContacts,setExportContacts,showHelp,setWarning,setForceRefresh,setPriorityVal,priorityDisplay,setPriorityDisplay} = props
  
  const handleHubspotExport = () => {
     	async function toHubspot() {
     		let res = await authenticatedFetch(`/companies/api/listings/${currentList.id}/hubspot`,{method:'POST'})
        setWarning({text:res.message,severity:res.status})
     	}
      toHubspot()
  }
  
  const setMyCompanies = () => {
    setFilterModel({"items":[{"id":1,"columnField":"owner_one","operatorValue":"equals","value":getUser()['name']}],"linkOperator":"and"})
    setFilterValue(`&owner_one=${getUser()['name']}`)
    setSortModel(defaultSort)
    setSortCode('')
    setFetchable(true)
	  setFavoritesText('')
	  setCurrentFav(null)
	  setCurrentList(null)
	  setNewCount(0)
	  setShowNew(false)
  }
  
  
  const addRemoveListItem = (list,remove=false) => {
     async function updateList() {
         setWarning({text:"Updating...",severity:'info'})
         await addRemoveList(list,selectionModel.map((x)=>({'id':x})),remove,type)
         setSelectionModel([])
         setWarning({text:"Saved!",severity:'success'})
         setForceRefresh(true)
         handleListClose(list)
     }
 	updateList()
  }
   
  return (
    <GridToolbarContainer>
	  <Box sx={{display:'flex',justifyContent:'space-between', width:'100%'}}>
		    <Box sx={{marginLeft:1}}>
            <Guide guideShown={showHelp} content={
              <Stack sx={{width:800}}>
                <Typography sx={{textAlign:'center',fontWeight:600,color:'gray',fontmarginBottom:1}}>Table Controls</Typography>
                <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>Filters:</Box>Most columns are filterable. Multiple filters can be combined, and both AND and OR operators are supported. If you make a filter you like, save it for easy access under Saved Filters</Typography>
                <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>Columns:</Box>Choose which columns to display, and which order to show them in. If you save a filter, column layout is also saved (as is sorting order). Many columns show an explanatory tooltip when you hover over their names.</Typography>
                <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>My Companies:</Box>Only show companies assigned to you.</Typography>
                <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>Priority:</Box>Select universe of companies to query, based on likelihood of investment match.</Typography>
                <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>Add Company:</Box>Find a company not in Jarvis? Add it here. </Typography>
              </Stack>
            } />
	          <GridToolbarFilterButton />
	          <GridToolbarColumnsButton sx={{marginLeft:2}} />
		      {(isJason() || (!isMobile() && (path === 'contacts/seconddegree' || path === 'employees') && isAdmin())) && <GridToolbarExport sx={{marginLeft:2}} csvOptions={{ delimiter: "|"}} />}
	            {type === "Contacts" &&
		    	<FilterButton color="primary" size="small" sx={{marginBottom:0,marginLeft:2}}  onClick={openAddEditContact}>
	              <AddCircleOutlineOutlinedIcon sx={{marginRight:1}}/> Add Contact
		    	</FilterButton>
	            }
		    	{(type === "Growth" || type === "Pipeline") &&		
          <>
		    	  <FilterButton color="primary" size="small" sx={{marginBottom:0,marginLeft:2}} onClick={setMyCompanies}>
	                <PersonIcon sx={{marginRight:1}}/>My Companies
		    	  </FilterButton>
              
		    	  <FilterButton color="primary" size="small" sx={{marginBottom:0,marginLeft:2}}>
                  <Slider
                    size="small"
                    sx={{width:'100px'}}
                    value={priorityDisplay}
                    onChange={(e,nv)=>setPriorityDisplay(nv)}
                    onChangeCommitted={(e,nv)=>setPriorityVal(nv)}
                    step={1}
                    min={1}
                    max={10}
                  />
		    	  </FilterButton>
		    	  <FilterButton color="primary" size="small" sx={{marginBottom:0,marginLeft:2}}>
              <NoMaxWidthTooltip title=<Stack sx={{width:800}}>
                 <Typography sx={{textAlign:'center',fontWeight:600,color:'white',fontmarginBottom:1}}>Priority – description of each as well as rough # of companies in each bucket. Selecting priority X includes all those with a higher priority as well.</Typography>
                 <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>1. </Box>In the MVP portfolio (80).</Typography>
                 <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>2. </Box>Currently engaged with, or attempting to get in touch with (750).</Typography>
                 <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>3. </Box>Reviewed in the past or have a prior specific connection to (3000).</Typography>
                 <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>4. </Box>High priority based on source or a combination of stage appropriatness, geography, top scores, and last round included a preferred investor (1500).</Typography>
                 <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>5. </Box>Appropriate stage, geography, and last round included a preferred investor (5000).</Typography>
                 <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>6. </Box>Appropriate stage, geography, and either a top score or has ever raised from a preferred investor (20000).</Typography>
                 <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>7. </Box>Appropriate stage, geograpy, and either indicators across multiple key data sources or a relatively good score (65000).</Typography>
                 <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>8. </Box>Appropriate stage and employee size (150000).</Typography>
                 <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>9. </Box>A wider range of previous stages and a relatively good score (150000).</Typography>
                 <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>10. </Box>Remaining (500000).</Typography>
               </Stack> 
              placement="bottom"><Box>Priority ({priorityDisplay})</Box>
            </NoMaxWidthTooltip>
		    	  </FilterButton>
            
		    	  <FilterButton color="primary" size="small" sx={{marginBottom:0,marginLeft:2}} onClick={openAddEdit}>
	                <AddCircleOutlineOutlinedIcon sx={{marginRight:1}}/>Add Company
		    	  </FilterButton>
          </>
	            }
		    	{(path === 'contacts/list' || path === 'contacts/seconddegree') &&		
          <>
		    	  <FilterButton color="primary" size="small" sx={{marginBottom:0,marginLeft:2}} onClick={()=>setExportContacts(!exportContacts)}>
	                <FileDownloadIcon sx={{marginRight:1}}/>Exporter
		    	  </FilterButton>
          </>
	        }
		    </Box>
	  	<Box sx={{marginRight:1}}>
		{selectionModel.length === 0 ?
	<Stack direction="row">
    <Guide guideShown={props.showHelp} content={
      <Stack sx={{width:800}}>
        <Typography sx={{textAlign:'center',fontWeight:600,color:'gray',fontmarginBottom:1}}>Saved Filters</Typography>
        <Typography sx={{marginTop:1}}>Your saved filters or those shared by team members will appear here. Click one to apply it.</Typography>
        <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>New:</Box>If notifications are on, this will show how many new companies matching this filter there are since last refreshed.</Typography>
        <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>Notifications:</Box>If this is on, this filter will run daily and a badge icon will indicate any new companies that match the filter criteria.  Additionally, you will receive a slack notification about new matching companies. When loading a saved filter with notifications, click the name of the search in the top-right to toggle between all companies that match the filter, and only new ones. You can also reset the filter which will mark all new companies as seen.</Typography>
        <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>Shared:</Box>Click this to share this filter with the rest of the team; otherwise, your filters are private to you.</Typography>
      </Stack>
    } />
		<FavoriteMenu favoriteAlertSum={favoriteAlertSum} handleFavoriteClick={handleFavoriteClick} showHelp={showHelp} />
      <Menu
        anchorEl={favoriteAnchorEl}
        open={Boolean(favoriteAnchorEl)}
        onClose={()=>handleFavoriteClose(null)}
		    anchorOrigin={{
		    vertical: 'bottom',
		    horizontal: 'left',
		  }}
		  transformOrigin={{
		    vertical: 'top',
		    horizontal: 'left',
		  }}
      >
		<TableContainer sx={{marginTop:-1,minWidth: 700,maxHeight:400}} component={Paper}>
		{favorites.length > 0 ?
			<Table stickyHeader size='small'>
			    <TableHead>
			             <TableRow >
                    <StyledTableCell>Name</StyledTableCell>
        				   {type === 'Growth' &&<Tooltip title='Count of new companies that match this favorite' placement='top' arrow><StyledTableCell align="center">New</StyledTableCell></Tooltip>}
        				   <Tooltip title='User who owns this favorite' placement='top' arrow><StyledTableCell align="center">Owner</StyledTableCell></Tooltip>
		                   <StyledTableCell sx={{width:200}} align="center">Description</StyledTableCell>
			               {type === 'Growth' && <Tooltip title='Alert when new companies match this filter (click to turn on/off). (Number) indicates priority for alert if there.' placement='top' arrow><StyledTableCell align="center">Notifications</StyledTableCell></Tooltip>}
			               <Tooltip title='Share this favorite with the team (owned favorites only, click to share)' placement='top' arrow><StyledTableCell align="center">Shared</StyledTableCell></Tooltip>
			               <Tooltip title='Delete this favorite (owned favorites only)' placement='top' arrow><StyledTableCell align="center">Delete</StyledTableCell></Tooltip>
			             </TableRow>
			           </TableHead>
						<TableBody>
						 {favorites.sort(function (a, b) {return a.name.toLowerCase().localeCompare(b.name.toLowerCase())}).map((val,ind) => (
						   <StyledTableRow key={ind}>
						      <StyledTableCell sx={{cursor:'pointer'}} component="th" scope="row" onClick={()=>handleFavoriteClose(val)}>
						         {val.name} {(type === 'Growth' && val.priority) && `(${val.priority})`}
						      </StyledTableCell>
						      {type === 'Growth' && <StyledTableCell align="center" sx={{cursor:'pointer'}} onClick={()=>handleFavoriteClose(val)}>{val.alert && val.recent}</StyledTableCell>}
						      <StyledTableCell align="center" sx={{cursor:'pointer'}} onClick={()=>handleFavoriteClose(val)}>{val.user}</StyledTableCell>
						      <StyledTableCell align="right" sx={{cursor:'pointer'}} onClick={()=>handleFavoriteClose(val)}>{val.description}</StyledTableCell>
						      {type === 'Growth' &&  <StyledTableCell align="center">
								 <IconButton style={{color:val.alert ? "black" : "#BDBDBD"}} onClick={()=>handleAlertSwap(val.id)}>
              						<NotificationsIcon fontSize='small'/>
                          {(val.alert && val.priority)&&<Typography variant="body2" color='primary'>({val.priority})</Typography>}
								 </IconButton>
							  </StyledTableCell>
							  }
						      <StyledTableCell align="center">
							  	 <IconButton disabled={!val.mine} style={{color:val.shared ? "black" : "#BDBDBD"}} onClick={()=>handlePublicSwap(val.id)}>
                			  	 	<PublicIcon fontSize='small'/>
              				    	 </IconButton>
							  </StyledTableCell>
						      <StyledTableCell align="center">
							  	 <IconButton disabled={!val.mine} onClick={()=>handleFavoriteDelete(val.id)}>
                			  	 	<DeleteIcon fontSize='small'/>
              				     	 </IconButton>
							  </StyledTableCell>
						   </StyledTableRow>
						          ))}
						 </TableBody>
				</Table>
			:
			<Text sx={{padding:10,textAlign:'center'}}>Filters you save or that are shared with you will appear here </Text>
			}
		</TableContainer>
	</Menu>
  	  {showNew && currentFav &&
  	  <>
  	  	<FilterButton onClick={handleNewClick} sx={{marginRight:1,marginBottom:0}} color="primary" size="small">
		  <NotificationsActiveOutlinedIcon sx={{marginRight:1}}/> {currentFav.name}
  	  	</FilterButton>  

          	<Menu
          	  anchorEl={newAnchorEl}
          	  open={Boolean(newAnchorEl)}
          	  onClose={handleNewClose}
          	  anchorOrigin={{vertical: "bottom",horizontal: "center",}}
          	  transformOrigin={{vertical: "top",horizontal: "center",}}
          	>
          	  <MenuItem style={{fontSize:sv.bodyText.fontSize * 0.8}} onClick={()=>setNewOnly(null)}>All</MenuItem>
          	  <MenuItem style={{fontSize:sv.bodyText.fontSize * 0.8}} onClick={()=>setNewOnly(currentFav.id)}>{`New Only (${newCount})`}</MenuItem>
          	  <MenuItem style={{fontSize:sv.bodyText.fontSize * 0.8}} onClick={handleClear}><RotateLeftIcon /> Reset</MenuItem>
          	</Menu>
  	   </>}
 	 <FilterButton color="primary" size="small" style={{marginBottom:0,marginRight:10}} onClick={(e)=>setListAnchorEl(e.currentTarget)}>
          <FormatListNumberedOutlinedIcon sx={{marginRight:1}}/>Lists
 	 </FilterButton>
   <Guide guideShown={props.showHelp} content={"Where Saved Filters are dynamic, lists are static. Select companies by clicking the checkboxes next to their names and then click the '<X> Selected' in the top-right to add them to an existing list, or create a new one. Click on lists to view and load your lists, and like searches they can be private or shared with the team."}/>
	 {currentList && 
  	  <FilterButton sx={{marginRight:1,marginBottom:0,pointerEvents:'none'}} color="primary" size="small">
	    <ListAltIcon sx={{marginRight:1}}/> {currentList.name}
  	  </FilterButton>
    }
  	{(path === 'contacts/list' && currentList) &&
    <>
  	  <FilterButton color="primary" size="small" sx={{marginBottom:0,marginLeft:2}} onClick={handleHubspotExport}>
            <img style={{ width: 20, marginRight:10, filter: "url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"colorize\"><feColorMatrix type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0.15 0 0 0 0 0.24 0 0 0 1 0\" /></filter></svg>#colorize')" }} src={hubspot} alt={'Hubspot'}/>Sync
  	  </FilterButton>
    </>
    }

         <Menu
           anchorEl={listAnchorEl}
           open={Boolean(listAnchorEl)}
           onClose={()=>setListAnchorEl(null)}
   		     anchorOrigin={{
   		      vertical: 'bottom',
   		      horizontal: 'left',
   		     }}
   		    transformOrigin={{
   		      vertical: 'top',
   		      horizontal: 'left',
   		    }}
         >
   		<TableContainer sx={{marginTop:-1,minWidth: 300,maxHeight:400}} component={Paper}>
   		{lists.length > 0 ?
   			<Table stickyHeader size='small'>
   			    <TableHead>
   			             <TableRow >
   			               <StyledTableCell>Name</StyledTableCell>
           				   <Tooltip title='User who owns this favorite' placement='top' arrow><StyledTableCell align="center">Owner</StyledTableCell></Tooltip>
   			               <Tooltip title='Share this favorite with the team (owned favorites only, click to share)' placement='top' arrow><StyledTableCell align="center">Shared</StyledTableCell></Tooltip>
   			               <Tooltip title='Delete this favorite (owned favorites only)' placement='top' arrow><StyledTableCell align="center">Delete</StyledTableCell></Tooltip>
   			             </TableRow>
   			           </TableHead>
   						<TableBody>
   						 {lists.sort(function (a, b) {return a.name.toLowerCase().localeCompare(b.name.toLowerCase())}).map((val,ind) => (
   						   <StyledTableRow key={ind}>
   						      <StyledTableCell sx={{cursor:'pointer'}} component="th" scope="row" onClick={()=>handleListClose(val)}>
   						         {val.name}
   						      </StyledTableCell>
   						      <StyledTableCell sx={{cursor:'pointer'}} align="center" onClick={()=>handleListClose(val.id)}>{val.user}</StyledTableCell>
   						      <StyledTableCell align="center">
   							  	 <IconButton disabled={!val.mine} style={{color:val.shared ? "black" : "#BDBDBD"}} onClick={()=>handleListPublicSwap(val.id)}>
                   			  	 	<PublicIcon fontSize='small'/>
                 				    	 </IconButton>
   							  </StyledTableCell>
   						      <StyledTableCell align="center">
   							  	 <IconButton disabled={!val.mine} onClick={()=>handleListDelete(val.id)}>
                   			  	 	<DeleteIcon fontSize='small'/>
                 				     	 </IconButton>
   							  </StyledTableCell>
   						   </StyledTableRow>
   						          ))}
   						 </TableBody>
   				</Table>
   			:
   			<Text sx={{padding:10,textAlign:'center'}}>Select rows to create your first list </Text>
   			}
   		</TableContainer>
   	</Menu>
	</Stack>
	:
	 <>
 		 <FilterButton color="primary" size="small" style={{marginBottom:0,marginLeft:10}} onClick={(e)=>setSelectedListAnchorEl(e.currentTarget)}>
    	      <CheckBoxOutlinedIcon sx={{marginRight:1}}/>{selectionModel.length} selected
 		 </FilterButton>
    	  	<Menu
    	  	  anchorEl={selectedListAnchorEl}
    	  	  open={Boolean(selectedListAnchorEl)}
    	  	  onClose={()=>setSelectedListAnchorEl(null)}
    	  	  anchorOrigin={{vertical: "bottom",horizontal: "center",}}
    	  	  transformOrigin={{vertical: "top",horizontal: "center",}}
    	  	> 
			  {selectionModel.length > 0 && currentList && <MenuItem sx={{fontSize:sv.bodyText.fontSize * 0.8,fontStyle:'italic',fontWeight:'bold'}} onClick={()=>addRemoveListItem(currentList.id,true)}>- Remove from list</MenuItem>}
			  <MenuItem sx={{fontSize:sv.bodyText.fontSize * 0.8}} disabled={true}>+ Add to list</MenuItem>
			  {lists.map((list,ind) => 
				  <MenuItem sx={{fontSize:sv.bodyText.fontSize * 0.8}} key={ind} onClick={()=>addRemoveListItem(list.id)}>{list.name}</MenuItem>
			  )}			  
    	  	  <MenuItem style={{fontSize:sv.bodyText.fontSize * 0.8,fontWeight:'bold'}} onClick={()=>setNewListModal(true)}>+ Create New List</MenuItem>
    	  	</Menu>
		</>
        }
	</Box>
	 </Box>
    </GridToolbarContainer>
  );
}
  
function CustomFilterPanel(props){
  const { doFavoriteOpen, currentFav, favoritesText, doClear, doFilter, saving} = props;

  return (
	  <Box>
		<Box sx={{display:'flex',justifyContent:'space-between',marginTop:1,height:10}}>
			<Box>	
	        {(!currentFav || (currentFav && currentFav.mine)) &&
      		<FilterButton disabled={saving} variant="outlined" color="secondary" size="small" onClick={()=>doFavoriteOpen(false)} style={{height:22,marginLeft:10,marginRight:3}}>
      		  Save
      		</FilterButton>
		    }
			{currentFav &&
      		<FilterButton variant="outlined" color="secondary" size="small" onClick={()=>doFavoriteOpen(true)} style={{height:22,marginLeft:10,marginRight:3}}>
      		  Save As
      		</FilterButton>
			}
			</Box>
			{currentFav && <Text component='span'>{favoritesText}</Text>}
			<Box sx={{marginRight:1}}>
      		<FilterButton variant="outlined" color="secondary" size="small" onClick={doClear} style={{height:22,marginLeft:10,marginRight:3}}>
      		  Clear
      		</FilterButton>
      		<FilterButton variant="contained" color="primary" size="small" onClick={doFilter} style={{height:22}}>
      		  Apply
      		</FilterButton>

	  		</Box>
		</Box>
    	<GridFilterPanel columnsSort={'asc'}/>
	  </Box>
  );
}

export { CustomFilterPanel, CustomToolbar };


export default function TableBase({history, path, columns, name, selection, type, defaultFilter,defaultSort,warning,setWarning,data,setData,setColumns,defaultColumns,handleCellEditCommit,apiRef,headerSearch='companies',showHelp,setShowHelp,insertMatches,pinnedColumns}) {
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(1)
  const [fetchable, setFetchable] = useState(true)
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [sortModel, setSortModel] = useState(defaultSort);
  const [sortCode, setSortCode] = useState('');
  const [filterModel, setFilterModel] = useState(defaultFilter || {'items':[]})
  const [favoriteAnchorEl, setfavoriteAnchorEl] = useState(null);
  const [newAnchorEl, setNewAnchorEl] = useState(null)
  const [listAnchorEl, setListAnchorEl] = useState(null)
  const [newOnly, setNewOnly] = useState(null)
  const [favorites, setFavorites] = useState([])
  const { register, handleSubmit, errors } = useForm();
  const [favoriteOpen, setFavoriteOpen] = useState(false);
  const [height] = useState(window.innerHeight);
  const [newFilterValue, setNewFilterValue] = useState('')
  const [showAddEdit, setShowAddEdit] = useState(false)
  const [showAddEditContact, setShowAddEditContact] = useState(false)
  const [lastUrl, setLastUrl] = useState('')
  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnOrder, setColumnOrder] = useState({})
  const [pageSize, setPageSize] = useState(25)
  const [favoriteAlertSum, setFavoriteAlertSum] = useState(0)
  const [showNew,setShowNew] = useState(false)
  const [currentFav,setCurrentFav] = useState(null)
  const [favoritesText, setFavoritesText] = useState('')
  const [newCount, setNewCount] = useState(0)
  const [saving, setSaving] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])
  const [lists, setLists] = useState([])
  const [selectedListAnchorEl, setSelectedListAnchorEl] = useState(null)
  const [newListModal, setNewListModal] = useState(false)
  const [currentList, setCurrentList] = useState(null)
  const [exportContacts, setExportContacts] = useState(false)
  const [contact, setContact] = useState({})
  const [hiddenEmps, setHiddenEmps] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [jarvisOpen, setJarvisOpen] = useState(false)
  const [forceRefresh, setForceRefresh] = useState(false)
  const [priorityVal, setPriorityVal] = useState(6)
  const [priorityDisplay, setPriorityDisplay] = useState(6)
  
  const [company,setCompany] = useState('')
  const [finalized, setFinalized] = useState(false)

  useEffect(()=>{
  	let sum = favorites.map(x => x.recent).reduce((a, b) => a + b, 0)
	  setFavoriteAlertSum(sum)
  },[favorites])

  useEffect(()=>{
    if (path === 'contacts/list') {
      let selRows = [...selectedRows].concat(data).filter((x)=>selectionModel.includes(x.id))
      setSelectedRows([...new Set(selRows)])
      
    } if (path === 'contacts/seconddegree') {
      let selRows = [...selectedRows.filter((x)=>selectionModel.includes(x.id))].concat(data.filter((x)=>selectionModel.includes(x.id)).sort(compareDates).map((x) => (
        {
          name:x.person__name,
          kind:'second_degree',
          url:x.person__url,
          id:x.person__id,
          employees:[{
            title:x.title,
            company:{'name':x.company__name},
            id:x.id,
            person_id: x.person__id,
            startdate: x.startdate,
            enddate: x.enddate
          }]}
      )))
      let uniqueEls = [...new Map(selRows.map((item) => [item["name"], item])).values(),]
      setSelectedRows([...new Set(uniqueEls)])
    }

  // eslint-disable-next-line
  },[selectionModel])

  const handleFavoriteClick = (event) => {
    setfavoriteAnchorEl(event.currentTarget);
  };
  
  const openAddEdit = () => {
    setShowAddEdit(true)  
  }
  
  const openAddEditContact = () => {
    setContact({})
    setShowAddEditContact(true)
  }

  const hideAddEdit = () => {
    setShowAddEdit(false)
  }

  const hideAddEditContact = () => {
    setShowAddEditContact(false)
  }
  
  const doClear = () => {
    setFilterModel({"items":[{"id":1,"columnField":"name","operatorValue":"contains","value":""}],"linkOperator":"and"})
    setFilterValue('&name=')
    setSortModel(defaultSort)
    setSortCode('')
    setFetchable(true)
	  setFavoritesText('')
	  setCurrentFav(null)
	  setCurrentList(null)
	  setNewCount(0)
    setPriorityVal(6)
    setPriorityDisplay(6)
	  setShowNew(false)
    document.title = name + " | Jarvis"
  }
  
  const closeListModal = () => {
  	setNewListModal(false)
	  setSelectedListAnchorEl(false)
  }
  
  
  var moveInArray = function (arr, from, to) {
    let copy = arr.slice(0)
  	var item = copy.splice(from, 1)
  	copy.splice(to, 0, item[0])
    return copy
  }
  
  const handleFavoriteDelete = (id) => {
    async function deleteFavorite() {
      let url = `/companies/api/favorites/${id}/delete`
      await authenticatedFetch(url, {method: 'POST'})
    }
    deleteFavorite()    
    let newFavs = [...favorites]
    let f = favorites.filter((x) => x.id === id)[0]
    let ind = newFavs.indexOf(f)
    newFavs.splice(ind,1)
    setFavorites(newFavs)
  }
  
  const handleListDelete = (id) => {
    async function deleteList() {
      let url = `/companies/api/listings/${id}/delete`
      await authenticatedFetch(url, {method: 'POST'})
    }
    deleteList()    
    let newLists = [...lists]
    let f = lists.filter((x) => x.id === id)[0]
    let ind = newLists.indexOf(f)
    newLists.splice(ind,1)
    setLists(newLists)
	doClear()
  }
  
  
  const handleAlertSwap = (id) => {
    async function swap() {
      let url = `/companies/api/favorites/${id}/swap`
      let res = await authenticatedFetch(url, {method: 'GET'})
      res = res.filter(x => x.kind === type)
      setFavorites(res)
    }
    swap()
  }
  
  const handlePublicSwap = (id) => {
    async function swap() {
      let url = `/companies/api/favorites/${id}/swap-public`
      let res = await authenticatedFetch(url, {method: 'GET'})
      res = res.filter(x => x.kind === type)
      setFavorites(res)
    }
    swap()
    let newFavs = [...favorites]
    let f = favorites.filter((x) => x.id === id)[0]
	  f.shared = !f.shared
    let ind = newFavs.indexOf(f)
    newFavs[ind] = f
    setFavorites(newFavs)
  }
  
  const handleListPublicSwap = (id) => {
    async function swap() {
      let url = `/companies/api/listings/${id}/swap`
      await authenticatedFetch(url, {method: 'GET'})
    }
    swap()
    let newLists = [...lists]
    let f = lists.filter((x) => x.id === id)[0]
	  f.shared = !f.shared
    let ind = newLists.indexOf(f)
    newLists[ind] = f
    setLists(newLists)
  }
  
  const handleListClose = (list) => {
  	if (list){
		  setListAnchorEl(null)
		  let filter = JSON.stringify({items:[{id: 11560, columnField: 'listing', operatorValue: 'listid', value: list.id}]})
      handleTableUpdates(filter,list.sort_model,list.column_order,list.column_visibility,10)
		  setCurrentFav(null)
		  setSelectionModel([])
		  setFetchable(true)
		  setCurrentList(list)
      document.title = list.name + " | Jarvis"
  	}
  }
  
  const handleTableUpdates = (filterModel,sortModel,columnOrder,columnVisibility,priority) => {
    let filter = JSON.parse(filterModel)
    let sort = JSON.parse(sortModel || "\"\"")
    let colOrder = JSON.parse(columnOrder || "\"\"")
    let colViz = JSON.parse(columnVisibility || "\"\"")
    
    if (filter){
      setFilterModel(filter)
      setFilterValue(makeFilterValue(filter))
    } else {
      setFilterModel({"items":[{"id":1,"columnField":"name","operatorValue":"contains","value":""}],"linkOperator":"and"})
      setFilterValue('&name=')
    }
    
    if (sort){
      setSortModel(sort)
      if (sort[0]){
          if (sort[0].sort === 'asc') {
              setSortCode(sort[0].field)
          } else {
              setSortCode(`-${sort[0].field}`)
          }
      }
    } else {
      setSortModel(defaultSort)
      setSortCode('')
    }
    
    let newCols = defaultColumns ?  defaultColumns.slice(0) : []
    if (colOrder){
      Object.entries(colOrder).sort(function(a, b) {
        let aValue = Object.values(a)[1];
        let bValue = Object.values(b)[1];
        return aValue - bValue;
      }).forEach((col) => {
        let obj = columns.filter((x) => x.field === col[0])[0]
        let objIndex = newCols.indexOf(obj)
        newCols = moveInArray(newCols,objIndex,col[1]-1)
      })
    setColumnOrder(colOrder)
    }
    
    if (colViz){
      Object.entries(colViz).forEach((col) => {
        let obj = columns.filter((x) => x.field === col[0])[0]
        let objIndex = newCols.indexOf(obj)
        let newObj = {...obj,...{hide:!col[1]}}
        newCols[objIndex] = newObj
      })
    }
    
    if (priority){
      setPriorityVal(priority)
      setPriorityDisplay(priority)
    }
    
    setColumns(newCols)
  }
  
  const handleFavoriteClose = (fav) => {
    if (fav){
	    setFavoritesText(fav.name)
      document.title = fav.name + " | Jarvis"
	    if (fav.recent > 0){
  	    	setShowNew(true)
  	    	setNewOnly(fav.id)
		      setNewCount(fav.recent)
	    }
      
      handleTableUpdates(fav.filter_model,fav.sort_model,fav.column_order,fav.column_visibility,fav.priority)
      
	    setCurrentFav(fav)
	    setCurrentList(null)
	    setNewCount(fav.recent)
	    setShowNew(true)
      setFetchable(true)
    }
    setfavoriteAnchorEl(null);
  }
  
  const handleNewClick = (event) => {
  	setNewAnchorEl(event.currentTarget)
  }
  
  const handleNewClose = (event) => {
  	setNewAnchorEl(null)
  }

  const doFilter = () => {
    setFetchable(true)
  }

  keyPress('Enter',() =>{
    if (!jarvisOpen && (filterValue !== newFilterValue)){
      setFetchable(true) 
    }
  })
  
  useEffect(() => {
    async function getFavorites() {
      let url = `/companies/api/favorites`
      let res = await authenticatedFetch(url, {
		    method: 'POST',
		    body: JSON.stringify({
		    	path: path
		    })
	    })
      res = res.filter(x => x.kind === type)
      setFavorites(res)
    }
    
    async function fetchLists() {
        const lists = await getLists(type)
        setLists(lists.filter(x => x.kind === type))
    }
    fetchLists()
    getFavorites()
  }, [type,path]);
  
  useEffect(() => {
    setFetchable(true)
  }, [newOnly]);
  
  function doFavoriteOpen(saveAs){
	apiRef.current.hideFilterPanel()
	setSaving(false)
	if (!currentFav || saveAs){
	    setFavoriteOpen(true)
	} else {
	    async function overwriteFav() {
	      let res = await authenticatedFetch(`/companies/api/favorites/${currentFav.id}`, {
	        method: 'PATCH', 
	        body: JSON.stringify({
	          filter_model: JSON.stringify(filterModel),
	          sort_model: JSON.stringify(sortModel),
	          column_order: JSON.stringify(columnOrder),
	          column_visibility: JSON.stringify(columnVisibility),
	          kind: type,
            priority:priorityVal
	        })
	      })
		  let f = favorites.filter(x => x.id === res.id)
		  let newFavs = [...favorites]
		  let ind = newFavs.indexOf(f)
		  newFavs[ind] = res
		  setFavorites(newFavs)
	    }
		setSaving(true)
		setTimeout(function(){
		      setSaving(false)
		    }, 1000);
	    overwriteFav()
		setFetchable(true)
	}
  }
  function favoriteClose(){   
    setFavoriteOpen(false)
  }
  
  const onFavoriteSubmit = (fav) => {
    favoriteClose()
    setFavoriteOpen(false)
    async function saveFav() {
      await authenticatedFetch(`/companies/api/favorites/create`, {
        method: 'POST', 
        body: JSON.stringify({
          name: fav.name,
		      description: fav.description,
          filter_model: JSON.stringify(filterModel),
          sort_model: JSON.stringify(sortModel),
          column_order: JSON.stringify(columnOrder),
          column_visibility: JSON.stringify(columnVisibility),
          kind: type,
          priority:priorityVal
        })
      })
      async function getFavorites() {
        let url = `/companies/api/favorites`
        let res = await authenticatedFetch(url, {
  		  method: 'POST',
  		  body: JSON.stringify({
  		  	path: path
  		  })
  	  })
		    res = res.filter(x => x.kind === type || x.kind === '')
        setFavorites(res)
      }
      getFavorites()    
    }
    saveFav()
  }
  
  const handlePageChange = (params) => {
    setPage(params+1)
    setFetchable(true)
  }
  
  const handlePageSizeChange = (params) => {
  	setPageSize(params)
	setFetchable(true)
  }
  
  const debouncedColumnOrder = useDebounce(columnOrder,300)
  
  useEffect(() => {
    if (currentList) {
      async function updateList() {
        let url = `/companies/api/listings/${currentList.id}/update`
        await authenticatedFetch(url, {
          method: 'POST',
          body: JSON.stringify({
            sort_model: JSON.stringify(sortModel),
            column_order: JSON.stringify(debouncedColumnOrder),
            column_visibility: JSON.stringify(columnVisibility),
          }),
        })
      }

      updateList()
    }
  }, [filterModel, sortModel, debouncedColumnOrder, columnVisibility, currentList]);

  const handleClear = () => {
 	  async function clearFav() {
 	       let url = `/companies/api/favorites/reset`
 	       let res = await authenticatedFetch(url, {
 	  		    method: 'POST',
 	  		    body: JSON.stringify({
 	  		    	path: path,
 	  		      search_id: currentFav.id
 	  		    })
 	  	   })
         res = res.filter(x => x.kind === type)
         setFavorites(res)
	       setShowNew(false)
	  	   setNewOnly(null)
 	  }
    document.title = name + " | Jarvis"
  	clearFav()
  }

  const handleColumnVisibilityChange = (params) => {
    let nobj = {}    
    nobj[params.field] = params.isVisible
    setColumnVisibility({...columnVisibility,...nobj})
  }
  
  const handleColumnOrderChange = (params) => {
    let key = null
    let val = null
    let nobj = {}        
    nobj[params.field] = params.targetIndex
    
    const inTheWay = Object.entries(columnOrder).filter(x=>x[1]===params.targetIndex)[0]
    if (inTheWay){
      key = inTheWay[0]
      if (params.oldIndex < params.targetIndex) {
        val = inTheWay[1] - 1
      } else {
        val = inTheWay[1] + 1
      }
      
      setColumnOrder({...columnOrder,...nobj,...{[key]:val}})
    } else {
      setColumnOrder({...columnOrder,...nobj})
    }
  }
  
  const handleSortModelChange = React.useCallback((params) => {	
    if (params[0]){
        if (params[0].sort === 'asc') {
            setSortCode(params[0].field)
        } else {
            setSortCode(`-${params[0].field}`)
        }
        setSortModel(params);   
        setFetchable(true)
    }
  }, [])
 
  function makeFilterValue(filterModel) {
    let str = ""
    filterModel.items.forEach(function(item){
          if (item.value || ['preferred_firm','preferred_individual','isEmpty','isNotEmpty','portco'].includes(item.operatorValue)){
            let op = ""
            let val = item.value
			      if (typeof(val) === 'object'){
			      	val = val.map((x) => x.id || x)
			      }
            let col = item.columnField
            switch (item.operatorValue){
              case "isAnyOf":
                op="_is_any="
                break
			        case "isOne":
				        op="_one="
				        break;
              case "isOneCompetitor":
                op="_one_competitor="
                break;
              case "isOneSimilar":
                op="_one_similar="
                break;
              case "isSimilarName":
                op="_similar_name="
                break;
              case "isCompetitorName":
                op="_competitor_name="
                break
			        case "isAll":
				        op="_all="
				        break;
			        case "isNot":
				        op="_not="
				        break;
              case "isExactly":
                op="_exactly="
                break;
			        case "preferred_firm":
				        op="_preferred="
				        val = "firm"
				        break;
			        case "preferred_individual":
				        op="_preferred="
				        val = "individual"
				        break;
              case "contains":
                  op = "=";
                  break;
              case "<":
                  op = "_max=";
                  val = Number(val)
                  break;
              case ">":
                  op = "_min=";
                  val = Number(val)
                  break;
              case "=":
                op = "=";
                break;
              case "is":
                op = "=";
                 break;
			        case "isEmpty":
				      op = "_empty="
				      val = true
				      break;
			        case "isNotEmpty":
				      op = "_empty=" 
				      val = false
				      break;
			        case "newerDays":
				        op = "_newer="
				        break;
			        case "olderDays":
				        op = "_older="
				        break
			        case "listid":
				        op = "_listid="
				        break
			        case "portco":
				        op = "_portco="
                val = true
				        break
              default:
				      op = `_${item.operatorValue}=`
                break;
            }
            let filter =`${col}${op}${val}`
            if (filterModel.linkOperator === 'or' && filterModel.items.length > 1){
              str += `|(${encodeURI(filter)})`
            } else{
              str += `&${filter}`
            }
          }
    })
    if (filterModel.linkOperator === 'or' && filterModel.items.length > 1){
      return `&filters=${str}`
    }
    else {
      return str
    }
  }
  
  const handleFilterChange = React.useCallback((params) => {
	  setFilterModel(params)
	  setFilterValue(makeFilterValue(params))
	  setNewFilterValue('')
  }, [])
  
 useEffect(() => {
   async function fetchData() {
     setLoading(true);
     let str = filterValue
     if (newFilterValue){
       str = newFilterValue
     }
     let pref = `/companies/api/${path}`
     let rest = `?page=${page}&size=${pageSize}&priorityVal=${priorityVal}`
     if (!str.includes('undefined') && !str.includes('NaN')){
       rest += str
     }
     if (sortCode){
       rest += `&ordering=${sortCode}`
     }
     let url = pref + encodeURI(rest)
     if (newOnly){url += `&newOnly=${newOnly}`}
     if (defaultFilter && defaultFilter === filterModel){
       url += makeFilterValue(defaultFilter)
     }
     if (url !== lastUrl || forceRefresh){
       setLastUrl(url)
       console.log(url)
	     const res = await authenticatedFetch(url, {method: 'GET'})
       console.log(res.results)
       if (columns.length > 0 && insertMatches){insertMatches(filterModel)}
       setRowCount(res.count)
       let resp = [...res.results]
	     setData(resp)
       setFetchable(false)
       setForceRefresh(false)
     }
     setLoading(false);
   }
   if (fetchable) {
     fetchData()    
   }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchable]);

  const handleBlur = React.useCallback((params, event) => {
      event.stopPropagation();
  }, []);
  
  useEffect(()=>{
    setFetchable(true)
  },[priorityVal])
  
  const handleEditClick = (emp) => {
    setContact(emp)
    setShowAddEditContact(true)
  }
  
  const showEmp = (e) => {
    let hidden = [...hiddenEmps]
    setHiddenEmps(hidden.filter((x)=>x!==e.id))
  }
  
  const hideEmp = (e) => {
    let hidden = [...hiddenEmps]
    hidden.push(e.id)
    setHiddenEmps(hidden)
  }
  
  const hideOtherEmps = (person_id,e) => {
    let hidden = [...hiddenEmps]
    hidden = hidden.concat(selectedRows.filter(s=>s.id===person_id)[0].employees.filter(emp=>emp.id!==e.id).map(e=>e.id))
    setHiddenEmps(hidden)
  }

  return (
    <div style={{ height:height - 95, width: '100%' }}>
      {!finalized &&
        <Header 
          history={history}
          name={name}
          warning={warning}
          setWarning={setWarning}
          showHelp={showHelp}
          setShowHelp={setShowHelp}
          path={path}
          setFilterModel={setFilterModel}
          setSortModel={setSortModel}
          setSortCode={setSortCode}
          makeFilterValue={makeFilterValue}
          setFilterValue={setFilterValue}
          setFetchable={setFetchable}
          setJarvisOpen={setJarvisOpen}
          selectionModel={selectionModel}
        />
        }
        {exportContacts ?
          <Container>
          {finalized ?
              <Box style={{textAlign:'center',marginBottom:30}}>
                <HeaderImg src={mvp} alt="MVP Ventures" onClick={()=>setFinalized(false)}/>
                <Title style={{fontSize: sv.h2.fontSize,marginBottom:0,paddinBottom:0}}>Potential Connections{company && `: ${company}`}</Title>      
                <Note><strong>Strictly private and confidential.</strong> Prepared only for review by {company}. Please note, this list is based on the MVP Limited Partner network and is preliminary; any potential introductions are based on willingness of the contact and take place only after a review with {company} leadership to better understand needs and asks.</Note>
              </Box>
              : 
              <>
                <Button
                  onClick={()=>setExportContacts(false)}
                  style={{marginRight:5,marginTop:20,marginBottom:20}}
                >
                  Back
                </Button>
                <Button variant="contained" size='small' color="primary" onClick={()=>setFinalized(true)} style={{marginRight:25}}>
                  Finish
                </Button>
                <Box>
                  <TextField label="Enter Company Name" value={company} onChange={(e)=>setCompany(e.target.value)} style={{marginBottom:30}}/>
                </Box>
              </>
          }
            <div>
                {selectedRows.map((emp,ind) =>
                  <Box key={ind}>
                    <li style={{marginTop:5, fontSize: sv.bodyText.fontSize * 0.8}}>
                      {emp.url ? <Link href={`${emp.url}`} target='_blank'>{emp.name}</Link> : <NoLink>{emp.name}</NoLink>}
                      {!finalized &&<EditOutlinedIcon style={{fontSize: sv.bodyText.fontSize * 0.9, marginLeft: 10, cursor:'pointer'}} onClick={()=>handleEditClick(emp)}/>}
                      <ul>
                        {emp.employees.filter((x)=>x.title).filter((x)=>!finalized || !hiddenEmps.includes(x.id)).sort(compareDates).map((e,id) =>
                          <li key={id}>
                            {(!finalized || !hiddenEmps.includes(e.id)) &&
                              <>{e.title} at {e.company?.name || e.institution}  {makeDateRange(e)}</>
                            }
                            {!finalized &&
                              <>{hiddenEmps.includes(e.id) ? <VisibilityOffIcon style={{fontSize:sv.bodyText.fontSize*0.9,cursor:'pointer',marginLeft:10}} onClick={()=>showEmp(e)}/> : <><VisibilityIcon style={{fontSize:sv.bodyText.fontSize*0.9,cursor:'pointer',marginLeft:10}} onClick={()=>hideEmp(e)}/>{path !== 'contacts/seconddegree' && <VisibilityOutlinedIcon style={{fontSize:sv.bodyText.fontSize*0.9,cursor:'pointer',marginLeft:10}} onClick={()=>hideOtherEmps(emp.id,e)}/>}</> }</>
                            }
                          </li>
                        )}
                      </ul>
                      
                    </li>
                  </Box>
                )}
            </div>
      </Container>     
        :
        <DataGridPro
          columns={columns}
          rows={data}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[25,50,100,500]}
          columnBuffer={20}
          rowCount={rowCount}
          paginationMode="server"
          onPageChange={handlePageChange} 
          onPageSizeChange={handlePageSizeChange}
          filterMode="server"
          filterModel={filterModel}      
          onFilterModelChange={handleFilterChange}
          sortingOrder={['desc', 'asc']}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          initialState={{
              sorting: {
                sortModel: defaultSort,
              },
              pinnedColumns: pinnedColumns
            }}
          onColumnVisibilityChange={handleColumnVisibilityChange}
          onColumnOrderChange={handleColumnOrderChange}
          loading={loading}
          showToolbar
		      checkboxSelection={selection}
		      disableSelectionOnClick
          keepNonExistentRowsSelected
		      onSelectionModelChange={(sec) => {
		        setSelectionModel(sec)
		      }}
		      selectionModel={selectionModel}
          density="compact"
          components={{
            Toolbar: CustomToolbar,
			      FilterPanel: CustomFilterPanel 
          }}
		      componentsProps={{
		              filterPanel: { doFavoriteOpen,currentFav,favoritesText,doClear,doFilter,saving },
			  		      toolbar: {favoriteAlertSum,handleFavoriteClick,favoriteAnchorEl,handleFavoriteClose,favorites,handleAlertSwap,handlePublicSwap,handleFavoriteDelete,path,showNew,handleNewClick,newCount,newAnchorEl,handleNewClose,setNewOnly,handleClear,openAddEditContact,openAddEdit,type,currentFav,selectionModel,lists,listAnchorEl,setListAnchorEl,selectedListAnchorEl,setSelectedListAnchorEl,setNewListModal,handleListDelete,handleListPublicSwap,handleListClose,currentList,setCurrentList,setSelectionModel,data,setData,setFilterModel,setFilterValue,setSortModel,defaultSort,setSortCode,setFetchable,setFavoritesText,setCurrentFav,setNewCount,setShowNew,exportContacts,setExportContacts,showHelp,setWarning,setForceRefresh,setPriorityVal,priorityDisplay,setPriorityDisplay}
		      }}
		      onCellEditCommit={handleCellEditCommit}
          onCellBlur={handleBlur}
  		    apiRef={apiRef}
        />}
      <Dialog open={favoriteOpen} onClose={favoriteClose}>
        <form onSubmit={handleSubmit(onFavoriteSubmit)}>   
          <DialogTitle>Add to Saved Filters</DialogTitle>
          <DialogContent>
		  	  <Section>
              <TextField
                inputRef={register({required: true})}
                name="name"
                label="Saved Favorite Name"
                fullWidth
                defaultValue={""}
              />
              {errors.name && <ErrorText>This field is required</ErrorText>}
			  </Section>
			  <Section>
              <TextField
                inputRef={register()}
                name="description"
                label="Description (optional)"
                multiline={true}
                rows={3}
                fullWidth
                defaultValue={""}
              />
			  </Section>
          </DialogContent>
          <DialogActions>
            <Button onClick={favoriteClose}>
              Cancel
            </Button>
            <Button type='submit' color="primary">
              Save
            </Button>
          </DialogActions>
        </form>       
      </Dialog>
       <NewListModal newListModal={newListModal} setLists={setLists} type={type} setWarning={setWarning} closeListModal={closeListModal} rows={selectionModel.map((x)=>({'id':x}))}/>
       <AddEditCompany setWarning={setWarning} open={showAddEdit} close={hideAddEdit} company={{}}/>
       <AddEditContact setWarning={setWarning} open={showAddEditContact} close={hideAddEditContact} contact={contact} setContact={setContact} data={data} setData={setData}/>
      </div>
  );
}
