import React, { useState, useEffect, useMemo, useRef } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import {useParams} from "react-router";
import { authenticatedFetch, getUser } from '../utils/auth';
import { styled } from '@mui/material/styles';
import { Title, Text } from '../constants/StyleComponents';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Link from '@mui/material/Link';
import Language from '@mui/icons-material/Language';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Grid2 from '@mui/material/Unstable_Grid2'
import {formatMMDDYYYY,formatDayMonthYear,millionMoney,getUsers,formatInvestors,cleanInvestments,cleanRound,makeDateRange} from '../utils/tools';
import sv from '../constants/Styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddEditCEO from './AddEditCEO.js'
import ChartMilestone from './ChartMilestone.js'
import Header from './Header';
import { Statuses } from '../constants/Constants'
import AddEditCompany from './AddEditCompany.js'
import AddEditReferrer from './AddEditReferrer.js'
import Chart from './CompanyPage/Chart.js'
import JarvisOutreach from './CompanyPage/JarvisOutreach.js'
import ScoreChart from './CompanyPage/ScoreChart.js'
import Guide from './CompanyPage/Guide.js'
import EditSnooze from './EditSnooze.js'
import InnerComments from './InnerComments'
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import pitchbook from '../assets/pitchbook.png';
import outreach from '../assets/outreach.png'
import cbi from '../assets/cbi.png'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import harmonic from '../assets/harmonic.png'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import NoteBox from './NoteBox'
import {isMobile} from '../utils/tools'
import RefreshIcon from '@mui/icons-material/Refresh';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import IntroTable from './IntroTable'
import ExpandableBox from './ExpandableBox'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import GroupsIcon from '@mui/icons-material/Groups';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ReactHtmlParser from 'react-html-parser';
import S3 from 'react-aws-s3'
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Badge from '@mui/material/Badge';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Alert from '@mui/material/Alert';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SyncIcon from '@mui/icons-material/Sync';
import { FileUploader } from "react-drag-drop-files"
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';

const UnstyledLink = styled('a')(({ disabled = false }) => ({
  cursor: disabled ? 'default' : 'pointer',
  color: disabled ? '#ccc' : 'inherit',
  pointerEvents: disabled ? 'none' : 'auto',
  textDecoration: 'none',
}))

const ReactS3Client = new S3({
    bucketName: "mvp-companies",
    region: 'us-west-1',
    accessKeyId: 'AKIAV7BV7GLLCIBG2CVF',
    secretAccessKey: "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"
})

const ToolerTip = styled(({ className, color, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))((props) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    backgroundColor: props.color || 'white',
    borderStyle:props.border || 'solid',
    borderColor:props.borderColor || 'black',
  },
}));

function UploadDialog({showFileUpload,setShowFileUpload,setUploadedFile,companyId,setFiles,files}) {
  const [fileType, setFileType] = useState('Select')
  const [uploadText, setUploadText] = useState('')
  const [uploadTitle, setUploadTitle] = useState('')
  const [warning,setWarning] = useState({text:'',severity:''})


  function FileListItem({ item, depth = 0 }) {
      const [open, setOpen] = useState(false)
      const paddingLeft = 4 * depth

      // Create a memoized version of the item object
      const memoizedItem = useMemo(() => {
        // Create a new object with the same properties as item
        return {
          id: item.id,
          name: item.name,
          created_at: item.created_at,
          parsed: item.parsed,
          is_folder: item.is_folder,
          url: item.url,
          folder: item.folder,
          needs_syncing: item.needs_syncing
        }}, [
        item.id,
        item.name,
        item.created_at,
        item.parsed,
        item.is_folder,
        item.url,
        item.folder,
        item.needs_syncing
      ]);

      return (
          <>
              <ListItem sx={{marginTop:0,marginBottom:0,paddingTop:0,paddingBottom:0}} secondaryAction={
                  memoizedItem.is_folder ? 
                      open ?
                            (
                            <IconButton edge="end" sx={{marginRight:'10px'}} onClick={()=>setOpen(false)}>
                                <ExpandLess />
                            </IconButton>
                        )
                      :
                
                        (
                        <IconButton edge="end" sx={{marginRight:'10px'}} onClick={()=>setOpen(true)}>
                            <ExpandMore />
                        </IconButton>
                      ) : (
                      <IconButton edge="end" sx={{marginRight:'10px'}} onClick={() => handleDelete(memoizedItem.id)}>
                          <DeleteIcon />
                      </IconButton>
                  )
              }>
                  <ListItemButton sx={{pl:paddingLeft,marginTop:0,marginBottom:0,paddingTop:0,paddingBottom:0}} onClick={memoizedItem.url ? () => window.open(memoizedItem.url) : null}>
                      <ListItemText
                          primary={
                          <>
                            {memoizedItem.needs_syncing && <SyncIcon sx={{verticalAlign:'middle',marginRight:1}}/>}
                            {memoizedItem.is_folder && <FolderOpenIcon sx={{verticalAlign:'middle',marginRight:1}}/>}
                            <Box component='span'>{memoizedItem.name}</Box>
                          </>
                            
                          }
                          secondary={
                              <>
                                  <Box component='span'>{formatMMDDYYYY(memoizedItem.created_at)}</Box>
                                  {memoizedItem.parsed && <SmartToyOutlinedIcon sx={{ verticalAlign: 'top', fontSize: '1.2em', marginLeft: 1 }} />}
                              </>
                          }
                      />
                  </ListItemButton>
              </ListItem>
              {memoizedItem.is_folder && (
                  <Collapse in={open} timeout="auto" unmountOnExit>
                      <List component="div" dense={true} disablePadding>
                          {files.filter(x=>x.folder===memoizedItem.id).map((subItem, index) => (
                              <FileListItem key={index} item={subItem} depth={depth+1}/>
                          ))}
                      </List>
                  </Collapse>
              )}
          </>
      );
  }

  useEffect(()=>{
    if (warning?.text){
      setTimeout(() => {setWarning({text:'',severity:''})}, warning.timeout || 60000)
    }
   // eslint-disable-next-line
  },[warning])

  const uploadDoc = (kind) => {
    async function doUpload(){
      setWarning({text:'Uploading...',severity:'info'})
      let res = await authenticatedFetch(`/companies/api/company/${companyId}/files/create_doc`, {
        method: 'POST', 
        body: JSON.stringify({
          name: uploadTitle,
          kind: kind,
          text: uploadText
        })
      })
      setFiles(res)
      if (kind === 'gdrive'){
        setWarning({text:'Success, full folder contents will sync within the hour',severity:'success'})
      } else {
        setWarning({text:'Success!',severity:'success'})
      }
      
      setUploadText('')
      setFileType('Select')
      setUploadTitle('')
    }
    doUpload()
  }
  
  const handleDelete = (fid) => {
    async function doDelete(){
      setWarning({text:'Deleting...',severity:'info'})
      let res = await authenticatedFetch(`/companies/api/files/${fid}/delete`, {method: 'POST'})
      setFiles(res)
      setWarning({text:'Deleted!',severity:'success'})
    }
    doDelete()
  }

  return (
      <Dialog
        open={showFileUpload}
        onClose={()=>setShowFileUpload(false)}
        fullWidth={true}
        maxWidth={'md'}
      >
        {warning && warning.text && <Alert severity={warning.severity} onClose={() => setWarning({})}>{warning.text}</Alert>}
        <DialogTitle>Files</DialogTitle>
          <List dense={true} sx={{ maxHeight: 400, overflow: 'auto' }}>
              {files.filter(x=>!x.folder).map((file, index) => (
                  <FileListItem key={file.id} item={file} />
              ))}
          </List>
        <DialogContent>
            <InputLabel>Add New</InputLabel>
            <Select
              size='small'
              value={fileType}
              onChange={(e)=>{
                setFileType(e.target.value)
                setUploadText('')
                setUploadTitle('')
              }}
            >
              <MenuItem key='Select' value='Select' disabled>Select</MenuItem>
              {['File','Google Doc/Sheet/Slides','Gdrive Folder','URL','Raw Text'].map((val,ind) =>
                <MenuItem key={ind} value={val} sx={{fontSize: sv.bodyIcon.fontSize*1.1}}>{val}</MenuItem>
              )}
             </Select>
            {fileType === 'Select' &&
                <Button variant="contained" disabled sx={{marginLeft:2}}>Upload</Button>
            }
            {fileType === 'File' &&
                <FileUploader children={
                  <Box component='span' sx={{marginLeft:2, width:'200px',fontStyle:'italic',fontSize:sv.bodyText.fontSize*0.9,cursor:'pointer',borderStyle:'dotted',borderColor:sv.colors.blue1,padding:2,textAlign:'center'}}>
                    Click to upload or drop file
                  </Box>
                  } hoverTitle=' ' onTypeError={(err)=>setWarning({text:err,severity:'error'})} handleChange={(file)=>setUploadedFile(file)} name="file" types={["JPG", "JPEG", "PNG", "PDF", "CSV", "XLS", "XLSX","ZIP","TXT","HTML","DOC", "DOCX", "PPT", "PPTX"]} 
                />
            }
            {fileType === 'Google Doc/Sheet/Slides' &&
              <Stack spacing={2} sx={{marginTop:2}}>
                <TextField size='small' label="Document URL" variant="outlined" value={uploadText} onChange={(e)=>setUploadText(e.target.value)}/>
                <Button
                  variant="contained"
                  sx={{marginLeft:2}}
                  onClick={()=>uploadDoc('google')}
                  disabled={!uploadText}
                >
                  Upload
                </Button>
              </Stack>
            }
            {fileType === 'Gdrive Folder' &&
              <Stack spacing={2} sx={{marginTop:2}}>
                <TextField size='small' label="Folder URL" variant="outlined" value={uploadText} onChange={(e)=>setUploadText(e.target.value)}/>
                <Button
                  variant="contained"
                  sx={{marginLeft:2}}
                  onClick={()=>uploadDoc('gdrive')}
                  disabled={!uploadText}
                >
                  Upload
                </Button>
              </Stack>
            }
            {fileType === 'URL' &&
            <Stack spacing={2} sx={{marginTop:2}}>
              <TextField size='small' label="Set Title" variant="outlined" value={uploadTitle} onChange={(e)=>setUploadTitle(e.target.value)}/>
              <TextField size='small' label="URL" variant="outlined" value={uploadText} onChange={(e)=>setUploadText(e.target.value)}/>
              <Button
                variant="contained"
                sx={{marginLeft:2}}
                onClick={()=>uploadDoc('url')}
                disabled={!uploadText || !uploadTitle}
              >
                Upload
              </Button>
            </Stack>
          }
            {fileType === 'Raw Text' &&
                <Stack spacing={2} sx={{marginTop:2}}>
                  <TextField size='small' label="Set Title" variant="outlined" value={uploadTitle} onChange={(e)=>setUploadTitle(e.target.value)}/>
                  <TextareaAutosize minRows={8} placeholder="Paste Text"value={uploadText} onChange={(e)=>setUploadText(e.target.value)}/>
                  <Button
                    variant="contained"
                    sx={{marginLeft:2}}
                    onClick={()=>uploadDoc('raw')}
                    disabled={!uploadTitle || !uploadText}
                  >
                    Upload
                  </Button>
                </Stack>
            }
        </DialogContent>
      </Dialog>
  )
}


function AlertDialog({openRefresh,setOpenRefresh,companyId,setWarning,setData}) {
    const handleClose = () => {
      setOpenRefresh(false);
    };
    
    function handleRefresh(everything) {
      async function refreshData() {
        let res = await authenticatedFetch(`/companies/api/company/${companyId}/refresh?everything=${everything}`, {method: 'GET'})
        setWarning({text:'Data update complete',severity:'success'})
        setData(res)
      }
      setWarning({text:'Refresh triggered, please wait (note, may take 30 seconds or more)...',severity:'info',timeout:60000})
      handleClose()
      refreshData() 
    }

    return (
      <div>
        <Dialog
          open={openRefresh}
          onClose={handleClose}
        >
          <DialogTitle>Refreshing data – Refresh historical headcount data?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Older historical headcount data may be irrecoverable if you choose Refresh Everything. Only choose this option if older headcounts are incorrect. Keep Headcounts will refresh all data and update headcount information as available.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>handleRefresh(true)} variant='outlined'>Refresh Everything</Button>
            <Button onClick={()=>handleRefresh(false)} variant='contained'>
              Keep Headcounts
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

function Company({history}) {
  const [sequencesLoading, setSequencesLoading] = useState(false)
  const [data,setData] = useState(null)
  const [users, setUsers] = useState([])
  const [owner, setOwner] = useState("")
  const [status,setStatus] = useState('')
  const [companyId, setCompanyId] = useState(null)
  const [postUpdateDate,setPostUpdateDate] = useState(null)
  const [snoozeDate, setSnoozeDate] = useState('')
  const [showAddEditCompany, setShowAddEditCompany] = useState(false)
  const [company, setCompany] = useState({})
  const [CEOOpen, setCEOOpen] = useState(false);
  const [ceoDetails, setCeoDetails] = useState({})
  const [warning,setWarning] = useState({text:'',severity:''})
  const [snoozeOpen, setSnoozeOpen] = useState(false)
  const [noteOpen, setNoteOpen] = useState(false)
  const [assignment, setAssignment] = useState({})
	const [displayedHighlightCategories,setDisplayedHighlightCategories] = useState([])
  const [sequences, setSequences] = useState([])
  const [sequenceList, setSequenceList] = useState([])
  const [oid, setOid] = useState(null)
  const [funding, setFunding] = useState({})
  const [posts, setPosts] = useState([])
  const [people, setPeople] = useState([])
  const [competitors, setCompetitors] = useState([])
  const [highlights, setHighlights] = useState([])
  const [indTech, setIndTech] = useState([])
  const [fundingSources, setFundingSources] = useState('All Sources')
  const [introRows, setIntroRows] = useState([])
  const [showMilestone, setShowMilestone] = useState(false)
  const [refreshGraphData, setRefreshGraphData] = useState(true)
  const [comments, setComments] = useState([])
  const [openRefresh, setOpenRefresh] = useState(false)
  const [tab,setTab] = useState(0)
  const [graphData, setGraphData] = useState([])
  const [employeeFilter, setEmployeeFilter] = useState('All')
  const [newEmployeeFilter, setNewEmployeeFilter] = useState(false)
  const [connectionsOnly, setConnectionsOnly] = useState(false)
  const [showHelp, setShowHelp] = useState(false)
  const [reminders, setReminders] = useState([])
  const [tasks, setTasks] = useState([])
  const [activeTask, setActiveTask] = useState({})
  const [relatedCos, setRelatedCos] = useState([])
  const [relatedInvestors, setRelatedInvestors] = useState([])
  const [relatedPeople, setRelatedPeople] = useState([])
  const [relatedInvestorType, setRelatedInvestorType] = useState('All')
  const [overlapInvestors, setOverlapInvestors] = useState([])
  const [investorStage, setInvestorStage] = useState('All')
  const [investorStagePercent, setInvestorStagePercent] = useState(0)
  const [height, setHeight] = useState(0); // State to store the dynamic height
  const firstGridRef = useRef(null)
  const [showFileUpload,setShowFileUpload] = useState(false)
  const [files, setFiles] = useState([])
  const [uploadedFile, setUploadedFile] = useState(null)
  const [showReferrer, setShowReferrer] = useState(false)
  const [relevantOnlyLps,setRelevantOnlyLps] = useState(false)
  
  useEffect(() => {
      // Function to update the height state
      const updateHeight = () => {
        if (firstGridRef.current) {
          setHeight(firstGridRef.current.offsetHeight);
        }
      };

      updateHeight();

      // Optional: Add event listeners if you expect height changes not triggered by tab changes, e.g., window resizing
      window.addEventListener('resize', updateHeight);

      return () => {
        window.removeEventListener('resize', updateHeight);
      };
    }, [tab, comments])
  
  useEffect(()=>{
    if (uploadedFile){
      setWarning({text:'Uploading...',severity:'info'})
      ReactS3Client
          .uploadFile(uploadedFile, uploadedFile.name)
          .then(data => {
            async function uploadFile() {
              let res = await authenticatedFetch(`/companies/api/company/${companyId}/files/create`, {
                method: 'POST', 
                body: JSON.stringify({
                  name: data.key,
                  url: data.location
                })
              })
              setFiles(res)
              setWarning({text:'Success!',severity:'success'})
              setUploadedFile(null)
            }
            uploadFile()
          })
          .catch(err => console.error(err))
    }
  },[uploadedFile,companyId])
  
  useEffect(() => {
    async function graphData() {
      const res = await authenticatedFetch(`/companies/api/company/${companyId}/graph_new`, {method: 'GET'})
      setGraphData(res.results.map(x => {
        const vad = x.milestone_set.filter((i)=>i.kind==='Value-Add')
        const met = x.milestone_set.filter((i)=>i.kind==='metric')
        const mile = x.milestone_set.filter((i)=>i.kind==='milestone')
        const value_add = vad && vad.length > 0 ? {value_add_value:0,value_add:vad.map((x)=>x.text).join(', ')} : null
        const metrics = met && met.length > 0 ? {metric_value:0,metric:met.map((i)=>`${i.text}: ${i.value}`).join(', ')} : null        
        const milestones = mile && mile.length > 0 ? {milestone_value:0,milestone:mile.map((x)=>x.text).join(', ')} : null
        return {
          ...x,
          ...value_add,
          ...metrics,
          ...milestones
        }
      }))
      setRefreshGraphData(false)
    }
    companyId && refreshGraphData && graphData()
  // eslint-disable-next-line
  },[companyId,refreshGraphData])
      
  function handleSnoozeClose(){
    setSnoozeOpen(false)
  }
  function handleMilestoneClose(){
    setShowMilestone(false)
  }
  function handleNoteClose(){
    setNoteOpen(false)
  }
  
  function handleCEOOpen(){
      setCEOOpen(true) 
  }
  function handleCEOClose(){
    setCEOOpen(false)
  }  
  
  const hideAddEditCompany = () => {
    setShowAddEditCompany(false)
  }

  function handleOwnerChange(event){
    setOwner(event.target.value)
    let own = event.target.value === 'None' ? null : event.target.value    
    
	  if (own && getUser()["name"] !== own){
	  	setAssignment({'assignee':own,'assigner':getUser()["name"]})
	  	setNoteOpen(true)
	  }
    sendData('owner',own)
  }

  function handleStatusChange(event){
    setStatus(event.target.value)
    if (event.target.value === 'Outreach'){
      setCEOOpen(true)
      !data.referrer && setShowReferrer(true)
    }
	
	  if (['Outreach','Discussions','Snoozed'].includes(event.target.value) && !owner){
	  	setOwner(getUser()["name"])
	  }
    
    if (event.target.value === 'Snoozed'){
      setSnoozeOpen(true)
    } else {
      setSnoozeDate(null)
    }
    sendData('status',event.target.value)
  }
  
  useEffect(()=>{
     if (company?.name){
       document.title = company.name + " | Jarvis"
     }
  },[company])

  useEffect(() => {
     async function getSequences() {
       let url = `/companies/api/sequences/${companyId}`
       let res = await authenticatedFetch(url, {method: 'GET'})
       setSequenceList(res)
     }
  if (companyId){getSequences()}
  }, [companyId]);

  const {id} = useParams();

  useEffect(() => {
    async function fetchData() {
      let url = `/companies/api/company/${id}`
      let res = await authenticatedFetch(url, {method: 'GET'})
      console.log("company",res)
      setData(res)
    }

    getUsers().then((v) => setUsers(v))  
    fetchData()
    
    async function getComments() {
      let url = `/companies/api/company/${id}/comments`
      let res = await authenticatedFetch(url, {method: 'GET'})
      setComments(res)
    }
    getComments()
    
    async function getReminders() {
      let url = `/companies/api/jarvis_get_reminders`
      let res = await authenticatedFetch(url, { method: 'POST', 
          body: JSON.stringify({
            company_id: id
          })
      })
      setReminders(res)
    }
    getReminders()
  }, [id])
  
  useEffect(()=>{
    if (data){
      data.ceo && setOid(data.ceo.outreach_id)
      setCeoDetails({...data.ceo,
        ...{companyId:data.id},
        connections: Array.from(((data.ceo||{}).employees||[])
            .filter(e => e.connections.length > 0)
            .map(e => e.connections.filter(c => c.name !== data.ceo.name))
            .flat()
            .reduce((acc, cur) => {
                const key = JSON.stringify(cur);
                acc.has(key) || acc.set(key, cur);
                return acc;
            }, new Map())
            .values()
          )
      })
      setCompanyId(data.id)
      setStatus(data.status)
      setSnoozeDate(data.snooze_date)
      let co = {
        id: data.id,
        name: data.name,
        website: data.website,
        li_url: data.li_url,
        tw_url:data.tw_url,
        pitchbook_id:data.pitchbook_id,
        harmonic_id:data.harmonic_id,
        cbi_profile_url:data.cbi_profile_url,
        referrer:data.referrer
      }
      setCompany(co)
    
      if (data.owner) {
        setOwner(data.owner)
      }
    }
  },[data])
  
  useEffect(() => {
    async function getFiles(){
      const res = await authenticatedFetch(`/companies/api/company/${companyId}/files`, {method: 'GET'})
      console.log("FILES",res.results)
      setFiles(res.results)
    }
    async function fundingData() {
      const res = await authenticatedFetch(`/companies/api/company/${companyId}/funding`, {method: 'GET'})
      setFunding(res) 
    }
    
    async function postData() {
      const res = await authenticatedFetch(`/companies/api/company/${companyId}/posts`, {method: 'GET'})
      setPosts(res) 
    }
    
    async function introRows() {
      const res = await authenticatedFetch(`/companies/api/company/${companyId}/intros`, {method: 'GET'})
      setIntroRows(res)
    }
    
    async function peopleData() {
      const res = await authenticatedFetch(`/companies/api/company/${companyId}/people`, {method: 'GET'})
      let highlightList = []
      res.results.forEach(p => {
        const title = p.employees.filter(e=>e.company?.id===companyId && e.enddate === null)[0].title
        
        p.employees.filter(e=>e.company?.id!==companyId).forEach(e => {
          e.highlights.forEach(h => {
            if (!highlightList.map(high=>high.name).includes(h)){
              highlightList.push({
                name: h,
                employees: []
              })
            }
            if (['Top School','Previous Founder','Previous Multi-Time Founder'].includes(h)){
              if (!highlightList.filter(high=>high.name===h)[0].employees.map(e=>e.name).includes(p.name)){
                highlightList.filter(high=>high.name===h)[0].employees.push({
                  name: p.name,
                  seniority: e.seniority,
                  currentTitle: title
                })
              }
            } else {
              highlightList.filter(high=>high.name===h)[0].employees.push({
                name: p.name,
                seniority: e.seniority,
                title: e.title,
                currentTitle: title,
                company: e.company?.name || e.institution
              })
            }
          })
        })
      })
      const peeps = (res.results.map((obj) => ({
        ...obj,
        connections: Array.from(obj.employees
            .filter(e => e.connections.length > 0)
            .map(e => e.connections.filter(c => c.name !== obj.name))
            .flat()
            .reduce((acc, cur) => {
                const key = JSON.stringify(cur);
                acc.has(key) || acc.set(key, cur);
                return acc;
            }, new Map())
            .values()
          )
      }))
      )

      const seniorityOrder = ['Founder', 'CXO', 'Director', 'Senior'];

      // Function to find a person's seniority based on the first employee match
      function findPersonSeniority(person, companyId) {
        const employee = person.employees.find(emp => emp?.company?.id === companyId);
        return employee ? employee.seniority : null; // Return null if no match is found
      }

      // Sorting function that uses the custom seniority order
      function sortPeopleBySeniority(people, companyId) {
        return people.sort((a, b) => {
          const seniorityA = findPersonSeniority(a, companyId);
          const seniorityB = findPersonSeniority(b, companyId);

          // Handle cases where seniority is not found (put them at the end)
          if (seniorityA === null) return 1;
          if (seniorityB === null) return -1;

          const rankA = seniorityOrder.indexOf(seniorityA);
          const rankB = seniorityOrder.indexOf(seniorityB);

          // Handle case for 'everyone else' by putting them at the end
          const rankAAdjusted = rankA === -1 ? seniorityOrder.length : rankA;
          const rankBAdjusted = rankB === -1 ? seniorityOrder.length : rankB;

          return rankAAdjusted - rankBAdjusted;
        });
      }
      setPeople(sortPeopleBySeniority(peeps, companyId))
      setHighlights(highlightList)
      setDisplayedHighlightCategories(highlightList.map(h=>h.name))
    }
    
    async function indTechKeywordData() {
      const res = await authenticatedFetch(`/companies/api/company/${companyId}/indtech`, {method: 'GET'})
      setIndTech([...new Set(res.industries.concat(res.technologies).concat(res.keywords))])
    }
    
    async function relatedPortcoData() {
      const res = await authenticatedFetch(`/companies/api/company/${companyId}/related_portcos`, {method: 'GET'})
      setRelatedCos(res.portcos)
      setOverlapInvestors(res.investors)
    }
    
    async function relatedInvestorData() {
      const res = await authenticatedFetch(`/companies/api/company/${companyId}/related_investors`, {method: 'GET'})
      setRelatedInvestors(res)
    }

    async function competitorData() {
        const res = await authenticatedFetch(`/companies/api/getcompetitors`, {
          method: 'POST', 
          body: JSON.stringify({
            companies: [companyId]
          })
        })
        setCompetitors(res)
    }

    if (companyId) {
      fundingData()
      peopleData()
      postData()
      competitorData()
      indTechKeywordData()
      getFiles()
      introRows()
      relatedPortcoData()
      relatedInvestorData()
    }
  },[companyId])
  
  useEffect(()=>{
    async function updateRelevantPeople() {
      setRelatedPeople([])
      const res = await authenticatedFetch(`/companies/api/company/${companyId}/related_people?lps_only=${relevantOnlyLps}`, {method: 'GET'})
      setRelatedPeople(res)
    }
    companyId && updateRelevantPeople()
  },[relevantOnlyLps,companyId])

  const groupByCompany = (employees) => {
    // Group roles by company
    const grouped = employees?.filter(e=>e.source!=='getceo' && (e.company||e.school)).reduce((acc, role) => {
      const org = role.company || role.school
      const oid = role.company ? `c${org.id}` : `s${org.id}` 

      if (!acc[oid]) {
        acc[oid] = {
          companyName: org.name,
          logo: org.logo,
          id: oid,
          roles: [],
        };
      }
      acc[oid].roles.push({
        title: role.title,
        startdate: role.startdate,
        enddate: role.enddate,
      });

      return acc;
    }, {});

    // Convert the grouped object into an array of company objects
    const companiesArray = Object.values(grouped);
    // Sort the companies based on the earliest startdate of their roles
    companiesArray.sort((a, b) => {
      const aEarliestDate = a.roles.reduce((earliest, role) => {
        return role.startdate && (!earliest || role.startdate < earliest) ? role.startdate : earliest;
      }, null);

      const bEarliestDate = b.roles.reduce((earliest, role) => {
        return role.startdate && (!earliest || role.startdate < earliest) ? role.startdate : earliest;
      }, null);

      // Compare the earliest dates, treating nulls as infinitely in the future
      if (aEarliestDate === null && bEarliestDate === null) return 0; // Both are null, considered equal
      if (aEarliestDate === null) return -1; // a is null, so it goes before b
      if (bEarliestDate === null) return 1; // b is null, so a goes before b
      
      return aEarliestDate.localeCompare(bEarliestDate);
    });
    return companiesArray.reverse();
  };
  
  const formattedValuation = useMemo(()=>{
    return funding && funding.investments && millionMoney(funding.valuation || funding.investments.filter(i=>i.valuation)[0]?.valuation)
  },[funding])
  
  const formattedStepUp = useMemo(()=>{
    if (funding && funding.canonical_investments) {
      const vals = funding.canonical_investments.map((i)=>i.valuation).filter((x)=>x)
      if (vals.length >=2 ) {
        let delta = (vals[0] / vals[1]).toFixed(1)
        if (delta > 1){
          delta = `▲ ${delta}`
        } else if (delta < 1){
          delta = `▼ ${delta}`
        }
        return delta + 'x (' + (millionMoney(vals[0]-vals[1])) + ')'
      }
    }
    return ""
  },[funding])

  const formattedHeadcountChange = useMemo(()=>{
    let delta = ""
    if (data?.current){
      const oneYearAgo = new Date()
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
      const formattedDate = oneYearAgo.toISOString().split('T')[0].slice(0, 8) + '01T00:00:00'
      const matchingObject = graphData.find(obj => obj.moyr_date === formattedDate)
    
      if (matchingObject){
        const old = matchingObject.current
        delta = ((data.current - old) / old * 100).toFixed(1)
        if (delta > 0) {
          delta = `▲ ${delta}% YoY`
        } else if (data < 0){
          delta = `▼ ${delta}% YoY`
        }
      }
    }
    return delta
  },[data,graphData])

  const cleanedInvestments = useMemo(()=>{
    return funding && cleanInvestments(funding.investments)
  },[funding])
  
  const cleanedCanonicalInvestments = useMemo(()=>{
    return funding && cleanInvestments(funding.canonical_investments)
  },[funding])
  
  const formattedLatestInvestors = useMemo(()=>{
    return cleanedCanonicalInvestments && cleanedCanonicalInvestments.length > 0 && formatInvestors(cleanedCanonicalInvestments[0].cleanInvestors)
  },[cleanedCanonicalInvestments])
  
  const competitorColumns = [
    { headerName: 'Name', field: 'name',width:120, renderCell:(params) =>(
      <span>
        <a href={`/company/${params.row.id}`} target="_blank" rel="noreferrer">{params.row.name}</a>
        {(params.row.description || params.row.li_shortdesc || params.row.pb_desc) &&
        <ToolerTip title={<Typography variant="body2" color='primary'>{params.row.description || params.row.pb_desc || params.row.li_shortdesc}</Typography>} placement='top'>
          <InfoOutlinedIcon style={{ fontSize: sv.bodyIcon.fontSize}}/>
        </ToolerTip>
        }
      </span>
      )},
    { headerName: 'Last Round', field: 'latest_round_type__name',valueGetter:(params) => params.row.latest_round_type__parent__name || params.row.latest_round_type__name  },  
    { headerName: 'Valuation', field: 'valuation', valueFormatter: (params) => params.value && `${millionMoney(params.value)}` },   
    { headerName: 'Amount', field: 'latest_round_amount', valueFormatter: (params) => params.value && `${millionMoney(params.value)}` },   
    { headerName: 'Date', field: 'latest_round_date', valueFormatter: (params) => formatMMDDYYYY(params.value) },   
    { headerName: 'Headcount', field: 'current', type: 'number',  },
    { headerName: 'Growth Score', field: 'growth_score', type: 'number',  },
    { headerName: 'Overall', field: 'overall_score', type: 'number',  },
  ]

  function sendData(k,v,opts={}) {
    if (companyId){
      setWarning({text:"Saving...",severity:'info'})
      authenticatedFetch(`/companies/api/company/${companyId}/update`, {
        method: 'PATCH', 
        body: JSON.stringify({...{
          [k]: v
        },...opts})
      })
      setWarning({text:"Saved!",severity:'success'})
    }
  }
  
  function handleSnoozeChange(event){
    setSnoozeDate(event.target.value)
    let val = event.target.value + " 00:00:00"
    sendData('snooze_date',val)
    setStatus('Snoozed')
  }
  
  function changeCEO(pid){
    setWarning({text:"Updating...",severity:'info'})
    async function updateCEO() {
      let res = await authenticatedFetch(`/companies/api/company/${companyId}/set_ceo`, {
        method: 'POST', 
        body: JSON.stringify({
          'ceo': pid
        })
      })
      setWarning({text:"Saved!",severity:'success'})
      setData(res)
    }
    updateCEO()
  }
  
  function deleteReminder(id) {
    setWarning({text:"Saving...",severity:'info'})
    async function doDelete() {
      let res = await authenticatedFetch(`/companies/api/jarvis_remove_reminder`, {
        method: 'POST', 
        body: JSON.stringify({
          'reminder_id': id
        })
      })
      setWarning({text:"Saved!",severity:'success'})
      setReminders(res)
    }
    doDelete()
  }
 
 useEffect(() => {
   if (oid){sequenceStates()}
   // eslint-disable-next-line
 },[oid])
 
 function sequenceStates() {
   async function sequences() {
     setSequencesLoading(true)
     const res = await authenticatedFetch(`/companies/api/outreach/${oid}/sequences`, {method: 'GET'})
     setSequences(res.sequences)
     setTasks(res.tasks)
     setSequencesLoading(false)
   }
   sequences()
 }
 
 function refreshNews(){
   async function updateNews(){
     setWarning({text:'Updating...',severity:'info'})
     const res = await authenticatedFetch(`/companies/api/company/${companyId}/posts/update`, {method: 'GET'})
     setPosts(res)
     setPostUpdateDate(new Date())
     setWarning({text:'Updated! Note: serveral sources may continue to update over the next few hours',severity:'success'})
   }
   updateNews()
 }

 function handleSequenceAdd(e){
   setWarning({text:'',severity:''})
   setSequencesLoading(true)
   let seq = e.target.value
    async function toOutreach(person) {
        setWarning({text:'Adding...',severity:'info'})
        let resp = await authenticatedFetch(`/companies/api/person/outreach`, {
          method: 'POST',
          body: JSON.stringify({
            pid: person.id,
            seq: seq,
            cid: person.companyId
          })
        })
        setOid(null)
        setOid(resp.oid)
        if(resp.oid){
          setWarning({text:resp.added,severity:'success'})
          setCeoDetails({...ceoDetails,outreach_id:resp.ceo.outreach_id})
        } else {
          setWarning({text:resp.added,severity:'error'})
        }
    }
    toOutreach(ceoDetails)
 }
  
  const WorkHistoryList = ({ p }) => {
    const emps = groupByCompany((p.students||[]).concat((p.employees||[])))
    return (
    <Paper sx={{maxHeight:350,overflow:'scroll',marginTop:2}} elevation={1}>
      <List dense={true}>
        {emps.map((company) => (
          <Box key={company.id} sx={{backgroundColor:sv.colors.blue5,margin:1}}>
            <ListItem >
              <ListItemAvatar>
                <Avatar src={company.logo} sx={{width:30,height:30}}/>
              </ListItemAvatar>
              <ListItemText primary={company.companyName} />
            </ListItem>
            {company.roles.map((role, index) => (
              <ListItem key={index} style={{ paddingLeft: '4em',paddingTop:0,paddingBottom:0}}>
                <ListItemText
                  primary={role.title}
                  secondary={makeDateRange(role)}
                  primaryTypographyProps={{}}
                  secondaryTypographyProps={{}}
                />
              </ListItem>
            ))}
          </Box>
        ))}
      </List>
    </Paper>
    );
  };
  
  function PersonHighlights({ highlights, personName }) {
    const personHighlights = highlights
      .map(highlight => highlight.employees
        .filter(employee => employee.name === personName)
        .map(employee => ({
          highlightName: highlight.name,
          title: employee.title,
          companyName: employee.company,
        }))
      )
      .flat() // Flatten the array
      .reduce((unique, item) => { // Ensure uniqueness
        const isUnique = !unique.some(uniqueItem => uniqueItem.highlightName === item.highlightName);
        if (isUnique) {
          unique.push(item);
        }
        return unique;
      }, []);

    return (
      <Stack direction="row" spacing={1} flexWrap='wrap' sx={{gap:'5px',marginTop:1}}>
        {personHighlights.map((item, index) => (
          <Tooltip key={index} title={(item.title && item.companyName) && `${item.title} at ${item.companyName}`}>
            <Chip label={item.highlightName} variant="outlined" size='small'/>
          </Tooltip>
        ))}
      </Stack>
    );
  }
  
  function EmployeeSection(props) {
    const { p,ceo } = props
        
    const current_emp = (p.employees||[]).filter((e)=>e.company?.id===companyId)[0]
    return (
      <Paper sx={{margin:2,width:'250px',padding:2,border:ceo && '2px solid #000'}}>
        {ceo && <Box sx={{fontWeight:'bold'}}>CEO</Box>}
        <Stack spacing={0} direction="row" justifyContent='center' sx={{marginTop:0.5}}>
          <Typography variant="body" component='div' sx={{marginBottom:0,paddingBottom:0}}>{p.name}</Typography>
          <Typography variant="body" component='div'>
           {(p.employees || p.students) &&<ToolerTip 
             border='none'
             title={
              <WorkHistoryList p={p} />
             }
           >    
               <InfoOutlinedIcon style={{ fontSize: sv.bodyIcon.fontSize*1.5,marginLeft:2}}/>
             </ToolerTip>}
            </Typography>
            <ToolerTip sx={{marginTop:100}} color={sv.colors.blue5} title={p.connections?.length > 0 && <Box sx={{overflow:'auto',height:200,paddingRight:2}}>
                      <Typography variant="button" sx={{color:'black'}}>{p.name} Connections:</Typography> 
                     {p.connections.map((c,i) =>
                        <Box key={i} sx={{marginLeft:2}}>
                          <Typography variant="button" component='div' sx={{marginTop:1}}><UnstyledLink target="_blank" href={`/person/${c.id}`}>{c.name} ({c.kind})</UnstyledLink><LinkedInIcon style={{cursor:'pointer',fontSize: sv.bodyText.fontSize*1.3, color:"0F4EB5",marginLeft:6 }} onClick={()=>window.open(`${c.url}`)}/></Typography>
                        </Box>  
                     )}
                  </Box>
            }>
              <GroupsIcon sx={{fontSize: sv.bodyText.fontSize*1.5, color:p.connections?.length > 0 ? "black" : "gray",marginLeft:1 }}/>
            </ToolerTip>
            {p.url && p.url.includes('/in/') && <LinkedInIcon style={{cursor:'pointer',fontSize: sv.bodyText.fontSize*1.3, color:"0F4EB5",marginLeft:6 }} onClick={()=>window.open(`${p.url}`)}/>}
            {!ceo && <ArrowUpward onClick={()=>changeCEO(p.id)} style={{cursor:'pointer',fontSize: sv.bodyIcon.fontSize*1.2,marginLeft:2}}/>}
            {ceo && <ArrowDownward onClick={()=>changeCEO(null)} style={{cursor:'pointer',fontSize: sv.bodyIcon.fontSize*1.2,marginLeft:2}}/>}
        </Stack>
        <>
          <Typography variant="caption" component='div' sx={{marginTop:'-0.2em',textAlign:'center'}} color='primary'>
            {current_emp?.title}
          </Typography>
          {p.bio_score !== null && 
            <Typography variant="caption" component='div' sx={{marginTop:'-0.2em',textAlign:'center'}} color='primary'>
              Bio Score: {p.bio_score}
            </Typography>
          }
        </>
        <PersonHighlights highlights={highlights} personName={p.name} />

      </Paper>
    )
  }

  function InvestmentRow(props) {
    const { investment } = props
    const [open, setOpen] = React.useState(false)
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
              
                            
          <TableCell component="th" scope="row" sx={{fontSize:sv.bodyIcon.fontSize * 1.2,color:sv.colors.blue1}}>{investment.round ? investment.round.parent ? `${investment.round.parent} (${investment.round.name})` : investment.round.name : 'Unknown Round'}</TableCell>
          <TableCell align="center" sx={{fontSize:sv.bodyIcon.fontSize * 1.2,color:sv.colors.blue1}}>{formatMMDDYYYY(investment.date)}</TableCell>
          <TableCell align="center" sx={{fontSize:sv.bodyIcon.fontSize * 1.2,color:sv.colors.blue1}}>{investment.amount && millionMoney(investment.amount)}</TableCell>
          <TableCell align="center" sx={{fontSize:sv.bodyIcon.fontSize * 1.2,color:sv.colors.blue1}}>{investment.valuation && millionMoney(investment.valuation)}</TableCell>
          {!isMobile() && <TableCell align="center"><ExpandableBox text={formatInvestors(investment.cleanInvestors || cleanedCanonicalInvestments.filter((i)=>i.id===investment.canonical_investment)[0]?.cleanInvestors.filter((x)=>x.source.includes(fundingSources)))} maxLength={120}/></TableCell>}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="subtitle1" gutterBottom component="div" color='primary' sx={{backgroundColor:'white',marginBottom:0,paddingLeft:1,color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.2}}>
                  {investment.round ? investment.round.name : 'Unknown Round'} Investors
                </Typography>
                <TableContainer component={Box} sx={{maxHeight:220}}>
                  <Table size="small" stickyHeader sx={{backgroundColor:'white',width:'60%'}}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.1}} align="left">Investor</TableCell>
                        <TableCell sx={{color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.1}} align="center">Partner</TableCell>
                        <TableCell sx={{color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.1}} align="center">Lead</TableCell>
                        <TableCell sx={{color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.1}} align="center">New</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {investment.cleanInvestors.sort((a, b) => {
                            if (a.lead !== b.lead) return a.lead ? -1 : 1;
                            if (a.new !== b.new) return a.new ? -1 : 1;
                        
                            if (a.rank === null) return 1;
                            if (b.rank === null) return -1;
                            return a.rank - b.rank;
                        }).map((inv) => (
                        <TableRow key={inv.id}>
                          <TableCell sx={{fontWeight:inv.rank && 700}} align="left">{inv.name} {inv.rank && `(${inv.rank})`}</TableCell>
                          <TableCell sx={{fontWeight:inv.partnerRank && 700}} align="center">{inv.partnerName} {inv.partnerRank && `(${inv.partnerRank})`}</TableCell>
                          <TableCell align="center">{inv.lead && 'Y'}</TableCell>
                          <TableCell align="center">{inv.new && 'Y'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  
  return (
    <>
      <Header 
        history={history}
        name={''}
        doSearch={null}
        warning={warning}
        setWarning={setWarning}
        showHelp={showHelp}
        setShowHelp={setShowHelp}
        companyName={company.name}
        companyId={companyId}
        files={files}
        intros={introRows}
      />
    { data &&
      <>
        {isMobile() ? 
           <AppBar position="sticky" style={{paddingTop:10,color:'#06283C',backgroundColor:'white'}}>
                <Title component="span" sx={{fontSize: sv.bodyText.fontSize * 2,}}>{company.name || data.name}</Title>
                <Stack direction='row'>
                   <UnstyledLink><EditOutlinedIcon style={{fontSize: sv.bodyIcon.fontSize*1.6, cursor:'pointer' }} onClick={() => {setShowAddEditCompany(true)}}/></UnstyledLink>
                   <UnstyledLink onClick={()=>setOpenRefresh(true)}><RefreshIcon sx={{fontSize: sv.bodyIcon.fontSize*1.6}}/></UnstyledLink>
                   <UnstyledLink href={`//www.${company.website}`} target="_blank">
                     <Language style={{ fontSize: sv.bodyText.fontSize * 1.6, color:"0F4EB5" }}/>
                   </UnstyledLink>
                   <UnstyledLink href={`//${company.li_url || data.li_url}`.replace('https://','').replace('http://','')} target="_blank">
                     <LinkedInIcon style={{ fontSize: sv.bodyText.fontSize * 1.6, color:"0F4EB5",opacity:data.li_url ? 1 : 0.3 }}/>
                   </UnstyledLink>
                   <UnstyledLink target="_blank" href={`//my.pitchbook.com/profile/${data.pitchbook_id}/company/profile`}><img style={{marginLeft:4,width:20,opacity:data.pitchbook_id ? 1 : 0.3}} src={pitchbook} alt="Pitchbook"/></UnstyledLink>
                   <UnstyledLink target="_blank" href={data.cbi_profile_url}><img style={{marginLeft:4,width:20,opacity:data.cbi_profile_url ? 1 : 0.3}} src={cbi} alt="CBI"/></UnstyledLink>
                   <UnstyledLink target="_blank" href={`//console.harmonic.ai/dashboard/company/${data.harmonic_id}`}><img style={{marginLeft:4,width:20,opacity:data.harmonic_id ? 1 : 0.3}} src={harmonic} alt="Harmonic"/></UnstyledLink>
                 </Stack>
                 <Stack direction="row" spacing={3} justifyContent={'space-around'}>
                         <FormControl size="small">
                           <Text style={{fontSize: sv.bodyIcon.fontSize*0.9}}>Status</Text>
                           <Select
                             value={status}
                             onChange={handleStatusChange}
                           >
                             {Statuses.map((val,ind) =>
                               <MenuItem key={ind} value={val} sx={{fontSize: sv.bodyIcon.fontSize*1.1}}>{val}</MenuItem>
                             )}
                            </Select>
                         </FormControl>
                        <FormControl size="small">
                            <Text style={{fontSize: sv.bodyIcon.fontSize*0.9}}>Owner</Text>
                            <Select
                              value={owner}
                              onChange={handleOwnerChange}
                            >
                              <MenuItem key={0} value=""  sx={{fontSize: sv.bodyIcon.fontSize*1.1,fontStyle:'italic'}}>None</MenuItem>
                              {users?.map((val,ind) => 
                                <MenuItem key={ind} value={val} sx={{fontSize: sv.bodyIcon.fontSize*1.1}}>{val}</MenuItem>
                              )}
                            </Select>
                        </FormControl>
                         <FormControl size="small" sx={{marginRight:2}}>
                           <Text style={{fontSize: sv.bodyIcon.fontSize*0.9}}>Snooze</Text>
                           <TextField
                               type="date"
                               value={(snoozeDate || "").split("T")[0]}
                               onChange={(e)=>handleSnoozeChange(e)}
                               name='snooze_date'	
                               size="small"				  
                           />
                         </FormControl>
                 </Stack>
          </AppBar>
          :
           <AppBar position="sticky" style={{paddingTop:10,color:'#06283C',backgroundColor:'white'}}>
              <Stack direction="row" justifyContent='space-between'>
                 <Box>
                     <Stack direction="row" spacing={1} sx={{paddingLeft:2,paddingTop:2}}>
                         <img src={data.logo} style={{maxHeight:sv.bodyText.fontSize * 3}} alt='logo'/>
                         <Title component="span" sx={{fontSize: sv.bodyText.fontSize * 2,}}>{company.name || data.name}</Title>
                         <Guide guideShown={showHelp} content={'Edit company details.'}>
                          <UnstyledLink><EditOutlinedIcon style={{fontSize: sv.bodyIcon.fontSize*1.6, cursor:'pointer' }} onClick={() => {setShowAddEditCompany(true)}}/></UnstyledLink>
                         </Guide>
                         <Guide guideShown={showHelp} content={'Upload decks and additional documents'}>
                           <Badge badgeContent={files.filter(x=>!x.is_folder).length} color="primary">
                               <UnstyledLink><FolderOpenIcon style={{fontSize: sv.bodyIcon.fontSize*1.6, cursor:'pointer' }} onClick={() => {setShowFileUpload(true)}}/></UnstyledLink>     
                           </Badge>
                         </Guide>
                         <Guide guideShown={showHelp} content={'Data looking incorrect or not filled in? Click to pull new data from all sources. Note: LinkedIn, Pitchbook, Harmonic, and CB Insights ids should be updated if incorrect before refreshing.'}>
                           <UnstyledLink onClick={()=>setOpenRefresh(true)}><RefreshIcon sx={{fontSize: sv.bodyIcon.fontSize*1.6}}/></UnstyledLink>
                         </Guide>
                         <UnstyledLink href={`//www.${company.website}`} target="_blank">
                           <Language style={{ fontSize: sv.bodyText.fontSize * 1.6, color:"0F4EB5" }}/>
                         </UnstyledLink>
                         <UnstyledLink disabled={!data.li_url && !company.li_url} href={`//${company.li_url || data.li_url}`.replace('https://','').replace('http://','')} target="_blank">
                           <LinkedInIcon style={{ fontSize: sv.bodyText.fontSize * 1.6, color:"0F4EB5",opacity:data.li_url ? 1 : 0.3 }}/>
                         </UnstyledLink>
                         <UnstyledLink target="_blank" disabled={!data.pitchbook_id} href={`//my.pitchbook.com/profile/${data.pitchbook_id}/company/profile`}><img style={{marginLeft:4,width:20,opacity:data.pitchbook_id ? 1 : 0.3}} src={pitchbook} alt="Pitchbook"/></UnstyledLink>
                         <UnstyledLink target="_blank" disabled={!data.cbi_profile_url} href={data.cbi_profile_url}> <img style={{marginLeft:4,width:20,opacity:data.cbi_profile_url ? 1 : 0.3}} src={cbi} alt="CBI"/></UnstyledLink>
                         <UnstyledLink target="_blank" disabled={!data.harmonic_id} href={`//console.harmonic.ai/dashboard/company/${data.harmonic_id}`}><img style={{marginLeft:4,width:20,opacity:data.harmonic_id ? 1 : 0.3}} src={harmonic} alt="Harmonic"/></UnstyledLink>
                     </Stack>
                 </Box>
                 <Stack direction="row" spacing={3} justifyContent={'space-around'}>
                     <Guide guideShown={showHelp} content={
                         <Stack>
                           <Typography sx={{textAlign:'center',fontWeight:600,color:'gray',fontmarginBottom:1}}>Statuses</Typography>
                           <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>New:</Box>Have not attempted to contact</Typography>
                           <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>Outreach:</Box>Attempting to make initial contact</Typography>
                           <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>Discussions:</Box>Engaged with company</Typography>
                           <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>Invested:</Box>Completed investment</Typography>
                           <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>Snoozed:</Box>Interesting but timing not right, re-surface at defined date for follow-up</Typography>
                           <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>Recycled:</Box>Conversation didn’t go anywhere or never got in touch</Typography>
                           <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>Trash:</Box>Not a relevant company (bad URL, not venture backed, acquired, etc)</Typography>
                         </Stack>
                     }>
                         <FormControl size="small">
                           <Text style={{fontSize: sv.bodyIcon.fontSize*0.9}}>Status</Text>
                           <Select
                             value={status}
                             onChange={handleStatusChange}
                           >
                             {Statuses.map((val,ind) =>
                               <MenuItem key={ind} value={val} sx={{fontSize: sv.bodyIcon.fontSize*1.1}}>{val}</MenuItem>
                             )}
                            </Select>
                         </FormControl>
                     </Guide>
                     <Guide guideShown={showHelp} content={'Set company owner.'}>
                        <FormControl size="small">
                            <Text style={{fontSize: sv.bodyIcon.fontSize*0.9}}>Owner</Text>
                            <Select
                              value={owner}
                              onChange={handleOwnerChange}
                              sx={{minWidth:120}}
                            >
                              <MenuItem key={0} value=""  sx={{fontSize: sv.bodyIcon.fontSize*1.1,fontStyle:'italic'}}>None</MenuItem>
                              {users?.map((val,ind) => 
                                <MenuItem key={ind} value={val} sx={{fontSize: sv.bodyIcon.fontSize*1.1}}>{val}</MenuItem>
                              )}
                            </Select>
                        </FormControl>
                     </Guide>
                     <Guide guideShown={showHelp} content={'Right company, wrong time? Pick a date in the future and receive a slack reminder to get back in touch. You can also snooze from the gmail extension or bcc / forward emails with a founder to jason+snooze@mvp-vc.com. Include a date like "early October" or a specific date ("January 1st", "1/1/2023"), and the relevant company will be snoozed until then.'}>
                         <FormControl size="small" sx={{marginRight:2}}>
                           <Text style={{fontSize: sv.bodyIcon.fontSize*0.9}}>Snooze</Text>
                           <TextField
                               type="date"
                               value={(snoozeDate || "").split("T")[0]}
                               onChange={(e)=>handleSnoozeChange(e)}
                               name='snooze_date'	
                               size="small"				  
                           />
                         </FormControl>
                     </Guide>
                 </Stack>
              </Stack>
          </AppBar>
          }
        <Grid2 container columns={12} sx={{backgroundColor:'white',padding:2}} spacing={2} alignItems='flex-start'>
            <Grid2 xs={isMobile() ? 12 : 8} sx={{height:`100vh`,overflow:'scroll'}}>
                <Tabs value={tab} variant="fullWidth" onChange={(e,nv)=>setTab(nv)}>
                    <Tab label="Overview"/>
                    <Tab label="Diligence"/>
                    <Tab label="Outreach" />
                    <Tab label="Value-Add" />
                </Tabs>
                
                {/* Overview Tab */}
                {tab === 0 &&
                <> 
                  <ExpandableBox text={(data.clean_description || data.harmonic_desc || data.description || data.li_longdesc || data.pb_desc)} maxLength={425} styles={{fontFamily:'system-ui',lineHeight:1.5,marginTop:2}}/>
                  {isMobile() ?
                    <>
                        {[
                          ['Founded', data.founded],
                          ['Headcount', data.current],
                          ['Latest Round', cleanRound(funding.latest_round_type, funding.latest_round_amount, funding.latest_round_date)],
                          ['Valuation', formattedValuation],
                          ['Latest Investors', ReactHtmlParser(formattedLatestInvestors)],
                          ['Growth Score', data.growth_score],
                          ['Retention Score', data.retention_score],
                          ['Investor Score', data.investor_score],
                          ['Team Score', data.employee_score],
                          ['Market Score', data.social_score]
                        ].map((vals, i) => (
                          <React.Fragment key={i}>
                            <Stack sx={{ marginBottom: 2 }}>
                              <Typography sx={{ fontWeight: 600, color: 'gray', marginBottom: 1 }} color='primary'>
                                {vals[0]}
                              </Typography>
                              <Typography sx={{ fontWeight: 700 }} color='primary'>
                                {vals[1]}
                              </Typography>
                            </Stack>
                            <Divider orientation={"horizontal"} flexItem sx={{ borderWidth: 1, borderColor: 'yourColorVariableHere' }}/>
                          </React.Fragment>
                        ))}
                      </>
                  :
                  <>
                    <Stack direction="row" justifyContent="space-evenly" sx={{marginTop:3}}>
                      <Stack sx={{maxWidth:'20%'}}>
                          <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>Founded</Typography>
                          <Typography sx={{fontWeight:700}} color='primary'>
                            {data.founded}
                          </Typography>
                      </Stack>
                      <Divider orientation="vertical" flexItem sx={{borderRightWidth:1,borderColor:sv.colors.blue1}}/>
                      <Stack sx={{maxWidth:'20%'}}>
                          <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>Portfolio Fit</Typography>
                            <Tooltip title={data.portfolio_fit_reason}><Typography sx={{fontWeight:700}} color='primary'>{data.portfolio_fit || 'N/A'}&nbsp;</Typography></Tooltip>
                      </Stack>
                      <Divider orientation="vertical" flexItem sx={{borderRightWidth:1,borderColor:sv.colors.blue1}}/>
                      <Stack sx={{maxWidth:'20%'}}>
                          <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>Headcount</Typography>
                          <Typography sx={{fontWeight:700}} color='primary'>
                            {data.current || 'N/A'}
                            {formattedHeadcountChange && <Chip component='span' sx={{marginLeft:1}} label={formattedHeadcountChange} variant="outlined" size='small'/>}
                          </Typography>
                      </Stack>
                      <Divider orientation="vertical" flexItem sx={{borderRightWidth:1,borderColor:sv.colors.blue1}}/>
                      <Stack sx={{maxWidth:'20%'}}>
                          <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>Total Raised</Typography>
                            <Typography sx={{fontWeight:700}} color='primary'>{millionMoney(funding?.canonical_investments?.map(x=>x.amount).reduce(function(a, b) { return a + b; }, 0))}&nbsp;
                            {(funding && funding.canonical_investments) && <Chip component='span' sx={{marginLeft:1}} label={`${funding.canonical_investments.length} rounds`} variant="outlined" size='small'/>}
                          </Typography>
                      </Stack>
                      <Divider orientation="vertical" flexItem sx={{borderRightWidth:1,borderColor:sv.colors.blue1}}/>
                      <Stack sx={{maxWidth:'20%'}}>
                          <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>Latest Round</Typography>
                          <Typography sx={{fontWeight:700}} color='primary'>
                            {cleanRound(funding.latest_round_type,funding.latest_round_amount,funding.latest_round_date)}
                          </Typography>
                      </Stack>
                      <Divider orientation="vertical" flexItem sx={{borderRightWidth:1,borderColor:sv.colors.blue1}}/>
                      <Stack sx={{maxWidth:'20%'}}>
                          <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>Valuation</Typography>
                            <Typography sx={{fontWeight:700}} color='primary'>{formattedValuation}&nbsp;
                            {formattedStepUp &&<Chip component='span' sx={{marginLeft:1}} label={formattedStepUp} variant="outlined" size='small'/>}
                          </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction='row' sx={{marginTop:5}} justifyContent='space-evenly'>
                        <Box>
                            <ScoreChart data={data}/>
                             {// <Tooltip title={portfolioFit.reason}>
                             //     <Stack direction='row' alignItems='center' sx={{marginTop:1}}>
                             //       <Typography sx={{fontWeight:600,color:'gray',marginRight:1,fontSize:sv.bodyIcon.fontSize}} color='primary'>Focus</Typography>
                             //       <SentimentVeryDissatisfiedIcon sx={{fontSize:sv.bodyIcon.fontSize*2}} color={portfolioFit.score === 1 ? "error" : "disabled"}/>
                             //       <SentimentDissatisfiedIcon sx={{fontSize:sv.bodyIcon.fontSize*2}} color={portfolioFit.score === 2 ? "error" : "disabled"}/>
                             //       <SentimentSatisfiedIcon sx={{fontSize:sv.bodyIcon.fontSize*2}} color={portfolioFit.score === 3 ? "warning" : "disabled"}/>
                             //       <SentimentSatisfiedAltIcon sx={{fontSize:sv.bodyIcon.fontSize*2}} color={portfolioFit.score === 4 ? "success" : "disabled"}/>
                             //       <SentimentVerySatisfiedIcon sx={{fontSize:sv.bodyIcon.fontSize*2}} color={portfolioFit.score === 5 ? "success" : "disabled"}/>
                             //     </Stack>
                             // </Tooltip>
                             // <Tooltip title={portfolioCompetitive.reason}>
                             //     <Stack direction='row' alignItems='center' sx={{marginTop:1}}>
                             //       <Typography sx={{fontWeight:600,color:'gray',marginRight:1,fontSize:sv.bodyIcon.fontSize}} color='primary'>Competitiveness</Typography>
                             //       <SentimentVeryDissatisfiedIcon sx={{fontSize:sv.bodyIcon.fontSize*2}} color={portfolioCompetitive.score === 1 ? "error" : "disabled"}/>
                             //       <SentimentDissatisfiedIcon sx={{fontSize:sv.bodyIcon.fontSize*2}} color={portfolioCompetitive.score === 2 ? "error" : "disabled"}/>
                             //       <SentimentSatisfiedIcon sx={{fontSize:sv.bodyIcon.fontSize*2}} color={portfolioCompetitive.score === 3 ? "warning" : "disabled"}/>
                             //       <SentimentSatisfiedAltIcon sx={{fontSize:sv.bodyIcon.fontSize*2}} color={portfolioCompetitive.score === 4 ? "success" : "disabled"}/>
                             //       <SentimentVerySatisfiedIcon sx={{fontSize:sv.bodyIcon.fontSize*2}} color={portfolioCompetitive.score === 5 ? "success" : "disabled"}/>
                             //     </Stack>
                             // </Tooltip>
                             }
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Stack sx={{width:'40%'}}>
                          <Stack>
                            <Typography sx={{fontWeight:700,color:'gray'}} color='primary'>Latest Investors</Typography>
                            <ExpandableBox text={formattedLatestInvestors} maxLength={300} styles={{fontFamily:'system-ui',lineHeight:1.5,marginTop:1}}/>
                          </Stack>
                          <Divider flexItem sx={{borderBottomWidth:1,borderColor:sv.colors.blue1,marginTop:2,marginBottom:2}}/>
                          <Stack>
                           <Typography sx={{fontWeight:600,color:'gray'}} color='primary'>Keywords</Typography>
                           <Stack direction='row' justifyContent="space-evenly" flexWrap='wrap' sx={{gap:'5px',marginTop:2,marginBottom:2}}>
                             {indTech.map((ind,i) => (
                               <Chip key={i} label={ind} variant='outlined' sx={{padding:2,fontSize:sv.bodyIcon.fontSize*1.2,fontFamily:'system-ui',color:sv.colors.blue1,borderColor:sv.colors.blue1,}}/>
                             ))}
                           </Stack>
                           <Divider flexItem sx={{borderBottomWidth:1,borderColor:sv.colors.blue1,marginTop:2,marginBottom:2}}/>
                           <Typography sx={{fontWeight:600,color:'gray'}} color='primary'>Team Highlights</Typography>
                           <Stack direction='row' justifyContent="space-evenly" flexWrap='wrap' sx={{gap:'5px',marginTop:2,marginBottom:2}}>
                           	{displayedHighlightCategories.map((c,ind) =>(
                           		<ToolerTip
                           			key={ind}
                                color={sv.colors.blue5}
                           			title=
                           				<Box key={ind} sx={{overflow:'auto',height:200,paddingRight:2}}>
                           					<Typography variant="body2" color='primary'>{c}:</Typography>
                           					<List>
                           						{highlights.filter((x)=>x.name===c)[0].employees.map((v,i) => {
                           						  return (
                           						    ['Top School','Previous Founder','Previous Multi-Time Founder'].includes(c) ? 
                                            <ListItem key={i} sx={{color:'black',display:'list-item'}}>{v.name} ({v.currentTitle})</ListItem>
                                          :
                                            <ListItem key={i} sx={{color:'black',display:'list-item'}}>{v.name} ({v.currentTitle}) - {v.title} at {v.company}</ListItem>
                           						  )
                           						}
                                 
                                      )}
                           					</List>
                           				</Box>
                           		><Chip label={c} variant='outlined' sx={{padding:2,fontSize:sv.bodyIcon.fontSize*1.2,fontFamily:'system-ui',color:sv.colors.blue1,borderColor:sv.colors.blue1,}}/>
                           	    </ToolerTip>
                           	))}
                           </Stack>
                          </Stack>
                        </Stack>
                    </Stack>
                  </>}
                </>}
                
                {/* Diligence Tab */}
                {tab === 1 &&
                  <>
                     <Paper elevation={3} sx={{padding:2}}>
                        <Typography sx={{fontWeight:600,color:'gray',fontSize:sv.bodyIcon.fontSize*1.5}} color='primary'>Traction</Typography>
                        <Box sx={{height:500}}>
                            <Chart isMobile={isMobile()} showHelp={showHelp} graphData={graphData} setShowMilestone={setShowMilestone} companyId={companyId} refreshGraphData={refreshGraphData} setRefreshGraphData={setRefreshGraphData}/>
                        </Box>
                      </Paper>
                      <Paper elevation={3} sx={{padding:2,marginTop:2}}>
                          <Typography sx={{fontWeight:600,color:'gray',fontSize:sv.bodyIcon.fontSize*1.5}} color='primary'>Funding History</Typography>
                            <Guide guideShown={showHelp} content={'By default we combine sources to generate a clean list of funding rounds, but you can also use the buttons on the right to choose to view data only from Pitchbook or CB Insights.'}>
                              <Stack direction="row" justifyContent="flex-end">
                                <Tooltip title="All Sources"><IconButton sx={{border:1,width:50,height:50,backgroundColor:fundingSources === 'All Sources' && '#e0e0e0',borderRadius:0}} onClick={()=>setFundingSources('All Sources')}><AllInclusiveIcon /></IconButton></Tooltip>
                                <Tooltip title="Pitchbook Only"><IconButton sx={{border:1,width:50,height:50,backgroundColor:fundingSources === 'Pitchbook' && '#e0e0e0',borderRadius:0}} onClick={()=>setFundingSources('Pitchbook')}><img style={{height:25,width:25,filter:'brightness(0) invert(0.3)'}} src={pitchbook} alt="Pitchbook"/></IconButton></Tooltip>
                                <Tooltip title="CB Insights Only"><IconButton sx={{border:1,width:50,height:50,backgroundColor:fundingSources === 'CBI' && '#e0e0e0',borderRadius:0}} onClick={()=>setFundingSources('CBI')}><img style={{height:25,width:25,filter:'brightness(0) invert(0.3)'}} src={cbi} alt="CBI" /></IconButton></Tooltip>
                                <Tooltip title="Harmonic Only"><IconButton sx={{border:1,width:50,height:50,marginRight:4,backgroundColor:fundingSources === 'Harmonic' && '#e0e0e0',borderRadius:0}} onClick={()=>setFundingSources('Harmonic')}><img style={{height:25,width:25,filter:'brightness(0) invert(0.3)'}} src={harmonic} alt="Harmonic" /></IconButton></Tooltip>
                              </Stack>
                          </Guide>
                          <TableContainer component={Box} sx={{maxHeight:450}}>
                                <Table size="small" stickyHeader >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell sx={{width:5}}/> 
                                      <TableCell sx={{width:200,color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.2}}>Round</TableCell>
                                      <TableCell sx={{width:20,color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.2}} align="center">Date</TableCell>
                                      <TableCell sx={{width:150,color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.2}} align="center">Amount</TableCell>
                                      <TableCell sx={{width:150,color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.2}} align="center">Post-Money Valuation</TableCell>                                                             
                                      {!isMobile() && <TableCell sx={{color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.2}} align="center">
                                          <Guide guideShown={showHelp} content={'Lead and new investors in round. Expand round to show full list of investors and partners. (L) indicates lead. (<number>) indicates firm ranking in our list of preferred firms.'}>
                                            Investors
                                          </Guide>
                                      </TableCell>}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {fundingSources === 'All Sources' ? 
                                    cleanedCanonicalInvestments.map((investment,ind) => (
                                      <InvestmentRow key={investment.id} investment={investment} ind={ind}/>
                                    ))
                                    :
                                    cleanedInvestments.filter((i)=>(i.source===fundingSources)).map((investment,ind) => (
                                      <InvestmentRow key={investment.id} investment={investment} ind={ind}/>
                                    ))
                                    }
                                  </TableBody>
                                </Table>
                          </TableContainer>
                      </Paper>
                      <Paper elevation={3} sx={{padding:2,marginTop:2}}>
                            <Typography sx={{fontWeight:600,color:'gray',fontSize:sv.bodyIcon.fontSize*1.5}} color='primary'>Team Detail</Typography>
                            <Stack direction='row' spacing={3} sx={{marginTop:2}}>
                                <FormControl fullWidth variant='standard' sx={{maxWidth:'150px'}} size='small'>
                                      <InputLabel>Seniority</InputLabel>
                                      <Select
                                        value={employeeFilter}
                                        onChange={(e)=>setEmployeeFilter(e.target.value)}
                                      >
                                        {['All','Founder','CXO','Director','Senior'].map((s,sIndex) =>
                                          <MenuItem key={sIndex} value={s}>{s}</MenuItem>
                                        )}
                                      </Select>
                                    </FormControl>
                                    <FormControlLabel control={<Checkbox checked={newEmployeeFilter} onChange={()=>setNewEmployeeFilter(!newEmployeeFilter)}/>} label="New Hires Only" />
                                    <FormControlLabel control={<Checkbox checked={connectionsOnly} onChange={()=>setConnectionsOnly(!connectionsOnly)}/>} label="Connections Only" />
                            </Stack>
                            {(people && data) && (
                              <Box sx={{ maxHeight: 300, overflowY: 'auto', marginTop: 2 }}>
                                {data.ceo && (() => {
                                  const ceo = people.filter(p => p.id === data.ceo.id)[0];
                                  return ceo && (!connectionsOnly || ceo.connections.length > 0) && (!newEmployeeFilter || ceo.employees.some(e => e.company?.id === companyId && (new Date() - new Date(e.startdate)) / 86400000 < 90)) && (['All', 'Founder'].includes(employeeFilter)) && (
                                    <EmployeeSection p={ceo} ceo={true} />
                                  );
                                })()}
                                <Stack direction='row' sx={{ maxWidth: '100%', flexWrap: 'wrap' }}>
                                  {people
                                    .filter(p => p.id !== data.ceo?.id && (!connectionsOnly || p.connections.length > 0) && (employeeFilter === 'All' || p.employees.filter(e => e.company?.id === companyId).some(x => x.seniority === employeeFilter)) && (!newEmployeeFilter || p.employees.some(e => e.company?.id === companyId && (new Date() - new Date(e.startdate)) / 86400000 < 90)))
                                    .map((p, ind) => (
                                      <EmployeeSection p={p} key={ind} />
                                    ))}
                                </Stack>
                              </Box>
                            )}
                        </Paper>
                        <Paper elevation={3} sx={{padding:2,marginTop:2}}>
                            <Typography sx={{fontWeight:600,color:'gray',fontSize:sv.bodyIcon.fontSize*1.5}} color='primary'>Similar Companies</Typography>
                            
                            <Box sx={{borderRadius:3,marginTop:2,paddingTop:1,paddingBottom:1,backgroundColor:'white',width:'100%'}}>
                                <TableContainer component={Box} sx={{height:250}}>
                                  <DataGridPro
                                    hideFooter
                                    columns={competitorColumns.map((col) => ({...col,disableClickEventBubbling: true}))}
                                    rows={competitors}
                                    density="compact"
                                  />
                                </TableContainer>
                            </Box>
                        </Paper>
                    </>
                }
                
                {/* Outreach Tab */}
                {tab === 2 &&
                  <Grid2 container sx={{marginTop:2,height:'600px',overflowY:'scroll'}}>  
                    <>
                        <Grid2 xs={isMobile() ? 12 : 6}>
                          <Stack direction='row' justifyContent='space-between' sx={{marginBottom:3}}>
                              <Box>
                                <Stack direction='row'>
                                  {(Object.keys(ceoDetails).length > 0 && ceoDetails.name) &&
                                    <>
                                      <Typography sx={{fontWeight:700}} color='primary'>{ceoDetails.name}</Typography>
                                      <LinkedInIcon style={{ cursor:'pointer',opacity:ceoDetails?.url.includes('/in/') ? 1 : 0.3,fontSize: sv.bodyText.fontSize*1.3, color:"0F4EB5",marginLeft:6 }} onClick={()=>window.open(`${ceoDetails.url}`)}/>
                                      <UnstyledLink target="_blank" style={{marginLeft:3,opacity:ceoDetails.outreach_id ? 1 : 0.3}} href={`//app1a.outreach.io/prospects/${ceoDetails.outreach_id}/sequences`}><img style={{width:15}} src={outreach} alt="Outreach"/></UnstyledLink>
                                    </>
                                  }
                                  <Box component='span' onClick={handleCEOOpen} sx= {{fontSize: sv.bodyIcon.fontSize,cursor:'pointer'}}>{!ceoDetails.name &&<em>Click to add CEO info</em>}<EditOutlinedIcon sx={{fontSize:sv.bodyIcon.fontSize*0.9,marginLeft:1}}/></Box>
                                </Stack>
                                {ceoDetails.email && <Typography sx={{fontSize: sv.bodyIcon.fontSize*0.9}} color='primary'>{ceoDetails.email}</Typography>}
                              </Box>
                              <Box>
                                <FormControl variant="standard" style={{width:250}} size="small" disabled={!ceoDetails.email || !ceoDetails.firstname || !ceoDetails.lastname}>
                                  <InputLabel style={{fontSize: sv.bodyIcon.fontSize*0.8}}>{ceoDetails.email ? 'Add to Sequence' : 'CEO email required'}</InputLabel>
                                    <Select
                                      value={''}
                                      onChange={handleSequenceAdd}
                                    >
                                      {sequenceList.filter((s)=>!sequences.map((x)=>x.sequence).includes(s.name))?.map((s,sIndex) =>
                                        <MenuItem key={sIndex} value={`${s.oid}`}>{s.name} ({s.user})</MenuItem>
                                      )}
                                      </Select>
                                </FormControl>
                              </Box>
                          </Stack>
                          <Paper elevation={1} sx={{padding:1}}>
                            <Typography sx={{color:'gray',fontWeight:600,marginBottom:1,fontSize:sv.bodyIcon.fontSize*1.1,textAlign:'left'}}>Sequence History</Typography>   
                            {sequencesLoading ? 
                            <CircularProgress sx={{marginTop:2}} color="inherit" size={20} />
                            :
                            <>
                              {sequences.map((s,ind)=>
                                <Box key={ind}>
                                  {ind > 0 &&<Divider flexItem sx={{borderBottomWidth:1,marginTop:2,marginBottom:2,borderColor:sv.colors.blue1}}/>}
                                  <Stack spacing={0} sx={{textAlign:'left',flexWrap:'wrap'}} key={ind}>
                                    <Typography component='div' color='primary' sx={{fontSize:sv.bodyIcon.fontSize*0.9}}>{ceoDetails.outreach_id && <UnstyledLink target="_blank" style={{marginLeft:3}} href={`//app1a.outreach.io/prospects/${ceoDetails.outreach_id}/sequences/${s.id}`}><img style={{width:15}} src={outreach} alt="Outreach"/></UnstyledLink>} {s.sequence} ({formatDayMonthYear(s.date)})</Typography> 
                                    <Typography component='div' color={tasks?.filter(x=>x.scheduled).map(t=>t.step_id).includes(s.id) ? 'orange' : tasks?.map(t=>t.step_id).includes(s.id) && 'red'} sx={{cursor:tasks?.map(t=>t.step_id).includes(s.id) && 'pointer',fontSize:sv.bodyIcon.fontSize*0.9}} onClick={()=>tasks?.map(t=>t.step_id).includes(s.id) && setActiveTask(tasks?.filter(t=>t.step_id===s.id)[0])}>
                                      Status: {tasks?.filter(x=>x.scheduled).map(t=>t.step_id).includes(s.id) ? `Scheduled ${formatMMDDYYYY(tasks.filter(x=>x.scheduled && x.step_id === s.id)[0].scheduled)}` : s.status}; {' '}
                                      {s.step ? `step: ${s.step}` : `Replied: ${s.replied}`} 
                                      {tasks?.map(t=>t.step_id).includes(s.id) && <EditOutlinedIcon sx={{fontSize:sv.bodyIcon.fontSize*1,marginLeft:1}}/>}
                                    </Typography>
                                    {s.reason && <Typography component='div' color='primary' sx={{fontSize:sv.bodyIcon.fontSize*0.9}}>Error: {s.reason}</Typography>}
                                  </Stack>
                                </Box>
                              )}
                            </>}
                          </Paper>
                          <WorkHistoryList p={ceoDetails} />
                          <Paper elevation={1} sx={{padding:1,marginTop:2}}>
                              <Typography sx={{color:'gray',fontWeight:600,marginTop:4,marginBottom:1,fontSize:sv.bodyIcon.fontSize*1.1,textAlign:'left'}}>Snoozed Emails</Typography>   
                              <List dense={true}>
                                {reminders.map((r, ind) => (
                                  <ListItem
                                    key={ind}
                                    secondaryAction={
                                      <IconButton edge="end" onClick={() => deleteReminder(r.id)} >
                                        <DeleteIcon />
                                      </IconButton>
                                    }
                                  >
                                    <ListItemButton onClick={() => window.open(`//mail.google.com/mail/u/0/#inbox/${r.email_thread}`)}>
                                      <ListItemIcon>
                                        <EmailOutlinedIcon />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={formatMMDDYYYY(r.date)}
                                        secondary={r.user_email && r.user_email}
                                        sx={{ marginLeft: 2 }}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                ))}
                              </List>
                          </Paper>
                        </Grid2>
                        
                        <Grid2 xs={isMobile() ? 12 : 6}>
                            <Paper elevation={1} sx={{padding:1,marginTop:1}}>
                                <Stack direction="row" justifyContent='space-between'>
                                  <Box>
                                    <Typography sx={{color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.1,textAlign:'left'}}>Recent News</Typography>   
                                    <Typography sx={{color:'gray',fontWeight:400,fontSize:sv.bodyIcon.fontSize*1,textAlign:'left'}}>Last full pull: {formatDayMonthYear(postUpdateDate || data.last_owler)||'Never'}</Typography>   
                                  </Box>
                                    <Button size="small" onClick={()=>refreshNews()} variant='outlined'>Check Now</Button>
                                </Stack>
                                {posts.length > 0 ?
                                <Box sx={{maxHeight:350,overflow:'scroll'}}>
                                    <List dense={true}>
                                       {posts.map((p,i)=>
                                         <Grid2 container key={i}>
                                           <Grid2 xs={3}>
                                             <ListItemText
                                               primary={p.category || 'Unknown Source'}
                                               secondary={formatDayMonthYear(p.date)}
                                             />                
                                           </Grid2>
                                           <Grid2 xs={9}>
                                               <Link sx={{textDecoration:'underline'}} underline="always" target="_blank" href={'//'+p.link}>{p.link}</Link>
                                               <Text sx={{marginTop:1}}><ExpandableBox text={p.text} maxLength={250}/></Text>
                                           </Grid2>
                                         </Grid2>
                                       )}
                                    </List>
                                </Box>
                                :
                                <Text style={{texstAlign:'center',marginTop:50,marginBottom:50,fontSize: sv.bodyIcon.fontSize*1.1}}>No recent news stories found</Text>
                                }
                            </Paper>
                            <Paper elevation={1} sx={{padding:1,marginTop:1}}>
                                <Typography sx={{color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.1,textAlign:'left'}}>Top Related Portcos</Typography>    
                                <List dense={true} sx={{maxHeight:350,overflow:'scroll'}}>
                                  {relatedCos.map((rc,ind)=>
                                    <ListItem key={ind}>
                                        <Avatar src={rc.logo} sx={{width:30,height:30}}/>
                                        <ListItemText
                                          primary={rc.name}
                                          secondary={`${rc.score.toFixed(2)}: ${rc.tag_names.join(', ')}`}
                                          sx={{marginLeft:2}}
                                        />
                                    </ListItem>
                                  )}
                                </List>
                            </Paper>
                            <Paper elevation={1} sx={{padding:1,marginTop:1}}>
                                <Typography sx={{color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.1,textAlign:'left'}}>Overlapping Investors</Typography>   
                                <List dense={true} sx={{maxHeight:350,overflow:'scroll'}}>
                                  {overlapInvestors.map((obj,i)=> {
                                    let key = Object.keys(obj)[0]
                                    let values = obj[key]
                                    return (
                                      <ListItem key={i}>
                                          <ListItemText
                                            primary={key}
                                            secondary={values.join(', ')}
                                            sx={{marginLeft:2}}
                                          />
                                      </ListItem>
                                    )}
                                  )}
                                </List>
                            </Paper>                            
                        </Grid2>
                    </>
                  </Grid2>
                 }
                 
                 {/* Value-Add Tab */}
                 {tab === 3 &&
                   <>
                     <Grid2 container>  
                       <Grid2 xs={isMobile() ? 12 : 6}>           
                         <Paper elevation={1} sx={{padding:1,marginTop:1}}>
                              <Typography sx={{color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.1,textAlign:'left'}}>Relevant Firms</Typography>    
                              <Stack direction='row' justifyContent='space-between'>
                                <Select
                                  value={relatedInvestorType}
                                  onChange={(e)=>setRelatedInvestorType(e.target.value)}
                                  size="small"
                                  sx={{width:120}}
                                  variant="standard"
                                >
                                {['All','Preferred','Strategic','Connected'].map((s,sIndex) =>
                                  <MenuItem key={sIndex} value={s}>{s}</MenuItem>
                                )}
                                </Select>
                                <Select
                                  value={investorStage}
                                  onChange={(e)=>setInvestorStage(e.target.value)}
                                  size="small"
                                  sx={{width:120}}
                                  variant="standard"
                                >
                                {[['All','All'],['Seed VC','seed_vc'],['Series A','series_a'],['Series B','series_b'],['Series C','series_c'],['Series D','series_d'],['Series Other','series_other']].map((s,sIndex) =>
                                  <MenuItem key={sIndex} value={s[1]}>{s[0]}</MenuItem>
                                )}
                                </Select>
                                <FormControl size="small" sx={{marginRight:2}}>
                                  <Text style={{fontSize: sv.bodyIcon.fontSize*0.9}}>Stage % ></Text>
                                  <TextField
                                      variant='standard'
                                      value={investorStagePercent}
                                      onChange={(e)=>setInvestorStagePercent(e.target.value)}
                                      size="small"				  
                                  />
                                </FormControl>
                            </Stack>
                            {relatedInvestors.length > 0 ?
                              <List dense={true} sx={{maxHeight:300,overflow:'scroll'}}>
                                {relatedInvestors.filter(i=>relatedInvestorType === 'All' || i.tags.includes(relatedInvestorType)).filter(i=>investorStage==='All'||i[investorStage]/i.total_funding*100 >= investorStagePercent).slice(0,100).map((rc,ind)=>
                                  <ListItem key={ind}>
                                      <ListItemText
                                        primary={rc.rank ? `${rc.name} – #${rc.rank} ${rc.tags.join(', ')}` : `${rc.name} – ${rc.tags.join(', ')}`}
                                        secondary={`${rc.score.toFixed(2)}: ${rc.tag_names.join(', ')}`}
                                        sx={{marginLeft:2}}
                                      />
                                  </ListItem>
                                )}
                              </List>
                              :
                              <CircularProgress sx={{marginTop:2}} color="inherit" size={20} />
                            }
                         </Paper>
                       </Grid2>
                       <Grid2 xs={isMobile() ? 12 : 6}>
                         <Paper elevation={1} sx={{padding:1,marginTop:1}}>
                            <Stack direction='row' justifyContent="space-between" alignItems="center">
                              <Typography sx={{color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.1,textAlign:'left'}}>Relevant People</Typography> 
                              <FormControlLabel control={<Checkbox checked={relevantOnlyLps} onChange={()=>setRelevantOnlyLps(!relevantOnlyLps)} />} label=<Typography sx={{ fontSize: 14 }}>LPs Only</Typography> />   
                            </Stack>
                            {relatedPeople.length > 0 ?
                              <List dense={true} sx={{maxHeight:326,overflow:'scroll'}}>
                                {relatedPeople.slice(0,100).map((rc,ind)=>
                                  <ListItem key={ind} secondaryAction=
                                      {rc.email &&
                                      (<IconButton edge="end" onClick={() => window.open(`mailto:${rc.email}`)}>
                                        <EmailOutlinedIcon />
                                      </IconButton>)}
                                  >
                                      <ListItemButton onClick={()=>rc.url && window.open(`${rc.url}`)}>
                                        <ListItemIcon>
                                          <LinkedInIcon sx={{color:"0F4EB5"}}/>
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={`${rc.name} – ${rc.kind}`}
                                          secondary={`${rc.score.toFixed(2)}: ${(rc.tag_names || []).join(', ')}`}
                                          sx={{marginLeft:2}}
                                        />
                                      </ListItemButton>
                                  </ListItem>
                                )}
                              </List>
                              :
                              <CircularProgress sx={{marginTop:2}} color="inherit" size={20} />
                            }
                         </Paper>
                       </Grid2>
                     </Grid2>
                     <Box sx={{height:300}}>
                       <IntroTable rows={introRows} setWarning={setWarning} setRows={setIntroRows} person={{}} company={data}/>
                     </Box>
                     
                   </>
                 }
            </Grid2>
            <Grid2 xs={isMobile() ? 12 : 4} sx={{backgroundColor:sv.colors.blue1}}>
              {!activeTask.body ?
                <InnerComments style={{borderRadius:3,paddingTop:2,backgroundColor:'white'}} companyId={companyId} setUploadedFile={setUploadedFile} setWarning={setWarning} comments={comments} setComments={setComments}/>
                :
                <JarvisOutreach companyId={companyId} activeTask={activeTask} setActiveTask={setActiveTask} email={ceoDetails.email} warning={warning} setWarning={setWarning} setSequencesLoading={setSequencesLoading} setSequences={setSequences} oid={oid} setTasks={setTasks} height={`${(height||800)-50}px`}/>
              }
              
            </Grid2>
        </Grid2>  
        
        <NoteBox open={noteOpen} close={handleNoteClose} companyId={companyId} companyName={company.name} assignment={assignment}/>                 
        <EditSnooze open={snoozeOpen} close={handleSnoozeClose} id={data.id} date={snoozeDate} setDate={setSnoozeDate}/>
        <ChartMilestone open={showMilestone} companyName={company.name} close={handleMilestoneClose} id={companyId} setRefreshGraphData={setRefreshGraphData}/>
        <AddEditCEO open={CEOOpen} setWarning={setWarning} close={handleCEOClose} ceoDetails={ceoDetails} setCeoDetails={setCeoDetails} hideSequences/>
        <AddEditCompany setWarning={setWarning} open={showAddEditCompany} close={hideAddEditCompany} company={company} setCompany={setCompany}/>						   
        <AddEditReferrer setWarning={setWarning} referrer={data.referrer} open={showReferrer} close={()=>setShowReferrer(false)} rowId={companyId}/>						   
        <AlertDialog openRefresh={openRefresh} setOpenRefresh={setOpenRefresh} companyId={companyId} setWarning={setWarning} setData={setData}/>
        <UploadDialog companyId={companyId} files={files} setUploadedFile={setUploadedFile} setFiles={setFiles} showFileUpload={showFileUpload} setShowFileUpload={setShowFileUpload} />
      </>
      }
    </> 
  );
}

export default Company;