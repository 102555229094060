import configuration from './config';
import qs from "qs";

export const getUser = () => {
  const userStr = localStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return {};
}

export const isJason = () => {
  return getUser()['email'] === 'jason@mvp-vc.com'
}
export const isAdmin = () => {
  if (['jason@mvp-vc.com','weston@mvp-vc.com','gray@mvp-vc.com','nick@mvp-vc.com','danny@mvp-vc.com'].includes(getUser()['email'])) return true
  else return false
}

export const isManager = () => {
  if (['jason@mvp-vc.com','weston@mvp-vc.com','alex@mvp-vc.com','gray@mvp-vc.com','andre@mvp-vc.com','danny@mvp-vc.com'].includes(getUser()['email'])) return true
  else return false
}
 
// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem('token') || null;
}
 
// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
}
 
// set the token and user from the session storage
export const setUserSession = (token,user) => {
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
}

export function isLoggedIn() {
  // ensure token is correct format and not expired
  let token = localStorage.getItem('token')
  if (token) {
    return true
  }
  return false  
}

export function isProfiled() {
  if (JSON.parse(localStorage.getItem('user'))?.profiled) {
    return true
  }
  else {
    return false
  }
}

export function setProfiled() {
  let user = JSON.parse(localStorage.getItem('user'))
  if (user){
    user.profiled = true
    localStorage.setItem('user', JSON.stringify(user))
  }
}

export function unsetProfiled() {
  let user = JSON.parse(localStorage.getItem('user'))
  if (user) {
    user.profiled = false
    localStorage.setItem('user', JSON.stringify(user))
  }
}

export function clearUserInfoAndForwardToLogin() {
  removeUserSession()
  window.location = `/login?forward_to=${window.location.pathname}`;
}

export async function logIn(email, password, history) {
  return fetch(configuration.apiUrl + '/api/token/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
        username: email,
        password: password
    })
  }).catch(e => {
    // provide a more friendly error message when fetch() can't get to the server
    return Promise.reject({
      message: loginServerErrorMessage
    });
  }).then(r => {
    return handleLoginResponse(r, history);
  });
}

const loginServerErrorMessage = 'Error';

function handleLoginResponse(response, history) {
  return response.json().then((json) => {
	  if (response.ok) {
      const authKey = json['access']
      if (authKey) {
        setUserSession(authKey,json["user"])
        if (history) {
          routeUserAfterLogin(history);
        }
        // return Promise.resolve(_userInfo);
      } else {
        return Promise.reject({message: loginServerErrorMessage})
      }
    } else {
      json.message = json.message || loginServerErrorMessage;
      return Promise.reject(json);
    }
  })
}

export async function streamingFetch(relativePath,init) {
  init = init || {};
  init['headers'] = init['headers'] || {};

  const token = getToken();
  if (!token) {
    throw new Error("The user must be authenticated to call this function");
  }

  init['headers']['Authorization'] = `Bearer ${token}`

  // default to json content type if it was not set
  if (!init['headers']['Content-Type']) {
    init['headers']['Content-Type'] = 'application/json';
  }
  
  return fetch.call(this, configuration.apiUrl + relativePath, init)
}

export async function authenticatedFetch(relativePath, init) {
  init = init || {};
  init['headers'] = init['headers'] || {};

  const token = getToken();
  if (!token) {
    throw new Error("The user must be authenticated to call this function");
  }

  init['headers']['Authorization'] = `Bearer ${token}`

  // default to json content type if it was not set
  if (!init['headers']['Content-Type']) {
    init['headers']['Content-Type'] = 'application/json';
  }
  return fetch.call(this, configuration.apiUrl + relativePath, init)
    .then(async (response) => {
      const json = response.json().catch(e => {
        console.error("Could not parse JSON. Error:", e);
        return Promise.resolve({});
      });
      if (response.status === 412){
        alert("Sorry, you don't have access to do that.")
        window.location.reload()
      }

      if (response.status === 401 || response.status === 403) {
        clearUserInfoAndForwardToLogin();
        return;
      }
      if (!response.ok) {
        return json.then(j => {
          throw j;
        });
      }
      return json;
    });
}

export async function unAuthenticatedFetch(relativePath, init) {
  init = init || {};
  init['headers'] = init['headers'] || {};

  // default to json content type if it was not set
  if (!init['headers']['Content-Type']) {
    init['headers']['Content-Type'] = 'application/json';
  }

  return fetch.call(this, configuration.apiUrl + relativePath, init)
    .then(async (response) => {
      const json = response.json().catch(e => {
        console.error("Could not parse JSON");
        return Promise.resolve({});
      });

      if (response.status === 401) {
        clearUserInfoAndForwardToLogin();
        return;
      }
      if (!response.ok) {
        return json.then(j => {
          throw j;
        });
      }
      return json;
    });
}


export function routeUserAfterLogin(history) {
  const forwardTo = qs.parse(window.location.search, {ignoreQueryPrefix: true}).forward_to
  history.push(forwardTo)
}

export async function sendResetPassword(email) {  
  return fetch(configuration.apiUrl + '/accounts/api/forgot_password/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: email
        })
  })
}

export async function confirmPasswordChange(uid, token, pw) {
  try {
    const response = await fetch(configuration.apiUrl + '/accounts/api/new_password/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ uid, token, new_password: pw })
    });

    const json = await response.json().catch(() => {
      return { message: "Could not parse JSON response" };
    });

    if (!response.ok) {
      return Promise.reject({ status: response.status, message: json.message || "Error occurred" });
    }

    return { status: response.status, message: json.message || "Password changed successfully" };
  } catch (error) {
    console.error("Request failed", error);
    return Promise.reject({ status: 500, message: "Internal Server Error" });
  }
}

export async function register(display, email, password) {
  return fetch(configuration.apiUrl + '/accounts/api/register/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: email,
      password: password,
      first_name: display,
      last_name: display,
      username: email
        })
  })
    .then(r => {
      const json = r.json().catch(e => {
        console.error("Could not parse JSON");
        return Promise.resolve({});
      });

      return !r.ok ? json.then(j => Promise.reject(j)) : json
    })
}