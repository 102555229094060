import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";
import { Section } from '../constants/StyleComponents';
import TextField from '@mui/material/TextField';
import { authenticatedFetch } from '../utils/auth';


export default function AddEditReferrer({open,close,rowId}) {
  const { register, handleSubmit } = useForm();
 
  const onSubmit = (form) => {
    async function updateReferrer(){
      authenticatedFetch(`/companies/api/company/${rowId}/update`, {
        method: 'PATCH', 
        body: JSON.stringify({
          referrer: form['referrer']
        })
      })
    }
    updateReferrer()
    close()
  }
  
  return (
    <Dialog 
      open={open}
      onClose={close}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Add Company Source</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>   
        <DialogContent>
          <DialogContentText>
          Record where you found about this company. Things like: referral from a (specific) person or company, news story, Jarvis search, etc.
          </DialogContentText>
                  <Section>
                    <TextField
                      inputRef={register}
                      name="referrer"
                      label="Referrer"
                      fullWidth
                      required={true}
                    />
                  </Section>
        </DialogContent>
        <DialogActions>
          <Button type='submit' color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
      }    
    </Dialog>
  );
  }
