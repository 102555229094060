import React, {useState,useEffect} from 'react';
import TableBase from './TableBase';
import sv from '../constants/Styles';
import { styled } from '@mui/material/styles';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import {Text} from '../constants/StyleComponents';
import Language from '@mui/icons-material/Language';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useGridApiRef,  GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";
import {formatMMDDYYYY,industries,technologies} from '../utils/tools'
import {listOperators,dateOperators,stringOperators,companySearch,tagOperators,multiInputOperators} from './helpers/Filters'

const Name = styled('a')({
  cursor:'pointer',
})

const Link = styled('a')({
  marginLeft: 5,
  textDecoration: null,
  fontSize: sv.bodyText.fontSize,
  cursor: 'pointer',
})

const StyledTip = styled(Tooltip)({
  marginLeft:5,
})

const TipText = styled(Text)({
  color:'white',
})

function renderMultiList(params){
	return <Tooltip placement='top-start' title={params.value}><Box>{params.value}</Box></Tooltip>
}

export default function SecondDegreeContacts({history,path,name}) {
  const [data,setData] = useState([])
  const apiRef = useGridApiRef();
  const [warning,setWarning] = useState({text:'',severity:''})
  const [industryList, setIndustryList] = useState([])
  const [technologyList, setTechnologyList] = useState([])
  
  useEffect(()=>{
	industries().then((v) => setIndustryList(JSON.parse(v)))
  technologies().then((v) => setTechnologyList(JSON.parse(v)))
  },[])

  const columns = 
  [
    { headerName: 'Name', field: 'person__name', width:180, renderCell: (params) => (
      <Name href={`//${params.row.person__url}`.replace('https://','').replace('http://','')} target="_blank">{params.row.person__name}<LinkedInIcon style={{ fontSize: sv.bodyText.fontSize, color:"0F4EB5", marginLeft:5}}/></Name>
    ),valueGetter:(params) => params.row.person__name,valueSetter:(value) => value},
    
    { headerName: 'Network Degree',type:'singleSelect',valueOptions:['1st','2nd'], field: 'network_degree', width:180, valueGetter:(params) => params.row.person__kind ? `1st (${params.row.person__kind})` : '2nd'},        
    { headerName: 'Network Kind',type:'singleSelect',valueOptions:['MVP LP','MVP Lead','Champ LP','Champ Lead','CEO','Candidate','Value-Add'], field: 'person__kind', width:180},        
    { headerName: 'LI URL', field: 'person__url', hide:true,sortable:false,filterable:false,valueGetter:(params) => params.row.person__url,valueSetter:(value) => value},
    { headerName: 'Employer',filterOperators:companySearch.concat(stringOperators.filter((op) => op.value === "contains")), field: 'company__name',width:180,renderCell:(params) => 
    (
      <Box component='span'>
      <Link href={`/company/${params.row.company__id}`} target="_blank">{params.row.company__name}</Link>
    {(params.row.ecompany__description || params.row.company__li_shortdesc) &&
    <StyledTip title={<TipText>{params.row.company__description || params.row.company__li_shortdesc || ''}</TipText>} placement='top'>
      <InfoOutlinedIcon style={{ fontSize: sv.bodyIcon.fontSize}}/>
    </StyledTip>
    }
        <Link
          href={`//www.${params.row.company__website}`}
          target="_blank"
        >
          <Language style={{ fontSize: sv.bodyIcon.fontSize }}/>
        </Link>
            {params.row.company__li_url &&
        <Link
          href={`//${params.row.company__li_url}`.replace('https://','').replace('http://','')}
          target="_blank"
        >
          <LinkedInIcon style={{ fontSize: sv.bodyIcon.fontSize, color:"0F4EB5", display: 'inline-block' }}/>
            </Link>} 
      </Box>)},
  { headerName: 'Employer Size', field: 'company__current', type:'number', width:150},
  { headerName: 'Jobtitle', field: 'title',sortable:false, width:150, renderCell: (params) => (
        <Tooltip 
          title={params.row.title} placement="top-start"
        >
          <Box>{params.row.title}</Box>
        </Tooltip>)},
  { headerName: 'Industries', description:'Industry tags',field: 'company__industries',valueGetter:(p)=>p.row.company__industries.map(c=>c.name).join(', '),filterOperators:multiInputOperators(industryList),renderCell: renderMultiList,width:150},
  { headerName: 'Technologies', description:'Technology tags',field: 'company__technologies',valueGetter:(p)=>p.row.company__technologies.map(c=>c.name).join(', '),filterOperators:multiInputOperators(technologyList),renderCell: renderMultiList,width:150},
  { headerName: 'Company Tags', description:'All company tags tags',field: 'company__tags',valueGetter:(p)=>p.row.company__tags.map(c=>c.name).join(', '),renderCell: renderMultiList,filterOperators:stringOperators.filter((op) => op.value === "contains").concat(tagOperators),width:150},
  { headerName: 'Location', field: 'location', width:180},
  { headerName: 'Start Date', field:'startdate', type:'date', width:120,valueFormatter: (params) => formatMMDDYYYY(params.value),filterOperators: dateOperators},
  { headerName: 'End Date', field:'enddate', type:'date', width:120,valueFormatter: (params) => formatMMDDYYYY(params.value),filterOperators: dateOperators},
  { headerName: 'Network Connection Name', field: 'connections',width:180, renderCell:(p) => (
    <Tooltip
      title={p.row.connections.map(c=>`${c.name} (${c.kind})`).join(', ')} placement="top-start"
    >
      <Box>{p.row.connections.map(c=>`${c.name} (${c.kind})`).join(', ')}</Box>
    </Tooltip>)},
	{ headerName: 'Network Connection Strength', field: 'connections_strength',width:180,type:'number',sortable:false, renderCell:(p)=>{
    const strength = p.row.connections.filter(c=>c.company_overlap===true).length
    return (
    <Tooltip
      title={p.row.connections.filter(c=>c.company_overlap===true).map(c=>c.name).join(', ')}
      placement="top-start"
    >
        <Box>{strength} {strength > 0 && <span sx={{marginLeft:'0.5em'}}>({p.row.connections.filter(c=>c.company_overlap===true).map(c=>c.name).join(', ')})</span>}</Box>        
    </Tooltip>
	)}},
  { headerName: 'Network Connection Kind', field:'connections_kind',type:'singleSelect',valueOptions:['MVP LP','MVP Lead','Champ LP','Champ Lead','CEO','Candidate','Value-Add'],hide:true},
	{ headerName: 'List', field:'listing',hide:true,filterOperators:listOperators},
  ] 
  
  const [cols, setCols] = useState([])
  const [defaultColumns, setDefaultColumns] = useState([])

  useEffect(()=>{
	  setCols(columns)
	  setDefaultColumns(columns)
   // eslint-disable-next-line
  },[industryList,technologyList])
    
    
  return (
      <>
        <TableBase
          data={data}
		      selection={true}
          setData={setData}
          history={history}
          pinnedColumns={{left: [GRID_CHECKBOX_SELECTION_COL_DEF.field,'person__name']}}
          path={'contacts/seconddegree'}
          columns={cols}
		      setColumns={setCols}
		      defaultColumns={defaultColumns}
          name={'Extended Network'}
          warning={warning}
  		    setWarning={setWarning}
          type={"Connections"}
		      apiRef={apiRef}
        />
      </>
  );
}