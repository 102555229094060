import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isLoggedIn,getUser } from '../utils/auth';

const Main = ({
  component: Component,
  path,
  isProtected,
  history, 
  ...rest }) => {

    let loggedIn = isLoggedIn();
    let redirect = undefined;
    
    if (isProtected && !loggedIn) {
        redirect = <Redirect
            to={{ pathname: "/login", search: `forward_to=${document.location.pathname}`, state: { loggedIn } }}
        />;
    }
    
    if (getUser().email === 'anu@mvp-vc.com' && isProtected && !['/contacts/list','/contacts/seconddegree','/connector'].includes(path)){
      redirect = <Redirect
        to={{pathname:"/contacts/list", state:{ loggedIn }}}
      />
    }
    //
    // if (isProtected && loggedIn && !isProfiled() && path !== '/profile') {
    //   redirect = <Redirect
    //       to={{ pathname: "/profile", state: { loggedIn } }}
    //   />
    // }
    
    if (getUser().email === 'wes@mvp-vc.com' && isProtected && path !== "/lead_gen") {
      redirect = <Redirect
          to={{ pathname: "/lead_gen", state: { loggedIn } }}
      />;
    }
    return (
        <Route
          path={path}
          render={props =>
            redirect ? redirect : (
              <Component {...props} />
            )
          }
          {...rest}
        />
    )
}

export default Main;



