import React, { useState, useEffect } from 'react'
import Header from './Header'
import { authenticatedFetch,getUser } from '../utils/auth'
import { formatDayMonthYear,isMobile,getUsers } from '../utils/tools'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import sv from '../constants/Styles';
import Divider from '@mui/material/Divider';
import { Text } from '../constants/StyleComponents';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Language from '@mui/icons-material/Language';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NoteBox from './NoteBox'
import AddEditCEO from './AddEditCEO'
import {formatInvestors,cleanInvestments} from '../utils/tools';
import { Statuses } from '../constants/Constants'
import Pagination from '@mui/material/Pagination';
import ReactHtmlParser from 'react-html-parser';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const Row = styled(Grid)({
	marginTop:20,
})

const Italic = styled('em')({
  color:'gray'
})  

const Body = styled(Text)({
	fontSize: isMobile() ? sv.bodyIcon.fontSize*0.8 : sv.bodyIcon.fontSize*1.2,
})

const Headline = styled(Box)({
	textAlign:'center',
	fontSize: sv.bodyText.fontSize * 2,
	marginTop: 50,
})

const Link = styled('a')({
	cursor:'pointer',
})

const Break = styled(Divider)({
	width:'100%',
	marginTop: 20,
})

const FilterButton = styled(Button)({
  fontSize: isMobile() ? sv.bodyIcon.fontSize*0.8 : sv.bodyIcon.fontSize*1,
  marginLeft: -10,
  marginBottom:10
})

export default function NewsFeed({history}) {
  const [companies, setCompanies] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [filter, setFilter] = useState('all')
  const [users, setUsers] = useState([])
  const [owners, setOwners] = useState({})
  const [statusList, setStatusList] = useState({})
  const [noteOpen, setNoteOpen] = useState(false)
  const [CEOOpen, setCEOOpen] = useState(false)
  const [companyId, setCompanyId] = useState(0)
  const [companyName, setCompanyName] = useState('')
  const [assignment, setAssignment] = useState({})
  const [CEODetails, setCEODetails] = useState({})
  const [warning,setWarning] = useState({text:'',severity:''})
  const [pages, setPages] = useState(0)
  const [page, setPage] = useState(1)
  const [filterName, setFilterName] = useState('All')
	const [pageLoading,setPageLoading] = useState(true)
  
  const PAGE_SIZE = 10
		
  const handleClick = (e) => {
  	setFilter(e.target.id)
    setFilterName(e.target.getAttribute('name'))
	  setAnchorEl(null)
    setPage(1)
  }
  useEffect(()=>{
    async function getComps() {
      setPageLoading(true)
      let url = `/companies/api/recent_rounds?filter=${filter}&page=${page}&size=${PAGE_SIZE}`
      let res = await authenticatedFetch(url, {method: 'GET'})
	    setPages(Math.ceil(res.count / PAGE_SIZE))
      setCompanies(res.results)
      setPageLoading(false)
    }
	getComps()
  },[filter,page])
  
 useEffect(() => {
   getUsers().then((v) => setUsers(v))  
 }, []);
 
 function handleNoteClose(){
   setNoteOpen(false)
 }
 
 function handleCEOClose(){
   setCEOOpen(false)
 }

 function handleOwnerChange(event){
   let nobj = {}    
   nobj[Number(event.target.name)] = event.target.value || 'None'
   setOwners({...owners,...nobj})
   async function fetchData() {
     let own = ['None',''].includes(event.target.value) ? null : event.target.value
     await authenticatedFetch(`/companies/api/company/${event.target.name}/update`, {
       method: 'PATCH',
       body: JSON.stringify({
         owner: own
       })
     })
 	  if (own && getUser()["name"] !== own){
		setCompanyId(event.target.value)
 	  	setAssignment({'assignee':own,'assigner':getUser()["name"]})
 	  	setCompanyName(companies.filter((x)=>x.id===Number(event.target.name))[0].name)
		setNoteOpen(true)
 	  }
   }
   fetchData()
 }
 
 function handleStatusChange(event){
	let comp = companies.filter((x) => x.id === Number(event.target.name))[0]

	if (event.target.value === "Outreach"){
		let ceo = comp.ceo || {}
		ceo['companyId'] = event.target.name
		setCEODetails(ceo)
		setCEOOpen(true)
	}
    let nobj = {}    
    nobj[Number(event.target.name)] = event.target.value
    setStatusList({...statusList,...nobj})
	
	if (['Outreach','Discussions','Snoozed'].includes(event.target.value) && !comp.owner){
		let nobj2 = {}
		nobj2[Number(event.target.name)] = getUser()["name"]
		setOwners({...owners,...nobj2})
	}
    async function fetchData() {
      await authenticatedFetch(`/companies/api/company/${event.target.name}/update`, {
        method: 'PATCH',
        body: JSON.stringify({
          status: event.target.value
        })
      })
    }
    fetchData()
 }

  return (
    <>
      <Header 
        history={history}
        name={'News'}
        warning={warning}
        setWarning={setWarning}
      />
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={pageLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
	  <Container>
		  <Headline>Recent Raises</Headline>
          <>
			      <FilterButton variant="outlined" onClick={(e)=>setAnchorEl(e.currentTarget)} color="primary" size="small">
			      	{filterName} ▾
			      </FilterButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={()=>setAnchorEl(null)}
              anchorOrigin={{vertical: "bottom",horizontal: "center",}}
              transformOrigin={{vertical: "top",horizontal: "center",}}
            >
              <MenuItem style={{fontSize:sv.bodyText.fontSize}} id="all" name="All" onClick={handleClick}>All</MenuItem>
              <MenuItem style={{fontSize:sv.bodyText.fontSize}} id="midas" name="midas investor" onClick={handleClick}>Midas Investor</MenuItem>
              <MenuItem style={{fontSize:sv.bodyText.fontSize}} id="preferred" name="preferred firm" onClick={handleClick}>Preferred Firm</MenuItem>
            </Menu>
          </>
          {companies.map((val,ind) =>
		  	<Row key={ind} container spacing={0}>
			    {isMobile() ? 
				<Grid item xs={12} style={{textAlign:'center',marginBottom:10}}>
			  		<span>{val.country}</span>
			    	<span style={{marginLeft:10}}>{formatDayMonthYear(val.latest_round_date)}</span>  
			    </Grid>
				 :
				<Grid item md={2}>
			  		<Box>{val.country}</Box>
			    	<Box>{formatDayMonthYear(val.latest_round_date)}</Box>  
  	              	<FormControl style={{width:120,marginTop:10}}>
  	              	  <InputLabel>Owner</InputLabel>
  	              	    {users.length > 0 &&
  	              	    <Select
  	              	      value={owners[val.id] || val.owner || ''}
  	              	      onChange={handleOwnerChange}
						              name={String(val.id)}
						              label='Owner'
                          sx={{height:40}}
  	              	    >
				                  <MenuItem key={0} value=""><Italic>None</Italic></MenuItem>
  	              	      {users.map((val,ind) => 
  	              	        <MenuItem key={ind} value={val}>{val}</MenuItem>
  	              	      )}
  	              	      </Select>
  	              	    }
  	              	</FormControl>
					<Box>
					          <Select
					            value={statusList[val.id] || val.status}
					            sx={{width:'120px',marginTop:1,height:40}}
								      onChange={handleStatusChange}
							 	      name={String(val.id)}
								      label='Status'
					          >
					          <MenuItem key={0} value="" disabled><Italic>Choose</Italic></MenuItem>
					          {Statuses.map((val,ind) =>
					            <MenuItem key={ind} value={val}>{val}</MenuItem>
					          )}
					        </Select>
					</Box>
			    </Grid>
					}
      	    	<Grid item xs={12} md={10}>
			  		<Body component='span'><Link href={`/company/${val.id}`} target="_blank" rel="noreferrer"><u>{val.name}</u></Link></Body>
			  		<Link href={`//www.${val.website}`} target="_blank" ><Language style={{ fontSize: sv.bodyIcon.fontSize }}/></Link>
			  		<Link href={`//${val.li_url}`.replace('https://','').replace('http://','')} target="_blank"><LinkedInIcon style={{ fontSize: sv.bodyIcon.fontSize, color:"0F4EB5", display: 'inline-block' }}/></Link>  
					<Body component='span'> 
              {' '}raised a {val.latest_round_amount ? `$${val.latest_round_amount.toFixed(1)}M ` : "recent "} 
              {val.latest_round_type && (val.latest_round_type.parent ? `${val.latest_round_type.parent} (${val.latest_round_type.name})` : val.latest_round_type.name)} 
              {' '}round
              
              {(val.canonical_investments[0] && cleanInvestments([val.canonical_investments[0]]) && cleanInvestments([val.canonical_investments[0]])[0].cleanInvestors?.length > 0) &&
                <>
                  {' from '}
                  {ReactHtmlParser(formatInvestors(cleanInvestments([val.canonical_investments[0]])[0].cleanInvestors))}
                  
                </>
              }
              {'.'}
              {val.ceo?.url && <><span>{' '}The CEO is{' '}</span><Link target="_blank" rel="noreferrer" href={val.ceo.url}><u>{val.ceo.name}.</u></Link></>}
					      <p>{val.li_shortdesc} {val.description} {val.pb_desc}</p>
            </Body>
				    {isMobile() && 
	  	              	<Box>
							<FormControl style={{height:50,width:80}}>
	  	              		  <InputLabel style={{fontSize: sv.bodyIcon.fontSize*0.6}}>Owner</InputLabel>
	  	              		    {users.length > 0 &&
	  	              		    <Select
	  	              		      value={owners[val.id] || val.owner || ''}
	  	              		      onChange={handleOwnerChange}
								  name={String(val.id)}
								  style={{fontSize: sv.bodyIcon.fontSize*0.6}}
	  	              		    >
					    	      <MenuItem key={0} value="" style={{fontSize: sv.bodyIcon.fontSize*0.6}}><Italic>None</Italic></MenuItem>
	  	              		      {users.map((val,ind) => 
	  	              		        <MenuItem key={ind} value={val} style={{fontSize: sv.bodyIcon.fontSize*0.6}}>{val}</MenuItem>
	  	              		      )}
	  	              		      </Select>
	  	              		    }
	  	              		</FormControl>
							<FormControl style={{height:50,width:80,marginLeft:10}}>
	  	              		  <InputLabel style={{fontSize: sv.bodyIcon.fontSize*0.6}}>Status</InputLabel>
						          <Select
						            displayEmpty
						            value={statusList[val.id] || val.status}
  								  	name={String(val.id)}
						            onChange={handleStatusChange}
								    style={{fontSize: sv.bodyIcon.fontSize*0.6}}
						          >
						          <MenuItem key={0} value="" disabled><Italic>Choose</Italic></MenuItem>
						          {Statuses.map((val,ind) =>
						            <MenuItem key={ind} value={val}>{val}</MenuItem>
						          )}
						        </Select>
	  	              		</FormControl>
					    </Box>
						}
			    </Grid>
			    <Break />
	 	  	</Row>
      	  )}
		  <Pagination count={pages} onChange={(e,v)=>setPage(v)}/>
	 </Container>
	  <NoteBox open={noteOpen} close={handleNoteClose} companyId={companyId} companyName={companyName} assignment={assignment}/>
	  <AddEditCEO open={CEOOpen} close={handleCEOClose} setWarning={setWarning} ceoDetails={CEODetails} setCeoDetails={setCEODetails}/>	  
    </>
  );
}