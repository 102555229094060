import React, {useState,useEffect} from 'react'
import Header from './Header'
import { authenticatedFetch } from '../utils/auth';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import 'react-csv-importer/dist/index.css';
import { Importer, ImporterField } from 'react-csv-importer';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';
import sv from '../constants/Styles';
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import {uuid} from '../utils/tools'
import Button from '@mui/material/Button';
import Tooltip,{tooltipClasses} from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import {trim} from '../utils/tools';
import IconButton from '@mui/material/IconButton';
import pitchbook from '../assets/pitchbook.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import hubspot from '../assets/hubspot.png'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddEditLimitedPartner from './AddEditLimitedPartner.js'

function createColumnWithTooltip({ field, headerName, width, valueGetter,tooltipWidth }) {
  return {
    field: field,
    headerName: headerName,
    width: width,
    valueGetter: (params) => valueGetter ? valueGetter(params) : params.value,
    renderCell: (params) => (
      <Tooltip title={valueGetter ? valueGetter(params) : params.value} placement="top" arrow>
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {valueGetter ? valueGetter(params) : params.value}
        </div>
      </Tooltip>
    ),
  };
}


const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: '500px',
    maxWidth: '500px',
  },
});

const Link = styled('a')({
  marginLeft: 5,
  textDecoration: null,
  fontSize: sv.bodyText.fontSize,
  cursor: 'pointer',
})


export default function LimitedPartner({history}) {
  const [activeStep, setActiveStep] = useState(0)
  const [warning,setWarning] = useState({text:'',severity:''})
  const [batchName, setBatchName] = useState('')
  const [batches, setBatches] = useState([])
  const [selectedBatch, setSelectedBatch] = useState(null)
  const [batchData, setBatchData] = useState([])
  const [selectedBatchName, setSelectedBatchName] = useState('')
  const [singleLookupName, setSingleLookupName] = useState('')
  const [singleLookupSite, setSingleLookupSite] = useState('')
  const [selectedLp, setSelectedLp] = useState(null)
  
  const rando = uuid()
  
  function sendData(){
    async function doSendData() {
      setWarning({text:"Executing lookup (may take ~20s)...",severity:'info'})
      let newBatches = await authenticatedFetch(`/companies/api/lp_search/create`, {
        method: 'POST',
        body: JSON.stringify({
          firm: singleLookupName,
          batch: singleLookupName,
          website: singleLookupSite,
          single: true
        })
      })
      setWarning({text:"Done!",severity:'success'})
      setBatches(newBatches) 
    }
    doSendData()
  } 
  
  
  function lookupEmail(id,pbId){
    async function getEmail() {
      setWarning({text:"Searching email...",severity:'info'})
      let url = `/companies/api/lp_email`
      let res = await authenticatedFetch(url, { method: 'POST', 
          body: JSON.stringify({
            pitchbook_id: pbId,
            person_id: id,
            current_batch: selectedBatch
          })
      })
      console.log("RES",res)
      setBatchData(res.data)
      if (res.email){
        setWarning({text:`Success! Found email ${res.email}`,severity:'success'})
      } else {
        setWarning({text:`Sorry, no email found.`,severity:'error'})
      }
    }
    getEmail()
  }
  
  useEffect(() => {
     async function getBatches() {
       let url = `/companies/api/lp_search/batches`
       let res = await authenticatedFetch(url, {method: 'GET'})
       setBatches(res.results)
     }
     getBatches()
  }, []);
  
  useEffect(() => {
     async function getBatch() {
       let url = `/companies/api/lp_search/${selectedBatch}?size=5000`
       let res = await authenticatedFetch(url, {method: 'GET'})
       setBatchData(res.results)
       console.log("RES",res.results)
       setActiveStep(1)
     }
     selectedBatch && getBatch()
  }, [selectedBatch]);
  
  const cols = [
    { headerName: 'Name', field: 'name',width:200, renderCell: (params) => {
      const pitchbookId = params.row.pitchbook_lp_id || params.row.investor?.investor_pitchbook_id;
      const pitchbookUrl = `//my.pitchbook.com/profile/${pitchbookId}`;

      return (
        <span>
          <span>{params.row.name}</span>
          {pitchbookId && (
            <Link target="_blank" style={{marginLeft: 5}} href={pitchbookUrl}>
              <img style={{width: 16}} src={pitchbook} alt="Pitchbook"/>
            </Link>
          )}
           <EditOutlinedIcon style={{fontSize: sv.bodyIcon.fontSize, cursor:'pointer' }} onClick={() => setSelectedLp(params.row)}/>
        </span>
      );
      },
    },
    createColumnWithTooltip({
        field: 'overlap_investments',
        headerName: 'Direct Portco Overlap',
        width: 200,
        valueGetter: (params) => params.row.overlap_investments?.map((x)=>x.name).join('; '),
    }),
    createColumnWithTooltip({
        field: 'pre_mvp_overlap_investments',
        headerName: 'Pre-MVP Portco Overlap',
        width: 200,
        valueGetter: (params) => params.row.pre_mvp_overlap_investments?.map((x)=>x.name).join('; '),
    }),
    createColumnWithTooltip({
        field: 'gsr_overlap_investments',
        headerName: 'GSR Portco Overlap',
        width: 200,
        valueGetter: (params) => params.row.gsr_overlap_investments?.map((x)=>x.name).join('; '),
    }),
    createColumnWithTooltip({
        field: 'related_portfolio_companies',
        headerName: 'Direct Relevant Portcos',
        width: 200,
        valueGetter: (params) => params.row.related_portfolio_companies?.map((x)=>x.name).join(', '),
    }),
    { headerName: 'Top Tags', field: 'investor_top_tags',width:200,valueGetter:(p)=>p.row.investor?.top_tags},
    createColumnWithTooltip({
        field: 'funds',
        headerName: 'Fund Investments',
        width: 200,
        valueGetter: (params) => params.row.funds?.map((x)=>x.name).join('; '),
    }),
    createColumnWithTooltip({
        field: 'fund_overlap_investments',
        headerName: 'Fund Portco Overlap',
        width: 200,
        valueGetter: (params) => params.row.fund_overlap_investments?.map((x)=>`${x.investor}: (${x.companies.join('; ')})`).join('; '),
    }),
    { headerName: 'People', field: 'people',width:200,renderCell:(params)=>(
      <NoMaxWidthTooltip
        placement='bottom-start'
        title=<ul>{params.row.people.filter(x=>x.hubspot_id==='').sort((a, b) => (b.email ? 1 : 0) - (a.email ? 1 : 0)).map((x,i)=>
          <li key={i} style={{marginBottom:7}}>
            <div>{`${x.name}`}{x.email !== '' ? `: ${x.email}` : <IconButton size='small' onClick={()=>lookupEmail(x.id,x.pb_person_id)} sx={{marginLeft:1}}><SearchIcon sx={{fontSize:'0.8em',color:'white'}}/></IconButton>}
            {x.url &&
            <Link href={`//${x.url}`.replace('https://','').replace('http://','')} target="_blank">
                <LinkedInIcon style={{ fontSize: sv.bodyText.fontSize, color:"blue", marginLeft:1}}/>
            </Link>}
          
          </div>
            <div style={{fontSize: '0.9em'}}>{`${trim(x.title, 70)}`}</div>
          </li>
        )}</ul>
        arrow
      >
        <Box>{params.row.people.filter(x=>x.hubspot_id==='').sort((a, b) => (b.email ? 1 : 0) - (a.email ? 1 : 0)).map((x) => `${x.name} ${x.title})${x.email && `: ${x.email}`}`).join('; ')}</Box>
      </NoMaxWidthTooltip>
    ),valueFormatter:(p)=>p.value.map((x) => `${x.name} ${x.title})${x.email && `: ${x.email}`}`).join('; ')},
    
    { headerName: 'Existing Hubspot Leads', field: 'hubspot',width:200,renderCell:(params)=>(
      <NoMaxWidthTooltip
        placement='bottom-start'
        title=<ul>{params.row.people.filter(x=>x.hubspot_id!=='').map((x,i)=>
          <li key={i} style={{marginBottom:7}}>
            <div>{x.name} <Link sx={{marginLeft:1}} target="_blank" href={`https://app.hubspot.com/contacts/8426488/contact/${x.hubspot_id}`}><img style={{width:15}} src={hubspot} alt="Hubspot"/></Link></div>
            <div style={{fontSize: '0.9em'}}>{x.deals}</div>
          </li>
        )}</ul>
        arrow
      >
        <Box>{params.row.people.filter(x=>x.hubspot_id!=='').map((x) => `${x.name}${x.deals && `: ${x.deals}`}`).join('; ')}</Box>
      </NoMaxWidthTooltip>
    ),valueGetter:(params)=>params.row.people.filter(x=>x.hubspot_id!=='').map((x) => `${x.name}${x.deals && `: ${x.deals}`}`).join('; ')},
    { headerName: 'Direct Investor Match', field: 'investor',width:200,valueGetter:(p)=>(p.value && p.value.investor_pitchbook_id) && "Yes",valueFormatter:(p)=>(p.value && p.value.investor_pitchbook_id !== "") ? "Yes" : ""},
    { headerName: 'Fund LP Match', field: 'pitchbook_lp_id',width:200,valueGetter:(p)=>p.value && "Yes",valueFormatter:(p)=>p.value && "Yes"},

 
  ]

  return <>
  <Header 
    history={history}
    name={'LP Search'}
    warning={warning}
    setWarning={setWarning}
  />
	<Grid alignItems='center' justifyContent='center'>
  
     {activeStep === 0 &&
       <>
        <Typography variant="h5" color='primary' sx={{marginTop:10,textAlign:'center'}}>Execute single lookup or upload CSV of Firm Names and Websites</Typography>
        <Typography variant="body2" color='primary' sx={{marginTop:1,textAlign:'center'}}>Additional columns may be included as well</Typography>
        <Box sx={{marginLeft:'10%'}}>
         <Box>
           <TextField 
              value={singleLookupName}
              onChange={(e)=>setSingleLookupName(e.target.value)}
              placeholder="Single Firm Name"
           />
          <TextField 
             sx={{marginLeft:2}}
             value={singleLookupSite}
             onChange={(e)=>setSingleLookupSite(e.target.value)}
             placeholder="Single Firm Website"
          />
         </Box>
         <Button sx={{marginTop:2}} variant="contained" onClick={()=>sendData()}>Lookup</Button>
        </Box>
        
        <Box sx={{width:'80%',marginTop:2,marginLeft:'10%'}}>
       <FormControl variant="standard" style={{width:400,marginBottom:20,marginTop:20}} size="small">
         <TextField
             variant="standard"
             value={batchName}
             onChange={(e)=>setBatchName(e.target.value)}
             placeholder="Enter batch name"
           />
       </FormControl>
        <Importer
          assumeNoHeaders={false}
          restartable={false}
          processChunk={async (rows, { startIndex }) => {
             for (let row of rows) {
               console.log("ROW",row)
               await authenticatedFetch(`/companies/api/lp_search/create`, {
                    method: 'POST',
                    body: JSON.stringify({
                      firm: row.firm,
                      batch: batchName || rando,
                      website: row.website
                    })
               })
             }
          }}
        >
          {({ preview }) => {
            return (
              <>
                <ImporterField name="firm" label="Firm" />
                <ImporterField name="website" label="Website" optional/>
              </>
            );
          }}
        </Importer>     

       <Table style={{marginTop:50}}>
           <TableHead>
             <TableRow>
                <TableCell>Batch (click to view)</TableCell>
                <TableCell>Creator</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Progress</TableCell>
             </TableRow>
           </TableHead>
           <TableBody>
            {batches.map((b,i) => 
              <TableRow key={i}>
                <TableCell style={{cursor:'pointer'}} onClick={()=>{
                  setSelectedBatch(b.id)
                  setSelectedBatchName(b.name)
                }}>{b.name}</TableCell>
                <TableCell>{b.user}</TableCell>
                <TableCell>{b.created_at}</TableCell>
                <TableCell>{b.done[0]} / {b.done[1]}</TableCell>
              </TableRow>
            )}
           </TableBody>
        </Table>
           
      </Box>
     </>
      }
      {activeStep === 1 &&
      <>
        
        <Typography variant="h5" color='primary' sx={{marginTop:10,textAlign:'center'}}>Batch {selectedBatchName} Results</Typography>
        <Box sx={{height:500,marginLeft:'1%',width:'98%',marginTop:5}}>
          <Button variant="text" onClick={()=>setActiveStep(0)}>Back</Button>
          <DataGridPro
            hideFooter
            columns={cols}
            rows={batchData}
            density="compact"
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </>
      }
	</Grid>
      <AddEditLimitedPartner setWarning={setWarning} open={selectedLp!==null} close={()=>setSelectedLp(null)} selectedLp={selectedLp} batch={selectedBatch}/>
  </>
}
