import React, {useEffect,useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";
import { Text, Section } from '../constants/StyleComponents';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { authenticatedFetch } from '../utils/auth';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ContactHistory from './ContactHistory'
import InnerComments from './InnerComments'

const ErrorText = styled(Text)({
  color:'red',
  fontStyle:'italic',
})

const CSelect = styled(Select)({
  width:'200px',
  height:'40px',
  fontSize:18,
  marginTop:10,
})

export default function AddEditContact({open,close,contact,setContact,data,setData,setPageLoading,setWarning}) {
  const [fellows, setFellows] = useState([])
  const { register, handleSubmit, errors } = useForm();
  const [kind,setKind] = useState('')
  const [connection,setConnection] = useState('')
  const [tab,setTab] = useState(0)
  const [person,setPerson] = useState({})
  const [comments,setComments] = useState([])
  
  useEffect(() => {
      async function getFellows() {
        let url = `/logins/api/fellows`
        let res = await authenticatedFetch(url, {method: 'GET'})
        setFellows(res.map(x => x.name))
      }
      if (open && fellows.length === 0){
          getFellows()
      }
  }, [open,fellows]);
  
  useEffect(()=>{
	  if ( Object.keys(contact).length !== 0){
	  	contact.kind && setKind(contact.kind)
	  	contact.fellow && setConnection(contact.fellow)
      
      contact.id && getComments()
      
	  }
    setPerson(contact)
    
    async function getComments() {
      let url = `/companies/api/company/${contact.id}/personcomments`
      let res = await authenticatedFetch(url, {method: 'GET'})
      setComments(res)
    }
  },[contact])
  
  const remove = () => {
    let url = `/companies/api/contacts/delete/${contact.id}`
    async function doDelete() {
      await authenticatedFetch(url, {
              method: 'POST'
      })
    }
    doDelete()
    close()
  }
  
  useEffect(()=> {
    if (Array.isArray(data)) {
      let obj = data.filter((x) => x.id === contact.id)[0]
      let objIndex = data.indexOf(obj)
      let newDataArray = [...data]
      newDataArray[objIndex] = contact
      setData(newDataArray)
    }
    // eslint-disable-next-line
  },[contact])
  
  const onSubmit = (form) => {
    if (setContact && setData){
      form.savable = true
      form.id = Number(contact['id'])
      setContact(form)
	  
      let obj = data.filter((x) => x.id === form.id)[0]
	    let objIndex = data.indexOf(obj)
      let newObj = {
        name: form.name,
        fellow: connection,
		    url:form.url,
		    kind:kind
      }
      let replacementObj = {...obj,...newObj}
      let newDataArray = [...data]
      newDataArray[objIndex] = replacementObj
      setData(newDataArray)
    }
    async function saveContact() {
      setPageLoading && setPageLoading(true)
      if (contact['id']){
        let url = `/companies/api/contacts/update/${contact['id']}`
        setWarning({text:"Saving...",severity:'info'})
        let res = await authenticatedFetch(url, {
                method: 'PATCH',
                body: JSON.stringify({
                  name: form['name'],
                  fellow: connection,
                  url: form['url'],
                  kind: kind,
                  email: form['email']
                })
        })
        setWarning({text:"Saved!",severity:'success'})
        setContact(res)
        setPageLoading && setPageLoading(false)
      }
      else{
        let url = `/companies/api/contacts/create`
        setWarning({text:"Saving...",severity:'info'})
        await authenticatedFetch(url, {
                method: 'POST',
                body: JSON.stringify({
                  name: form['name'],
                  fellow: connection,
                  url: form['url'],
                  kind: kind
                })
        })
        setWarning({text:"Saved!",severity:'success'})
      }
    }

    saveContact()
    close()
  }
  
  return (
    <Dialog 
      open={open}
      onClose={close}
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle>Add/Edit Contact</DialogTitle>
      <AppBar position="static" color="default">
              <Tabs value={tab} onChange={(e,nv)=>setTab(nv)} centered>
  			 	<Tab label="Profile" />	  			
	  			<Tab label="Notes" />
                <Tab label="Work History" />
                <Tab label="Education History" />
              </Tabs>
            </AppBar>
      
      {tab === 1 &&
      	<InnerComments person={contact} setWarning={setWarning} comments={comments} setComments={setComments}/>
      }
	  
      {tab === 0 &&
      <form onSubmit={handleSubmit(onSubmit)}>   
        <DialogContent>
          <DialogContentText>
          Create or edit a contact. Contact must be connected to chosen connection. All fields all required and LinkedIn URL must be a linkedin.com/in/ URL
          </DialogContentText>
                  <Section>
                    <TextField
                      inputRef={register}
                      name="name"
                      label="Contact Name"
                      required={true}
                      fullWidth
                      onChange={(e)=>setPerson({...person,...{'name':e.target.value}})}
                      defaultValue={person['name']}
                    />
                    {errors.name && <ErrorText>This field is required</ErrorText>}
                  </Section>
                  <Section>
                    <TextField
                      inputRef={register({pattern:/\/in\//})}
                      name="url"
                      label="LinkedIn URL"
                      required={true}
                      fullWidth
                      defaultValue={person['url']}
                    />
                      {errors.li_url && <ErrorText>Hmm that does not look like a valid LinkedIn URL</ErrorText>}
                  </Section>
                  <Section>
                    <TextField
                      inputRef={register}
                      name="email"
                      label="Email"
                      fullWidth
                      defaultValue={person['email']}
                    />
                      {errors.li_url && <ErrorText>Hmm that does not look like a valid LinkedIn URL</ErrorText>}
                  </Section>
                  <Section>
                      <InputLabel>Kind</InputLabel>
                    <CSelect
                      value={kind}
                      onChange={(e)=>setKind(e.target.value)} 
                      required={person.kind !== 'second_degree'}   
                      disabled={['Champ Lead','MVP Lead','Champ LP','MVP LP','MVP Team'].includes(kind)}
                    >
                        <MenuItem value='Choose' disabled>Choose</MenuItem>
                        <MenuItem value={'Candidate'}>Candidate</MenuItem>
                        <MenuItem value={'Portco CEO'}>Portfolio CEO</MenuItem>
                        <MenuItem value={'Value-Add'}>Value-Add</MenuItem>
                        <MenuItem value={'Champ Lead'} disabled>Champ Lead</MenuItem>
                        <MenuItem value={'Champ LP'} disabled>Champ LP</MenuItem>
                        <MenuItem value={'MVP Lead'} disabled>MVP Lead</MenuItem>
                        <MenuItem value={'MVP LP'} disabled>MVP LP</MenuItem>
                        <MenuItem value={'MVP Team'} disabled>MVP Team</MenuItem>
                      </CSelect>
                       {errors.kind && <ErrorText>This field is required</ErrorText>}
                  </Section>
                  {fellows.length > 0 &&
                  <Section>
                    <InputLabel>Connection</InputLabel>
                    <CSelect
                      value={connection}
                      onChange={(e)=>setConnection(e.target.value)}                      
                      >
                      <MenuItem value='Choose' disabled>Choose</MenuItem>
                      {fellows.map((val,ind) =>
                        <MenuItem key={ind} value={val}>{val}</MenuItem>
                      )}
                      </CSelect>
                  </Section>
                    }
        </DialogContent>
        <DialogActions>
          <Button onClick={remove} color="inherit" style={{marginRight:'60%'}}>
            Delete
          </Button>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button type='submit' color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
      }    
      
      {tab === 2 &&
      <ContactHistory 
          contact={contact}
          type={'employees'}
          setContact={setContact}
      />
      }
      {tab === 3 &&
      <ContactHistory 
          contact={contact}
          type={'students'}
          setContact={setContact}
      />
      }
    </Dialog>
  );
  }
