import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { Title, Text, Section } from '../constants/StyleComponents';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import mvp from '../assets/mvp.png';
import { logIn, register, isLoggedIn, routeUserAfterLogin, sendResetPassword } from '../utils/auth';

const Content = styled(Container)({
  marginTop: 20,
})

const HeaderImg = styled('img')({
  width:300,
  margin:30,
})

const FormField = styled(TextField)({
  marginRight:10,
  color:"primary",
  width:500,
})

const Link = styled(Text)({
  cursor:'pointer',
  color:'#384A7F',
})

export default function Login({history}) {
  const [login,setLogin] = useState(true)
  const [error,setError] = useState('')
  const [success,setSuccess] = useState('')
  const [passwordResetPage, setPasswordResetPage] = useState(false)
  const [passwordResetDone, setPasswordResetDone] = useState(false)
  
  const initialValues = {
        email: "",
        password: "",
        display: "",
        confirm: "",
  }
  const [values, setValues] = useState(initialValues || {});
    
  
  if (isLoggedIn()) {
    routeUserAfterLogin(history);
  }
  
  const handleLogin = (event) => {
    event.preventDefault();
    if (login){
      setError('');
      setSuccess('');
        logIn(values.email.toLowerCase(), values.password, history)
          .catch((error) => {
            setError("Incorrect email or password, please try again");
          });
    }
    else {
      if (values.password !== values.confirm){
        setError("Password and confirmation must match")
      }
        else {
        register(values.display, values.email.toLowerCase(), values.password, history)
          .catch((error) => {
            setError(error.message);
          });
          setLogin(true)
          setSuccess("Success! You may now log in once approved.")
      }
    }
  }
  
  const handlePasswordReset = () => {
    sendResetPassword(values.email)
    setPasswordResetDone(true)
  }
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        const { name, value } = target;
        event.persist();
        setValues({ ...values, [name]: value });
  };
  
  const loginSwitch = () => {
    if (passwordResetPage){
      setPasswordResetPage(false)
      setPasswordResetDone(false)
    } else {
      setLogin(!login)
    }
    setError('')
    setSuccess('');
  }
  
  const resetPassword = () => {
    setError('')
    setSuccess('')
    setPasswordResetPage(true)
  }
   
  return (
    <>
      <HeaderImg src={mvp} alt="MVP Ventures"/>
      <Content maxwidth="lg">
        <Title>Jarvis by MVP</Title>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {success && <p style={{ color: 'green' }}>{success}</p>}
    { passwordResetPage ?
        passwordResetDone ?
        <>
          <Section>Success! Email will be sent to {values.email}. The email might take a few minutes to arrive. Please follow instructions in the email and check your spam folder if missing.</Section>
          <Section> <Link onClick={loginSwitch}>Login</Link></Section>
        </>
        :
        <>
          <Text>Enter your email address to get a link to reset your password.</Text>
          <Section>
            <form onSubmit={handlePasswordReset} autoComplete="off">
              <Section>
              <FormField
                required
                label="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                variant="filled"
              />
              </Section>
              <Section>
                <Button type="submit" variant="contained" color="primary">Reset Password</Button>
              </Section>
            </form>
          </Section>
          <Section> <Link onClick={loginSwitch}>Login</Link></Section>
      </>
      :
      login ? 
      <>
        <Section>
          <form onSubmit={handleLogin} autoComplete="off">
            <Section>
            <FormField
              required
              label="Email"
              name="email"
              value={values.email}
              onChange={handleChange}
              variant="filled"
            />
            </Section>
            <Section>
            <FormField
              required
              label="Password"
              name="password"
              value={values.password}
              onChange={handleChange}
              variant="filled"
              type="password"
            />
            </Section>
            <Section>
              <Button type="submit" variant="contained" color="primary">Login</Button>
            </Section>
          </form>
        </Section>
        <Section>
              <Link onClick={loginSwitch}>Need an account? Click here to register</Link>
              <Link onClick={resetPassword}>Reset Password</Link>
        </Section>
      </>
          :
      <>
        <Section>
          <form onSubmit={handleLogin} autoComplete="off">
            <Section>
            <FormField
              required
              label="Display Name"
              name="display"
              value={values.display}
              onChange={handleChange}
              variant="filled"
            />
            </Section>
            <Section>
            <FormField
              required
              label="Email"
              name="email"
              value={values.email}
              onChange={handleChange}
              variant="filled"
            />
            </Section>
            <Section>
            <FormField
              required
              label="Password"
              name="password"
              value={values.password}
              onChange={handleChange}
              variant="filled"
              type="password"
            />
            </Section>
            <Section>
            <FormField
              required
              label="Password Confirmation"
              name="confirm"
              value={values.confirm}
              onChange={handleChange}
              variant="filled"
              type="password"
            />
            </Section>
            <Section>
              <Button type="submit" variant="contained" color="primary">Register</Button>
            </Section>
          </form>
        </Section>
        <Section>
              <Link onClick={loginSwitch}>Already registered? Click to login</Link>
        </Section>
      </>
      }
      </Content>
    </>
  );
}
 

 