import React, { useState, useEffect, useRef, useMemo } from 'react';
import {useParams} from "react-router";
import { authenticatedFetch } from '../utils/auth';
import { styled } from '@mui/material/styles';
import { Title } from '../constants/StyleComponents';
import Box from '@mui/material/Box';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Language from '@mui/icons-material/Language';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Grid2 from '@mui/material/Unstable_Grid2'
import {formatMMDDYYYY,money,tags,titleize} from '../utils/tools';
import sv from '../constants/Styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Header from './Header';
import InnerComments from './InnerComments'
import IntroTable from './IntroTable'
import TextField from '@mui/material/TextField';
import hubspot from '../assets/hubspot.png'
import {isMobile} from '../utils/tools'
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import Chip from '@mui/material/Chip';
import AddEditContact from './AddEditContact.js'
import CircleIcon from '@mui/icons-material/Circle';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ExpandableBox from './ExpandableBox'
import MessageIcon from '@mui/icons-material/Message';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ReactHtmlParser from 'react-html-parser';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SyncIcon from '@mui/icons-material/Sync';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DeleteIcon from '@mui/icons-material/Delete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import Alert from '@mui/material/Alert';
import ListItemButton from '@mui/material/ListItemButton';
import Badge from '@mui/material/Badge';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import CircularProgress from '@mui/material/CircularProgress';

const UnstyledLink = styled('a')(({ disabled = false }) => ({
  cursor: disabled ? 'default' : 'pointer',
  color: disabled ? '#ccc' : 'inherit',
  pointerEvents: disabled ? 'none' : 'auto',
  textDecoration: 'none',
}))





function UploadDialog({showFileUpload,setShowFileUpload,setFiles,files}) {
  const [warning,setWarning] = useState({text:'',severity:''})

  function FileListItem({ item, depth = 0 }) {
      const [open, setOpen] = useState(false)
      const paddingLeft = 4 * depth

      // Create a memoized version of the item object
      const memoizedItem = useMemo(() => {
        // Create a new object with the same properties as item
        return {
          id: item.id,
          name: item.name,
          created_at: item.created_at,
          parsed: item.parsed,
          is_folder: item.is_folder,
          url: item.url,
          folder: item.folder,
          needs_syncing: item.needs_syncing
        }}, [
        item.id,
        item.name,
        item.created_at,
        item.parsed,
        item.is_folder,
        item.url,
        item.folder,
        item.needs_syncing
      ]);

      return (
          <>
              <ListItem sx={{marginTop:0,marginBottom:0,paddingTop:0,paddingBottom:0}} secondaryAction={
                  memoizedItem.is_folder ? 
                      open ?
                            (
                            <IconButton edge="end" sx={{marginRight:'10px'}} onClick={()=>setOpen(false)}>
                                <ExpandLess />
                            </IconButton>
                        )
                      :
                
                        (
                        <IconButton edge="end" sx={{marginRight:'10px'}} onClick={()=>setOpen(true)}>
                            <ExpandMore />
                        </IconButton>
                      ) : (
                      <IconButton edge="end" sx={{marginRight:'10px'}} onClick={() => handleDelete(memoizedItem.id)}>
                          <DeleteIcon />
                      </IconButton>
                  )
              }>
                  <ListItemButton sx={{pl:paddingLeft,marginTop:0,marginBottom:0,paddingTop:0,paddingBottom:0}} onClick={memoizedItem.url ? () => window.open(memoizedItem.url) : null}>
                      <ListItemText
                          primary={
                          <>
                            {memoizedItem.needs_syncing && <SyncIcon sx={{verticalAlign:'middle',marginRight:1}}/>}
                            {memoizedItem.is_folder && <FolderOpenIcon sx={{verticalAlign:'middle',marginRight:1}}/>}
                            <Box component='span'>{memoizedItem.name}</Box>
                          </>
                            
                          }
                          secondary={
                              <>
                                  <Box component='span'>{formatMMDDYYYY(memoizedItem.created_at)}</Box>
                                  {memoizedItem.parsed && <SmartToyOutlinedIcon sx={{ verticalAlign: 'top', fontSize: '1.2em', marginLeft: 1 }} />}
                              </>
                          }
                      />
                  </ListItemButton>
              </ListItem>
              {memoizedItem.is_folder && (
                  <Collapse in={open} timeout="auto" unmountOnExit>
                      <List component="div" dense={true} disablePadding>
                          {files.filter(x=>x.folder===memoizedItem.id).map((subItem, index) => (
                              <FileListItem key={index} item={subItem} depth={depth+1}/>
                          ))}
                      </List>
                  </Collapse>
              )}
          </>
      );
  }

  useEffect(()=>{
    if (warning?.text){
      setTimeout(() => {setWarning({text:'',severity:''})}, warning.timeout || 60000)
    }
   // eslint-disable-next-line
  },[warning])
  
  const handleDelete = (fid) => {
    async function doDelete(){
      setWarning({text:'Deleting...',severity:'info'})
      let res = await authenticatedFetch(`/companies/api/files/${fid}/delete`, {method: 'POST'})
      setFiles(res)
      setWarning({text:'Deleted!',severity:'success'})
    }
    doDelete()
  }

  return (
      <Dialog
        open={showFileUpload}
        onClose={()=>setShowFileUpload(false)}
        fullWidth={true}
        maxWidth={'md'}
      >
        {warning && warning.text && <Alert severity={warning.severity} onClose={() => setWarning({})}>{warning.text}</Alert>}
        <DialogTitle>Files</DialogTitle>
          <List dense={true} sx={{ maxHeight: 400, overflow: 'auto' }}>
              {files.filter(x=>!x.folder).map((file, index) => (
                  <FileListItem key={file.id} item={file} />
              ))}
          </List>
      </Dialog>
  )
}

export default function Person({history}) {
  const [data,setData] = useState(null)
  const [warning,setWarning] = useState({text:'',severity:''})
  const [commentsOpen, setCommentsOpen] = useState(isMobile() ? false : true)
  const [showAddEditContact, setShowAddEditContact] = useState(false)
  const [employers, setEmployers] = useState([])
  const [tagList, setTagList] = useState([])
  const [tagValue, setTagValue] = useState(null)
  const filter = createFilterOptions();
  const {id} = useParams()
  const [introRows, setIntroRows] = useState([])
  const [comments, setComments] = useState([])
  const [tab,setTab] = useState(0)
  const [profileTextOpen, setProfileTextOpen] = useState(false)
  const [files, setFiles] = useState([])
  const [showFileUpload, setShowFileUpload] = useState(false)
  const [closestCompanies, setClosestCompanies] = useState([])
  const [closestPeople, setClosestPeople] = useState([])
  const [closestOnlyLps,setClosestOnlyLps] = useState(false)
  
  const boxRef = useRef(null)  

  useEffect(() => {
    data && tags().then((v) => setTagList(JSON.parse(v).filter((x)=>!data.tags.includes(x.name))))
    if (data?.name){document.title = data.name + " | Jarvis"}
  },[data])
  
  const currentVintageYear = new Date().getMonth() < 9 ? new Date().getFullYear() : new Date().getFullYear() + 1
  
  function boldHeader(params){
    return (
      <strong style={{fontSize:18}}>{titleize(params.field)}</strong>
    )
  }
  const investmentCols = [
    { headerName: 'Name',renderHeader:(params)=>boldHeader(params),headerAlign: 'center', field: 'name',filterable:false,width:200},
    { headerName: 'Amount',renderHeader:(params)=>boldHeader(params),headerAlign: 'center',align:'center', field: 'amount', type: 'number',width:120,filterable:false,valueFormatter: (params) => money(params.value)},
    { headerName: 'Date',renderHeader:(params)=>boldHeader(params),headerAlign: 'center',align:'center', field: 'date', type: 'date',width:120,filterable:false,valueFormatter: (params) => formatMMDDYYYY(params.value)},
     
  ]
  
  function scoreIcon(score,kind){
    if (score === null){return null}
    if (kind === 'investor' && score === 0){return null}
    if (score >= 80) {
      return <KeyboardDoubleArrowUpIcon sx={{color:'green',verticalAlign:'middle'}}/>
    } else if (score >= 50) {
      return <KeyboardArrowUpIcon sx={{color:'green',verticalAlign:'middle'}}/>
    } else if (score >= 0 || (score && kind === 'investor')) {
      return <HorizontalRuleIcon sx={{color:'#ffd700',verticalAlign:'middle'}}/>
    } else if (score >= -50) {
      return <KeyboardArrowDownIcon sx={{color:'red',verticalAlign:'middle'}}/>
    } else if (score) {
      return <KeyboardDoubleArrowDownIcon sx={{color:'red',verticalAlign:'middle'}}/>
    }
  }

  function handleContactClose(){
    setShowAddEditContact(false)
  }
  
  const handleValueAddInterest = (interest) => {
    updatePerson('value_add_interest',interest)
  }
  
  const updatePerson = (k,v) => {
    async function updateData() {      
      let res = await authenticatedFetch(`/companies/api/contacts/update/${id}`, {
        method: 'PATCH', 
        body: JSON.stringify({
          [k]: v
        })
      })
      setData(res)
      
      let empList = [];
      [...new Set(res.employees.map((e)=>e.company?.name || e.institution))].forEach((e) => {
        empList.push(res.employees.filter((emp) => (emp.company?.name || emp.institution) === e)[0])
      })
      setEmployers(empList)
    }
    updateData()
  }
  
  useEffect(() => {
    async function fetchData() {
      let url = `/companies/api/person/${id}`
      let res = await authenticatedFetch(url, {method: 'GET'})
      console.log("data",res)
      setData(res)
      setIntroRows(res.intros)
    }
    
    
    async function getComments() {
      let url = `/companies/api/company/${id}/personcomments`
      let res = await authenticatedFetch(url, {method: 'GET'})
      setComments(res)
    }
    async function getFiles(){
      const res = await authenticatedFetch(`/companies/api/person/${id}/files`, {method: 'GET'})
      setFiles(res.results)
    }
    
    async function getClosestCompanies(){
      const res = await authenticatedFetch(`/companies/api/person/${id}/closest_companies`, {method: 'GET'})
      setClosestCompanies(res)
    }    
    
    getClosestCompanies()
    fetchData()
    getComments()
    getFiles()
  }, [id]);
  
  useEffect(()=>{
    async function updateClosestPeople() {
      setClosestPeople([])
      const res = await authenticatedFetch(`/companies/api/person/${id}/closest_people?lps_only=${closestOnlyLps}`, {method: 'GET'})
      setClosestPeople(res)
    }
    id && updateClosestPeople()
  },[closestOnlyLps,id])
  
  useEffect(() => {
    if (data){
      let empList = [];
      [...new Set(data.employees.map((e)=>e.company?.name || e.institution))].forEach((e) => {
        empList.push(data.employees.filter((emp) => (emp.company?.name || emp.institution) === e)[0])
      })
      setEmployers(empList)
    }
  },[data])

  return (
    <>
    <Header 
      history={history}
      name={''}
      doSearch={null}
      warning={warning}
      setWarning={setWarning}
    />
    { data &&
      <>
       <AppBar position="sticky" style={{paddingTop:10,color:'#06283C',backgroundColor:'white'}}>
            <Grid2 container>
              <Grid2 xs={12}>
               <Stack direction={isMobile() ? "column" : "row"} sx={{paddingLeft:2,paddingTop:2}}>
                <Title component="span" sx={{fontSize: sv.bodyText.fontSize * 2,}}>{data.name}</Title>
                <Stack direction="row" spacing={1} >
                  <UnstyledLink sx={{marginLeft:1}}><EditOutlinedIcon style={{fontSize: sv.bodyIcon.fontSize*1.4, cursor:'pointer' }} onClick={() => {setShowAddEditContact(true)}}/></UnstyledLink>
                  <UnstyledLink disabled={!data.url} href={`//${data.url}`.replace('https://','').replace('http://','')} target="_blank" sx={{marginLeft:1}}><LinkedInIcon style={{ fontSize: sv.bodyText.fontSize * 1.4, color:"0F4EB5",marginLeft:5,opacity:data.url ? 1 : 0.3}}/></UnstyledLink>
                  <UnstyledLink disabled={!data.hubspot_id} sx={{marginLeft:1}} target="_blank" href={`https://app.hubspot.com/contacts/8426488/contact/${data.hubspot_id}`}><img style={{width:22,opacity:data.hubspot_id ? 1 : 0.3}} src={hubspot} alt="Hubspot"/></UnstyledLink>
                  <Badge badgeContent={files.filter(x=>!x.is_folder).length} color="primary">
                      <UnstyledLink><FolderOpenIcon style={{fontSize: sv.bodyIcon.fontSize*1.6, cursor:'pointer' }} onClick={() => {setShowFileUpload(true)}}/></UnstyledLink>     
                  </Badge>
                  <UnstyledLink disabled={!data.email} sx={{marginLeft:1}} target="_blank" href={`mailto:${data.email}`}><EmailOutlinedIcon sx={{opacity:data.email ? 1 : 0.3}}/></UnstyledLink>
                  {isMobile() &&<UnstyledLink style={{marginLeft:10}}><MessageIcon sx={{fontSize: sv.bodyIcon.fontSize*1.8, cursor:'pointer' }} onClick={()=>setCommentsOpen(!commentsOpen)}/></UnstyledLink>}
                </Stack>
               </Stack>
              </Grid2>
            </Grid2>
      </AppBar>
     <Grid2 container columns={12} ref={boxRef} sx={{padding:2}} spacing={2}>
       <Grid2 xs={isMobile() ? 12 : 8} sx={{padding:2,overflow:'auto',borderRadius:3,backgroundColor:'white'}}>
              <Autocomplete
                  value={tagValue}
                  onChange={(event, newValue) => {
                    if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setTagValue({
                        name: newValue.inputValue,
                      });
                      updatePerson('tags',data.tags.concat(titleize(newValue.inputValue)))
                    } else {
                      setTagValue(newValue)
                      newValue && updatePerson('tags',data.tags.concat(titleize(newValue.name)))
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.name);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        name: `Add "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={tagList}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                   if (option.inputValue) {
                      return option.inputValue;
                    }
                    if (option.name){
                      return option.name
                    } 
                    return option
                    
                  }}
                  renderOption={(props, option) => <li {...props}>{option.name}</li>}
                  sx={{ width: 150}}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} sx={{backgroundColor:sv.colors.blue5,borderRadius:3}} size="small" label="+ Tag" />
                  )}
                  />
              <Box sx={{margin:2,marginTop:0}}>
                {data.tags.map((t,ind) => (
                  <Chip key={ind} variant='outlined' size='small' label={t} onDelete={()=>updatePerson('tags',data.tags.filter((x)=>x !== t))} color='primary' sx={{padding:1,fontSize:14,marginRight:2,marginTop:2}}/>
                ))}
              </Box>
            
            
            
            <Tabs value={tab} variant="fullWidth" onChange={(e,nv)=>setTab(nv)}>
                <Tab label="Investor Overview"/>
                <Tab label="Value-Add"/>
                <Tab label="Background" />
            </Tabs>
                
              {tab === 0 &&
              <>
                
                <Grid2 xs={12} sx={{borderRadius:3,backgroundColor:'white',marginTop:1}}>
                  <Stack direction={isMobile() ? "column" : "row"} justifyContent="space-evenly">
                    <Stack>
                        <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>Status</Typography>
                        <Typography sx={{fontWeight:700}} color='primary'>{data.kind}&nbsp;</Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem sx={{borderRightWidth:2,borderColor:sv.colors.blue1}}/>
                    <Stack>
                        <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>Signup Date</Typography>
                        <Typography sx={{fontWeight:700}} color='primary'>{formatMMDDYYYY(data.created_at)}&nbsp;
                          <Chip component='span' sx={{marginLeft:1}} label={`First Investment: ${formatMMDDYYYY((data.deals[data.deals.length - 1] || {}).date) || 'N/A'}`} variant="outlined" size='small'/>
                        </Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem sx={{borderRightWidth:2,borderColor:sv.colors.blue1}}/>
                    <Stack>
                        <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>Total $$</Typography>
                        <Typography sx={{fontWeight:700}} color='primary'>{money(data.deals.map((d)=>d.amount).reduce(function(a,b){return a+b},0))}&nbsp;
                          <Chip component='span' sx={{marginLeft:1}} label={`${money(data.deals.filter((d)=>d.vintage_year === currentVintageYear).map((d)=>d.amount).reduce(function(a,b){return a+b},0))} this year`} variant="outlined" size='small'/>
                        </Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem sx={{borderRightWidth:2,borderColor:sv.colors.blue1}}/>
                    <Stack>
                        <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>Fund Investor</Typography>
                        <Typography sx={{fontWeight:700}} color='primary'>{data.deals.some((d)=>d.is_vintage) ? 'Yes' : 'No'}&nbsp;</Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem sx={{borderRightWidth:2,borderColor:sv.colors.blue1}}/>
                    <Stack>
                        <Typography sx={{fontWeight:600,color:'gray',fontmarginBottom:1}} color='primary'>Co-Invests</Typography>
                        <Typography sx={{fontWeight:700}} color='primary'>{data.deals.filter((x)=>!x.is_vintage).length}&nbsp;</Typography>
                    </Stack>
                  </Stack>
                </Grid2>
                <Stack direction={isMobile() ? 'column' : 'row'} sx={{marginTop:4}}>
                  <Box sx={{ height:300,width:'50%'}}>
                      <DataGridPro
                        hideFooter
                        columns={investmentCols}
                        rows={data.deals}
                        density="compact"
                      />
                  </Box>

                  <Stack justifyContent="space-evenly" sx={{margin:0,backgroundColor:'white',borderRadius:3,height:'93.5%',padding:2}}>
                    <Stack>
                      <Typography sx={{fontWeight:600,color:'gray'}} color='primary'>Hubspot Owner</Typography>
                      <ExpandableBox text={data.user} maxLength={300} styles={{fontFamily:'system-ui',lineHeight:1.5,marginTop:1}}/>
                    </Stack>
                    <Divider flexItem sx={{borderBottomWidth:1,borderColor:sv.colors.blue1,marginTop:2,marginBottom:2}}/>
                    <Stack>
                     <Typography sx={{fontWeight:600,color:'gray'}} color='primary'>Next Step</Typography>
                     <ExpandableBox text={data.next_step} maxLength={300} styles={{fontFamily:'system-ui',lineHeight:1.5,marginTop:1}}/>
                    </Stack>
                    <Divider flexItem sx={{borderBottomWidth:1,borderColor:sv.colors.blue1,marginTop:2,marginBottom:2}}/>
                    <Stack>
                     <Typography sx={{fontWeight:600,color:'gray'}} color='primary'>Industries</Typography>
                     <ExpandableBox text={data.industries.join(', ')} maxLength={300} styles={{fontFamily:'system-ui',lineHeight:1.5,marginTop:1}}/>
                    </Stack>
                    <Divider flexItem sx={{borderBottomWidth:1,borderColor:sv.colors.blue1,marginTop:2,marginBottom:2}}/>
                    <Stack>
                     <Typography sx={{fontWeight:600,color:'gray'}} color='primary'>Technologies</Typography>
                     <ExpandableBox text={data.technologies.join(', ')} maxLength={300} styles={{fontFamily:'system-ui',lineHeight:1.5,marginTop:1}}/>
                    </Stack>
                  </Stack>  
                </Stack> 
              </>}
              
              {tab === 1 &&            
                  <>
                    <Stack direction='row' alignItems='center' sx={{marginTop:2,marginBottom:2}}>
                      <Typography sx={{fontWeight:600,color:'gray',marginRight:1,fontSize:sv.bodyIcon.fontSize}} color='primary'>Value-Add Interest</Typography>
                      <SentimentVeryDissatisfiedIcon onClick={()=>handleValueAddInterest(1)} sx={{cursor:'pointer',fontSize:sv.bodyIcon.fontSize*2}} color={data.value_add_interest === 1 ? "error" : "disabled"}/>
                      <SentimentDissatisfiedIcon onClick={()=>handleValueAddInterest(2)} sx={{cursor:'pointer',fontSize:sv.bodyIcon.fontSize*2}} color={data.value_add_interest === 2 ? "error" : "disabled"}/>
                      <SentimentSatisfiedIcon onClick={()=>handleValueAddInterest(3)} sx={{cursor:'pointer',fontSize:sv.bodyIcon.fontSize*2}} color={data.value_add_interest === 3 ? "warning" : "disabled"}/>
                      <SentimentSatisfiedAltIcon onClick={()=>handleValueAddInterest(4)} sx={{cursor:'pointer',fontSize:sv.bodyIcon.fontSize*2}} color={data.value_add_interest === 4 ? "success" : "disabled"}/>
                      <SentimentVerySatisfiedIcon onClick={()=>handleValueAddInterest(5)} sx={{cursor:'pointer',fontSize:sv.bodyIcon.fontSize*2}} color={data.value_add_interest === 5 ? "success" : "disabled"}/>
                    </Stack>
                    <Stack direction={isMobile() ? "column" : "row"} justifyContent='space-around'>
                      <Paper elevation={1} sx={{padding:1,marginTop:1,width:isMobile() ? '100%' : '45%'}}>
                          <Typography sx={{color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.1,textAlign:'left'}}>Relevant Portcos</Typography>    
                          <List dense={true} sx={{maxHeight:350,overflow:'scroll'}}>
                            {closestCompanies.map((rc,ind)=>
                              <ListItem key={ind}>
                                  <Avatar src={rc.logo} sx={{width:30,height:30}}/>
                                  <ListItemText
                                    primary={rc.name}
                                    secondary={`${rc.score.toFixed(2)}: ${rc.tag_names.join(', ')}`}
                                    sx={{marginLeft:2}}
                                  />
                              </ListItem>
                            )}
                          </List>
                      </Paper>
                      <Paper elevation={1} sx={{padding:1,marginTop:1,width:isMobile() ? '100%' : '45%'}}>
                         <Stack direction='row' justifyContent="space-between" alignItems="center">
                           <Typography sx={{color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.1,textAlign:'left'}}>Similar People</Typography> 
                           <FormControlLabel control={<Checkbox checked={closestOnlyLps} onChange={()=>setClosestOnlyLps(!closestOnlyLps)} />} label=<Typography sx={{ fontSize: 14 }}>LPs Only</Typography> />   
                         </Stack>
                         {closestPeople.length > 0 ?
                           <List dense={true} sx={{maxHeight:344,overflow:'scroll'}}>
                             {closestPeople.slice(0,100).map((rc,ind)=>
                               <ListItem key={ind} secondaryAction=
                                   {rc.email &&
                                   (<IconButton edge="end" onClick={() => window.open(`mailto:${rc.email}`)}>
                                     <EmailOutlinedIcon />
                                   </IconButton>)}
                               >
                                   <ListItemButton onClick={()=>rc.url && window.open(`${rc.url}`)}>
                                     <ListItemIcon>
                                       <LinkedInIcon sx={{color:"0F4EB5"}}/>
                                     </ListItemIcon>
                                     <ListItemText
                                       primary={`${rc.name} – ${rc.kind}`}
                                       secondary={`${rc.score.toFixed(2)}: ${(rc.tag_names || []).join(', ')}`}
                                       sx={{marginLeft:2}}
                                     />
                                   </ListItemButton>
                               </ListItem>
                             )}
                           </List>
                           :
                           <CircularProgress sx={{marginTop:2}} color="inherit" size={20} />
                         }
                      </Paper>
                         
                         
                    </Stack>
                    <Box sx={{height:300}}>
                      <IntroTable rows={introRows} setWarning={setWarning} setRows={setIntroRows} person={data} company={{}}/>
                    </Box>
                  </>
              }
              
              {tab === 2 &&
                <>
                  <Button variant="contained" sx={{margin:1}} disabled={!data.url || !data.profile_text} onClick={()=>setProfileTextOpen(true)}>Show Full Profile Text</Button>
                  <Stack direction={isMobile() ? "column" : "row"} justifyContent="space-between" sx={{width:'100%'}}>
                    <Box sx={{borderRadius:3,backgroundColor:'white',marginTop:1,width:isMobile() ? '100%' : '47%',padding:2}}>
                        <Typography sx={{color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.4}}>Employment</Typography> 
                        {!data.url && <Typography variant="body1" component='div' sx={{textAlign:'center',padding:2,fontStyle:'italic'}} color='primary'>UnstyledLinkedIn profile required</Typography>}
                        <Box sx={{overflow:'auto',height:300}}>
                          {employers.map((e,ind) => (
                            <Box key={ind} sx={{marginTop:3}}>
                              <Typography variant="body1" component='span' sx={{marginLeft:2,fontWeight:'bold',fontSize:sv.bodyText.fontSize*1.1}} color='primary'>{e.company ? <UnstyledLink href={`/company/${e.company.id}`} target="_blank">{e.company?.name || e.institution}</UnstyledLink> : e.company?.name || e.institution}</Typography>
 		                          {e.company &&
                                 <>
                                   <UnstyledLink
 		                                 href={`//${e.company.li_url}`.replace('https://','').replace('http://','')}
 		                                 target="_blank"
 		                               >
 		                                 <LinkedInIcon style={{ fontSize: sv.bodyText.fontSize * 1.4, color:"0F4EB5" }}/>
 		                                </UnstyledLink>
                                    <UnstyledLink
                                      href={`//www.${e.company.website}`}
                                      target="_blank"
                                    >
                                      <Language style={{ fontSize: sv.bodyText.fontSize * 1.4, color:"0F4EB5" }}/>
                                    </UnstyledLink>
                                    <>
                                      <Typography component='div' variant="body2" sx={{paddingLeft:2,paddingRight:2}} color='primary'>Employees: {e.company.current}</Typography>
                                      {e.company.growth_score > 0 &&<Typography component='div' variant="body2" sx={{paddingLeft:2,paddingRight:2}} color='primary'>Growth: {e.company.growth_score} {scoreIcon(e.company.growth_score,'retgrow')}</Typography>}
                                      {(e.company.industries && e.company.technologies.length > 0) &&<Typography component='div' variant="body2" sx={{paddingLeft:2,paddingRight:2}} color='primary'>Industries: {e.company.industries.concat(e.company.technologies).join(', ')}</Typography>}
                                    </>
                                    <Typography variant="body2" sx={{marginTop:1,paddingLeft:2,paddingRight:2,maxWidth:'90%'}} color='primary'>{e.company.clean_description || e.company.description || e.company.harmonic_desc || e.company.li_longdesc}</Typography>
                              </>}
                              {data.employees.filter((x) => (x.company?.name || x.institution) === (e.company?.name || e.institution)).map((x,ind) => (
                               
                               <Box key={ind} sx={{marginLeft:5,maxWidth:'70%',marginTop:2}}>
                                  <CircleIcon color="primary" sx={{fontSize:sv.bodyText.fontSize*0.8}}/>
                                  <Typography variant="body1" component='span' sx={{marginLeft:1,fontWeight:'bold'}} color='primary'>{x.title}</Typography>
                                  {x.startdate && <Typography variant="body1" component='div' sx={{marginLeft:3}} color='primary'>{formatMMDDYYYY(x.startdate)} - {formatMMDDYYYY(x.enddate) || 'Present'}</Typography>}
                                  <Typography variant="body2" component='div' sx={{marginLeft:3}} color='primary'>{x.description}</Typography>
                               </Box>
                              ))}
                            </Box>
                          ))}
                        </Box>
                    </Box>
                    <Box sx={{borderRadius:3,backgroundColor:'white',marginTop:1,width:isMobile() ? '100%' : '47%',padding:2}}>
                        <Typography sx={{color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.4}}>Education</Typography>   
                        {!data.url && <Typography variant="body1" component='div' sx={{textAlign:'center',padding:2,fontStyle:'italic'}} color='primary'>LinkedIn profile required</Typography>}
                        <Box sx={{overflow:'auto',height:300}}>
                          {data.students.map((e,ind) => (
                            <Box key={ind} sx={{marginTop:3}}>
                              <Typography variant="body1" component='span' sx={{marginLeft:2,fontWeight:'bold',fontSize:sv.bodyText.fontSize*1.1}} color='primary'>{e.school?.name || e.institution}</Typography>
                              {data.students.filter((x) => (x.school?.name || x.institution) === (e.school?.name || e.institution)).map((x,ind) => (
                               
                               <Box key={ind} sx={{marginLeft:5,maxWidth:'70%',marginTop:2}}>
                                  <CircleIcon color="primary" sx={{fontSize:sv.bodyText.fontSize*0.8}}/>
                                  <Typography variant="body1" component='span' sx={{marginLeft:1,fontWeight:'bold'}} color='primary'>{x.title}</Typography>
                                  {x.startdate && <Typography variant="body1" component='div' sx={{marginLeft:3}} color='primary'>{formatMMDDYYYY(x.startdate)} - {formatMMDDYYYY(x.enddate) || 'Present'}</Typography>}
                                  <Typography variant="body2" component='div' sx={{marginLeft:3}} color='primary'>{x.description}</Typography>
                               </Box>
                              ))}
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  </Stack>
                </>
              }
        </Grid2>
        <Grid2 xs={isMobile() ? 12 : 4} sx={{backgroundColor:sv.colors.blue4}}>
              <InnerComments style={{borderRadius:3,paddingTop:2,backgroundColor:'white',height:'99%'}} setWarning={setWarning} person={data} comments={comments} setComments={setComments}/>
        </Grid2>
      </Grid2>
        <AddEditContact setWarning={setWarning} open={showAddEditContact} close={handleContactClose} contact={data} person={data} data={data} setContact={setData}/>
        <Dialog fullWidth={true} maxWidth={'lg'} onClose={()=>setProfileTextOpen(false)} open={profileTextOpen}>
          <DialogTitle>{data.name} Profile</DialogTitle>
          <DialogContent>{ReactHtmlParser(data.profile_text.replace(/\n/g, "<br>"))}</DialogContent>
        </Dialog>
      </>
      }
      <UploadDialog files={files} setFiles={setFiles} showFileUpload={showFileUpload} setShowFileUpload={setShowFileUpload} />
    </>
      

      
  );
}