import Growth from '../components/Growth';
import Login from '../components/Login';
import LinkedIn from '../components/LinkedIn';
import Company from '../components/Company';
import Person from '../components/Person';
import Investor from '../components/Investor';
import ContactList from '../components/ContactList'
import SecondDegreeContacts from '../components/SecondDegreeContacts'
import FullEmployeeList from '../components/FullEmployeeList'
import VCList from '../components/VCList'
import Scorecard from '../components/Scorecard'
import Stats from '../components/Stats'
import Connector from '../components/Connector'
import NewsFeed from '../components/NewsFeed'
import Profile from '../components/Profile'
import Docgen from '../components/Docgen'
import OutreachFlow from '../components/OutreachFlow'
import Messaging from '../components/Messaging'
import OutreachSender from '../components/OutreachSender'
import LiFinder from '../components/LiFinder'
import AutoOutreach from '../components/AutoOutreach'
import CompanyConnections from '../components/CompanyConnections'
import Tasks from '../components/Tasks'
import EmailFinder from '../components/EmailFinder'
import LimitedPartner from '../components/LimitedPartner'
import PasswordReset from '../components/PasswordReset'
// import OutreachTasks from '../components/OutreachTasks'

const routes = [

  // LOGGED OUT ROUTES
  {
    path:'/connect',
    component: LinkedIn
  },
  {
    path:'/login',
    component: Login
  },
  {
    path:'/password-reset-confirm/:uid/:token',
    component: PasswordReset
  },
  // LOGGED IN ROUTES
  
  {
    path:'/companies',
    component: Growth,
    isProtected: true
  },
  {
    path:'/company/:id',
    component: Company,
    isProtected: true
  },
  {
    path:'/company/:id/connections',
    component: CompanyConnections,
    isProtected: true
  },
  {
    path:'/person/:id/connections',
    component: CompanyConnections,
    isProtected: true
  },
  {
    path:'/person/:id',
    component: Person,
    isProtected: true
  },
  // {
  //   path:'/outreach',
  //   component: OutreachTasks,
  //   isProtected: true
  // },
  {
    path:'/investor/:id',
    component: Investor,
    isProtected: true
  },
  {
    path:'/vcs/edit',
    component: VCList,
    isProtected: true
  },
  {
    path:'/contacts/list',
    component: ContactList,
    isProtected: true,
  },
  {
    path:'/people',
    component: FullEmployeeList,
    isProtected: true,
  },
  {
    path:'/contacts/seconddegree',
    component: SecondDegreeContacts,
    isProtected: true,
  },
  {
    path:'/scorecard/:id',
    component: Scorecard,
    isProtected: true
  },
  {
    path:'/stats',
    component: Stats,
    isProtected: true
  },
  {
    path:'/connector',
    component: Connector,
    isProtected: true
  },
  {
    path:'/news',
    component: NewsFeed,
    isProtected: true
  },
  {
    path:'/profile',
    component: Profile,
    isProtected: true
  },
  {
    path:'/docgen',
    component: Docgen,
    isProtected: true
  },
  {
    path:'/outreach',
    component: OutreachFlow,
    isProtected: true
  },
  {
    path:'/email_lookup',
    component: EmailFinder,
    isProtected: true
  },
  {
    path:'/lp_search',
    component: LimitedPartner,
    isProtected: true
  },
  {
    path:'/messaging',
    component: Messaging,
    isProtected: true
  },
  {
    path:'/lead_gen',
    component: OutreachSender,
    isProtected: true
  },
  {
    path:'/li_outreach',
    component: AutoOutreach,
    isProtected: true
  },
  {
    path:'/tasks',
    component: Tasks,
    isProtected: true
  },
  {
    path:'/finder',
    component: LiFinder,
    isProtected: true
  },
  {
    path:'*',
    component: NewsFeed,
    isProtected: true
  }
]

export default routes
