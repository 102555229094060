import React, {useEffect,useState} from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { authenticatedFetch } from '../utils/auth';
import { DataGridPro,GridToolbarContainer } from '@mui/x-data-grid-pro';
import {formatMMDDYYYY} from '../utils/tools';
import {randomId} from '@mui/x-data-grid-generator';
import AddIcon from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import SearchBox from './SearchBox'

export default function AddEditContact({contact,type,setContact}) {
  const [rows, setRows] = useState([])
    
  useEffect(()=>{
	if ( Object.keys(contact).length !== 0){
		setRows(contact[type])
	}
  },[contact,type])
  
  const empDelete = (id,type) => {
    const url = `/companies/api/person/${type}/history/delete`
    async function doDelete() {
      let res = await authenticatedFetch(url, {
              method: 'POST',
		  	      body: JSON.stringify({
		  	  	  emp: id,
				      person: contact.id
		  	  })
      })
	  setRows(res[type])
    setContact && setContact(res)
    }
    doDelete()
	
  }
	
	const handleCellEditCommit = (params) => {
		async function doUpdate(){
			let res = await authenticatedFetch(`/companies/api/person/${type}/history/edit`, {
				method: 'POST',
				body: JSON.stringify({
				  id: String(params.id),
				  val: params.value,
          field: params.field,
			   	person:contact.id
				 	})
			 })
		   setRows(res[type])
       setContact && setContact(res)
				  	}
		doUpdate()
	}

    function institutionCell(params) {
      return <InstitutionCell {...params} />;
    }
  
    const InstitutionCell = (props) => {
      const { id, api, field } = props;
	    const [search,setSearch] = useState(null)
	    const [inputValue,setInputValue] = useState(null)
      const doChange = (e,nv) => {
         if (nv){
	          const editProps = {
	            value: nv.id
	          }
         	  setSearch(null)
			      setInputValue('')
            
			      api.setCellMode(id, field, "edit");
			      api.setEditRowsModel({[id]:{[field]:editProps}})
            
			      api.commitCellChange({id:id,field:field})
			      api.setCellMode(id, field, "view");
		     }
	  }	


      return (
       <SearchBox
		 fullWidth
         value={search}
         setValue={setSearch}
         doChange={doChange}
         inputValue={inputValue}
         setInputValue={setInputValue}
         searchText={"Search"}
		 url={type === 'employees' ? `/companies/api/search/companies` : `/companies/api/search/schools`}
       />
      );  
    }
	  
	  
  
  const cols = [
    { headerName: type === 'employees' ? 'Company' : 'School', editable:true,renderEditCell: institutionCell, field: 'institution',flex:1},
    { headerName: type === 'employees' ? 'Title' : 'Degree' , editable:true, field: 'title',flex:1},
    { headerName: 'Start', editable:true, type:'date', field: 'startdate',flex:0.7,valueFormatter: (params) => formatMMDDYYYY(params.value)},
    { headerName: 'End', editable:true, type:'date', field: 'enddate',flex:0.7,valueFormatter: (params) => formatMMDDYYYY(params.value)},
    { headerName: 'Location', editable:true, field: 'location',flex:1},
	{ headerName: '', editable:false, field: '', sortable:false, filterable:false, renderCell: (p) => ( 
        <IconButton
          edge="end"
          style={{marginLeft:20}}
          onClick={()=>empDelete(p.id,type)}
          size="large">
          <Delete style={{color:'#a11b13'}} />
        </IconButton>)}	
  ]
  
function AddToolbar(props) {

  const handleClick = () => {
    const id = randomId();
    let newRows = [{'institution':'','title':'','stardate':'','enddate':'','location':'','id':id}].concat([...rows])
	setRows(newRows)
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        New Row
      </Button>
    </GridToolbarContainer>
  );
}

  return (
		<Box style={{width:'100%',height:'500px'}}>
        	<DataGridPro
        	  columns={cols}
        	  rows={rows}
        	  density="compact"
			  onCellEditCommit={handleCellEditCommit}			  
			  components={{Toolbar: AddToolbar,}}           
        	/>
		</Box>
    )
  }
