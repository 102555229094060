import React, { useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import Header from './Header'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import ConnectorCompaniesStep from './ConnectorCompaniesStep'
import ConnectorContactStep from './ConnectorContactStep'
import ConnectorFinalStep from './ConnectorFinalStep'
import Grid from '@mui/material/Grid';
import { authenticatedFetch } from '../utils/auth';
import mvp from '../assets/mvp.png';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { BoldText } from '../constants/StyleComponents';

const HeaderImg = styled('img')({
  width:200,
  margin:30,
})

const ErrorText = styled(Text)({
  color:'red',
  fontStyle:'italic',
})

export default function Connector({history}) {
  
  const [activeStep, setActiveStep] = React.useState(0)
  const [companyList,setCompanyList] = useState([])
  const [contactList,setContactList] = useState([])
  const [companySelected,setCompanySelected] = useState([])
  const [contactSelected,setContactSelected] = useState([])
  const [finalized, setFinalized] = useState(false)
  const [saved, setSaved] = useState([])
  const [anchorEl,setAnchorEl] = useState(null)
  const [saveOpen,setSaveOpen] = useState(false)
  const { register, handleSubmit, errors } = useForm();
  const [warning,setWarning] = useState({text:'',severity:''})
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(true)
  const steps = ['Companies','Contacts','Finalize']
  const [allContacts, setAllContacts] = useState([])
  const [savedSearchId, setSavedSearchId] = useState(null)
  const [savedSearchName, setSavedSearchName] = useState('')

  async function getSaved() {
    let url = `/companies/api/connectors`
    let res = await authenticatedFetch(url, {method: 'GET'})
    setSaved(res)
  }

  useEffect(() => {
    getSaved()
  }, []);
  
  const saveClose = () => {
    setSaveOpen(false)
  }
  
  const doSave = (inp) => {
    async function save() {
      let res = await authenticatedFetch(`/companies/api/connectors/create`, {
        method: 'POST', 
        body: JSON.stringify({
          name: inp.name,
          data: JSON.stringify({
            companyList:companyList,
            contactList:contactList,
            companySelected:companySelected,
            contactSelected:contactSelected
          })
        })
      })
	  setSavedSearchId(res.id)
	  setSavedSearchName(res.name)
      saveClose()
      getSaved()
    }
    save()
  }
  
  const doSaveReplace = () => {
    async function save() {
      let res = await authenticatedFetch(`/companies/api/connectors/${savedSearchId}`, {
        method: 'PATCH', 
        body: JSON.stringify({
          data: JSON.stringify({
            companyList:companyList,
            contactList:contactList,
            companySelected:companySelected,
            contactSelected:contactSelected
          })
        })
      })
      saveClose()
      getSaved()
	  setSavedSearchName(res.name)
    }
	setSavedSearchName('...saving...')
    save()
  }
  
  const handleSavedClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
  
  const handleSavedDelete = (cid) => {
    async function deleteSaved() {
      let url = `/companies/api/connectors/${cid}/delete`
      let res = await authenticatedFetch(url, {method: 'POST'})
      setSaved(res)
    }
    deleteSaved()    
  }
  
  const handleSave = () => {
	  if (savedSearchId){
	  	doSaveReplace()
	  }
	  else {
	    setSaveOpen(true)
	 }
  }
  
  const handleSaveAs = () => { 
	  setSaveOpen(true)
  }
  
  const handleSavedClose = (event) => {
    if (event.target.id){
      let save = saved.filter(function(x) {return x.id===Number(event.target.id)})[0]
      let data = JSON.parse(save.data)
      setCompanyList(data.companyList)
      setContactList(data.contactList)
	  setAllContacts(data.contactList)
      setCompanySelected(data.companySelected)
	  setContactSelected(data.contactSelected)
	  setSavedSearchId(Number(event.target.id))
	  setSavedSearchName(save.name)
    }
    setAnchorEl(null);
  };
  
  const handleNext = () => {
    if (activeStep === 0){
      if (companySelected.length === 0){
        setWarning("Must select at least one company first")
        return
      }
      if (companySelected.length === 0){
        setContactList([])
      } else{
		setLoading(true)
        async function get() {
          let custom = contactList.filter((x)=> x.custom === true)
          const res = await authenticatedFetch(`/companies/api/getcontacts`, {
            method: 'POST', 
            body: JSON.stringify({
              companies: companySelected
            })
          })
          setContactList(res.concat(custom))
		      setAllContacts(res.concat(custom))
		      setLoading(false)
        }
        get()
      }
    }
    if (activeStep === 1){
      if (contactSelected.length === 0){
        setWarning("Must select at least one contact first")
        return
      }
    }
    setActiveStep((prevActiveStep) => Math.min(prevActiveStep + 1,steps.length-1))
    if (activeStep === steps.length - 1){
      setFinalized(true)
    }

  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setWarning('')
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <ConnectorCompaniesStep 
                  companyList={companyList} 
                  setCompanyList={setCompanyList}
                  companySelected={companySelected}
                  setCompanySelected={setCompanySelected}
                />
      case 1:
        return <ConnectorContactStep 
          contactList={contactList} 
          setContactList={setContactList}
          contactSelected={contactSelected}
          setContactSelected={setContactSelected}
		  loading={loading}
		  showAll={showAll}
		  allContacts={allContacts}
        />
      case 2:
        return <ConnectorFinalStep 
          contactList={contactList} 
          contactSelected={contactSelected}
          setContactList={setContactList}
          setContactSelected={setContactSelected}
          finalized={finalized}
        />
      default:
        return 'Unknown stepIndex';
    }
  }
  
  return <>
  {!finalized ?
    <Header 
      history={history}
      name={'Connector'}
      warning={warning}
      setWarning={setWarning}
    />
      :
    <HeaderImg src={mvp} alt="MVP Ventures" onClick={()=>setFinalized(false)}/>
  }
    <Container disableGutters={true} maxWidth="xl" style={{paddingTop:10}}>
    {!finalized &&
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={8}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            style={{marginRight:5}}
          >
            Back
          </Button>
          <Button variant="contained" size='small' color="primary" onClick={handleNext} style={{marginRight:25}}>
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
          <Button variant="contained" color="secondary" size='small' onClick={handleSave} style={{marginRight:5}}>
            Save
          </Button>
          {savedSearchId && 
			  <Button variant="contained" size='small' color="secondary" onClick={handleSaveAs} style={{marginRight:5}}>
            		Save As
          	   </Button>
		  	}
          <Button onClick={handleSavedClick} color="secondary" size="small">
            Saved ▾
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleSavedClose}
            anchorOrigin={{vertical: "bottom",horizontal: "center",}}
            transformOrigin={{vertical: "top",horizontal: "center",}}
          >
            <List>
          {saved.map((val,ind) => 
            <MenuItem style={{width:'100%', minWidth:150}} id={val.id} key={ind} onClick={handleSavedClose}>
            {val.name}
              <ListItemSecondaryAction >
                  <IconButton
                    edge="end"
                    style={{marginLeft:20}}
                    onClick={()=>handleSavedDelete(val.id)}
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </MenuItem>
          )}
          </List>
          </Menu>
		  {activeStep === 1 && <FormControlLabel style={{marginLeft:10}} control={<Switch size="small" checked={showAll} onChange={()=>setShowAll(!showAll)}/>} label="Show All" />}
        </Grid>
        <Grid item xs={4}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
      }
	  {savedSearchId &&
	  <Box style={{textAlign:'center',margin:20}}>
	  	<BoldText component='span'>Saved Filter: {savedSearchName}</BoldText>
	  </Box>
	  }
      <Box>
        {getStepContent(activeStep)}
      </Box>
    </Container>
      <Dialog open={saveOpen} onClose={saveClose}>
        <form onSubmit={handleSubmit(doSave)}>   
          <DialogTitle>Save Connections</DialogTitle>
          <DialogContent>
              <TextField
                inputRef={register({required: true})}
                name="name"
                label="Name"
                fullWidth
                defaultValue={""}
              />
              {errors.name && <ErrorText>This field is required</ErrorText>}
          </DialogContent>
          <DialogActions>
            <Button onClick={saveClose} color="primary">
              Cancel
            </Button>
            <Button type='submit' color="primary">
              Save
            </Button>
          </DialogActions>
        </form>       
      </Dialog>
  </>;
}