import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";
import { Text, Section } from '../constants/StyleComponents';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { authenticatedFetch } from '../utils/auth';

const ErrorText = styled(Text)({
  color:'red',
  fontStyle:'italic',
})

export default function AddEditCompany({open,close,company,setCompany,setWarning}) {
  const { register, handleSubmit, errors } = useForm();
  
  const onSubmit = (form) => {
    close()
    let method = company['id'] ? 'PATCH' : 'POST'
    let url = company['id'] ? `/companies/api/company/${company['id']}/update` : `/companies/api/company`
    async function saveCompany() {
      setWarning({text:"Saving...",severity:'info'})
      let res = await authenticatedFetch(url, {
              method: method,
              body: JSON.stringify({
                name: form['name'],
                website: form['website'],
                li_url: form['li_url'],
                tw_url: form['tw_url'],
                pitchbook_id: form['pitchbook_id'],
                harmonic_id:form['harmonic_id'] || null,
                cbi_profile_url:form['cbi_profile_url'] || '',
                referrer:form['referrer']
              })
      })
      if (res['error']){
        setWarning({text:res['error'],severity:'error'})
      } else {
         setWarning({text:"Saved!",severity:'success'})
      }
    }
    if (setCompany){
      setCompany({id:Number(company['id']),name:form['name'],website:form['website'],li_url:form['li_url'],savable:true,tw_url:form['tw_url'],pitchbook_id:form['pitchbook_id'],harmonic_id:form['harmonic_id'],referrer:form['referrer'],cbi_profile_url:form['cbi_profile_url']})   
    }
    saveCompany()
  }

  return (
    <Dialog 
      open={open}
      onClose={close}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <form onSubmit={handleSubmit(onSubmit)}>   
        <DialogTitle>{company['name'] ? "Edit" : "Add"} Company</DialogTitle>
        <DialogContent>
          <DialogContentText>
      {company['name'] ? 
      "Update name, website, and data provider ids." :
      "Create a new company. All fields all required and LinkedIn URL must be a /company/ URL"
      }
          </DialogContentText>
                  <Section>
                    <TextField
                      inputRef={register({required: true})}
                      name="name"
                      label="Company Name"
                      fullWidth
                      defaultValue={company['name']}
                    />
                    {errors.name && <ErrorText>This field is required</ErrorText>}
                  </Section>
                  <Section>
                    <TextField
                      inputRef={register({required: true})}
                      name="website"
                      label="Company Website"
                      fullWidth
                      defaultValue={company['website']}
                    />
                    {errors.website && <ErrorText>This field is required</ErrorText>}
                  </Section>
                  <Section>
                    <TextField
                      inputRef={register({pattern:/\/company\//})}
                      name="li_url"
                      label="LinkedIn URL"
                      fullWidth
                      defaultValue={company['li_url']}
                    />
                      {errors.li_url && <ErrorText>Hmm that does not look like a valid LinkedIn URL</ErrorText>}
                  </Section>
                  <Section>
                    <TextField
                      inputRef={register()}
                      name="tw_url"
                      label="Twitter Link"
                      fullWidth
                      defaultValue={company['tw_url']}
                    />
                  </Section>
                  <Section>
                    <TextField
                      inputRef={register()}
                      name="pitchbook_id"
                      label="Pitchbook ID"
                      fullWidth
                      defaultValue={company['pitchbook_id']}
                    />
                  </Section>
                  <Section>
                    <TextField
                      inputRef={register()}
                      name="harmonic_id"
                      label="Harmonic ID"
                      fullWidth
                      defaultValue={company['harmonic_id']}
                    />
                  </Section>
                  <Section>
                    <TextField
                      inputRef={register()}
                      name="cbi_profile_url"
                      label="CB Insights ID"
                      fullWidth
                      defaultValue={company['cbi_profile_url']}
                    />
                  </Section>
                  <Section>
                    <TextField
                      inputRef={register()}
                      name="referrer"
                      label="Referrer"
                      fullWidth
                      defaultValue={company['referrer']}
                    />
                  </Section>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button type='submit' color="primary">
            Save
          </Button>
        </DialogActions>
      </form>       
    </Dialog>
  );
  }
