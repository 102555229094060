import React, { useState, useEffect } from 'react';
import TableBase from './TableBase';
import { authenticatedFetch } from '../utils/auth';
import Checkbox from '@mui/material/Checkbox';

export default function VCList({history,path,name}) {
  const [data, setData] = useState([])
  const [cols, setCols] = useState([])
  const [nobj, setNobj] = useState({})
	
  function handleCheck(row,field){
    let val = row[field]

    setNobj({[field]:!val,'id':row['id']})

    async function fetchData() {
      await authenticatedFetch(`/companies/api/investors/update/${row.id}`, {
        method: 'PATCH',
        body: JSON.stringify({
          [field]: !val
        })
      })
    }
    fetchData()
  }

  useEffect(() => {
    if (nobj['id']){
      let obj = data.filter((x) => x.id === nobj['id'])[0]
      let objIndex = data.indexOf(obj)
      let replacementObj = {...obj,...nobj}
      let newDataArray = [...data]
      newDataArray[objIndex] = replacementObj
      setData(newDataArray)
    }
  // eslint-disable-next-line
  },[nobj])

  const columns = 
  [    
    {headerName: 'Kind', field:'kind',hide:true,type:'singleSelect',valueOptions:['Preferred Firm','Midas Investor','All']},
    { headerName: 'Name', field: 'name', width:180 },
      { headerName: 'Preferred Firm', description:'Whether investor is MVP Preferred Firm',field: 'preferred',width:150,
      renderCell: (params: ValueFormatterParams) => (
      <Checkbox
        checked={params.row.preferred}
        onChange={()=>handleCheck(params.row,'preferred')}
        name={`${params.row.id}`}
        color="secondary"
      />
      )}, 
    { headerName: 'Midas Investor', description:"Whether investor is MVP Preferred individual investor",field: 'midas',width:150,renderCell: (params: ValueFormatterParams) => (
      <Checkbox
        checked={params.row.midas}
        onChange={()=>handleCheck(params.row,'midas')}
        name={`${params.row.id}`}
        color="secondary"
      />
      )},
      { headerName: 'Score', description:"Point score for investor",field: 'score', type:'number', width:180 },
      { headerName: 'Rank', description:"Investor rank",field: 'rank', type:'number', width:180 },
  ]
  
  if (cols.length === 0){
    setCols(columns)
  }
    
  return (
      <>
        <TableBase
          data={data}
          setData={setData}
          history={history}
          path={'investors'}
          columns={cols}
          defaultColumns={cols}
          setColumns={setCols}
          name={'Investor Lists'}
          type={"VCs"}
          defaultFilter={{"items":[{"id":1,"columnField":"preferred","operatorValue":"is","value":"true"}],"linkOperator":"and"}}
        />
      </>
  );
}