import React from 'react';
import CompanyTable from './CompanyTable';

export default function Growth({history}) {

  return (
    <CompanyTable
      history={history}
      path="growth"
      name="Companies"
    />
  );
}