import React, { useEffect,useState } from "react";
import { styled } from '@mui/material/styles';
import { DragDropContext } from "react-beautiful-dnd";
import TaskListDraggable from "./TaskListDraggable";
import Container from '@mui/material/Container'
import Header from './Header'
import { authenticatedFetch } from '../utils/auth'
import AddEditIntro from './AddEditIntro.js'
import { IntroStatuses } from '../constants/Constants'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import sv from '../constants/Styles';
import Select from '@mui/material/Select';
import {getUsers} from '../utils/tools';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

const ListGrid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
  gridGap: '4px'
})

const removeFromList = (list, index) => {
  const result = Array.from(list);
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

const addToList = (list, index, element) => {
  const result = Array.from(list || []);
  result.splice(index, 0, element);
  return result;
};

export default function Tasks({history}) {
  const [warning,setWarning] = useState({text:'',severity:''})
  const [data, setData] = useState([])
  const [showEditIntro, setShowEditIntro] = useState(false)
  const [intro, setIntro] = useState({})
  const [tasks, setTasks] = useState({})
  const [users, setUsers] = useState([])
  const [owner, setOwner] = useState('Show All')
  const [companySearch, setCompanySearch] = useState('')
  const [personSearch, setPersonSearch] = useState('')
  
  const hideEditIntro = () => {
    setShowEditIntro(false)
  }
  
  async function updateRank(status,index,intro) {
    let obj = {...intro,...{rank:index,status:status,source:'allTasks'}}
    
    await authenticatedFetch(`/companies/api/intros/edit`, {
        method: 'POST',
        body: JSON.stringify(obj)
    })
  }

  useEffect(()=>{
    async function getData(){
      let url = `/companies/api/intros?owner=${owner}`
      let res = await authenticatedFetch(url, {method: 'GET'})
      setData(res.results)
    }
    getData()
    
  },[owner])

  useEffect(()=>{
    getUsers().then((v) => setUsers(v))  
  },[])
  
  useEffect(()=>{
    setTasks(
      data.filter(
        (x)=>(x.company?.name || '').toLowerCase().includes(companySearch.toLowerCase()))
        .filter((x)=>(x.person?.name || '').toLowerCase().includes(personSearch.toLowerCase()))
        .reduce((r,i) => {
        // i.status = i.status.toLowerCase().includes('success') ? 'Success': i.status
        r[i.status] = r[i.status] || []
        r[i.status].push(i)
        return r
      },Object.create(null))
    )
  },[data,companySearch,personSearch])

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    
    const listCopy = { ...tasks };

    const sourceList = listCopy[result.source.droppableId];
    const [removedElement, newSourceList] = removeFromList(
      sourceList,
      result.source.index
    );

    updateRank(result.destination.droppableId,result.destination.index,removedElement)
    
    listCopy[result.source.droppableId] = newSourceList;
    const destinationList = listCopy[result.destination.droppableId];
    listCopy[result.destination.droppableId] = addToList(
      destinationList,
      result.destination.index,
      removedElement
    );

    setTasks(listCopy);
  };

  return (
    <>
        <Header 
          history={history}
          name={'Tasks'}
          warning={warning}
          setWarning={setWarning}
        />
        <Container disableGutters={true} maxWidth="xl" style={{paddingTop:10}}>
          
          <Stack direction="row">
              <FormControl variant="standard" size="small" sx={{width:150,marginBottom:2}}>
                <InputLabel>Owner</InputLabel>
                  {users.length > 0 &&
                  <Select
                    value={owner}
                    onChange={(e)=>setOwner(e.target.value)}
                  >
                <MenuItem key={'All'} value="Show All" style={{fontSize: sv.bodyIcon.fontSize*0.8}}>Show All</MenuItem>
                <MenuItem key={'Me'} value="Mine Only" style={{fontSize: sv.bodyIcon.fontSize*0.8}}>Mine Only</MenuItem>
                    {users.map((val,ind) => 
                      <MenuItem key={ind} value={val} style={{fontSize: sv.bodyIcon.fontSize*0.8}}>{val}</MenuItem>
                    )}
                    </Select>
                  }
              </FormControl>
              <TextField
                variant="outlined" 
                size="small"
                sx={{marginLeft:2,marginTop:0.5}}
                placeholder='Company filter...'
                value={companySearch}
                onChange={(e)=>setCompanySearch(e.target.value)}
              />
              <TextField
                variant="outlined" 
                size="small"
                sx={{marginLeft:2,marginTop:0.5}}
                placeholder='Person filter...'
                value={personSearch}
                onChange={(e)=>setPersonSearch(e.target.value)}
              />
          </Stack>     
          
           <DragDropContext onDragEnd={onDragEnd}>
             <ListGrid>
               {IntroStatuses.map((listKey) => (
                 <TaskListDraggable
                   elements={tasks[listKey] || []}
                   key={listKey}
                   prefix={listKey}
                   setIntro={setIntro}
                   setShowEditIntro={setShowEditIntro}
                 />
               ))}
             </ListGrid>
           </DragDropContext>
           <AddEditIntro setWarning={setWarning} open={showEditIntro} close={hideEditIntro} intro={intro} setData={setData}/>						   
        </Container>
    
      </>
  );
}