import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";
import { Section } from '../constants/StyleComponents';
import TextField from '@mui/material/TextField';
import { authenticatedFetch } from '../utils/auth';

export default function AddEditLimitedPartner({open,close,selectedLp,setWarning,batch}) {
  const { register, handleSubmit } = useForm();
  const onSubmit = (form) => {
    console.log("FORM",form)
    close()
    let url = `/companies/api/lp_search/${batch}/pitchbook`
    async function saveLp() {
      setWarning({text:"Saving...",severity:'info'})
      let res = await authenticatedFetch(url, {
              method: 'POST',
              body: JSON.stringify({
                lp_id: selectedLp.id,
                pb_id: form['pitchbook_id'],
              })
      })
      setWarning({text:"Saved",severity:'success'})
      console.log("RES",res)
    }
    saveLp()
  }

  return (
    <Dialog 
      open={open}
      onClose={close}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <form onSubmit={handleSubmit(onSubmit)}>   
        <DialogTitle>{selectedLp?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
                  <Section>
                    <TextField
                      inputRef={register()}
                      name="pitchbook_id"
                      label="Pitchbook ID"
                      fullWidth
                      defaultValue={selectedLp?.pitchbook_lp_id||selectedLp?.investor?.investor_pitchbook_id}
                    />
                  </Section>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button type='submit' color="primary">
            Save
          </Button>
        </DialogActions>
      </form>       
    </Dialog>
  );
  }
