import React, { useState, useEffect } from 'react';
import TableBase from './TableBase';
import sv from '../constants/Styles';
import { styled } from '@mui/material/styles';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Tooltip,{tooltipClasses} from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import AddEditContact from './AddEditContact.js'
import {formatMMDDYYYY,industries,technologies,tags,fellows,money,formatYYYY} from '../utils/tools'
import { authenticatedFetch } from '../utils/auth';
import { useGridApiRef,  GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";
import {multiInputOperators,dateOperators,companySearch,stringOperators,tagOperators} from './helpers/Filters'
import {listOperators} from './helpers/Filters'
import hubspot from '../assets/hubspot.png'

const useStyles = makeStyles((theme) => ({
 root: {
   '& .editable': {
      backgroundColor: 'rgba(245, 245, 245)',
    },
 }
}));

const Link = styled('a')({
  marginLeft: 5,
  textDecoration: null,
  fontSize: sv.bodyText.fontSize,
  cursor:'pointer',
})

const Tip = styled('li')({
  fontSize: sv.bodyText.fontSize*0.8,
  marginBottom:10,
  lineHeight: 1
})

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '600px',
  },
});


// DUPLICATED FROM COMPANYTABLE, REFACTOR
function renderMultiList(params){
	return <Tooltip placement='top-start' title={(params.value||[]).join(', ')}><Box>{(params.value||[]).join(', ')}</Box></Tooltip>
}

export default function ContactList({history,path,name}) {
  const classes = useStyles();
  const [contact, setContact] = useState({})
  const [showAddEditContact, setShowAddEditContact] = useState(false)
  const [data, setData] = useState([])
  const [fellowList, setFellowList] = useState([])
  const apiRef = useGridApiRef();
  const [industryList, setIndustryList] = useState([])
  const [technologyList, setTechnologyList] = useState([])
  const [tagList, setTagList] = useState([])
  const [warning,setWarning] = useState({text:'',severity:''})
  
  const currentVintageYear = new Date().getMonth() < 9 ? new Date().getFullYear() : new Date().getFullYear() + 1
  
  useEffect(()=>{
	  industries().then((v) => setIndustryList(JSON.parse(v)))
    technologies().then((v) => setTechnologyList(JSON.parse(v)))
	  tags().then((v) => setTagList(JSON.parse(v)))
    fellows().then((v) => setFellowList(JSON.parse(v)))
    
    const params = (new URLSearchParams(window.location.search))
    if (params.get('add_url')){
      setContact({
        'name':params.get('add_name'),
        'url':params.get('add_url'),
        'kind':params.get('add_type')
      })
      setShowAddEditContact(true)
    }
  },[])

  const handleCellEditCommit = (params) => {
	let val = params.value === 'None' ? null : params.value
     async function updateData() {
       await authenticatedFetch(`/companies/api/contacts/update/${params.id}`, {
         method: 'PATCH', 
         body: JSON.stringify({
           [params.field]: val
         })
       })
     }
     updateData()	 
  }

  useEffect(() => {
    if (contact.id && contact.savable === true) {
      contact.savable = false
      let obj = data.filter((x) => x.id === contact.id)[0]
      let objIndex = data.indexOf(obj)
      let replacementObj = {...obj,...contact}
      let newDataArray = [...data]
      newDataArray[objIndex] = replacementObj
      setData(newDataArray)
   }
  },[contact,data])

  const hideAddEditContact = () => {
    setShowAddEditContact(false)
  }
  
  const handleContact = (params) => {
    setContact(params)
    setShowAddEditContact(true)
  }
  
  const formatHistoryItem = (e) => {
    if (e){
      return e.institution+", "+e.title+" ("+formatYYYY(e.startdate)+"-"+formatYYYY(e.enddate)+")"
    }
  }
  const columns = 
  [
    { headerName: 'Name', field: 'name', width:180, renderCell: (params) => (
      <span>
        <Link href={`/person/${params.row.id}`} target="_blank">{params.row.name}</Link>
        <Link onClick={()=>handleContact(params.row)} style={{fontStyle: params.row.url ? 'normal' : 'italic'}}><EditOutlinedIcon style={{fontSize: sv.bodyText.fontSize * 0.9, marginLeft: 5}} /></Link>
      {params.row.url && 
      <Link
        href={`//${params.row.url}`.replace('https://','').replace('http://','')}
        target="_blank"
      >
        <LinkedInIcon 
          style={{ fontSize: sv.bodyText.fontSize, color:"0F4EB5", marginLeft:2}}
        />
      </Link>
      }
      {params.row.hubspot_id && <Link sx={{marginLeft:1}} target="_blank" href={`https://app.hubspot.com/contacts/8426488/contact/${params.row.hubspot_id}`}><img style={{width:15}} src={hubspot} alt="Hubspot"/></Link>}
      
      </span>
    ) },
    { headerName: 'Kind', field: 'kind',width:155,valueGetter: (p) => (p.value || ''),filterOperators:multiInputOperators(['Candidate','Portco CEO','Champ Lead','Champ LP','MVP Lead','MVP LP','MVP Team','Value-Add'])},
    { headerName: 'Tags', description:'Person tags',field: 'tags',filterOperators:multiInputOperators(tagList),renderCell: renderMultiList,width:150},
    { headerName: 'Roles', field: 'employees', width:200,filterable:false,sortable:false,valueFormatter:({ value }) => value.map(e=>`${e.title} at ${e.institution}`),renderCell:(params)=>(
      <NoMaxWidthTooltip 
        classes={{ tooltip: classes.customWidth }}
        title={(<ul>{params.row.employees.map((e,i)=><Tip key={i} sx={{fontWeight:e.enddate ? 200 : 800}}>{formatHistoryItem(e)}</Tip>)}</ul>)}
      >
        <Box>{params.value && params.value[0] && params.value[0].institution+", "+params.value[0].title}</Box>
      </NoMaxWidthTooltip>
    )},
   { headerName: 'Education', field: 'students', sortable:false, width:200,renderCell:(params)=>(
     <NoMaxWidthTooltip 
       classes={{ tooltip: classes.customWidth }}
       title={(<ul>{params.row.students.map((e,i)=><Tip key={i}>{formatHistoryItem(e)}</Tip>)}</ul>)}
     >
       <Box>{params.value && params.value[0] && params.value[0].institution+", "+params.value[0].title}</Box>
     </NoMaxWidthTooltip>
   )},
   { headerName: 'Location', field: 'location'},
   { headerName: 'Industries', description:'Industry tags',field: 'industries',filterOperators:multiInputOperators(industryList),renderCell: renderMultiList,width:150},
   { headerName: 'Technologies', description:'Technology tags',field: 'technologies',filterOperators:multiInputOperators(technologyList),renderCell: renderMultiList,width:150},
   { headerName: 'Company Tags', description:'All company tags tags',field: 'company_tags',renderCell: renderMultiList,filterOperators:stringOperators.filter((op) => op.value === "contains").concat(tagOperators),width:150},
   { headerName: 'Value-Add Interest', description:'Willingness to help with value-add',field: 'value_add_interest',cellClassName: 'editable',type:'singleSelect',valueOptions:[1,2,3,4,5],valueGetter: (p) => (p.value || ''),editable: true,width:150},
    { headerName: 'Value-Add Summary', field: 'expertise',width:200,renderCell:(params)=>(
      <NoMaxWidthTooltip 
        classes={{ tooltip: classes.customWidth }}
        title={params.value}
      >
        <Box>{params.value}</Box>
      </NoMaxWidthTooltip>
    )},
   { headerName: 'Next Activity Date',field: 'next_activity_date',type:'date',filterOperators: dateOperators,width:150,valueFormatter: (params) => formatMMDDYYYY(params.value)},
   { headerName: 'Next Step',field: 'next_step',width:150,renderCell:(params)=>(
      <NoMaxWidthTooltip 
        classes={{ tooltip: classes.customWidth }}
        title={params.value}
      >
        <Box>{params.value}</Box>
      </NoMaxWidthTooltip>
    )},
    { headerName: 'Deals',field: 'deals',width:150,sortable:false,renderCell:(params)=>(
      <NoMaxWidthTooltip 
        classes={{ tooltip: classes.customWidth }}
        title={params.value.map((d,i)=>`${d.name} (${money(d.amount)})`).join(", ")}
      >
        <Box>{params.value.map((d,i)=>`${d.name} (${money(d.amount)})`).join(", ")}</Box>
      </NoMaxWidthTooltip>
    )},
    { headerName: 'Profile Text', field: 'profile_text', filterType: 'string', hide:true,sortable:false},
    { headerName: 'Fund Investor',sortable:false,field: 'fund_investor',type:'boolean',width:150,renderCell:(params)=>(<Box>{params.row.deals.some((d)=>d.is_vintage) ? 'Yes' : 'No' }</Box>)},
    { headerName: 'Deal Count',field:'deal_count',type:'number',width:150,renderCell:(params)=>(<Box>{params.row.deals.length}</Box>)},
    { headerName: 'This Year $$',field:'yr_deal_dollars',type:'number',width:150,renderCell:(params)=>(<Box>{money(params.row.deals.filter((d)=>d.vintage_year === currentVintageYear).map((d)=>d.amount).reduce(function(a,b){return a+b},0))}</Box>)},
    { headerName: 'Total $$',field:'deal_dollars',width:150,type:'number',renderCell:(params)=>(<Box>{money(params.row.deals.map((d)=>d.amount).reduce(function(a,b){return a+b},0))}</Box>)},
    { headerName: 'Date Added', field: 'created_at', type:'date', width:120,valueFormatter: (params) => formatMMDDYYYY(params.value),filterOperators: dateOperators},
  	{ headerName: 'List', field:'listing',hide:true,filterOperators:listOperators},
  	{ headerName: 'Notes', field:'notes',hide:true},
    { headerName: 'Reminder Date',description:"Date to remind for follow-up",cellClassName: 'editable', editable:true,field: 'snooze_date',width:180,type:'date',filterOperators: dateOperators,valueFormatter: (params) => formatMMDDYYYY(params.value)},
    { headerName: 'Current Jobtitle', field: 'title',width:150,hide:true,filterOperators:stringOperators.filter((op) => op.value === "contains") },
    { headerName: 'All-Time Jobtitle', field: 'alltime_title',width:150,hide:true,filterOperators:stringOperators.filter((op) => op.value === "contains") },
    { headerName: 'Current Employer', field: 'employer',width:150,hide:true,filterOperators:companySearch.concat(stringOperators.filter((op) => op.value === "contains"))},
    { headerName: 'All-Time Employer', field: 'alltime_employer',width:150,hide:true,filterOperators:companySearch.concat(stringOperators.filter((op) => op.value === "contains")) },
    { headerName: 'All Background', field: 'background',hide:true},
    { headerName: 'Profile URL', field: 'url',hide:true},
    { headerName: 'MVP Connections', field: 'mvp_connections'},
  ]
  
  const [cols, setCols] = useState([])
  const [defaultColumns, setDefaultColumns] = useState([])
  
  useEffect(()=>{
	  setCols(columns)
	  setDefaultColumns(columns)
     // eslint-disable-next-line
  },[industryList,technologyList,tagList,fellowList])
  
  const getCompetitors = async (ids,names) => {
      async function get() {
          const res = await authenticatedFetch(`/companies/api/getcompetitors`, {
              method: 'POST', 
              body: JSON.stringify({
                  companies: ids,
                  company_names: names
              })
          });
          return res.map(r => r.id);
      }
      return get();
  }
  
  const getSimilar = async (ids,names) => {
      async function get() {
          const res = await authenticatedFetch(`/companies/api/getsimilar`, {
              method: 'POST', 
              body: JSON.stringify({
                  companies: ids,
                  company_names: names
              })
          });
          return res.map(r => r.id);
      }
      return get();
  }

  const insertMatches = async (filterModel) => {
    const fields = [
      ['alltime_employer',function(emps,val){return emps.filter(e=>val.includes(e.company?.id)).map(e=>formatHistoryItem(e))},function(emps,val){return emps.filter(e => e.institution.toLowerCase().includes(val.toLowerCase())).map(e=>formatHistoryItem(e))}],
      ['employer',function(emps,val){return emps.filter(e=>e.enddate === null && val.includes(e.company?.id)).map(e=>formatHistoryItem(e))},function(emps,val){return emps.filter(e =>e.enddate === null && e.institution.toLowerCase().includes(val.toLowerCase())).map(e=>formatHistoryItem(e))}],
      ['alltime_title',null,function(emps,val){return emps.filter(e => e.title.toLowerCase().includes(val.toLowerCase())).map(e=>formatHistoryItem(e))}],
      ['title',null,function(emps,val){return emps.filter(e =>e.enddate === null && e.title.toLowerCase().includes(val.toLowerCase())).map(e=>formatHistoryItem(e))}],
    ]
    
    let keys = filterModel.items.map(x=>x.columnField)
    let operators = filterModel.items.map(x=>x.operatorValue)
    let columns = [...cols].filter(c => c.headerName !== 'Matches')
    let competitors = []
    
    if (operators.includes('isOneCompetitor')){
      competitors = await getCompetitors(filterModel.items.filter(x=>x.operatorValue==='isOneCompetitor').map(f=>f.value.map(v=>v.id)).flat(),null)
    } else if (operators.includes('isCompetitorName')){
      competitors = await getCompetitors(null,filterModel.items.filter(x=>x.operatorValue==='isCompetitorName')[0].value)
    } else if (operators.includes('isOneSimilar')){
      competitors = await getSimilar(filterModel.items.filter(x=>x.operatorValue==='isOneSimilar').map(f=>f.value.map(v=>v.id)).flat(),null)
    } else if (operators.includes('isSimilarName')){
      competitors = await getSimilar(null,filterModel.items.filter(x=>x.operatorValue==='isSimilarName')[0].value)
    }
     
    if (keys.some(k=>fields.map(f=>f[0]).includes(k))){
      const matchCol = { headerName: 'Matches',width:250, description:'Roles that match employer/jobtitle filters',field: 'matches',filterable:false,sortable:false,
        renderCell:(p) => {
          let output = []
          fields.forEach(f=>{
            if (keys.includes(f[0])){
              let filters = filterModel.items.filter(x=>x.columnField.includes(f[0]))
              filters.forEach(filter => {
                if (filter.operatorValue === 'isOne'){
                  output = output.concat(f[1](p.row.employees,filter.value.map(v=>v.id)))
                } else if (filter.operatorValue === 'contains'){
                  output = output.concat(f[2](p.row.employees,filter.value))
                } else if (filter.operatorValue === 'isOneCompetitor' || filter.operatorValue === 'isOneSimilar'){
                  output = output.concat(f[1](p.row.employees,competitors.concat(filter.value.map(v=>v.id))))
                } else if (filter.operatorValue === 'isCompetitorName' || filter.operatorValue === 'isSimilarName'){
                  output = output.concat(f[1](p.row.employees,competitors))
                }
              })
            }
          })

          return (
            <NoMaxWidthTooltip 
              classes={{ tooltip: classes.customWidth }}
              title={output.join('; ')}
            >
              <Box sx={{backgroundColor:sv.colors.blue4,width:'100%',height:'100%'}}>{output.join('; ')}</Box>
            </NoMaxWidthTooltip>
          )
        }
      }
      columns.unshift(matchCol)
      setCols(columns)
    }
  }

  
  return (
    <Box className={classes.root}>
        <TableBase
          data={data}
          setData={setData}
          history={history}
          path={'contacts/list'}
          columns={cols}
          pinnedColumns={{left: [GRID_CHECKBOX_SELECTION_COL_DEF.field,'name']}}
		      setColumns={setCols}
		      defaultColumns={defaultColumns}
          name={'MVP Network'}
          type={"Contacts"}
		      handleCellEditCommit={handleCellEditCommit}
          setContact={setContact}
          warning={warning}
  		    setWarning={setWarning}
		      apiRef={apiRef}
		      selection={true}
          insertMatches={insertMatches}
        />
          <AddEditContact setWarning={setWarning} open={showAddEditContact} close={hideAddEditContact} contact={contact} setContact={setContact} data={data} setData={setData}/>
      </Box>
  );
}