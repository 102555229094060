import React from 'react';
import Dialog from '@mui/material/Dialog';
import InnerComments from './InnerComments'

export default function CommentBox({open,close,companyId, companyName, comments, setComments, setWarning}) {

  return (
    <>
     
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth={'md'}>
        <InnerComments companyId={companyId} name={companyName} setWarning={setWarning} comments={comments} setComments={setComments}/>
      </Dialog>
    </>
  )
}