import React, {useEffect,useState, useRef} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import { streamingFetch } from '../utils/auth';
import Radio from '@mui/material/Radio';
import NewListModal from './NewListModal.js'
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import TypingText from './helpers/TypingText'
import sv from '../constants/Styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import ReactHtmlParser from 'react-html-parser';
import Progress from './Progress'
import {extractFilterSort,markdownToHtml} from '../utils/tools'
import Grid from '@mui/material/Unstable_Grid2';
import Link from '@mui/material/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Checkbox from '@mui/material/Checkbox';
import {getLists,addRemoveList} from './helpers/APIs'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip,{tooltipClasses} from '@mui/material/Tooltip';
import Language from '@mui/icons-material/Language';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Select from '@mui/material/Select';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const useStyles = makeStyles((theme) => ({
  customMenuItem: {
    width: 250, // Adjust width as needed
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sliderContainer: {
    width: '90%',
    marginTop: theme.spacing(1),
  },
}))

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '600px',
  },
});
      
export default function JarvisGPT({open,close,setWarning,path,setSortModel,setSortCode,setFilterModel,makeFilterValue,setFilterValue,setFetchable,selectionModel,companyName,companyId,intros,files}) {
  const [radioVal, setRadioVal] = useState('')
  const [secondaryRadioVal, setSecondaryRadioVal] = useState('portfolio')
  const [input, setInput] = useState('')
  const [placeholderText, setPlaceholderText] = useState("Choose a data type to get started.\n– Page filters: Describe how you want to filter and sort the current page.\n– <Specific Company>: Ask questions about a specific company.\n– Companies / People: Ask about companies or people in the database.")
  const [loading, setLoading] = useState(false)
  const [convoDisplay, setConvoDisplay] = useState([])
  const endOfStackRef = useRef(null)
  const [newResponse, setNewResponse] = useState(false)
  const [personList, setPersonList] = useState([])
  const [companyList, setCompanyList] = useState([])
  const [checkedList, setCheckedList] = useState([])
  const [lists, setLists] = useState([])
  const [selectedListAnchorEl, setSelectedListAnchorEl] = useState(null)
  const [type, setType] = useState('')
  const [newListModal, setNewListModal] = useState(false)
  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null)
  const [temperature, setTemperature] = useState(0.2)
  const [lookupLimit, setLookupLimit] = useState(25)
  const [conversationId, setConversationId] = useState(null)
  const classes = useStyles()
  const [model, setModel] = useState('Claude')
  const [activeTyping, setActiveTyping] = useState(false)

  useEffect(()=>{
      if (radioVal === 'filter') {
        setPlaceholderText('Ask me to sort and filter the current page, like "Show me companies that raised from Sequoia in the last 90 days ranked by growth score" on the companies page, or "Show me investors with experience in marketing" on the network page.')
        setSecondaryRadioVal('')
      } else if (radioVal === 'companies') {
        setPlaceholderText('Ask me about companies in our database, like "which companies are most similar to Uber", "what companies have we talked to in the fintech space", or "what healthcare companies have we invested in?".')
        setSecondaryRadioVal('portfolio')
      } else if (radioVal === 'lps') {
        setPlaceholderText('Ask me about our LP and extended network, like "Who do we know that works in finance", or "Who would be a good CMO candidate for a tech company".')
        setSecondaryRadioVal('1st')
      } else if (radioVal === 'general') {
        setPlaceholderText('I am a general AI, ask me anything.')
        setSecondaryRadioVal('')
      } else if (radioVal === 'selection') {
        setPlaceholderText('Ask about the current selected people or companies, like "which of these is most similar to Uber" or "who from this list would be best for a CFO role".')
        setSecondaryRadioVal('')
      }
  },[radioVal])
  
  const addRemoveListItem = (list,remove=false) => {
     async function updateList() {
         setWarning({text:"Updating...",severity:'info'})
         await addRemoveList(list,checkedList,remove,type)
         setWarning({text:"Saved!",severity:'success'})
     }
 	updateList()
  }

  const handleToggle = (id) => () => {
    const currentIndex = checkedList.indexOf(id);
    const newChecked = [...checkedList];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedList(newChecked);
  };
  
  const refreshConvo = () => {
    setConvoDisplay([])
    setPersonList([])
    setCompanyList([])
    setCheckedList([])
    setInput('')
    setRadioVal('')
    setSecondaryRadioVal('')
    setConversationId(null)
    setLoading(false)
    setPlaceholderText("Choose a data type to get started.\n– Page filters: Describe how you want to filter and sort the current page.\n– Selection: Ask questions about the selected people or companies.\n– Companies / People: Ask about companies or people in the database.")
  }
  
  const enterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }
  
  useEffect(()=>{
    async function fetchLists() {
        const lists = await getLists(type)
        setLists(lists.filter(x => x.kind === type))
    }
    if (type !== ''){fetchLists()}
  },[type])
  
  useEffect(()=>{
    if (radioVal === 'lps') {
      if (secondaryRadioVal === '2nd'){
        setType('Connections')
      } else {
        setType('Contacts')
      }
    } else if (radioVal === 'companies'){
      setType('Growth')
    }
  },[radioVal,secondaryRadioVal])

  function wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  async function pauseAndClose() {
    await wait(4000)
    close()
  }

   useEffect(()=>{
     endOfStackRef.current?.scrollIntoView({ behavior: 'smooth' })
   },[convoDisplay])
   
   useEffect(()=>{
     setNewResponse(false)
   },[open])
   
   function updateFilterModel(content) {
     return new Promise((resolve) => {
       if (content && content.items){setFilterModel(content)}
       resolve();
     });
   }
   
   function updateFilterValue(content) {
     return new Promise((resolve) => {
       if (content && content.items){setFilterValue(makeFilterValue(content))}
       resolve()
     });
   }
   
   function updateSortModel(content) {
     return new Promise((resolve) => {
       content && setSortModel(content)
       resolve()
     });
   }
   
   function updateSortValue(content){
     return new Promise((resolve) => {
       if (content && content.length > 0){
         if (content[0].sort === 'asc'){
           setSortCode(content[0].field)
         } else {
           setSortCode(`-${content[0].field}`)
         }
       }
       resolve()
     })
   }
  
 function splitJSONObjects(jsonString, buffer) {
     buffer += jsonString

     const completeObjects = []
     let depth = 0
     let lastSplit = 0
     let inString = false

     for (let i = 0; i < buffer.length; i++) {
         const char = buffer[i]

         // Toggle inString status on quotes, except when escaped
         if (char === '"' && (i === 0 || buffer[i - 1] !== '\\')) {
             inString = !inString
         }

         if (!inString) {  // Only count braces when not in a string
             if (char === '{') {
                 depth++
             } else if (char === '}') {
                 depth--
                 if (depth === 0) {
                     // Try parsing the complete JSON object
                     try {
                         const obj = JSON.parse(buffer.substring(lastSplit, i + 1))
                         completeObjects.push(obj)
                         lastSplit = i + 1  // Move lastSplit to the position after the current object
                     } catch (e) {
                         console.error('Error parsing JSON:', e)
                         // Move lastSplit to the next character to skip over the problematic JSON
                         lastSplit = i + 1
                     }
                 }
             }
         }
     }

     buffer = buffer.substring(lastSplit);

     return { completeObjects, buffer };
 }
 
  
  const sendMessage = (overrideInput='',overrideSecondary='') => {
    let text = overrideInput || input
    async function askJarvis(display) {
      setLoading(true)
      const res = await streamingFetch(`/companies/api/jarvisgpt`, {
              method: 'POST',
              body: JSON.stringify({
                radioVal: radioVal,
                content: text,
                conversationId: conversationId,
                secondaryRadio: overrideSecondary || secondaryRadioVal,
                temperature: temperature,
                lookupLimit: lookupLimit,
                selected: selectionModel,
                path: path,
                model:model,
                companyId:companyId
              })
      })
      setPlaceholderText('')
      setInput('')
      
      const reader = res.body.getReader()
      const decoder = new TextDecoder('utf-8')
      
      let complete = false
      let chunks = []
      let jsonBuffer = ''
      
      setActiveTyping(true)
      
      while (!complete) {
          const { value, done } = await reader.read()
    
          complete = done
          if (value) {
              const chunk = decoder.decode(value, { stream: true });
              let { completeObjects, buffer } = splitJSONObjects(chunk, jsonBuffer)
              jsonBuffer = buffer
              completeObjects.forEach(json => {
                if (json.type === 'id') {
                    setConversationId(json.data);
                } else if (json.type === 'message') {
                    setLoading(false)
                    chunks.push(json.data)
                    if (radioVal !== 'filter'){
                      setConvoDisplay(display.concat({'kind':'jarvis','text':chunks.join('')}))
                    } else {
                      setConvoDisplay(display.concat({'kind':'jarvis','text':"Updating filters now. If I didn't get it right feel free to come back here and clarify for me."}))
                    }
                }
              })
          }
          
      }
      
      setActiveTyping(false)

      if (radioVal === 'general' || radioVal === 'company'){return}
      
      let result = `\`${chunks.join('')}\``

      if (radioVal === 'filter'){
         var [filterContent, sortContent] = extractFilterSort(result)
         if (filterContent || sortContent){
           updateFilterModel(filterContent)
           .then(() => {
             updateFilterValue(filterContent)
           })
           .then(() => {
             updateSortModel(sortContent)
           }).then(() => {
             updateSortValue(sortContent)
           })
           .then(() => {
             setFetchable(true)
           })
         }
         pauseAndClose()
         return
      }
      let startIndex = result.indexOf('['); // or str.indexOf('[') for an array
      let endIndex = result.lastIndexOf(']'); // or str.lastIndexOf(']') for an array
      let jsonString = result.substring(startIndex, endIndex + 1);
      
      let json = ''
      let formattedResponse = ''
      try {
        json = JSON.parse(jsonString)
      } catch (error) {
        console.log("JSON ERROR. jsonString:",jsonString)
        console.error(error)
      }
      
      
      if (json.length === 0 || json === null || json === ''){
        formattedResponse = "Sorry, I wasn't able to find any relevant information."
      } else if (type !== 'Growth'){

          json.forEach(p => {
            if (formattedResponse.length > 0){formattedResponse += "<br /><br />"}
            formattedResponse += `– ${p.name}`
            if (p.kind){formattedResponse += ` (${p.kind})`}
            formattedResponse += ` - ${p.explanation}`
          })
          result = result.replace(/\[json\].*?\[(?:\/json|json)\]/s,formattedResponse)
          let newPersonList = [...personList,...json.map(p=>({name:p.name,id:p.id,url:p.url}))]
          setPersonList(newPersonList.filter((value,index,self)=>index===self.findIndex(t=>t.id===value.id)))
      } else {
          json.forEach(c => {
            if (formattedResponse.length > 0){formattedResponse += "<br /><br />"}
            formattedResponse += `– ${c.name}: ${c.explanation}`
          })
          result = result.replace(/\[json\].*?\[(?:\/json|json)\]/s,formattedResponse)
          let newCompanyList = [...companyList,...json.map(c=>({name:c.name,id:c.id,url:c.url}))]
          setCompanyList(newCompanyList.filter((value,index,self)=>index===self.findIndex(t=>t.id===value.id)))
      }
      setConvoDisplay(display.concat({'kind':'jarvis','text':formattedResponse}))

    }
    let display = [...convoDisplay]
    display = display.concat({'kind':'user','text':text})
    setConvoDisplay(display)
    askJarvis(display)
    
  }
  
  const closeListModal = () => {
  	setNewListModal(false)
	  setSelectedListAnchorEl(false)
  }
  
  const handleMeetingPrep = () => {
    let text = 'Prep me for my next meeting'
    sendMessage(text,'meeting_prep')
  }
  
  const handleValueAddSummary = () => {
    let text = "Summarize how we've helped this company"
    sendMessage(text,'value_add_summary')
  }
  
  const handleLastMeetingSummary = () => {
    let text = "Summarize my last meeting with this company"
    sendMessage(text,'last_meeting_summary')
  }

  return (
      <Dialog 
        open={open}
        onClose={close}
        fullWidth={true}
        maxWidth={(personList.length === 0 && companyList.length === 0) ? 'md' : 'lg'}
        PaperProps={{
                style: {
                  height: '80vh', // Inline style
                },
              }}
      >
        <DialogTitle>JarvisGPT
          <FormLabel component='div'>What do you want to ask about?</FormLabel>
          <Stack direction='row' justifyContent='space-between'>
              <Stack>
                  <RadioGroup
                    row
                    value={radioVal}
                    onChange={(e)=>setRadioVal(e.target.value)}
                  >
                    <FormControlLabel value="filter" disabled={convoDisplay.length > 0 || !['growth','contacts/seconddegree','contacts/list'].includes(path)} control={<Radio />} label="Page Filters" />
                    {companyName && <FormControlLabel value="company" disabled={convoDisplay.length > 0} control={<Radio />} label={companyName} />}
                    {false &&<FormControlLabel value="selection" disabled={selectionModel?.length === 0 || convoDisplay.length > 0 || !['growth','contacts/seconddegree','contacts/list'].includes(path)} control={<Radio />} label="Selection" />}
                    <FormControlLabel value="companies" disabled={convoDisplay.length > 0} control={<Radio />} label="Companies" />
                    <FormControlLabel value="lps" disabled={convoDisplay.length > 0} control={<Radio />} label="People" />
                    <FormControlLabel value="general" disabled={convoDisplay.length > 0} control={<Radio />} label="General" />
                  </RadioGroup>
                  {secondaryRadioVal !== '' &&
                    <RadioGroup
                      row
                      value={secondaryRadioVal}
                      onChange={(e)=>setSecondaryRadioVal(e.target.value)}
                    >
                    {radioVal === 'companies' &&
                      <>
                        <FormControlLabel value="targets" disabled control={<Radio />} label="Targets" />
                        <FormControlLabel value="met" disabled control={<Radio />} label="Engaged" />
                        <FormControlLabel value="portfolio" disabled={convoDisplay.length > 0} control={<Radio />} label="Portfolio" />
                        <FormControlLabel value="all" disabled control={<Radio />} label="All" />
                      </>
                    }
                    </RadioGroup>
                  }
                  {(radioVal === 'company' && convoDisplay.length === 0) &&
                    <Box sx={{marginTop:3}}>
                      <Typography sx={{color:'gray',fontWeight:600,fontSize:sv.bodyIcon.fontSize*1.1,textAlign:'center'}}>Click for a specific template or ask anything about the company</Typography >
                      <Stack direction='row' justifyContent='space-around' sx={{marginTop:2}}>
                        <Button size="small" variant="outlined" color="primary" onClick={handleMeetingPrep}>
                          Meeting Prep
                        </Button>
                        <Button size="small" disabled={intros.length === 0} variant="outlined" color="primary" onClick={handleValueAddSummary}>
                          Value-Add Summary
                        </Button>
                        <Button size="small" disabled={files.filter(x=>x.name?.startsWith('Fireflies')).length === 0} variant="outlined" color="primary" onClick={handleLastMeetingSummary}>
                          Past Meeting Summary
                        </Button>
                      </Stack>
                    </Box>
                  }
              </Stack>
              <Stack alignItems="flex-start">
                <IconButton
                  onClick={refreshConvo}
                  sx={{padding:1}}
                >
                  <Box sx={{fontSize:sv.bodyIcon.fontSize}}>Clear Chat</Box>
                  <RefreshIcon />
                </IconButton>
                  <Select
                    size='small'
                    value={model}
                    onChange={(e)=>setModel(e.target.value)}
                    disabled={convoDisplay.length > 0}
                  >
                    {['Claude','ChatGPT','Perplexity'].map((val,ind) =>
                      <MenuItem key={ind} value={val} sx={{fontSize: sv.bodyIcon.fontSize*1.1}}>{val}</MenuItem>
                    )}
                   </Select>
                <div>
                  <IconButton
                    onClick={(e)=>setSettingsAnchorEl(e.currentTarget)}
                    sx={{padding:1}}
                  >
                    <Box sx={{fontSize:sv.bodyIcon.fontSize}}>Settings</Box>
                  </IconButton>
                  <Menu
                    anchorEl={settingsAnchorEl}
                    keepMounted
                    open={Boolean(settingsAnchorEl)}
                    onClose={()=>setSettingsAnchorEl(null)}
                  >
                    <MenuItem className={classes.customMenuItem}>
                      <Typography variant="subtitle1">
                        Temperature – {temperature} 
                        <Tooltip 
                          sx={{marginLeft:1}}
                          title={"A higher temperature value typically makes the output more diverse and creative but might also increase its likelihood of straying from the context. Conversely, a lower temperature value makes the AI's responses more focused and deterministic, sticking closely to the most likely prediction."} placement="right"
                        >
                          <HelpOutlineIcon />
                        </Tooltip>
                      </Typography>
                      <div className={classes.sliderContainer}>
                        <Slider
                          value={temperature}
                          onChange={(event, newValue) => setTemperature(newValue)}
                          step={0.1}
                          min={0}
                          max={1}
                          valueLabelDisplay="auto"
                          marks={true}
                          size='small'
                        />
                      </div>
                    </MenuItem>
                    <MenuItem className={classes.customMenuItem}>
                      <Typography variant="subtitle1">
                          Lookup Limit – {lookupLimit}
                          <Tooltip 
                            sx={{marginLeft:1}}
                            title={"Controls how much data to send through the AI. A higher number will result in more possible answers but slower response time. Only relevant for Companies and People."} placement="right"
                          >
                            <HelpOutlineIcon />
                          </Tooltip>
                      </Typography>
                      <div className={classes.sliderContainer}>
                        <Slider
                          value={lookupLimit}
                          onChange={(event, newValue) => setLookupLimit(newValue)}
                          step={5}
                          min={0}
                          max={100}
                          valueLabelDisplay="auto"
                          marks={true}
                          size='small'
                        />
                      </div>
                    </MenuItem>
                  </Menu>
                </div>
                <NoMaxWidthTooltip 
                  sx={{marginLeft:1}}
                  title={
                    <List>
                      <ListItem sx={{ display: 'list-item' }}><Link sx={{color: 'white !important',textDecoration: 'underline',transition: 'color 0.3s','&:hover':{color: 'white',}}} href={`https://docs.google.com/document/d/1QIkNXuEJnzpgA5ZIUsClK-NUTrCvLDEeKQDAytfrnLY/edit`} target='_blank'>Click here for the full guide</Link></ListItem>
                      <ListItem sx={{ display: 'list-item' }}>Be specific – ask for what you want, and describe it in detail as necessary.</ListItem>
                      <ListItem sx={{ display: 'list-item' }}>Follow-up and clarify – Jarvis remembers the conversation, so ask follow-up questions or provide more information to clarify what you want.</ListItem>
                      <ListItem sx={{ display: 'list-item' }}>Use ***website*** to refer to companies. If you include a URL inside three stars (***), Jarvis will lookup additional information about the company you referenced and use that in its thinking</ListItem>
                      <ListItem sx={{ display: 'list-item' }}>Use the selectors to choose as narrow a company group as possible. If you only want portfolio companies, select that. If you want only ones we have talked to, use engaged etc</ListItem>
                    </List>
                  }
                >
                  <IconButton
                    sx={{padding:1}}
                  >
                    <Box sx={{fontSize:sv.bodyIcon.fontSize,marginRight:1}}>Tips</Box>
                    <HelpOutlineIcon />
                  </IconButton>
                </NoMaxWidthTooltip>
            </Stack>
              
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{height:'100%'}} spacing={2}>
              <Grid xs={(personList.length === 0 && companyList.length === 0) ? 12 : 9} sx={{height:'100%',overflow: 'auto'}}>
                  <Stack justifyContent='space-between' direction='column'>
                      {convoDisplay.map((comment, index) =>
                          <Box key={index} sx={{padding:1, backgroundColor:comment.kind === 'jarvis' && sv.colors.blue4, position: 'relative'}} display='flex' alignItems='flex-start'>
                              {comment.kind === 'user' ? <PersonIcon sx={{marginRight:1}}/> : <SmartToyIcon sx={{marginRight:1}}/>}
                              <Box>
                                  {(comment.kind === 'user' || index < convoDisplay.length - 1 || !newResponse) ? ReactHtmlParser(markdownToHtml(comment.text)) : <TypingText text={comment.text} speed={1} />}
                              </Box>
                              {(comment.kind !== 'user' && !activeTyping) &&<ContentCopyIcon sx={{color:'gray',fontSize:sv.bodyText.fontSize,position: 'absolute', bottom: 8, right: 8,cursor:'pointer'}} onClick={() => navigator.clipboard.writeText(comment.text)}/>}
                          </Box>
                      )}
                  </Stack>
                  <Box ref={endOfStackRef} sx={{marginTop:-20}}/>
              </Grid>
              <Grid xs={(personList.length === 0 && companyList.length === 0) ? 0 : 3} sx={{overflow: 'auto'}}>
                <Stack direction='column'>
                {(personList.length > 0 || companyList.length > 0) &&
                  <>
                		 <Button color="primary" size="small" style={{marginBottom:0,marginLeft:10}} onClick={(e)=>setSelectedListAnchorEl(e.currentTarget)}>
                   	      Add to List
                		 </Button>
              	  	<Menu
              	  	  anchorEl={selectedListAnchorEl}
              	  	  open={Boolean(selectedListAnchorEl)}
              	  	  onClose={()=>setSelectedListAnchorEl(null)}
              	  	  anchorOrigin={{vertical: "bottom",horizontal: "center",}}
              	  	  transformOrigin={{vertical: "top",horizontal: "center",}}
              	  	> 
          			       <MenuItem sx={{fontSize:sv.bodyText.fontSize * 0.8}} disabled={true}>+ Add to list</MenuItem>
          			       {lists.map((list,ind) => 
          				       <MenuItem sx={{fontSize:sv.bodyText.fontSize * 0.8}} key={ind} onClick={()=>addRemoveListItem(list.id)}>{list.name}</MenuItem>
          			       )}			  
                       <MenuItem style={{fontSize:sv.bodyText.fontSize * 0.8,fontWeight:'bold'}} onClick={()=>setNewListModal(true)}>+ Create New List</MenuItem>
              	  	</Menu>
                   </>
                  }
                  {personList.map((p,i) =>
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          checked={checkedList.indexOf(p.id) !== -1}
                          onChange={handleToggle(p.id)}
                          value={p.id}
                        />
                      }
                      label={
                        <Box>     
                          <Link href={`https://mvpvc.herokuapp.com/person/${p.id}`} target='_blank'>{p.name}</Link>
                          {p.url && <Link href={`${p.url}`} target="_blank"><LinkedInIcon style={{ fontSize: sv.bodyIcon.fontSize*1.5, color:"0F4EB5", display: 'inline-block' }}/></Link>}
                        </Box>
                      }
                    />  
                  )}
                  {companyList.map((c,i) =>
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          checked={checkedList.indexOf(c.id) !== -1}
                          onChange={handleToggle(c.id)}
                          value={c.id}
                        />
                      }
                      label={
                        <Box>     
                          <Link href={`https://mvpvc.herokuapp.com/company/${c.id}`} target='_blank'>{c.name}</Link>
                          {c.url && <Link href={`https://www.${c.url}`} target="_blank"><Language style={{ fontSize: sv.bodyIcon.fontSize*1.5, color:"0F4EB5", display: 'inline-block' }}/></Link>}
                        </Box>
                      }
                    />  
                  )}
                </Stack>
              </Grid>
          </Grid>
        </DialogContent>     
        <DialogActions>
          <TextareaAutosize
            minRows={5}
            style={{fontSize:sv.bodyIcon.fontSize*1.2,width:'100%'}}
            label="Add note"
            variant="filled"
            value={input}
            onChange={(e)=>setInput(e.currentTarget.value)}
            placeholder={placeholderText}
            disabled={radioVal===''}
            onKeyDown={enterPress} 
            autoFocus
          />
          {loading ?
              <Progress />
            :
            <Button type='submit' color="primary" disabled={radioVal==='' || input===''} onClick={sendMessage}>
              Send
            </Button>
          }
        </DialogActions>
        <NewListModal newListModal={newListModal} setLists={setLists} type={type} setWarning={setWarning} closeListModal={closeListModal} rows={checkedList}/>
      </Dialog>
  );
  }
