import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Popover from '@mui/material/Popover';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Box from '@mui/material/Box';
import sv from '../../constants/Styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  blinkingSquare: {
    width: '30px',
    height: '30px',
    backgroundColor: '#51B748',
    position: 'absolute',
    borderRadius:20,
    cursor:'pointer',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    animation: `$blink 1s linear infinite`
  },
  "@keyframes blink": {
    "0%": {
      opacity: 1
    },
    "50%": {
      opacity: 0
    },
    "100%": {
      opacity: 1
    }
  }
}));

export default function CustomComponent({ children, guideShown, content }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget)
    } else {
      setAnchorEl(null)
    }
  };
  
  useEffect(()=>{
      setAnchorEl(null)
  },[guideShown])

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <ClickAwayListener onClickAway={()=>setAnchorEl(null)}>
      <div style={{position: 'relative',cursor:guideShown && 'pointer'}} onClick={handlePopoverOpen}>
        {guideShown && <div className={classes.blinkingSquare} ></div>}
        {children}
        <Popover
          className={classes.popover}
          open={open && guideShown}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
        >
          <Box sx={{border:2,padding:2,backgroundColor:sv.colors.blue5,width:800}}>
            {content}
          </Box>
        </Popover>
      </div>
   </ClickAwayListener>
  );
}
