import React, { useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import { Title,Text } from '../constants/StyleComponents'
import { styled } from '@mui/material/styles'
import sv from '../constants/Styles'
import Box from '@mui/material/Box'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ContactEdit from './ContactEdit'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const grid = 8;

const SubTitle = styled(Title)({
  fontSize: sv.bodyText.fontSize * 0.8,
  marginBottom:-10,
  padding:0
})

const SectionTitle = styled(Title)({
  fontSize: sv.bodyText.fontSize * 1.1,
  marginBottom:-10,
  paddingLeft: grid
  
})

const Link = styled('a')({
  '&:link': {
      color: '#1B8D77',
      textDecoration:'underline'
  },
  '&:visited': {
      color: '#1B8D77',
      textDecoration:'underline'
    },
  '&:hover': {
      color: '#1B8D77',
      textDecoration:'underline'
    },
  '&:active': {
      color: '#1B8D77',
      textDecoration:'underline'
    },
    cursor:'pointer',
})

const BlackLink = styled(Link)({
  '&:link': {color: 'black'},
  '&:visited': {color: 'black'},
  '&:hover': {color: 'black'},
  '&:active': {color: 'black'},
})

const Note = styled(Text)({
  fontStyle:'italic',
  fontSize: sv.bodyText.fontSize * 0.9,
  marginLeft:'15%',
  marginRight:'15%'
})

export default function CompanyConnections({history,contactList,setContactList,contactSelected,setContactSelected,finalized}) {
  const [companies,setCompanies] = useState([])
  const [open, setOpen] = useState(false)
  const [person, setPerson] = useState({})
  const [company,setCompany] = useState('')
  const [groups, setGroups] = useState([])
  const [groupTitles, setGroupTitles] = useState([''])

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    paddingLeft: grid * 8,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
  });
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "white",
    padding: grid,
	textAlign:'left',
	borderWidth: '1px',
	borderStyle: 'solid',
	marginTop: grid,
	fontSize: sv.bodyText.fontSize * 0.9,
	marginBottom:finalized ? 0 : 50
  });

  function onDragEnd(result) {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(groups[sInd], source.index, destination.index);
      const newGroup = [...groups];
      newGroup[sInd] = items;
      setGroups(newGroup);
    } else {
      const result = move(groups[sInd], groups[dInd], source, destination);
      const newGroup = [...groups];
      newGroup[sInd] = result[sInd];
      newGroup[dInd] = result[dInd];

      setGroups(newGroup);
    }
  }
  
  function handleClose(){
    setOpen(false)
  }
  
  useEffect(()=>{
    let selected = contactList.filter((x) => contactSelected.includes(x.id))
    let arr = [...new Set(selected.map((x)=>[x.employee__company__name,x.employee__company__website]))]
	let set  = new Set(arr.map(JSON.stringify))
    setCompanies(Array.from(set).map(JSON.parse))
  // eslint-disable-next-line
  },[contactList])

  useEffect(()=>{
	  setGroups([companies.sort((a,b) => (a[0] < b[0]) ? -1 : 1 ).map(function(comp){
	  	return {
			"id":comp[0],
	  		"company_name":comp[0],
			"company_url":comp[1],
			"contacts": contactList.filter((emp) => comp[0] === emp.employee__company__name && contactSelected.includes(emp.id)).map(function(e){
				return {
					"url":e.employee__person__url,
					"name":e.employee__person__name,
					"title":e.employee__title,
					"id":e.employee__company__id
				}
			})
	  	}		
	  })])
   // eslint-disable-next-line
  },[companies])
  
  function handleClick(emp){
    if (emp){
      setPerson(emp)
    } else{
      setPerson({})
    }
    setOpen(true)
  }
  
  return (
    <Container>
  {finalized ?
    <Box style={{textAlign:'center',marginBottom:30}}>
      <Title style={{fontSize: sv.h2.fontSize,marginBottom:0,paddinBottom:0}}>Potential Connections{company && `: ${company}`}</Title>      
      <Note><strong>Strictly private and confidential.</strong> Prepared only for review by {company}. Please note, this list is based on the MVP Limited Partner network and is preliminary; any potential introductions are based on willingness of the contact and take place only after a review with {company} leadership to better understand needs and asks.</Note>
    </Box>
    : 
    <Box>
      <TextField label="Enter Company Name" value={company} onChange={(e)=>setCompany(e.target.value)} style={{marginBottom:30}}/>
      <Button variant="contained" color="secondary" size='small' style={{marginLeft:150,marginTop:20}} onClick={()=>handleClick()}>
      Add Contact
      </Button>
    </Box>
      }
      <div>
          {!finalized &&
          <>
                <button
                  type="button"
                  onClick={() => {
                    setGroups([...groups, []])
                    setGroupTitles([...groupTitles,''])
                  }}
                  style={{marginTop:20,marginLeft:10}}
                >
                  Add Section
                </button>
               </>}
            <div >
              <DragDropContext onDragEnd={onDragEnd}>
                {groups.map((el, ind) => (
                  <Droppable key={ind} droppableId={`${ind}`}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                      >
                      {finalized ? 
                          <SectionTitle>{groupTitles[ind]}</SectionTitle>
                      :
                          <SectionTitle>
                              <TextField label="Section Name" value={groupTitles[ind]} style={{marginBottom:30}} onChange={(e)=>{
                                  let newTitles = [...groupTitles]
                                  newTitles[ind] = e.target.value
                                  setGroupTitles(newTitles)}} />
                              <IconButton
                                edge="end"
                                style={{marginBottom:5}}
                                onClick={()=>{
                                    if (el.length === 0){
                                        let newTitles = [...groupTitles]
                                        newTitles.splice(ind,1)
                                        setGroupTitles(newTitles)
                                        let newGroups = [...groups]
                                        newGroups.splice(ind,1)
                                        setGroups(newGroups)
                                    } else {
                                        alert('Must remove all companies from section before deleting')
                                    }
                                }}
                                size="large">
                                  <DeleteIcon style={{fontSize: sv.bodyText.fontSize * 0.9}}/>
                              </IconButton>
                          </SectionTitle>
                          
                      }
                        {el.map((item, index) => (
                          <Draggable
                            key={index}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div style={{ display: "flex"}}>
                                  
                                <Box key={ind}>
                                   <BlackLink href={`//${item.company_url}`} target='_blank'><SubTitle>{item.company_name}</SubTitle></BlackLink>
                                  <ul>
                                    {item.contacts.map((emp,ind) =>
                                      <Box key={ind}>
                                        <li style={{marginTop:5, fontSize: sv.bodyText.fontSize * 0.8}}>
                                          <Link href={`${emp.url}`} target='_blank'>{emp.name}</Link>
                                          , {emp.title}
                                          {!finalized &&<EditOutlinedIcon style={{fontSize: sv.bodyText.fontSize * 0.9, marginLeft: 10, cursor:'pointer'}} onClick={()=>handleClick(emp)}/>}
                                        </li>
                                </Box>
                                    )}
                                  </ul>
                                </Box>
                          
                          
                                  
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
              </DragDropContext>
            </div>
          </div>
      <ContactEdit open={open} close={handleClose} person={person} contactList={contactList} setContactList={setContactList} contactSelected={contactSelected} setContactSelected={setContactSelected}/>
      
    </Container>
  );
}