/**
 * Responsible for handling configuration stuff
 */

const configuration = {};
configuration.apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000'

export default configuration;


// API_URL=https://mvp-vc.herokuapp.com/companies npm run start
