import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Section, ErrorText } from '../constants/StyleComponents';
import { useForm } from "react-hook-form";
import {createList} from './helpers/APIs'

export default function NewListModal({newListModal,setLists,type,setWarning,closeListModal,rows}) {
  const { register, handleSubmit, errors } = useForm();
  
  const newListSubmit = (list) => {
  	  closeListModal()
     async function saveList() {
         setWarning({text:"Adding...",severity:'info'})
         const lists = await createList(list.name,list.description,type,rows)
         setLists(lists.filter(x => x.kind === type))
         setWarning({text:"Saved!",severity:'success'})
     }
 	saveList()
  }

  return (
    <Dialog open={newListModal} onClose={closeListModal}>
      <form onSubmit={handleSubmit(newListSubmit)}>   
        <DialogTitle>Create New List</DialogTitle>
        <DialogContent>
  	  <Section>
            <TextField
              inputRef={register({required: true})}
              name="name"
              label="List Name"
              fullWidth
              defaultValue={""}
            />
            {errors.name && <ErrorText>This field is required</ErrorText>}
	  </Section>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeListModal}>
            Cancel
          </Button>
          <Button type='submit' color="primary">
            Save
          </Button>
        </DialogActions>
      </form>       
    </Dialog>
  )
}