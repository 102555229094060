
const text = '#222'
const subtleText = '#666'
const buttonText = '#FFFFFF'
const background = '#ffffff'

const cta = '#5874A3'
const ctaHover = '#6BB7E2'
const subtleCta = 'rgba(45,156,219,.2)'

const cell = '#BDBDBD'
const cellHover = '#EAEAEA'
const cellSubtle = '#EDEDED'

const danger = '#B8421C'
const success = '#6CB06B'
const warning = '#CAA535'

const radius = 4
const grid = 8
const buttonHeight = grid*5
const appPadding = grid*5
const titlePadding = grid*8
const sectionPadding = grid*14
const headerHeight = grid*12

const padSmall = grid*2
const padMedium = grid*3
const padLarge = grid*4

const textSize = 14

const StyleVariables = {

  colors: {
    text: text,
    subtleText: subtleText,
    buttonText: buttonText,
    background: background,
    cta: cta,
    ctaHover: ctaHover,
    subtleCta: subtleCta,
    cell: cell,
    cellHover: cellHover,
    cellSubtle: cellSubtle,
    danger: danger,
    success: success,
    warning: warning,
    blue1: '#06283c',
    blue2: '#1279b6',
    blue3: '#8bcdf3',
    blue4: '#c8e7f9',
    blue5: '#f2f9fe',
    chart1: '#493f72',
    chart2: '#ab4680',
    chart3: '#f55e5b',
    chart4: '#ffa600',
    
  },

  radius: radius,
  grid: grid,
  buttonHeight: buttonHeight,
  appPadding: appPadding,
  titlePadding: titlePadding,
  sectionPadding: sectionPadding,
  headerHeight: headerHeight,

  title: {
    color: text,
    fontWeight: '800',
    fontSize: 36,
    lineHeight: '1.25em',
    letterSpacing: .2,
  },

  h1: {
    color: text,
    fontWeight: '800',
    fontSize: 32,
    lineHeight: '1.25em',
    letterSpacing: .2,
  },

  h2: {
    color: text,
    fontWeight: '800',
    fontSize: 28,
    lineHeight: '1.25em',
    letterSpacing: .2,
  },

  h3: {
    color: text,
    fontWeight: '800',
    fontSize: 20,
    lineHeight: '1.25em',
    letterSpacing: .2,
  },

  h4: {
    color: text,
    fontWeight: '700',
    fontSize: 18,
    lineHeight: '1.25em',
    letterSpacing: .2,
  },

  bodyText: {
    color: text,
    fontSize: textSize,
    lineHeight: '1.25em',
    letterSpacing: .2,
  },
  bodyIcon: {
    fontSize: textSize * 1,
  },
  label: {
    color: subtleText,
    fontSize: 14,
    lineHeight: '1.25em',
    textTransform: 'capitalize',
    letterSpacing: .5,
  },

  error: {
    color: warning,
    margin: '10px 0'
  },

  success: {
    color: success,
    margin: '10px 0'
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },

  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  box: {
    boxSizing: 'border-box'
  },

  borderRadius: {
    borderRadius: radius+'px',
  },

  transition: {
    transition: 'all .15s ease-out'
  },

  buttonBase: {
    height: buttonHeight,
    background: cta,
    color: buttonText,
    border: 'none',
    padding: `0 ${grid*2}px`,
    fontSize: 16,
    fontWeight: 400,
    transition: 'all .2s ease-out',
    cursor: 'pointer',
    width: 'fit-content',
    borderRadius: radius+'px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box'
  },

  pageLayout: {
    height: '100%',
    width: '100%',
    maxWidth: 850,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: grid*4
  },

  borderStyle: `1px solid ${cellSubtle}`,

  padSmall: {
    padding: `${padSmall}px 0`
  },

  padMedium: {
    padding: `${padMedium}px 0`
  },

  padLarge: {
    padding: `${padLarge}px 0`
  }

}

export default StyleVariables
