import React, {useState,useEffect} from 'react'
import Header from './Header'
import { authenticatedFetch,getUser } from '../utils/auth';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import 'react-csv-importer/dist/index.css';
import { Importer, ImporterField } from 'react-csv-importer';
import mvp from '../assets/mvp-square-white.svg'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { styled } from '@mui/material/styles';
import sv from '../constants/Styles'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import {uuid} from '../utils/tools'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CsvDownloader from 'react-csv-downloader';

const NameText = styled(Typography)({
  fontSize: sv.bodyText.fontSize*1.2,
  lineHeignt: 40,
  marginLeft:20,
})

const Logo = styled('img')({
  height:40,
})

export default function OutreachFlow({history}) {
  const [warning,setWarning] = useState({text:'',severity:''})
  const [jobtitle, setJobtitle] = useState('')
  const [group, setGroup] = useState('')
  const [batches, setBatches] = useState([])
  const [refreshBatches, setRefreshBatches] = useState(true)
  const [loading, setLoading] = useState(false)
  
  const rando = uuid()

  useEffect(() => {
     async function getBatches() {
       let url = `/companies/api/outreach_batch_list`
       let res = await authenticatedFetch(url, {method: 'GET'})
       setBatches(res.results)  
       setRefreshBatches(false) 
     }
     refreshBatches && getBatches()
  }, [refreshBatches]);

  const getData = (bid) => {
    setLoading(true)
    let resp = authenticatedFetch( `/companies/api/batch/${bid}/download`, {method: 'POST'})
    setLoading(false)
    return Promise.resolve(resp)
  };
  
  return <>
  {getUser().email === 'wes@mvp-vc.com' ?
    <AppBar position="sticky" style={{boxShadow: 'none'}}>
        <Toolbar variant="dense">
            	<span>
            	  <Logo component='span' src={mvp}/>
            	</span>
            	<NameText component='span'>
            	  Outreach Generator
            	</NameText>
        </Toolbar>
    </AppBar>
    :
  <Header 
    history={history}
    name={'Outreach Cleaner'}
    warning={warning}
    setWarning={setWarning}
  />
  }
	<Grid alignItems='center' justifyContent='center' sx={{marginTop:5}}>
        <Typography variant="h5" color='primary' sx={{marginTop:5,textAlign:'center'}}>Upload CSV with Firstname, Lastname, Email, LinkedIn, and historical role info</Typography>
        <Box sx={{width:'60%',marginTop:2,marginLeft:'20%'}}>
          <FormControl variant="standard" style={{width:300,marginBottom:30,marginTop:25}} size="small">
            <TextField
                variant="standard"
                value={group}
                onChange={(e)=>setGroup(e.target.value)}
                placeholder="File name"
              />
          </FormControl>
          <Stack direction="row" alignItems="center">
            <FormControl variant="standard" style={{width:500}} size="small">
              <TextField
                  variant="standard"
                  value={jobtitle}
                  onChange={(e)=>setJobtitle(e.target.value)}
                  placeholder="Enter position keyword(s)"
                />
            </FormControl>
            <Typography variant="body" sx={{marginBottom:5,marginLeft:5}}><b>{"{{custom2}}"}</b>: Will search "position" fields for this text to create to create custom2 as "[position] at [company]". Example: "Limited Partner" in this field for a prospect who was a Limited Partner at a16z would set "custom2" to "Limited Partner at a16z".</Typography>
          </Stack>
        <Importer
           assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
           restartable={false} // optional, lets user choose to upload another file when import is complete
           processChunk={async (rows, { startIndex }) => {
              for (let row of rows) {
                let company,custom2;
                if (row.current_position && row.current_position.toLowerCase().includes(jobtitle.toLowerCase())){
                  company = row.current_company
                } else if (row.current_position_2 && row.current_position_2.toLowerCase().includes(jobtitle.toLowerCase())){
                  company = row.current_company_2
                } else if (row.current_position_3 && row.current_position_3.toLowerCase().includes(jobtitle.toLowerCase())){
                  company = row.current_company_3
                } else if (row.previous_position && row.previous_position.toLowerCase().includes(jobtitle.toLowerCase())){
                  company = row.previous_company
                } else if (row.previous_position_2 && row.previous_position_2.toLowerCase().includes(jobtitle.toLowerCase())){
                  company = row.previous_company_2
                } else if (row.previous_position_3 && row.previous_position_3.toLowerCase().includes(jobtitle.toLowerCase())){
                  company = row.previous_company_3
                } else {
                  company = ''
                }
                if (company){
                  custom2 = `${jobtitle} at ${company}`
                }
                await authenticatedFetch(`/companies/api/leadgen`, {
                  method: 'POST',
                  body: JSON.stringify({
                    firstname: row.firstname,
                    lastname: row.lastname,
                    email1: row.email1,
                    email1_status: row.email1_status,
                    email2: row.email2,
                    email2_status: row.email2_status,
                    email3: row.email3,
                    email3_status: row.email3_status,
                    email4: row.email4,
                    email4_status: row.email4_status,
                    linkedin: row.linkedin,
                    custom2: custom2,
                    batch: group || rando,
                    position: jobtitle,
                    current_company:row.current_company,
                    current_position:row.current_position
                  })
                })
              }
           }}
           onComplete={({ file, preview, fields, columnFields }) => {
             // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
           }}
           onClose={({ file, preview, fields, columnFields }) => {
             setRefreshBatches(true)
           }}
           >
           <ImporterField name="firstname" label="first_name" />
           <ImporterField name="lastname" label="last_name" />
           <ImporterField name="email1" label="email1" />
           <ImporterField name="email1_status" label="email1_status" optional/>
           <ImporterField name="email2" label="email2" optional/>
           <ImporterField name="email2_status" label="email2_status" optional/>
           <ImporterField name="email3" label="email3" optional/>
           <ImporterField name="email3_status" label="email3_status" optional/>
           <ImporterField name="email4" label="email4" optional/>
           <ImporterField name="email4_status" label="email4_status" optional/>
           <ImporterField name="linkedin" label="person_linkedin_url" />
           <ImporterField name="current_position" label="current_position" optional/>
           <ImporterField name="current_company" label="company_name" optional/> 
           <ImporterField name="current_position_2" label="current_position_2" optional/>
           <ImporterField name="current_company_2" label="current_company_2" optional/>
           <ImporterField name="current_position_3" label="current_position_3" optional/>
           <ImporterField name="current_company_3" label="current_company_3" optional/>
           <ImporterField name="previous_position" label="previous_position" optional/>
           <ImporterField name="previous_company" label="previous_company" optional/>
           <ImporterField name="previous_position_2" label="previous_position_2" optional/>
           <ImporterField name="previous_company_2" label="previous_company_2" optional/>
           <ImporterField name="previous_position_3" label="previous_position_3" optional/>
           <ImporterField name="previous_company_3" label="previous_company_3" optional/>
       </Importer>
      </Box>
      <Box sx={{height:500,width:'60%',marginLeft:'20%',marginTop:5}}>
        <Typography variant="h6" color='primary' sx={{textAlign:'center'}}>Files</Typography>
        <Table>
            <TableHead>
              <TableRow>
                 <TableCell>Folder</TableCell>
                 <TableCell>Position</TableCell>
                 <TableCell>Count</TableCell>
                 <TableCell>Created At</TableCell>
                 <TableCell>Download</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
             {batches.map((b,i) => 
               <TableRow key={i}>
                 <TableCell>{b.name}</TableCell>
                 <TableCell>{b.position}</TableCell>
                 <TableCell>{b.lead_count}</TableCell>
                 <TableCell>{b.created_at}</TableCell>
                 <TableCell><CsvDownloader
                       datas={()=>getData(b.id)}
                       filename={b.name}
                >{loading ? 'Loading...' :<FileDownloadIcon sx={{cursor:'pointer'}}/>}</CsvDownloader></TableCell>
               </TableRow>
             )}
            </TableBody>
         </Table>
      </Box>
	</Grid>

  </>
}
