import { authenticatedFetch } from '../../utils/auth';

export async function getLists(type) {
      let url = `/companies/api/listings`
      return await authenticatedFetch(url, {
		    method: 'POST',
		    body: JSON.stringify({
		    	kind: type
		    })
      })
}

export async function createList(name,description,type,selected) {
		return await authenticatedFetch('/companies/api/listings/create',{
			method: 'POST',
			body: JSON.stringify({
			  name: name,
			  description: description,
			  kind: type,
			  selected:selected
			})
    })
}

export async function addRemoveList(lid,selected,remove,type) {
  return await authenticatedFetch(`/companies/api/listings/${lid}/update`,{
         method: 'POST',
         body: JSON.stringify({
           remove: remove,
           selected:selected,
           kind: type
         })
  })
}