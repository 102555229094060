import React, { useState, useEffect } from 'react';
import { authenticatedFetch, getUser } from '../utils/auth';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";
import { styled } from '@mui/material/styles';
import {formatMMDDYYYY} from '../utils/tools';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import { Section,Text } from '../constants/StyleComponents';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import sv from '../constants/Styles';
import ExpandableBox from './ExpandableBox'
import Editor from 'react-simple-wysiwyg';
import { FileUploader } from "react-drag-drop-files"
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

// const ScorecardBox = styled(Box)({
//   marginTop:25,
//   marginRight:25
// })

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default function InnerComment({companyId, intro, name, person, setWarning, comments, setComments,style,valueAdd,height, setUploadedFile=null}) {
  const { register, handleSubmit, reset } = useForm()
  // const [scorecards, setScorecards] = useState([])
  const [id, setId] = useState(null)
  const [ending, setEnding] = useState('')
  const [commentText, setCommentText] = useState('')
  const [customOpen, setCustomOpen] = useState(null)
  const [datePicker, setDatePicker] = useState('')
  const [displayedComments, setDisplayedComments] = useState(comments)
  const [tabValue, setTabValue] = useState(0)
  const [slackChannel, setSlackChannel] = useState('')

  useEffect(()=>{
    if (tabValue === 0) {
      setDisplayedComments(comments)
    } else if (tabValue === 1) {
      setDisplayedComments(comments.filter((x)=>x.kind === 'Note' || x.kind === 'Value-Add'))
    } else if (tabValue === 2) {
      setDisplayedComments(comments.filter((x)=>x.kind === 'Email' || x.kind === 'Meeting'))
    } else if (tabValue === 3){
      setDisplayedComments(comments.filter((x)=>x.kind === 'Update'))
    }
  },[tabValue,comments])

  const remindMe = (comment,time) => {
    const d = new Date()
    let date;
        
    if (time === 'next_week') {
      date = new Date (d.setDate(d.getDate() + (7-d.getDay())%7+1))
    } else if (time === 'next_month') {
      date = new Date(d.getFullYear(), d.getMonth() + 1, 1)
    } else if (time === 'one_week') {
      date = new Date(d.setDate(d.getDate() + 7))
    } else if (time === 'four_week') {
      date = new Date(d.setDate(d.getDate() + 28))
    } else if (time === 'eight_week') {
      date = new Date(d.setDate(d.getDate() + 56))
    } else if (time === 'twelve_week') {
      date = new Date(d.setDate(d.getDate() + 84))
    } else {
      date = time || datePicker
    }
    
    async function remind(){
      await authenticatedFetch(`/companies/api/comments/${comment.id}/remind`,{
        method:'POST',
        body: JSON.stringify({
          date: date
        })
      })
      setWarning({text:`Reminder set for ${formatMMDDYYYY(date)}`,severity:'success'})
      setCustomOpen(null)
    }
    remind()
  }
      
  const deleteComment = (cid) => {
    async function deleteComment(){
      let res = await authenticatedFetch(`/companies/api/comments/${cid}/delete`,{
        method:'POST',
        body: JSON.stringify({})
      })
      setComments(res)
      setWarning({text:`Comment deleted`,severity:'success'})
    }
    deleteComment()
  }    
  
  const onSubmit = (form) => {
    async function addComment(){
      let res = await authenticatedFetch(`/companies/api/company/${id}/${ending}/create`,{
        method:'POST',
        body: JSON.stringify({
          comment: commentText,
          slack: form.checkbox,
          slackChannel: slackChannel,
          kind: valueAdd ? 'Value-Add' : 'Note'
        })
      })
      setCommentText('')
      setComments(res)
      setWarning({text:`Saved`,severity:'success'})
    }
    setWarning({text:`Saving...`,severity:'info'})
    addComment()
    reset()
  }
  
  const remindSubmit = (form) => {
    remindMe(form,form.date)
  }
  
  // const handleChange = (event) => {
//     if (event.target.value === 'new') {
//       async function createScorecard(){
//         const res = await authenticatedFetch(`/companies/api/company/${companyId}/scorecard`,{
//           method:'POST',
//         })
//         window.open(`/scorecard/${res.id}`,'_blank').focus()
//       }
//       createScorecard()
//     } else {
//       window.open(`/scorecard/${event.target.value}`,'_blank').focus()
//     }
//   }
  
  useEffect(() => {	
	   if (companyId){
	   	setId(companyId)
	   	setEnding('comments')
      if (['gray@mvp-vc.com','cecilia@mvp-vc.com'].includes(getUser()['email'])){
        setSlackChannel('Value-Add')
      } else {
        setSlackChannel('Deals')
      }
      // getScorecards()
	   } else if (person){
	   	 setId(person.id)
	   	 setEnding('personcomments')
	   } else if (intro){
	     setId(intro.id)
       setEnding('introcomments')
	   }
      // async function getScorecards() {
      //   let url = `/companies/api/company/${companyId}/scorecards`
      //   let res = await authenticatedFetch(url, {method: 'GET'})
      //   setScorecards(res)
      // }
  }, [companyId,person,intro]);

  return (
    <Box sx={{paddingLeft:1,paddingRight:1,...style}}>
        <form onSubmit={handleSubmit(onSubmit)}>   
          {name && <DialogTitle>{name}: Add A Note</DialogTitle>}
            {/* <ScorecardBox>
                 <InputLabel id="scorecard-select">Scorecards</InputLabel>
                 <Select
                   labelId="scorecard-select"
                   onChange={handleChange}
                   style={{width:'150px'}}
                   value={''}
                 >
                   <MenuItem value={'new'}>New</MenuItem>
                   {scorecards.map((s,sIndex) =>
                     <MenuItem key={sIndex} value={`${s.id}`}>{s.user} - {formatMMYYYY(s.created_at)}</MenuItem>
                   )}
                 </Select>
               </ScorecardBox>
             */}
           
           <Editor 
              value={commentText} 
              onChange={(e)=>setCommentText(e.target.value)} 
              containerProps={{ style: {backgroundColor:'white',marginTop:10,resize: 'vertical' }}}
           />
          <Stack direction='row' justifyContent={intro ? 'flex-end' : 'space-around'}  sx={{marginTop:1}}>
              {(companyId && setUploadedFile) && <FileUploader children={
                <Box sx={{width:'120px',fontStyle:'italic',fontSize:sv.bodyText.fontSize*0.9,cursor:'pointer',borderStyle:'dotted',borderColor:sv.colors.blue1,padding:2,textAlign:'center'}}>
                  Click to upload or drop file
                </Box>
              } hoverTitle=' ' onTypeError={(err)=>setWarning({text:err,severity:'error'})} handleChange={(file)=>setUploadedFile(file)} name="file" types={["JPG", "JPEG", "PNG", "PDF", "CSV", "XLS", "XLSX","ZIP","TXT","HTML","DOC", "DOCX", "PPT", "PPTX"]} />}
              {(person || intro) ?
                <FormControlLabel
                  control={
                    <Checkbox
                      inputRef={register()}
                      name='checkbox'
                      defaultChecked
                    />
                  }
                  label="Post to Slack"
                />
                :
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Slack</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={slackChannel}
                      label="Age"
                      sx={{width:180}}
                      size='small'
                      onChange={(e)=>setSlackChannel(e.target.value)}
                    >
                      {['Deals','Value-Add','None'].map((s,sIndex) =>
                        <MenuItem key={sIndex} value={s}>{s}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
           
              }
              
                <Button type='submit' variant='contained' sx={{color:'white',height:'90%'}}>
                  Save
                </Button>
          </Stack>
          {(companyId && !valueAdd) &&
          <Tabs value={tabValue} onChange={(e,nv) =>setTabValue(nv)} centered>    
                    <Tab label="All" />
                    <Tab label="Notes" />
                    <Tab label="Emails" />
                    <Tab label="Updates" />                    
                  </Tabs>
            }
            <Box display="flex" flexDirection="column" height={`calc(100vh - 400px)`} overflow="scroll">
                <Box flexGrow={1} overflow="auto">
                  {displayedComments.map((val,ind) => 
                    <Grid container justifyContent="flex-end" key={ind}>
                        <Paper elevation={0} sx={{width:'100%',backgroundColor:sv.colors.blue5,marginBottom:3,padding:1,marginRight:2}}>
                         <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Stack direction="row">
                              <Text component="span" sx={{fontWeight:'bold'}}>{val.user || 'Unknown'}</Text>
                              <Text component="span" sx={{fontSize:sv.bodyText.fontSize*0.9,marginLeft:1}}>{formatMMDDYYYY(val.date || val.created_at)}</Text>
                            </Stack>
                            <Stack direction="row">
                              <IconButton>
                                <LightTooltip title={
                                    <Stack>
                                      <MenuItem disabled>Remind me...</MenuItem>
                                      <MenuItem onClick={()=>remindMe(val,'next_week')}>Next Week</MenuItem>
                                      <MenuItem onClick={()=>remindMe(val,'next_month')}>Next Month</MenuItem>
                                      <MenuItem onClick={()=>remindMe(val,'one_week')}>In 1 Week</MenuItem>
                                      <MenuItem onClick={()=>remindMe(val,'four_week')}>In 4 Weeks</MenuItem>
                                      <MenuItem onClick={()=>remindMe(val,'eight_week')}>In 8 Weeks</MenuItem>
                                      <MenuItem onClick={()=>remindMe(val,'twelve_week')}>In 12 Weeks</MenuItem>
                                      <MenuItem onClick={()=>setCustomOpen(val)}>Custom</MenuItem>
                                    </Stack>}
                                 >
                                  <AccessAlarmIcon sx={{fontSize:18,cursor:'pointer',color:'black'}}/>
                                 </LightTooltip>
                              </IconButton>
                              <IconButton onClick={()=>deleteComment(val.id)} sx={{cursor:'pointer',color:'black'}} disabled={getUser()['name']!==val.user}>
                                  <DeleteIcon sx={{fontSize:18,}}/>
                              </IconButton>
                             
                            </Stack>
                          </Stack>
                          <Text sx={{marginTop:1}}><ExpandableBox text={val.text} maxLength={250}/></Text>
                        </Paper>
                    </Grid>
                  )}
                </Box>
          </Box>
        </form>       
        
        <Dialog 
          open={customOpen===null ? false : true}
          onClose={()=>setCustomOpen(null)}
          maxWidth={'sm'}
        >
          <form onSubmit={handleSubmit(remindSubmit)}>   
          <DialogTitle>Create New Reminder</DialogTitle>
          <DialogContent>
            <DialogContentText>
              What date should this reminder be set for?
            </DialogContentText>
                    <Section>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              minDate={new Date()}
                              label="Select Date"
                              value={datePicker}
                              onChange={(newValue) => {
                                setDatePicker(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                      <TextField
                        sx={{display:'none'}}
                        value={customOpen?.id}
                        name="id"
                        inputRef={register()}
                      />
                    </Section>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>setCustomOpen(null)} color="primary">
              Cancel
            </Button>
            <Button type='submit' color="primary">
              Save
            </Button>
          </DialogActions>
         </form>
        </Dialog>
        
                
                
    </Box>
  )
}