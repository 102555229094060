import React, {useEffect,useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Section } from '../constants/StyleComponents';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SearchBox from './SearchBox'
import { IntroCategories,IntroStatuses } from '../constants/Constants'
import {getUsers} from '../utils/tools';
import { authenticatedFetch } from '../utils/auth';
import AddEditContact from './AddEditContact.js'
import InnerComments from './InnerComments'
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

export default function AddEditIntro({open,close,intro,setData,setWarning,source='allTasks'}) {
  const [companyValue,setCompanyValue] = useState(null)
  const [companyInputValue,setCompanyInputValue] = useState(null)
  const [personValue,setPersonValue] = useState(null)
  const [personInputValue,setPersonInputValue] = useState(null)
  const [users, setUsers] = useState([])
  const [showAddEditContact, setShowAddEditContact] = useState(false)
  const [comments,setComments] = useState([])
  const [category, setCategory] = useState('')
  const [owner, setOwner] = useState('')
  const [status, setStatus] = useState('')
  const [miniWarning, setMiniWarning] = useState({text:'',severity:''})
  const [doSave, setDoSave] = useState(false)
  
  useEffect(()=>{
    async function getComments() {
      let url = `/companies/api/company/${intro.id}/introcomments`
      let res = await authenticatedFetch(url, {method: 'GET'})
      setComments(res)
    }
    (intro.id && /^\d+$/.test(intro.id)) && getComments()    
    setCategory(intro.category || '')
    setOwner(intro.owner || '')
    setStatus(intro.status || '')
  },[intro])
  
  const hideAddEditContact = () => {
    setShowAddEditContact(false)
  }
    
  useEffect(()=>{
    getUsers().then((v) => setUsers(v))  
  },[])
  
  const handleChange = (method,value) => {
    method(value)
    setDoSave(true)
  }
  
  useEffect(() => {
    async function saveIntro() {
      setMiniWarning({text:"Saving...",severity:'info'})
      let res = await authenticatedFetch(`/companies/api/intros/edit`, {
        method: 'POST',
        body: JSON.stringify({
          source: source,
          basePerson: intro.person?.id,
          baseCompany: intro.company?.id,
          person: personValue?.id,
          company: companyValue?.id,
          id: intro.id,
          status: status,
          category: category,
          owner: owner
        }),
      })
    
      setMiniWarning({text:"Saved!",severity:'success'})
      setData(res)
      setDoSave(false)
    }
    doSave && saveIntro()
   // eslint-disable-next-line
  }, [doSave])
    
  const doCompanyChange = (event, newValue) => {
    if (newValue){
      handleChange(setCompanyValue,newValue)
    }
  }
  
  const doPersonChange = (event, newValue) => {
    if (newValue){
      handleChange(setPersonValue,newValue)
    }
  }

  useEffect(()=>{
    intro.company && setCompanyValue(intro.company)
    intro.person && setPersonValue(intro.person)
  },[intro])
  
  return (
    <Dialog 
      open={open}
      onClose={close}
      fullWidth={true}
      maxWidth={'md'}
    >
      {miniWarning && miniWarning.text && <Alert severity={miniWarning.severity} onClose={() => setMiniWarning({})}>{miniWarning.text}</Alert>}
      <DialogTitle>Edit Value-Add Task</DialogTitle>
        <DialogContent>
            <Section>
              <SearchBox
                value={companyValue}
                setValue={setCompanyValue}
                inputValue={companyInputValue}
                setInputValue={setCompanyInputValue}
                doChange={doCompanyChange}
                searchText={"Company"}
    			      url={`/companies/api/search/companies`}
              />
            </Section>
            <Section>
              <SearchBox
                value={personValue}
                setValue={setPersonValue}
                inputValue={personInputValue}
                setInputValue={setPersonInputValue}
                doChange={doPersonChange}
                searchText={"Person"}
    			      url={`/companies/api/search/people`}
              />
              <Button size="small" variant="outlined" sx={{marginTop:1}} onClick={()=>setShowAddEditContact(true)}>New Person</Button>
            </Section>
                    <Stack direction='row' justifyContent='space-between' sx={{marginTop:2}}>
                    <Stack sx={{width:'30%'}}>
                    <InputLabel>Category</InputLabel>
                    <Select
                      value={category}
                      onChange={(e)=>handleChange(setCategory,e.target.value)}
                      size='small'
                    >
                      <MenuItem value='Choose' disabled>Choose</MenuItem>
                      {IntroCategories.map((c,ind)=>
                         <MenuItem key={ind} value={c}>{c}</MenuItem>
                      )}
                    </Select>
                    </Stack>
                    <Stack sx={{width:'30%'}}>
                        <InputLabel>Owner</InputLabel>
                        <Select
                          value={owner}
                          onChange={(e)=>handleChange(setOwner,e.target.value)}
                          size='small'
                        >
                              <MenuItem value='Choose' disabled>Choose</MenuItem>
                              {users.map((c,ind)=>
                                 <MenuItem key={ind} value={c}>{c}</MenuItem>
                              )}
                      </Select>
                    </Stack>
                   <Stack sx={{width:'30%'}}>
                          <InputLabel>Status</InputLabel>
                          <Select
                            value={status}
                            onChange={(e)=>handleChange(setStatus,e.target.value)}
                            size='small'
                          >
                                 <MenuItem value='Choose' disabled>Choose</MenuItem>
                                 {IntroStatuses.map((c,ind)=>
                                    <MenuItem key={ind} value={c}>{c}</MenuItem>
                                 )}
                        </Select>
                      </Stack>
                    </Stack>
                    <InnerComments setWarning={setWarning} intro={intro} comments={comments} setComments={setComments}/>
        </DialogContent>
      <AddEditContact setWarning={setWarning} open={showAddEditContact} close={hideAddEditContact} contact={{}}/>
    </Dialog>
  );
  }
