import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { Text, Section } from '../constants/StyleComponents';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import mvp from '../assets/mvp.png';
import { useParams } from 'react-router-dom';
import { confirmPasswordChange } from '../utils/auth';
import sv from '../constants/Styles';

const Content = styled(Container)({
  marginTop: 20,
})

const HeaderImg = styled('img')({
  width:300,
  margin:30,
})

const FormField = styled(TextField)({
  marginRight:10,
  color:"primary",
  width:500,
})

export default function PasswordReset({history}) {
  const { uid, token } = useParams();
  const [error,setError] = useState('')
  const [success, setSuccess] = useState('');
  
  const initialValues = {
        password: "",
        confirm: "",
  }
  const [values, setValues] = useState(initialValues || {});

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        const { name, value } = target;
        event.persist();
        setValues({ ...values, [name]: value });
  };
  
  const handleSubmit = async (e) => {
      e.preventDefault()
      if (values.password && values.password !== values.confirm) {
        setError('Passwords do not match');
        return;
      }
      
     try {
          const result = await confirmPasswordChange(uid, token, values.password);
          console.log('Password change successful:', result.message);
          setSuccess("Sorry, unable to change password.")
          history.push('/')
        } catch (error) {
          console.error('Password change failed:', error.status, error.message);
          setError("Sorry, unable to change password.")
        }
      
      

      // const response = await confirmPasswordChange(uid,token,values.password)
      // console.log("RESPONSE",response)
      // debugger
      // if (response.ok) {
      //   setSuccess('Password reset successful');
      //   history.push('/login'); // Redirect to login page
      // } else {
      //   setError('Password reset failed');
      // }
    };
    
    

  return (
    <>
      <HeaderImg src={mvp} alt="MVP Ventures" onClick={()=>history.push('/')}/>
      <Content maxwidth="lg">
        <Text sx={{fontSize:sv.bodyIcon.fontSize*1.5}}>Please enter your new password here</Text>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {success && <p style={{ color: 'green' }}>{success}</p>}
        <Section>
          <form onSubmit={handleSubmit} autoComplete="off">
            <Section>
              <FormField
                required
                label="Password"
                name="password"
                value={values.password}
                onChange={handleChange}
                variant="filled"
                type="password"
              />
            </Section>
            <Section>
              <FormField
                required
                label="Password Confirmation"
                name="confirm"
                value={values.confirm}
                onChange={handleChange}
                variant="filled"
                type="password"
              />
            </Section>
            <Section>
              <Button type="submit" variant="contained" color="primary">
                Reset Password
              </Button>
            </Section>
          </form>
        </Section>
      </Content>
    </>
  );
}
 

 