import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import sv from '../../constants/Styles';
import { ComposedChart,Bar, Line, XAxis, YAxis, ResponsiveContainer, Scatter, Legend, Tooltip as ChartTip, Dot } from 'recharts';
import Slider from '@mui/material/Slider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import IconButton from '@mui/material/IconButton';
import BarChartIcon from '@mui/icons-material/BarChart';
import pitchbook from '../../assets/pitchbook.png';
import cbi from '../../assets/cbi.png'
import harmonic from '../../assets/harmonic.png'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Tooltip from '@mui/material/Tooltip';
import Guide from './Guide.js'
import Typography from '@mui/material/Typography';
import Diversity3Icon from '@mui/icons-material/Diversity3';

function Chart({history,companyId,refreshGraphData,setRefreshGraphData,setShowMilestone,graphData,showHelp,isMobile=false}) {
  const [expandedGraphData, setExpandedGraphData] = useState([])
  const [slicedGraphData, setSlicedGraphData] = useState([])
  const [sliderVals, setSliderVals] = React.useState([0,0])
  const [tabValue, setTabValue] = useState(0)
  const [fundingSources, setFundingSources] = useState('All Sources')
  const [selectedGrowthButton,setSelectedGrowthButton] = useState('headcount')
  const [breakdown, setBreakdown] = useState(false)
  const [title,setTitle] = useState('Headcount Growth')
  const [sliderValMax, setSliderValMax] = useState(0)
  const [showMetrics, setShowMetrics] = useState(true)
  const [fundingOverlay, setFundingOverlay] = useState(false)
  
  useEffect(()=>{
    let titleText = ''
    let obj = null

    if (tabValue === 0){
      if (selectedGrowthButton === 'headcount'){
        titleText = 'Headcount Growth'
        obj = expandedGraphData.filter((x)=>x.current || x.followers)[0]
      } else if (selectedGrowthButton === 'traffic'){
        titleText = 'Web Traffic Growth'
        obj = expandedGraphData.filter((x)=>x.current || x.web_traffic)[0]
      } else {
        titleText = 'Social Growth'
        obj = expandedGraphData.filter((x)=>x.current || x.followers)[0]
      }
      if (breakdown){
        titleText += selectedGrowthButton === 'headcount' ? ' by Department' : ' by Network'
      }
    } else if (tabValue === 1){
      titleText = `Funding History (${fundingSources})`
      obj = expandedGraphData.filter((x)=>x.amount || x.valuation)[0]
    } else if (tabValue === 2){
      titleText = 'Momentum Scores'
      obj = expandedGraphData.filter((x)=>x.growth_score || x.retention_score || x.social_score || x.employee_score || x.investor_score)[0]
    }
    setTitle(titleText)
    if (obj){
      let ind = expandedGraphData.indexOf(obj)
      setSlicedGraphData(expandedGraphData.slice(ind))
    }
    else {
      setSlicedGraphData(expandedGraphData)
    }
  },[tabValue,selectedGrowthButton,breakdown,fundingSources,expandedGraphData])

  useEffect(()=>{
    setSlicedGraphData(expandedGraphData.slice(sliderVals[0],sliderVals[1]))
  },[sliderVals,expandedGraphData])

  useEffect(()=>{
    setSliderValMax(expandedGraphData.length)
    setSliderVals([0,expandedGraphData.length])
  },[expandedGraphData])

  useEffect(()=>{
    const key = fundingSources === 'All Sources' ? 'canonicalinvestment_set' : 'investment_set'
    const newData = graphData.map(x => {
      const inv = x[key].filter((i)=>(fundingSources === 'All Sources')||(i.source === fundingSources))
      const amount = inv && inv.length > 0 ? inv[0].amount : null
      const valuation = inv && inv.length > 0 ? inv[0].valuation : null
      const round_name = inv && inv.length > 0 ? inv[0].round?.parent || inv[0].round?.name : null
      return {
        ...x,
        amount,
        valuation,
        round_name
      }
    })
    setExpandedGraphData(newData)
  },[fundingSources,graphData])

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box sx={{backgroundColor:'white',border:1,paddingLeft:2,paddingRight:2}}>
          <Box sx={{fontWeight:'bold',paddingTop:1,paddingBottom:1}}>{`${label}`}</Box>
          {payload.filter((x)=>x.value!==0 && !['amount','valuation','round_name','value_add_value','milestone_value','metric_value'].includes(x.dataKey)).map((p,i) => (
            <Box key={i} sx={{paddingBottom:1}}>{p.name || p.dataKey}: {p.value}</Box>
          ))}
          {payload[0].payload.round_name && 
            <Box sx={{paddingBottom:1}}>{payload[0].payload.round_name}: {payload[0].payload['amount'] === 0 ? 'Unkonwn Amt' : `$${payload[0].payload['amount']}M`}</Box>
          }
          {payload.filter((x)=>x.dataKey==='valuation').map((p,i)=>(
            <Box key={i} sx={{paddingBottom:1}}>Valuation: ${p.value}M</Box>
          ))}
          {payload.filter((p)=>p.dataKey==='value_add_value').map((p,i)=>
            <Box key={i} sx={{paddingBottom:1}}>{`Value-Add: ${p.payload['value_add']}`}</Box>
          )}
          {payload.filter((p)=>p.dataKey==='milestone_value').map((p,i)=>
            <Box key={i} sx={{paddingBottom:1}}>{`Milestone: ${p.payload['milestone']}`}</Box>
          )}
          {payload.filter((p)=>p.dataKey==='metric_value').map((p,i)=>
            <Box key={i} sx={{paddingBottom:1}}>{`Metric: ${p.payload['metric']}`}</Box>
          )}
        </Box>
      );

    }
        return null;
  };
  
  const RenderDot = ({ cx, cy, color }) => {
    return (
      <Dot cx={cx} cy={cy} fill={color} r={10} />
    )
  }

  return (
    <> 
        <Stack direction="row" justifyContent="space-between">
        <Guide guideShown={showHelp} content={
           <Stack>
             <Typography sx={{textAlign:'center',fontWeight:600,color:'gray',fontmarginBottom:1}}>Timeline Overview</Typography>
             <Typography sx={{marginTop:1}}>Toggle between growth and funding views. Views allow you to toggle on milestones which include value-add tasks and custom metrics. You can also show funding history overlaid on top of growth or momentum views.</Typography>
             <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>Growth:</Box>View company headcount, web traffic, and social media following over time. Available breakdowns include headcount by department and followers by social network.</Typography>
             <Typography sx={{marginTop:1}}><Box component="span" sx={{fontWeight:800,marginRight:1}}>Valuation:</Box>View company valuation over time and funding rounds raised. By default we combine sources to generate a clean list of funding rounds, but you can also choose to view data only from Pitchbook or CB Insights.</Typography>
           </Stack>
          }>
          <Tabs value={tabValue} onChange={(e,nv)=>setTabValue(nv)}>
            <Tab label="Growth" />
            <Tab label="Valuation" />
            {false &&<Tab label="Momentum" />}
          </Tabs>
          </Guide>
          <Stack direction="row" justifyContent="flex-end" sx={{marginRight:'2%'}}>
            {tabValue === 0 && <Tooltip title="Headcount"><IconButton sx={{border:1,width:50,height:50,backgroundColor:selectedGrowthButton === 'headcount' && '#e0e0e0',borderRadius:0}} onClick={()=>setSelectedGrowthButton('headcount')}><PeopleAltOutlinedIcon /></IconButton></Tooltip>}
            {tabValue === 0 && <Tooltip title="Web Traffic"><IconButton sx={{border:1,width:50,height:50,backgroundColor:selectedGrowthButton === 'traffic' && '#e0e0e0',borderRadius:0}} onClick={()=>setSelectedGrowthButton('traffic')}><LanguageIcon /></IconButton></Tooltip>}
            {tabValue === 0 && <Tooltip title="Social"><IconButton sx={{border:1,width:50,height:50,marginRight:4,backgroundColor:selectedGrowthButton === 'social' && '#e0e0e0',borderRadius:0}} onClick={()=>setSelectedGrowthButton('social')}><Diversity3Icon /></IconButton></Tooltip>}
            {(tabValue === 0 && selectedGrowthButton !== 'traffic' && !isMobile) && <Tooltip title="Toggle Breakdown"><IconButton sx={{border:1,width:50,height:50,backgroundColor:breakdown && '#e0e0e0',borderRadius:0}} onClick={()=>setBreakdown(!breakdown)}><BarChartIcon /></IconButton></Tooltip>}
            {((tabValue === 0 || tabValue === 2) && !isMobile) && <Tooltip title="Overlay Funding"><IconButton sx={{border:1,width:50,height:50,backgroundColor:fundingOverlay && '#e0e0e0',borderRadius:0}} onClick={()=>setFundingOverlay(!fundingOverlay)}><AttachMoneyIcon /></IconButton></Tooltip>}
            {(!isMobile && tabValue === 1) && <Tooltip title="All Sources"><IconButton sx={{border:1,width:50,height:50,backgroundColor:fundingSources === 'All Sources' && '#e0e0e0',borderRadius:0}} onClick={()=>setFundingSources('All Sources')}><AllInclusiveIcon /></IconButton></Tooltip>}
            {(!isMobile && tabValue === 1) && <Tooltip title="Pitchbook Only"><IconButton sx={{border:1,width:50,height:50,backgroundColor:fundingSources === 'Pitchbook' && '#e0e0e0',borderRadius:0}} onClick={()=>setFundingSources('Pitchbook')}><img style={{height:25,width:25,filter:'brightness(0) invert(0.3)'}} src={pitchbook} alt="Pitchbook"/></IconButton></Tooltip>}
            {(!isMobile && tabValue === 1) && <Tooltip title="CB Insights Only"><IconButton sx={{border:1,width:50,height:50,backgroundColor:fundingSources === 'CBI' && '#e0e0e0',borderRadius:0}} onClick={()=>setFundingSources('CBI')}><img style={{height:25,width:25,filter:'brightness(0) invert(0.3)'}} src={cbi} alt="CBI" /></IconButton></Tooltip>}
            {(!isMobile && tabValue === 1) && <Tooltip title="Harmonic Only"><IconButton sx={{border:1,width:50,height:50,marginRight:4,backgroundColor:fundingSources === 'Harmonic' && '#e0e0e0',borderRadius:0}} onClick={()=>setFundingSources('Harmonic')}><img style={{height:25,width:25,filter:'brightness(0) invert(0.3)'}} src={harmonic} alt="Harmonic" /></IconButton></Tooltip>}
            
            {!isMobile && <Tooltip title="Show Milestones"><IconButton sx={{border:1,width:50,height:50,backgroundColor:showMetrics && '#e0e0e0',borderRadius:0}} onClick={()=>setShowMetrics(!showMetrics)}><StarBorderIcon /></IconButton></Tooltip>}
          </Stack>
          
        </Stack>
        <Box sx={{ position: 'relative' }}>
          <Box sx={{ position: 'absolute', top: -20, left: '50%', transform: 'translateX(-50%)',paddingLeft:1,paddingRight:1}}>
              <h3>{title}</h3>
          </Box>   
        </Box>
        <ResponsiveContainer width="100%" height="80%"> 
       <ComposedChart
         data={slicedGraphData}
         margin={{
           top: 30,
           right: isMobile ? -40 : 30,
           left: isMobile ? -40 : 20,
           bottom: 5,
         }}
        >

       <Legend />
        <XAxis name="Date" dataKey="moyr" />
        <YAxis yAxisId='left' dx={5}/>
       	<YAxis yAxisId='right' dx={-5} orientation='right' tick={fundingOverlay ? true : false}/>
        <ChartTip content={<CustomTooltip />}/>
        {(tabValue === 0 && selectedGrowthButton === 'headcount') &&
          <>
            <Line type="monotone" yAxisId='left' animationDuration={500} connectNulls={true} name="Headcount" dataKey="current" stroke={sv.colors.blue1} strokeWidth={3}/>
            {breakdown &&
              <>
                <Bar dataKey="technology_ct" name="Technology" stackId="1" yAxisId='left' barSize={10} fill={sv.colors.blue1} />
                <Bar dataKey="sales_ct" name="Sales" stackId="1" yAxisId='left' barSize={10} fill={sv.colors.chart2} />
                <Bar dataKey="operations_ct" name="Operations" stackId="1" yAxisId='left' barSize={10} fill={sv.colors.chart3} />
                <Bar dataKey="other_ct" name="Unknown" stackId="1" yAxisId='left' barSize={10} fill={sv.colors.blue3} />
              </>  
            }
          </>
        }
        {(tabValue === 0 && selectedGrowthButton === 'social') &&
          <>
            <Line type="monotone" yAxisId='left' connectNulls={true} name="Followers" dataKey="followers" stroke={sv.colors.blue1} strokeWidth={3}/>
            {breakdown &&
              <>
                <Bar dataKey="tw_followers" name="Twitter" stackId="1" yAxisId='left' barSize={10} fill={sv.colors.blue1} />
                <Bar dataKey="li_followers" name="LinkedIn" stackId="1" yAxisId='left' barSize={10} fill={sv.colors.blue2} />
                <Bar dataKey="fb_followers" name="Facebook" stackId="1" yAxisId='left' barSize={10} fill={sv.colors.blue3} />
              </>  
            }
          </>
        }
        {(tabValue === 0 && selectedGrowthButton === 'traffic') &&
          <>
            <Line type="monotone" yAxisId='left' connectNulls={true} name="Web Traffic" dataKey="web_traffic" stroke={sv.colors.blue1} strokeWidth={3}/>
            {breakdown &&
              <>
                <Bar dataKey="web_traffic" name="Web Traffic" stackId="1" yAxisId='left' barSize={10} fill={sv.colors.blue1} />
              </>  
            }
          </>
        }
        {(tabValue === 1 || fundingOverlay) &&
          <>
            <Bar dataKey="amount" name="Amount" legendType="none" tooltipType="none" yAxisId={fundingOverlay ? 'right' : 'left'} barSize={20} fill={fundingOverlay ? sv.colors.chart1 : sv.colors.blue1} />
            <Line dataKey="valuation" type="monotone" name="Valuation" yAxisId={fundingOverlay ? 'right' : 'left'} connectNulls={true} fill={fundingOverlay ? sv.colors.chart1 : sv.colors.blue1} strokeWidth={3} />
          </>
        }
        
        {tabValue === 2 &&
         <>
           <Line type="monotone" yAxisId={'left'} connectNulls={true} name="Growth Score" dataKey="growth_score" stroke={sv.colors.blue1} strokeWidth={3} />
           <Line type="monotone" yAxisId={'left'} connectNulls={true} name="Retention Score" dataKey="retention_score" stroke={sv.colors.blue2} strokeWidth={3}/>
           <Line type="monotone" yAxisId={'left'} connectNulls={true} name="Market Score" dataKey="social_score" stroke={sv.colors.chart1} strokeWidth={3}/>
           <Line type="monotone" yAxisId={'left'} connectNulls={true} name="Team Score" dataKey="employee_score" stroke={sv.colors.chart2} strokeWidth={3}/>
           <Line type="monotone" yAxisId={'left'} connectNulls={true} name="Investor Score" dataKey="investor_score" stroke={sv.colors.chart3} strokeWidth={3}/>
         </>
        }
        
        {showMetrics &&
          <>
            <Scatter name="Value Add" legendType="none" dataKey="value_add_value" yAxisId='right' fill={sv.colors.chart2} shape={<RenderDot color={sv.colors.chart2} />} />
            <Scatter name="Milestone" legendType="none" dataKey="milestone_value" label yAxisId='right' fill={sv.colors.chart3} shape={<RenderDot color={sv.colors.chart3}/>} />
            <Scatter name="Metric" legendType="none" dataKey="metric_value" label yAxisId='right' fill={sv.colors.chart4} shape={<RenderDot color={sv.colors.chart4} />} />
          </>
        }
       </ComposedChart>
      </ResponsiveContainer>
      <Box sx={{width:'86%',marginLeft:'7%'}}>
        <Slider
          value={sliderVals}
          onChange={(e,v)=>setSliderVals(v)}
          min={0}
          marks={true}
          size="small"
          max={sliderValMax}
        />
      </Box>
    </>
  );
}

export default Chart;