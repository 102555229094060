import { Draggable } from "react-beautiful-dnd";
import React from "react";
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box'
import {formatMMDDYYYY,daysSince} from '../utils/tools';
import Tooltip from '@mui/material/Tooltip';
import sv from '../constants/Styles';

const CardHeader = styled('div')({
  fontWeight: '700',
  fontSize: sv.bodyText.fontSize * 1.2,
})

const DragItem = styled('div')({
  padding: '10px',
  borderRadius: '6px',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
  background: 'white',
  margin: '0 0 8px 0',
  display: 'grid',
  gridGap: '20px',
  flexDirection: 'column',
})

export default function ValueAddListItem({ item, index, setIntro, setShowEditIntro }) {  
  const handleOpen = (item) => {
    setIntro(item)
    setShowEditIntro(true)
  }
  
  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name: string) {
    if (name){
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: name.split(' ').map((x)=>x[0]).slice(0,2).join(''),
      };
    }
    
  }

  return (
    <Draggable draggableId={item.id.toString()} index={index}>
      {(provided, snapshot) => {
        return (
          <DragItem
            ref={provided.innerRef}
            snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={()=>handleOpen(item)}
          >
            <CardHeader>{item.company && <a href={`/company/${item.company.id}`} target="_blank" rel="noreferrer">{item.company.name}</a>} {item.company && item.person && "<>"} {item.person && <a href={`/person/${item.person.id}`} target="_blank" rel="noreferrer">{item.person.name}</a>}</CardHeader>
            <Box>{item.category && <Box component='span' sx={{fontWeight:600}}>{item.category}: </Box>}{item.description}</Box>
            <Box>
              {item.result &&<Box><Box component='span' sx={{fontWeight:600}}>Result:</Box> {item.result}</Box>}
              {!item.status.includes('Success') && item.status !== 'Failed' && item.next_step && item.next_step_date && 
                <Box sx={{marginTop:2,color:new Date(item.next_step_date) < new Date() && 'red'}}>
                  <Box component='span' sx={{fontWeight:800}}>{formatMMDDYYYY(item.next_step_date)}:</Box>
                  <Box component='span'>{' '}{item.next_step}</Box>
                </Box>
              }
              
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{marginTop:3}}
              >
                <Avatar {...stringAvatar(item.owner)}/>
                <Box>
                  <Tooltip title="date created"><Box component='span'>{formatMMDDYYYY(item.created_at)}</Box></Tooltip>
                  <Tooltip title="days in stage" sx={{marginLeft:1}}><Box component='span'>({daysSince(item.status_set_date)})</Box></Tooltip>
                </Box>
              </Stack>
            </Box>
          </DragItem>
        );
      }}
    </Draggable>
  );
};
