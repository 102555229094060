import React, {useState,useEffect} from 'react';
import { DataGridPro,GridToolbarContainer,GridActionsCellItem } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import SearchBox from './SearchBox'
import { authenticatedFetch, getUser } from '../utils/auth';
import {formatMMDDYYYY,daysSince} from '../utils/tools';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import GetAppIcon from '@mui/icons-material/GetApp';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Box from '@mui/material/Box';
import Language from '@mui/icons-material/Language';
import sv from '../constants/Styles';
import {getUsers} from '../utils/tools';
import { IntroCategories,IntroStatuses } from '../constants/Constants'
import AddEditIntro from './AddEditIntro.js'

export default function IntroTable({rows,setRows,person,company,setWarning}) {
  const [users, setUsers] = useState([])
  const [showEditIntro, setShowEditIntro] = useState(false)
  const [intro, setIntro] = useState({})
  
  useEffect(()=>{
    getUsers().then((v) => setUsers(v))  
  },[])
  
  const hideEditIntro = () => {
    setShowEditIntro(false)
  }
  
  
  function institutionCell(params) {
    return <SearchCell {...params} type={Object.keys(company).length===0 ? 'companies' : 'people'}/>;
  }

	const handleCellEditCommit = (params) => {
    async function doUpdate(){
      let res = await authenticatedFetch(`/companies/api/intros/edit`, {
        method: 'POST',
        body: JSON.stringify({
          basePerson: person.id,
          baseCompany: company.id
        })
      })
      setRows(res)
    }
    doUpdate()
    return params
	}
    
  const handleDelete = (id) => {
    async function doDelete(){
      let res = await authenticatedFetch(`/companies/api/intros/${id}/delete`, {
        method: 'POST',
        body: JSON.stringify({
          person: person.id,
          company: company.id,
        })
      })
      setRows(res)
    }
    doDelete()
  }

  const SearchCell = (props) => {
    const { id, api, field, type, value } = props;
    const [search,setSearch] = useState(null)
    const [inputValue,setInputValue] = useState(null)
    const doChange = (e,nv) => {
       if (nv){
       	  setSearch(null)
		      setInputValue('')
          api.setEditCellValue({ id, field, value: nv.id.toString() });
	     }
  }	

    return (
     <SearchBox
	    fullWidth
       value={search}
       setValue={setSearch}
       doChange={doChange}
       inputValue={inputValue}
       setInputValue={setInputValue}
       searchText={value?.name || value || "Search"}
	     url={type === 'companies' ? `/companies/api/search/companies` : `/companies/api/search/people`}
     />
    );  
  }

  const cols = [
    { headerName:'Company',editable:Object.keys(company).length===0,width:150,field:'company',renderEditCell: institutionCell,renderCell: (params) => (
          <>
            <Box>{params.row.company?.name}</Box>
            {Object.keys(company).length===0 && params.row.company?.name && <Language style={{cursor:'pointer',fontSize: sv.bodyText.fontSize,marginLeft:1}} onClick={()=>window.open(`https://mvpvc.herokuapp.com/company/${params.row.company.id}`,'_blank')}/>}
          </>
    )},
    { headerName:'Person',editable:Object.keys(person).length===0,width:150,field:'person',renderEditCell: institutionCell,renderCell: (params) => (
          <>
            <Box>{params.row.person?.name}</Box>
            {Object.keys(person).length===0 && params.row.person?.name && <Language style={{cursor:'pointer',fontSize: sv.bodyText.fontSize,marginLeft:1}} onClick={()=>window.open(`https://mvpvc.herokuapp.com/person/${params.row.person.id}`,'_blank')}/>}
          </>     
    )},
    { headerName: 'Actions',field: 'delete',filterable:false,sortable:false,width:100, renderCell: (params: ValueFormatterParams) => (
        <>
          <GridActionsCellItem
            icon={<EditOutlinedIcon />}
            label="Edit"
            onClick={()=>{
              setIntro(params.row)
              setShowEditIntro(true)
            }}
          />
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={()=>handleDelete(params.row.id)}
          />
        </>
    )},
    { headerName:'Owner',editable:true,width:150,field:'owner',type:'singleSelect',valueOptions:users}, 
    { headerName:'Category',editable:true,width:150,field:'category',type:'singleSelect',valueOptions:IntroCategories},
    { headerName:'Status',editable:true,type:'singleSelect',valueOptions:IntroStatuses,width:150,field:'status'},
    { headerName:'Created At',width:150,field:'created_at',type:'date',valueFormatter: (params) => formatMMDDYYYY(params.value)},
    { headerName:'Days Since Created',width:150,field:'days_since_created',type:'number',valueFormatter: (params) => daysSince(rows.filter((r)=>r.id===params.id)[0].created_at)},
    { headerName:'Days In Status',width:150,field:'status_set_date',type:'number',valueFormatter: (params) => daysSince(params.value)},
    
  ]
  
  function EditToolbar(props) {
    const handleClick = () => {
      setShowEditIntro(true)

      async function makeIntro() {
        let res = await authenticatedFetch(`/companies/api/intros/edit`, {
          method: 'POST',
          body: JSON.stringify({
            baseCompany: company?.id,
            status:'To Do',
            owner:getUser()['name']
          }),
        })
        setRows(res)
        setIntro(res[0])
      }
      makeIntro()
      setIntro({company:company,status:'To Do',owner:getUser()['name']}) 
    }

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add task
        </Button>
        <Button color="primary" startIcon={<GetAppIcon />} onClick={()=>window.open('companies' ? `/company/${company.id || person.id}/connections` : `/person/${company.id || person.id}/connections`)}>
          Export
        </Button>
      </GridToolbarContainer>
    );
  }
  

  return (
  <>
    <DataGridPro
      columns={cols}
      rows={rows}
      editMode='row'
      density="compact"
      experimentalFeatures={{ newEditingApi: true }}
      components={{Toolbar: EditToolbar,}}
      processRowUpdate={handleCellEditCommit}			  
    />
    <AddEditIntro setWarning={setWarning} source={'introTable'} open={showEditIntro} setData={setRows} close={hideEditIntro} intro={intro}/>
  </>
  );
}