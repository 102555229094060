import { Droppable } from "react-beautiful-dnd";
import TaskListItem from "./TaskListItem";
import React from "react";
import { styled } from '@mui/material/styles';

const ColumnHeader = styled('div')({
  textTransform: 'uppercase',
  textAlign:'center',
  marginBottom: '20px',
  color:'#51b748',
  fontWeight:600,
  fontSize:20
})

const DroppableStyles = styled('div')({
  padding: '10px',
  borderRadius: '6px',
  background: '#06283c'
})

export default function TaskListDraggable({ prefix, elements, setIntro, setShowEditIntro }) {
  
  return (
  <DroppableStyles>
    <ColumnHeader>{prefix}</ColumnHeader>
    <Droppable droppableId={`${prefix}`}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {elements.map((item, index) => (
            <TaskListItem key={item.id} item={item} index={index} setIntro={setIntro} setShowEditIntro={setShowEditIntro}/>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DroppableStyles>
)}