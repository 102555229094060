import React, {useState} from 'react'
import Header from './Header'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import sv from '../constants/Styles';
import TextField from '@mui/material/TextField';
import { Section } from '../constants/StyleComponents';
import InputAdornment from '@mui/material/InputAdornment';
import { authenticatedFetch } from '../utils/auth';
import {Text} from '../constants/StyleComponents';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

const Content = styled(Container)({
    width:'100%'
})

const Headline = styled(Box)({
	textAlign:'center',
	fontSize: sv.bodyText.fontSize * 2,
	marginTop: 50,
})

const Link = styled('a')({
  cursor: 'pointer',
})

const Info = styled(Text)({
    fontSize: sv.bodyText.fontSize * 0.9,
	color:'#2e735c',
})

export default function NewsFeed({history}) {
  const [fund, setFund] = useState('')
  const [subscription, setSubscription] = useState('')
  const [supplement, setSupplement] = useState('')
    
  const [company,setCompany] = useState('')
  const [dealshare,setDealshare] = useState('')
  const [amount,setAmount] = useState('')
  const [security,setSecurity] = useState('')
  const [folder,setFolder] = useState('')
  const [date,setDate] = useState('')
  const [closingDate,setClosingDate] = useState('')
  
 const submit = (key,func) => {
         async function sendIt() {
            await authenticatedFetch(`/companies/api/documents`, {
              method: 'POST',
              body: JSON.stringify({
                key: key,
                company: company,
                dealshare: dealshare,
                amount: amount,
                security: security,
                folder: folder,
                date: date,
                closingDate: closingDate,
              })
            })
            func('')
         }
         sendIt()
}

const doIt = (type) => {
    let func;
    switch (type) {
      case 'fund':
        setFund('loading')
        func = setFund
        break;
      case 'subscription':
        setSubscription('loading')
        func = setSubscription
        break;
      case 'supplement':
        setSupplement('loading')
        func = setSupplement
        break;
      default:
          break;
    }
    submit(type,func)
}
  return <>
    <Header 
      history={history}
      name={'Document Generator'}
    />
    <Content>
        <Headline>Deal Document Generator</Headline>
             <Grid container spacing={2}>
                 <Grid item xs={6}>
                     <Section>
                       <TextField
                         value={company}
                         onChange={(e)=>setCompany(e.target.value)}
                         label="Company Name"
                         fullWidth
                       />
                       <Info>Full Company Name, e.g. Mighty Buildings, Inc</Info>
                     </Section>
                     <Section>
                       <TextField
                         value={dealshare}
                         onChange={(e)=>setDealshare(e.target.value)}
                         label="Co-Invest Series"
                         fullWidth
                       />
                       <Info>Co-Invest Series Name, e.g. MVP Co-Invest XX</Info>
                     </Section>
                     <Section>
                       <TextField
                         value={amount}
                         onChange={(e)=>setAmount(new Intl.NumberFormat('en-US').format(e.target.value.replace(/[^0-9]/g,'')))}
                         label="Amount"
                         InputProps={{
                                     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                   }}
                       />
                       <Info>Deal amount (USD)</Info>
                     </Section>        
                 </Grid>
                 <Grid item xs={6}>
                     <Section>
                       <TextField
                         value={security}
                         onChange={(e)=>setSecurity(e.target.value)}
                         label="Security"
                         fullWidth
                       />
                       <Info>Name of security, e.g. Series A Preferred or SAFE dated 10/28/2021</Info>
                     </Section>
                     <Section>
                       <TextField
                         value={folder}
                         onChange={(e)=>setFolder(e.target.value)}
                         label="Folder Id"
                         fullWidth
                       />
                       <Info>GDrive link to deal folder within MVP Deals</Info>
                     </Section>
                     <Section>
                       <TextField
                           value={date}
                           onChange={(e)=>setDate(e.target.value)}
                           label="Date"
                           type="date"
                           InputLabelProps={{
                             shrink: true,
                           }}
                         />
                    <Info>Initial date of CoInvest</Info>
                     </Section>
                     <Section>
                           <TextField
                               value={closingDate}
                               onChange={(e)=>setClosingDate(e.target.value)}
                               label="Closing Date"
                               type="date"
                               InputLabelProps={{
                                 shrink: true,
                               }}
                             />
                       <Info>Date all funds transferred</Info>
                     </Section>
                 </Grid>
             </Grid> 


          <h3>Documents (click to generate):</h3>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ul>
                  {false &&<li><Link onClick={()=>doIt('fund')}>Fund Agreement</Link>{fund === 'loading' &&<CircularProgress color="inherit" size={20} style={{marginLeft:5}}/>}</li>}
                  <li><Link onClick={()=>doIt('supplement')}>A&R LLCA</Link>{supplement === 'loading' &&<CircularProgress color="inherit" size={20} style={{marginLeft:5}}/>}</li>
                  <li><Link onClick={()=>doIt('subscription')}>Subscription Agreement</Link>{subscription === 'loading' &&<CircularProgress color="inherit" size={20} style={{marginLeft:5}}/>}</li>
                 </ul>
              </Grid>
            </Grid>
   </Content>
  </>;
}