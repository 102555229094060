import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ReactHtmlParser from 'react-html-parser';
import sanitizeHtml from 'sanitize-html';

const ExpandableBox = ({ text, maxLength, styles }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const canExpand = text && text.length > maxLength
  const displayText = (isExpanded || !canExpand) ? text : `${text.slice(0, maxLength)}...`;

  return (
    <Box sx={{...styles}}>
      <Box>{ReactHtmlParser(sanitizeHtml(displayText))}</Box>
      {canExpand &&
        <Box sx={{display: 'flex',justifyContent: 'center',marginTop:1}}>
          <Button size="small" onClick={toggleExpand} sx={{fontSize:'10px'}}>
            {isExpanded ? '- Collapse' : '+ Expand'}
          </Button>
        </Box>
      }
    </Box>
  );
};

export default ExpandableBox;