import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function CircularDeterminate() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 3));
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);


  return (
      <CircularProgress sx={{marginRight:2,marginBottom:2,marginLeft:2}} size={30} variant="determinate" value={progress} />
  );
}