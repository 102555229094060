import React, { useState } from 'react';
import { authenticatedFetch} from '../../utils/auth';
import Box from '@mui/material/Box';
import sv from '../../constants/Styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import {Editor,EditorProvider,Toolbar,BtnBold,BtnBulletList,BtnClearFormatting,BtnItalic,BtnLink,BtnNumberedList,BtnRedo,BtnStrikeThrough,BtnStyles,BtnUnderline,BtnUndo,HtmlButton,Separator,createButton} from 'react-simple-wysiwyg';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import AddIcon from '@mui/icons-material/Add';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import EditDeliveryDate from './EditDeliveryDate'

function JarvisOutreachGPT({jarvisOutreach,showJarvisOutreach,setShowJarvisOutreach}){
  const [activeStep, setActiveStep] = React.useState(0)
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  };
   
  return (
    <Dialog open={showJarvisOutreach} onClose={()=>setShowJarvisOutreach(false)} fullWidth={true} maxWidth={'md'}>
        <DialogTitle>Jarvis Outreach Suggestions</DialogTitle>
        <DialogContent>
          {Object.keys(jarvisOutreach).length > 0 ?
            <>
              <Typography sx={{fontWeight:600,color:'gray',marginBottom:1,marginTop:2,fontSize:sv.bodyIcon.fontSize*1.2}} color='primary'>{Object.keys(jarvisOutreach)[activeStep]}</Typography>
              {Object.values(jarvisOutreach)[activeStep].map((sug,i)=>(
                <Stack direction='row' key={i} alignItems="center" justifyContent='space-between'>
                  <Typography sx={{fontWeight:400,color:'primary',marginBottom:1,marginTop:2}} color='primary'>{sug}</Typography>
                  <IconButton
                    size="small"
                    onClick={() => {
                      document.execCommand('insertText', false, sug)
                      setShowJarvisOutreach(false)
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Stack>
              ))}
            
            
              
              <MobileStepper
                variant="dots"
                steps={Object.keys(jarvisOutreach).length || 0}
                position="static"
                activeStep={activeStep}
                sx={{ maxWidth: 400, flexGrow: 1,marginTop:20}}
                nextButton={
                  <Button size="small" onClick={handleNext} disabled={activeStep === (Object.keys(jarvisOutreach).length - 1)}>
                    Next
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    <KeyboardArrowLeft />
                    
                    Back
                  </Button>
                }
              />
            </>
            :
            <DialogContentText>Sorry, no suggestions found</DialogContentText>
            }
        </DialogContent>
    </Dialog>
  )
}

function JarvisOutreach({history,companyId,activeTask,setActiveTask,email,warning,setWarning,setSequencesLoading,setSequences,oid,setTasks,height}) {
  const [jarvisOutreach, setJarvisOutreach] = useState({})
  const [showJarvisOutreach, setShowJarvisOutreach] = useState(false)
  const [showEditDeliveryDate, setShowEditDeliveryDate] = useState(false)
  
  
  const jarvisInsert = () => {
    async function getJarvisOutreach() {
      setWarning({text:"Thinking...",severity:'info'})
      let url = `/companies/api/company/${companyId}/jarvis_outreach`
      let res = await authenticatedFetch(url, {method: 'GET'})
      setJarvisOutreach(res)
      setShowJarvisOutreach(true)
      setWarning({text:"Done!",severity:'success'})
    }
    
    if (Object.keys(jarvisOutreach).length === 0){
      getJarvisOutreach()
    } else {
      setShowJarvisOutreach(true)
    }
  };

  const JarvisOutreachButton = createButton('OutreachJarvis', <SmartToyOutlinedIcon />, jarvisInsert);
  
  function saveMailing(mid) {
    async function saveMail() {
      setWarning({text:'Saving...',severity:'info'})
      await authenticatedFetch(`/companies/api/outreach/mailing/${mid}/save`, {method: 'POST', 
         body: JSON.stringify({
           'body': activeTask.body,
           'subject':activeTask.subject
         })
       })
       setWarning({text:'Saved!',severity:'success'})
    }
    saveMail()
  }
 
  function sendMailing(mid,date) {
    async function sendMail() {
      setWarning({text:'Sending...',severity:'info'})
      setSequencesLoading && setSequencesLoading(true)
      let res = await authenticatedFetch(`/companies/api/outreach/mailing/${mid}/send`, {method: 'POST', 
         body: JSON.stringify({
           'body': activeTask.body,
           'subject':activeTask.subject,
           'oid':oid,
           'date':date
         })
       })
       setWarning({text:'Sent!',severity:'success'})
       setSequences && setSequences(res.sequences)
       setTasks(res.tasks)
       setActiveTask({})
       setSequencesLoading(false)
    }
    sendMail()
  }

  return (
    <Box sx={{backgroundColor:'white',padding:2}} height={height} overflow="scroll">
      <Stack>
        <IconButton sx={{width:10,float:'right'}} onClick={() => setActiveTask({})}><CloseIcon /></IconButton>
        <Stack direction='row'>
          <Typography sx={{fontWeight:700,fontSize:sv.bodyIcon.fontSize*1.1,textAlign:'left'}}>To:&nbsp;</Typography> 
          <Typography sx={{fontWeight:400,fontSize:sv.bodyIcon.fontSize*1.1,textAlign:'left'}}>{email}</Typography> 
        </Stack>
        <Stack direction='row' sx={{marginTop:1}}>
          <Typography sx={{fontWeight:700,fontSize:sv.bodyIcon.fontSize*1.1,textAlign:'left'}}>Subject:&nbsp;</Typography>
          <TextareaAutosize
              minRows={1}
              style={{width:'100%'}}
              value={activeTask?.subject || ''}
              onChange={(e)=>setActiveTask({...activeTask,subject:e.target.value})}
          />
        </Stack>
        <EditorProvider>
            <Editor 
               value={activeTask?.body} 
               onChange={(e)=>setActiveTask({...activeTask,body:e.target.value})} 
               containerProps={{ style: {backgroundColor:'white',marginTop:10,resize: 'vertical' }}}
            >
               <Toolbar>
                  <BtnUndo />
                  <BtnRedo />
                  <Separator />
                  <BtnBold />
                  <BtnItalic />
                  <BtnUnderline />
                  <BtnStrikeThrough />
                  <Separator />
                  <BtnNumberedList />
                  <BtnBulletList />
                  <Separator />
                  <BtnLink />
                  <BtnClearFormatting />
                  <HtmlButton />
                  <Separator />
                  <BtnStyles />
                  <Separator />
                  <JarvisOutreachButton />
                </Toolbar>
            </Editor>
        </EditorProvider>
        <Stack direction='row' sx={{marginTop:1}}>
           <Button size="small" onClick={()=>saveMailing(activeTask.mid)} variant='outlined' sx={{marginRight:2}}>Save</Button>
           <Button size="small" onClick={()=>sendMailing(activeTask.mid,null)} variant='contained' sx={{marginRight:2}}>Send</Button>
           <IconButton size="small" onClick={()=>setShowEditDeliveryDate(true)} variant='contained' sx={{marginRight:2}}><ScheduleSendIcon sx={{color:sv.colors.blue1}} /></IconButton>
        </Stack>
      </Stack>
      <JarvisOutreachGPT jarvisOutreach={jarvisOutreach} showJarvisOutreach={showJarvisOutreach} setShowJarvisOutreach={setShowJarvisOutreach} />
      <EditDeliveryDate open={showEditDeliveryDate} close={()=>setShowEditDeliveryDate(false)} sendMailing={sendMailing} activeTask={activeTask}/>
    </Box>
  );
}

export default JarvisOutreach;